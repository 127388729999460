.country-flag {
  max-width: 25px;
  border: 1px solid #f5f5f5;
  padding: 1px;
}
.rdt_TableHeader {
  font-size: 15px !important;
  padding-left: 0 !important;
  min-height: 20px !important;
  margin-bottom: 20px;
  color: #393f49 !important;
}
.highcharts-title {
  font-size: 15px !important;
  color: #393f49 !important;
}
.link-referrer {
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
    max-width: 400px;
}
