.list-group-item.link-wrapper,
.list-group-item.link-wrapper__header {
  display: flex;
  justify-content: flex-start;
  align-items: center;


  .creation-date {
    flex: 0 1 10%;
    position: relative;

    &:after {
      content: '';
      width: 1px;
      height: 100%;
      background-color: #e6eceb;
      position: absolute;
      top: 0;
      right: 18px;
    }
  }

  .header-element {
    &:after {
      content: none !important;
    }
  }

  .initial-url {
    flex: 0 1 25%;
    word-break: break-all;
    text-align: left;
    padding-right: 30px;
    position: relative;
    margin-right: 20px;

    &:hover {
      cursor: default;
    }

    &:after {
      content: '';
      width: 1px;
      height: 100%;
      background-color: #e6eceb;
      position: absolute;
      top: 0;
      right: 12px;
    }

    span {
      position: absolute;
      top: 80%;
      left: 21%;
      z-index: 20;

    }
  }

  .shorted-url {
    flex: 1 1 20%;
    margin-right: 20px;
    word-break: break-all;
    position: relative;

    &:after {
      content: '';
      width: 1px;
      height: 100%;
      background-color: #e6eceb;
      position: absolute;
      top: 0;
      right: 18px;
    }

    a {
      border-bottom: 1px dashed;
      transition: all 15ms;

      &:hover {
        border-bottom: 1px dashed #f8f9fa;
      }
    }
  }

  .url-description {
    flex: 1 1 15%;
    margin-right: 50px;
    position: relative;

    &:after {
      content: '';
      width: 1px;
      height: 100%;
      background-color: #e6eceb;
      position: absolute;
      top: 0;
      right: -18px;
    }

  }
  .url-description__wrapper {
        max-height: 94px;
    overflow: hidden;
  }

  .link-clicks {
    flex: 1 1 10%;
    text-align: center;
  }

  .link-tools {
    flex: 1 1 20%;
    text-align: right;
    display: flex;
    justify-content: flex-end;
    align-items: center;

    .tool-item {
      margin-right: 20px;
      cursor: pointer;

      &:last-of-type {
        margin-right: 0;
      }
    }
  }
}
