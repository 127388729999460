/*!
 * Name: RootUI - Dashboards and Administration areas for React + Redux and HTML
 * Version: 1.1.4
 * Author: dexad, nK
 * Website: https://nkdev.info/
 * Purchase: https://themeforest.net/user/_nk/portfolio
 * Support: https://nk.ticksy.com/
 * License: You must have a valid license purchased only from ThemeForest (the above link) in order to legally use the theme for your project.
 * Copyright 2020.
 */
/*------------------------------------------------------------------
  [Table of contents]
  
  1. Demo
  2. Base
  3. Preloader
  4. Typography
  5. Bootstrap
  6. Helpers
  7. Navbar
  8. Spotlight Mode
  9. Section lines
  10. Header
  11. Sign
  12. Error
  13. Mailbox
  14. Messenger
  15. Project Management
  16. File Manager
  17. Profile
  18. Footer
  19. Elements
    - Element Alert
    - Element Badge
    - Element Breadcrumb
    - Element Buttons
    - Element Card
    - Element Carousel
    - Element Collapse
    - Element Table
    - Element Pagination
    - Element Dropdown
    - Element Forms
    - Element List group
    - Element Media object
    - Element Modal
    - Element Navs
    - Element Popovers
    - Element Progress
    - Element Spinners
    - Element Toast
    - Element Blockquote
    - Element Search
    - Element Popup
    - Element Snippet
    - Element Timeline
    - Element Changelog
    - Element Kanban
    - Element Task
    - Element Icons
    - Element Content Boxes
    - Element Image Boxes
    - Element Widget
    - Element Social links
    - Element Box Window
    - Element Feature
  20. Plugins
    - Plugin Yay
    - Plugin Overlay Scrollbars
    - Plugin Emojione Area
    - Plugin Sortablejs
    - Plugin Swiper
    - Plugin Fullcalendar
    - Plugin Fancybox
    - Plugin Jstree
    - Plugin Sweetalert
    - Plugin Jqvmap
    - Plugin Datatable
    - Plugin DateTime Picker
    - Plugin DateRange Picker
    - Plugin Touchspin
    - Plugin Ion Range Slider
    - EasyMDE (Markdown)
    - Colorpicker
    - Plugin Chartist
    - Plugin Selectize
    - Plugin Peity (chart)
    - Plugin eCharts
    - Plugin Flot (chart)
    - Plugin Chartjs
    - Plugin Highlight
    - Plugin Quill
    - Plugin Dropzone
-------------------------------------------------------------------*/
/**
  [Typography]

  Body:       13px/1.8 Open Sans, sans-serif;
  Title:      Nunito Sans, sans-serif
  Blockquote: PT Serif, serif

  Note: Most of font sizes are relative to the base font size (rem)
 */
/*------------------------------------------------------------------

  Demo

 -------------------------------------------------------------------*/
.demo > .card,
.demo > .list-group {
    display: inline-block;
    width: 32rem;
    max-width: 100%;
}

.demo > .card .list-group-item,
.demo > .list-group .list-group-item {
    color: #6c757d;
}

.demo > .card .list-group-item.active,
.demo > .list-group .list-group-item.active {
    color: #fff;
}

.demo > .card .list-group-item.disabled,
.demo > .list-group .list-group-item.disabled {
    color: #50565c;
}

.demo > .table-responsive-md > .dataTables_wrapper > .rui-datatable {
    min-width: 600px;
}

.demo > .modal {
    position: static;
    display: block;
    background-color: rgba(234, 236, 240, 0.85);
}

.demo > .modal.fade {
    display: none;
}

.demo .spinner-border,
.demo .spinner-grow {
    margin-right: 10px;
}

.demo h6:last-child {
    margin-bottom: -3px;
}

.demo p:last-child {
    margin-bottom: -6px;
}

.demo .display-4:last-child {
    margin-bottom: -9px;
}

.demo .lead:last-child {
    margin-bottom: -8px;
}

.demo .blockquote:last-child {
    margin-bottom: 0;
}

.demo .table {
    margin-bottom: 0;
}

.demo .table + .table {
    margin-top: 20px;
}

.demo .progress + .progress {
    margin-top: 10px;
}

.demo .rui-touchspin {
    max-width: 250px;
}

.demo .rui-touchspin + .rui-touchspin {
    margin-top: 20px;
}

.demo-icons {
    display: -webkit-box;
    display: -webkit-flex;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-orient: vertical;
    -webkit-box-direction: normal;
    -webkit-flex-direction: column;
        -ms-flex-direction: column;
            flex-direction: column;
    -webkit-box-align: center;
    -webkit-align-items: center;
        -ms-flex-align: center;
            align-items: center;
    -webkit-box-pack: center;
    -webkit-justify-content: center;
        -ms-flex-pack: center;
            justify-content: center;
    padding: 25px 20px;
    color: #6c757d;
    text-align: center;
    cursor: pointer;
    background-color: #f8f9fa;
    border: 1px solid #f8f9fa;
    border-radius: .25rem;
    -webkit-transition: background-color .15s ease-in-out, border-color .15s ease-in-out;
    -o-transition: background-color .15s ease-in-out, border-color .15s ease-in-out;
    transition: background-color .15s ease-in-out, border-color .15s ease-in-out;
}

.demo-icons > .rui-icon {
    width: 26px;
    height: 26px;
    stroke: #393f49;
    margin-bottom: 15px;
}

.demo-icons:hover {
    background-color: #f3f4f7;
    border-color: #d3dde5;
}

.demo-colors {
    padding: 25px 30px;
    margin-top: 10px;
    border-radius: .25rem;
}

/*------------------------------------------------------------------

  Base

 -------------------------------------------------------------------*/
body {
    position: relative;
    height: 100%;
    min-height: 100vh;
    padding: 0;
    margin: 0;
    overflow-x: hidden;
    overflow-y: initial;
    background-color: #fff;
}

body.rui-no-transition * {
    -webkit-transition: none !important;
    -o-transition: none !important;
    transition: none !important;
}

.rui-main {
    overflow: hidden;
}

.rui-page {
    display: -webkit-box;
    display: -webkit-flex;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-orient: vertical;
    -webkit-box-direction: normal;
    -webkit-flex-direction: column;
        -ms-flex-direction: column;
            flex-direction: column;
    min-height: 100vh;
}

.rui-navbar-top ~ .rui-page {
    min-height: calc(100vh - 70px);
}

.rui-page .rui-footer {
    margin-top: auto;
}

.rui-page .rui-page-title {
    position: relative;
    display: block;
    padding: 30px 15px;
}

@media (max-width: 575.98px) {
    .rui-page .rui-page-title {
        padding: 30px 5px;
    }
}

.rui-page .rui-page-title nav + h1,
.rui-page .rui-page-title nav + .h1 {
    margin-top: 5px;
}

.rui-page .rui-page-title h1,
.rui-page .rui-page-title .h1 {
    margin-bottom: -10px;
}

.rui-page .rui-page-content {
    position: relative;
    display: block;
    padding: 30px 15px;
    z-index: 1;
}

@media (max-width: 575.98px) {
    .rui-page .rui-page-content {
        padding: 30px 5px;
    }
}

.rui-page .rui-page-sidebar-sticky {
    position: -webkit-sticky;
    position: sticky;
    top: 0;
    will-change: top;
    -webkit-transition: top .15s ease-in-out;
    -o-transition: top .15s ease-in-out;
    transition: top .15s ease-in-out;
}

.rui-page .rui-page-sidebar-sticky.rui-scrollbar .os-scrollbar {
    padding-right: 10px;
}

.rui-page .rui-page-sidebar-sticky.rui-scrollbar .os-scrollbar.os-scrollbar-vertical {
    width: 16px;
}

.rui-page .rui-page-sidebar-sticky.rui-scrollbar .os-scrollbar.os-scrollbar-horizontal {
    height: 8px;
}

.rui-page .rui-page-sidebar-sticky .rui-page-sidebar {
    padding-left: 15px;
    margin-left: -15px;
}

.rui-page .rui-page-sidebar {
    height: auto;
    max-height: 100vh;
    border-left: 1px solid #e6ecf0;
}

.rui-page .rui-page-sidebar > .nav {
    display: -webkit-box;
    display: -webkit-flex;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-orient: vertical;
    -webkit-box-direction: normal;
    -webkit-flex-direction: column;
        -ms-flex-direction: column;
            flex-direction: column;
    padding-top: 15px;
    padding-bottom: 15px;
    margin: 0;
    font-size: 13px;
    background-color: #fff;
}

.rui-page.rui-page-react {
    min-height: auto;
}

.rui-page.rui-page-react > div {
    display: -webkit-box;
    display: -webkit-flex;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-orient: vertical;
    -webkit-box-direction: normal;
    -webkit-flex-direction: column;
        -ms-flex-direction: column;
            flex-direction: column;
    min-height: 100vh;
}

.rui-navbar-top ~ .rui-page.rui-page-react {
    min-height: auto;
}

.rui-navbar-top ~ .rui-page.rui-page-react > div {
    min-height: calc(100vh - 70px);
}

@media (max-width: 767.98px) {
    body:not(.rui-section-lines).yay-hide .yaybar.yay-hide-to-small .yaybar-wrap > ul {
        padding-top: 0;
    }
    body:not(.rui-section-lines) .yaybar.yay-hide-to-small ~ .rui-navbar-mobile {
        left: 0;
    }
    body:not(.rui-section-lines) .yaybar.yay-hide-to-small ~ .rui-navbar-mobile .rui-navbar-head {
        -webkit-transition: padding-left .15s ease-in-out;
        -o-transition: padding-left .15s ease-in-out;
        transition: padding-left .15s ease-in-out;
    }
    body:not(.rui-section-lines) .yaybar.yay-hide-to-small ~ .rui-navbar-mobile:not(.rui-navbar-show) .rui-navbar-head {
        padding-left: 0;
    }
    body:not(.rui-section-lines) .yaybar.yay-hide-to-small ~ .content-wrap .rui-page-title {
        padding-top: 5px;
        padding-left: 0;
    }
    body:not(.rui-section-lines) .yaybar.yay-hide-to-small ~ .content-wrap .rui-page-title > .container,
    body:not(.rui-section-lines) .yaybar.yay-hide-to-small ~ .content-wrap .rui-page-title > .container-fluid {
        padding-left: 0;
    }
    body:not(.rui-section-lines) .yaybar.yay-hide-to-small ~ .content-wrap .rui-page-content {
        padding-left: 0;
    }
    body:not(.rui-section-lines) .yaybar.yay-hide-to-small ~ .content-wrap .rui-page-content > .container,
    body:not(.rui-section-lines) .yaybar.yay-hide-to-small ~ .content-wrap .rui-page-content > .container-fluid {
        padding-left: 0;
    }
    body:not(.rui-section-lines) .yaybar.yay-hide-to-small ~ .content-wrap .rui-footer,
    body:not(.rui-section-lines) .yaybar.yay-hide-to-small ~ .content-wrap .rui-footer .container,
    body:not(.rui-section-lines) .yaybar.yay-hide-to-small ~ .content-wrap .rui-footer .container-fluid {
        padding-left: 0;
    }
}

.rui-navbar-autohide .rui-page-sidebar-sticky {
    margin-top: -30px;
    -webkit-transform: translateY(0);
        -ms-transform: translateY(0);
            transform: translateY(0);
}

.rui-navbar-autohide.rui-navbar-show .rui-page-sidebar-sticky {
    top: 70px;
}

.rui-separator {
    display: block;
    border-bottom: 1px solid #e6ecf0;
}

.bg-image {
    position: absolute;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    overflow: hidden;
    background-position: 50% 50%;
    background-size: cover;
    z-index: -1;
}

.bg-image img {
    width: 100%;
    height: 100%;
    font-family: "object-fit: cover";
    -o-object-fit: cover;
       object-fit: cover;
}

.bg-image > * {
    position: absolute;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    background-position: 50% 50%;
    background-size: cover;
    z-index: 1;
}

.rui-gap,
.rui-gap-1,
.rui-gap-2,
.rui-gap-3,
.rui-gap-4,
.rui-gap-5,
.rui-gap-6 {
    display: block;
    height: 15px;
}

.rui-gap-1 {
    height: 25px;
}

.rui-gap-2 {
    height: 30px;
}

.rui-gap-3 {
    height: 40px;
}

.rui-gap-4 {
    height: 60px;
}

.rui-gap-5 {
    height: 80px;
}

.rui-gap-6 {
    height: 100px;
}

.rui-body-scrollbar-measure {
    position: absolute;
    top: -9999px;
    width: 50px;
    height: 50px;
    overflow: scroll;
}

.rui-gmap {
    min-height: 400px;
    border-radius: .25rem;
}

/*------------------------------------------------------------------

  Preloader

 -------------------------------------------------------------------*/
.rui-page-preloader {
    display: none;
}

.rui-page-preloader.rui-page-preloader-active {
    position: absolute;
    display: block;
    top: 0;
    right: 0;
    left: 0;
    height: 1px;
    opacity: 0;
    -webkit-transition: .3s opacity ease-in-out;
    -o-transition: .3s opacity ease-in-out;
    transition: .3s opacity ease-in-out;
    z-index: 1002;
}

.rui-page-loading-state .rui-page-preloader.rui-page-preloader-active {
    opacity: 1;
}

.rui-page-preloader.rui-page-preloader-active .rui-page-preloader-inner {
    display: -webkit-box;
    display: -webkit-flex;
    display: -ms-flexbox;
    display: flex;
    height: 100%;
    -webkit-transform: translateZ(0);
            transform: translateZ(0);
}

.rui-page-preloader.rui-page-preloader-active .rui-page-preloader-inner > div {
    -webkit-box-flex: 1;
    -webkit-flex: 1;
        -ms-flex: 1;
            flex: 1;
    background: #725ec3;
    -webkit-box-shadow: 0 2px 20px #725ec3;
            box-shadow: 0 2px 20px #725ec3;
    -webkit-animation: rui-preloader-animation 0.8s infinite alternate ease;
            animation: rui-preloader-animation 0.8s infinite alternate ease;
}

.rui-page-preloader.rui-page-preloader-active .rui-page-preloader-inner > div:nth-child(1) {
    -webkit-animation-delay: 0.08s;
            animation-delay: 0.08s;
}

.rui-page-preloader.rui-page-preloader-active .rui-page-preloader-inner > div:nth-child(2) {
    -webkit-animation-delay: 0.16s;
            animation-delay: 0.16s;
}

.rui-page-preloader.rui-page-preloader-active .rui-page-preloader-inner > div:nth-child(3) {
    -webkit-animation-delay: 0.24s;
            animation-delay: 0.24s;
}

.rui-page-preloader.rui-page-preloader-active .rui-page-preloader-inner > div:nth-child(4) {
    -webkit-animation-delay: 0.32s;
            animation-delay: 0.32s;
}

.rui-page-preloader.rui-page-preloader-active .rui-page-preloader-inner > div:nth-child(5) {
    -webkit-animation-delay: 0.4s;
            animation-delay: 0.4s;
}

.rui-page-preloader.rui-page-preloader-active .rui-page-preloader-inner > div:nth-child(6) {
    -webkit-animation-delay: 0.48s;
            animation-delay: 0.48s;
}

.rui-page-preloader.rui-page-preloader-active .rui-page-preloader-inner > div:nth-child(7) {
    -webkit-animation-delay: 0.56s;
            animation-delay: 0.56s;
}

.rui-page-preloader.rui-page-preloader-active .rui-page-preloader-inner > div:nth-child(8) {
    -webkit-animation-delay: 0.64s;
            animation-delay: 0.64s;
}

.rui-page-preloader.rui-page-preloader-active .rui-page-preloader-inner > div:nth-child(9) {
    -webkit-animation-delay: 0.72s;
            animation-delay: 0.72s;
}

.rui-page-preloader.rui-page-preloader-active .rui-page-preloader-inner > div:nth-child(10) {
    -webkit-animation-delay: 0.8s;
            animation-delay: 0.8s;
}

@-webkit-keyframes rui-preloader-animation {
    100% {
        -webkit-box-flex: 10;
        -webkit-flex: 10;
                flex: 10;
        background: transparent;
        -webkit-box-shadow: 0 0 0 transparent;
                box-shadow: 0 0 0 transparent;
    }
}

@keyframes rui-preloader-animation {
    100% {
        -webkit-box-flex: 10;
        -webkit-flex: 10;
            -ms-flex: 10;
                flex: 10;
        background: transparent;
        -webkit-box-shadow: 0 0 0 transparent;
                box-shadow: 0 0 0 transparent;
    }
}

/*------------------------------------------------------------------

  Typography

 -------------------------------------------------------------------*/
html {
    font-size: 13px;
}

body {
    font-family: "Open Sans", sans-serif;
    line-height: 1.8;
    color: #4b515b;
    letter-spacing: normal;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
}

a {
    color: #725ec3;
    -webkit-transition: color .1s ease-in-out;
    -o-transition: color .1s ease-in-out;
    transition: color .1s ease-in-out;
}

a:hover, a.hover {
    color: #443388;
    text-decoration: none;
}

a:active {
    color: #725ec3;
}

p {
    margin-top: -5px;
    margin-bottom: 1.8rem;
}

b,
strong {
    font-weight: 600;
}

small {
    font-size: 85%;
}

label {
    margin-bottom: .7rem;
    font-weight: 400;
    color: #6c757d;
}

pre {
    display: -webkit-box;
    display: -webkit-flex;
    display: -ms-flexbox;
    display: flex;
    -webkit-flex-wrap: wrap;
        -ms-flex-wrap: wrap;
            flex-wrap: wrap;
}

code:not(.hljs) {
    display: inline-block;
    padding: 3px 5px;
    color: #6c757d;
    background-color: #f8f9fa;
    border-radius: .2rem;
}

mark,
.mark {
    padding: 3px 6px;
    border-radius: .2rem;
}

/* headings */
h1,
h2,
h3,
h4,
h5,
h6,
.h1,
.h2,
.h3,
.h4,
.h5,
.h6 {
    margin-bottom: 1.7rem;
    font-family: "Nunito Sans", sans-serif;
    font-weight: 400;
    line-height: 1.5;
    color: #393f49;
    text-transform: none;
    letter-spacing: normal;
}

h1,
.h1 {
    margin-top: -5px;
    font-size: 1.846rem;
}

h2,
.h2 {
    margin-top: -4px;
    font-size: 1.538rem;
}

h3,
.h3 {
    margin-top: -3px;
    font-size: 1.385rem;
}

h4,
.h4 {
    margin-top: -6px;
    font-size: 1.231rem;
}

h5,
.h5 {
    margin-top: -2px;
    font-size: 1.077rem;
}

h6,
.h6 {
    margin-top: -2px;
    font-size: .923rem;
}

.display-1,
.display-2,
.display-3,
.display-4 {
    font-weight: 300;
    text-transform: none;
    letter-spacing: normal;
}

.display-1 {
    margin-top: -11px;
    margin-bottom: 1.15rem;
    font-size: 3.846rem;
}

.display-2 {
    margin-top: -9px;
    margin-bottom: 1.25rem;
    font-size: 3.385rem;
}

.display-3 {
    margin-top: -7px;
    margin-bottom: 1.45rem;
    font-size: 2.769rem;
}

.display-4 {
    margin-top: -5px;
    margin-bottom: 1.55rem;
    font-size: 2.154rem;
}

/* Lead */
.lead {
    margin-top: -7px;
    margin-bottom: 1.65rem;
    font-size: 1.385rem;
    font-weight: 300;
}

/*------------------------------------------------------------------

  Bootstrap

 -------------------------------------------------------------------*/
.row.vertical-gap {
    margin-top: -30px;
}

.row.vertical-gap > .col,
.row.vertical-gap > [class*="col-"] {
    padding-top: 30px;
}

.row.xs-gap {
    margin-right: -5px;
    margin-left: -5px;
}

.row.xs-gap > .col,
.row.xs-gap > [class*="col-"] {
    padding-right: 5px;
    padding-left: 5px;
}

.row.xs-gap.vertical-gap {
    margin-top: -10px;
}

.row.xs-gap.vertical-gap > .col,
.row.xs-gap.vertical-gap > [class*="col-"] {
    padding-top: 10px;
}

.row.sm-gap {
    margin-right: -10px;
    margin-left: -10px;
}

.row.sm-gap > .col,
.row.sm-gap > [class*="col-"] {
    padding-right: 10px;
    padding-left: 10px;
}

.row.sm-gap.vertical-gap {
    margin-top: -20px;
}

.row.sm-gap.vertical-gap > .col,
.row.sm-gap.vertical-gap > [class*="col-"] {
    padding-top: 20px;
}

.row.md-gap {
    margin-right: -20px;
    margin-left: -20px;
}

.row.md-gap > .col,
.row.md-gap > [class*="col-"] {
    padding-right: 20px;
    padding-left: 20px;
}

.row.md-gap.vertical-gap {
    margin-top: -40px;
}

.row.md-gap.vertical-gap > .col,
.row.md-gap.vertical-gap > [class*="col-"] {
    padding-top: 40px;
}

.row.lg-gap {
    margin-right: -30px;
    margin-left: -30px;
}

.row.lg-gap > .col,
.row.lg-gap > [class*="col-"] {
    padding-right: 30px;
    padding-left: 30px;
}

.row.lg-gap.vertical-gap {
    margin-top: -60px;
}

.row.lg-gap.vertical-gap > .col,
.row.lg-gap.vertical-gap > [class*="col-"] {
    padding-top: 60px;
}

/*------------------------------------------------------------------

  Helpers

 -------------------------------------------------------------------*/
.rounded {
    border-radius: .25rem !important;
}

.border {
    border-color: #f3f4f7 !important;
}

/*
 * Text Color Helper
 */
.text-white {
    color: #fff !important;
}

.text-black {
    color: #000 !important;
}

.text-1 {
    color: #393f49 !important;
}

.text-2 {
    color: #4b515b !important;
}

.text-grey-1 {
    color: #f8f9fa !important;
}

.text-grey-2 {
    color: #f3f4f7 !important;
}

.text-grey-3 {
    color: #eaecf0 !important;
}

.text-grey-4 {
    color: #d7d9e0 !important;
}

.text-grey-5 {
    color: #bcbec0 !important;
}

.text-grey-6 {
    color: #a4a6a8 !important;
}

/*
 * Bg Color Helper
 */
.bg-white {
    background-color: #fff !important;
}

.bg-black {
    background-color: #000 !important;
}

.bg-1 {
    background-color: #393f49 !important;
}

.bg-2 {
    background-color: #4b515b !important;
}

.bg-grey-1 {
    background-color: #f8f9fa !important;
}

.bg-grey-2 {
    background-color: #f3f4f7 !important;
}

.bg-grey-3 {
    background-color: #eaecf0 !important;
}

.bg-grey-4 {
    background-color: #d7d9e0 !important;
}

.bg-grey-5 {
    background-color: #bcbec0 !important;
}

.bg-grey-6 {
    background-color: #a4a6a8 !important;
}

/*
 * Image Fit
 */
.rui-img {
    max-width: 100%;
    height: auto;
}

.rui-img img {
    max-width: 100%;
    height: auto;
}

/*
 * Text Align Helper
 */
.text-left {
    text-align: left;
}

.text-center {
    text-align: center;
}

.text-right {
    text-align: right;
}

.text-justify {
    text-align: justify;
}

/*
 * Inline Block Helper
 */
.dib {
    display: inline-block !important;
}

/*
 * Block Helper
 */
.db {
    display: block !important;
    width: 100%;
}

/*
 * Text Transform Helper
 */
.text-uppercase {
    text-transform: uppercase !important;
}

/*
 * White-space Helper
 */
.wsw {
    white-space: normal !important;
}

.wsnw {
    white-space: nowrap !important;
}

.wsp {
    white-space: pre !important;
}

/*
 * Width Helper
 */
.w-5 {
    width: 5% !important;
}

.w-10 {
    width: 10% !important;
}

.w-15 {
    width: 15% !important;
}

.w-20 {
    width: 20% !important;
}

.w-25 {
    width: 25% !important;
}

.w-30 {
    width: 30% !important;
}

.w-35 {
    width: 35% !important;
}

.w-40 {
    width: 40% !important;
}

.w-45 {
    width: 45% !important;
}

.w-50 {
    width: 50% !important;
}

.w-75 {
    width: 75% !important;
}

.w-100 {
    width: 100% !important;
}

/*
 * Font Weight Helper
 */
.fw-100 {
    font-weight: 100 !important;
}

.fw-200 {
    font-weight: 200 !important;
}

.fw-300 {
    font-weight: 300 !important;
}

.fw-400 {
    font-weight: 400 !important;
}

.fw-500 {
    font-weight: 500 !important;
}

.fw-600 {
    font-weight: 600 !important;
}

.fw-700 {
    font-weight: 700 !important;
}

.fw-800 {
    font-weight: 800 !important;
}

.fw-900 {
    font-weight: 900 !important;
}

/*
 * Font Size Helper
 */
.fs-4 {
    font-size: 4px !important;
}

.fs-5 {
    font-size: 5px !important;
}

.fs-6 {
    font-size: 6px !important;
}

.fs-7 {
    font-size: 7px !important;
}

.fs-8 {
    font-size: 8px !important;
}

.fs-9 {
    font-size: 9px !important;
}

.fs-10 {
    font-size: 10px !important;
}

.fs-11 {
    font-size: 11px !important;
}

.fs-12 {
    font-size: 12px !important;
}

.fs-13 {
    font-size: 13px !important;
}

.fs-14 {
    font-size: 14px !important;
}

.fs-15 {
    font-size: 15px !important;
}

.fs-16 {
    font-size: 16px !important;
}

.fs-17 {
    font-size: 17px !important;
}

.fs-18 {
    font-size: 18px !important;
}

.fs-19 {
    font-size: 19px !important;
}

.fs-20 {
    font-size: 20px !important;
}

.fs-22 {
    font-size: 22px !important;
}

.fs-24 {
    font-size: 24px !important;
}

.fs-26 {
    font-size: 26px !important;
}

.fs-28 {
    font-size: 28px !important;
}

.fs-30 {
    font-size: 30px !important;
}

.fs-35 {
    font-size: 35px !important;
}

.fs-40 {
    font-size: 40px !important;
}

.fs-45 {
    font-size: 45px !important;
}

.fs-50 {
    font-size: 50px !important;
}

/*
 * Line Height Helper
 */
.lh-0 {
    line-height: 0px !important;
}

.lh-5 {
    line-height: 5px !important;
}

.lh-10 {
    line-height: 10px !important;
}

.lh-15 {
    line-height: 15px !important;
}

.lh-20 {
    line-height: 20px !important;
}

.lh-25 {
    line-height: 25px !important;
}

.lh-30 {
    line-height: 30px !important;
}

.lh-35 {
    line-height: 35px !important;
}

.lh-40 {
    line-height: 40px !important;
}

.lh-45 {
    line-height: 45px !important;
}

.lh-50 {
    line-height: 50px !important;
}

/*
 * Border Radius Helper
 */
.br-0 {
    border-radius: 0px !important;
}

.br-1 {
    border-radius: 1px !important;
}

.br-2 {
    border-radius: 2px !important;
}

.br-3 {
    border-radius: 3px !important;
}

.br-4 {
    border-radius: 4px !important;
}

.br-5 {
    border-radius: 5px !important;
}

.br-6 {
    border-radius: 6px !important;
}

.br-7 {
    border-radius: 7px !important;
}

.br-8 {
    border-radius: 8px !important;
}

.br-9 {
    border-radius: 9px !important;
}

.br-10 {
    border-radius: 10px !important;
}

.br-12 {
    border-radius: 12px !important;
}

.br-14 {
    border-radius: 14px !important;
}

.br-16 {
    border-radius: 16px !important;
}

.br-18 {
    border-radius: 18px !important;
}

.br-20 {
    border-radius: 20px !important;
}

.br-25 {
    border-radius: 25px !important;
}

.br-30 {
    border-radius: 30px !important;
}

.br-35 {
    border-radius: 35px !important;
}

/*
 * Paddings Helper
 */
.p-0 {
    padding: 0px !important;
}

.p-1 {
    padding: 1px !important;
}

.p-2 {
    padding: 2px !important;
}

.p-3 {
    padding: 3px !important;
}

.p-4 {
    padding: 4px !important;
}

.p-5 {
    padding: 5px !important;
}

.p-6 {
    padding: 6px !important;
}

.p-7 {
    padding: 7px !important;
}

.p-8 {
    padding: 8px !important;
}

.p-9 {
    padding: 9px !important;
}

.p-10 {
    padding: 10px !important;
}

.p-15 {
    padding: 15px !important;
}

.p-20 {
    padding: 20px !important;
}

.p-25 {
    padding: 25px !important;
}

.p-30 {
    padding: 30px !important;
}

.p-35 {
    padding: 35px !important;
}

.p-40 {
    padding: 40px !important;
}

.p-45 {
    padding: 45px !important;
}

.p-50 {
    padding: 50px !important;
}

.p-60 {
    padding: 60px !important;
}

.p-70 {
    padding: 70px !important;
}

.p-80 {
    padding: 80px !important;
}

.p-85 {
    padding: 85px !important;
}

.p-90 {
    padding: 90px !important;
}

.p-100 {
    padding: 100px !important;
}

.pt-0 {
    padding-top: 0px !important;
}

.pt-1 {
    padding-top: 1px !important;
}

.pt-2 {
    padding-top: 2px !important;
}

.pt-3 {
    padding-top: 3px !important;
}

.pt-4 {
    padding-top: 4px !important;
}

.pt-5 {
    padding-top: 5px !important;
}

.pt-6 {
    padding-top: 6px !important;
}

.pt-7 {
    padding-top: 7px !important;
}

.pt-8 {
    padding-top: 8px !important;
}

.pt-9 {
    padding-top: 9px !important;
}

.pt-10 {
    padding-top: 10px !important;
}

.pt-15 {
    padding-top: 15px !important;
}

.pt-20 {
    padding-top: 20px !important;
}

.pt-25 {
    padding-top: 25px !important;
}

.pt-30 {
    padding-top: 30px !important;
}

.pt-35 {
    padding-top: 35px !important;
}

.pt-40 {
    padding-top: 40px !important;
}

.pt-45 {
    padding-top: 45px !important;
}

.pt-50 {
    padding-top: 50px !important;
}

.pt-60 {
    padding-top: 60px !important;
}

.pt-70 {
    padding-top: 70px !important;
}

.pt-80 {
    padding-top: 80px !important;
}

.pt-85 {
    padding-top: 85px !important;
}

.pt-90 {
    padding-top: 90px !important;
}

.pt-100 {
    padding-top: 100px !important;
}

.pl-0 {
    padding-left: 0px !important;
}

.pl-1 {
    padding-left: 1px !important;
}

.pl-2 {
    padding-left: 2px !important;
}

.pl-3 {
    padding-left: 3px !important;
}

.pl-4 {
    padding-left: 4px !important;
}

.pl-5 {
    padding-left: 5px !important;
}

.pl-6 {
    padding-left: 6px !important;
}

.pl-7 {
    padding-left: 7px !important;
}

.pl-8 {
    padding-left: 8px !important;
}

.pl-9 {
    padding-left: 9px !important;
}

.pl-10 {
    padding-left: 10px !important;
}

.pl-15 {
    padding-left: 15px !important;
}

.pl-20 {
    padding-left: 20px !important;
}

.pl-25 {
    padding-left: 25px !important;
}

.pl-30 {
    padding-left: 30px !important;
}

.pl-35 {
    padding-left: 35px !important;
}

.pl-40 {
    padding-left: 40px !important;
}

.pl-45 {
    padding-left: 45px !important;
}

.pl-50 {
    padding-left: 50px !important;
}

.pl-60 {
    padding-left: 60px !important;
}

.pl-70 {
    padding-left: 70px !important;
}

.pl-80 {
    padding-left: 80px !important;
}

.pl-85 {
    padding-left: 85px !important;
}

.pl-90 {
    padding-left: 90px !important;
}

.pl-100 {
    padding-left: 100px !important;
}

.pr-0 {
    padding-right: 0px !important;
}

.pr-1 {
    padding-right: 1px !important;
}

.pr-2 {
    padding-right: 2px !important;
}

.pr-3 {
    padding-right: 3px !important;
}

.pr-4 {
    padding-right: 4px !important;
}

.pr-5 {
    padding-right: 5px !important;
}

.pr-6 {
    padding-right: 6px !important;
}

.pr-7 {
    padding-right: 7px !important;
}

.pr-8 {
    padding-right: 8px !important;
}

.pr-9 {
    padding-right: 9px !important;
}

.pr-10 {
    padding-right: 10px !important;
}

.pr-15 {
    padding-right: 15px !important;
}

.pr-20 {
    padding-right: 20px !important;
}

.pr-25 {
    padding-right: 25px !important;
}

.pr-30 {
    padding-right: 30px !important;
}

.pr-35 {
    padding-right: 35px !important;
}

.pr-40 {
    padding-right: 40px !important;
}

.pr-45 {
    padding-right: 45px !important;
}

.pr-50 {
    padding-right: 50px !important;
}

.pr-60 {
    padding-right: 60px !important;
}

.pr-70 {
    padding-right: 70px !important;
}

.pr-80 {
    padding-right: 80px !important;
}

.pr-85 {
    padding-right: 85px !important;
}

.pr-90 {
    padding-right: 90px !important;
}

.pr-100 {
    padding-right: 100px !important;
}

.pb-0 {
    padding-bottom: 0px !important;
}

.pb-1 {
    padding-bottom: 1px !important;
}

.pb-2 {
    padding-bottom: 2px !important;
}

.pb-3 {
    padding-bottom: 3px !important;
}

.pb-4 {
    padding-bottom: 4px !important;
}

.pb-5 {
    padding-bottom: 5px !important;
}

.pb-6 {
    padding-bottom: 6px !important;
}

.pb-7 {
    padding-bottom: 7px !important;
}

.pb-8 {
    padding-bottom: 8px !important;
}

.pb-9 {
    padding-bottom: 9px !important;
}

.pb-10 {
    padding-bottom: 10px !important;
}

.pb-15 {
    padding-bottom: 15px !important;
}

.pb-20 {
    padding-bottom: 20px !important;
}

.pb-25 {
    padding-bottom: 25px !important;
}

.pb-30 {
    padding-bottom: 30px !important;
}

.pb-35 {
    padding-bottom: 35px !important;
}

.pb-40 {
    padding-bottom: 40px !important;
}

.pb-45 {
    padding-bottom: 45px !important;
}

.pb-50 {
    padding-bottom: 50px !important;
}

.pb-60 {
    padding-bottom: 60px !important;
}

.pb-70 {
    padding-bottom: 70px !important;
}

.pb-80 {
    padding-bottom: 80px !important;
}

.pb-85 {
    padding-bottom: 85px !important;
}

.pb-90 {
    padding-bottom: 90px !important;
}

.pb-100 {
    padding-bottom: 100px !important;
}

.py-0 {
    padding-top: 0px !important;
    padding-bottom: 0px !important;
}

.py-1 {
    padding-top: 1px !important;
    padding-bottom: 1px !important;
}

.py-2 {
    padding-top: 2px !important;
    padding-bottom: 2px !important;
}

.py-3 {
    padding-top: 3px !important;
    padding-bottom: 3px !important;
}

.py-4 {
    padding-top: 4px !important;
    padding-bottom: 4px !important;
}

.py-5 {
    padding-top: 5px !important;
    padding-bottom: 5px !important;
}

.py-6 {
    padding-top: 6px !important;
    padding-bottom: 6px !important;
}

.py-7 {
    padding-top: 7px !important;
    padding-bottom: 7px !important;
}

.py-8 {
    padding-top: 8px !important;
    padding-bottom: 8px !important;
}

.py-9 {
    padding-top: 9px !important;
    padding-bottom: 9px !important;
}

.py-10 {
    padding-top: 10px !important;
    padding-bottom: 10px !important;
}

.py-15 {
    padding-top: 15px !important;
    padding-bottom: 15px !important;
}

.py-20 {
    padding-top: 20px !important;
    padding-bottom: 20px !important;
}

.py-25 {
    padding-top: 25px !important;
    padding-bottom: 25px !important;
}

.py-30 {
    padding-top: 30px !important;
    padding-bottom: 30px !important;
}

.py-35 {
    padding-top: 35px !important;
    padding-bottom: 35px !important;
}

.py-40 {
    padding-top: 40px !important;
    padding-bottom: 40px !important;
}

.py-45 {
    padding-top: 45px !important;
    padding-bottom: 45px !important;
}

.py-50 {
    padding-top: 50px !important;
    padding-bottom: 50px !important;
}

.py-60 {
    padding-top: 60px !important;
    padding-bottom: 60px !important;
}

.py-70 {
    padding-top: 70px !important;
    padding-bottom: 70px !important;
}

.py-80 {
    padding-top: 80px !important;
    padding-bottom: 80px !important;
}

.py-85 {
    padding-top: 85px !important;
    padding-bottom: 85px !important;
}

.py-90 {
    padding-top: 90px !important;
    padding-bottom: 90px !important;
}

.py-100 {
    padding-top: 100px !important;
    padding-bottom: 100px !important;
}

.px-0 {
    padding-right: 0px !important;
    padding-left: 0px !important;
}

.px-1 {
    padding-right: 1px !important;
    padding-left: 1px !important;
}

.px-2 {
    padding-right: 2px !important;
    padding-left: 2px !important;
}

.px-3 {
    padding-right: 3px !important;
    padding-left: 3px !important;
}

.px-4 {
    padding-right: 4px !important;
    padding-left: 4px !important;
}

.px-5 {
    padding-right: 5px !important;
    padding-left: 5px !important;
}

.px-6 {
    padding-right: 6px !important;
    padding-left: 6px !important;
}

.px-7 {
    padding-right: 7px !important;
    padding-left: 7px !important;
}

.px-8 {
    padding-right: 8px !important;
    padding-left: 8px !important;
}

.px-9 {
    padding-right: 9px !important;
    padding-left: 9px !important;
}

.px-10 {
    padding-right: 10px !important;
    padding-left: 10px !important;
}

.px-15 {
    padding-right: 15px !important;
    padding-left: 15px !important;
}

.px-20 {
    padding-right: 20px !important;
    padding-left: 20px !important;
}

.px-25 {
    padding-right: 25px !important;
    padding-left: 25px !important;
}

.px-30 {
    padding-right: 30px !important;
    padding-left: 30px !important;
}

.px-35 {
    padding-right: 35px !important;
    padding-left: 35px !important;
}

.px-40 {
    padding-right: 40px !important;
    padding-left: 40px !important;
}

.px-45 {
    padding-right: 45px !important;
    padding-left: 45px !important;
}

.px-50 {
    padding-right: 50px !important;
    padding-left: 50px !important;
}

.px-60 {
    padding-right: 60px !important;
    padding-left: 60px !important;
}

.px-70 {
    padding-right: 70px !important;
    padding-left: 70px !important;
}

.px-80 {
    padding-right: 80px !important;
    padding-left: 80px !important;
}

.px-85 {
    padding-right: 85px !important;
    padding-left: 85px !important;
}

.px-90 {
    padding-right: 90px !important;
    padding-left: 90px !important;
}

.px-100 {
    padding-right: 100px !important;
    padding-left: 100px !important;
}

/*
 * Margins Helper
 */
.m-0 {
    margin: 0px !important;
}

.m-1 {
    margin: 1px !important;
}

.m-2 {
    margin: 2px !important;
}

.m-3 {
    margin: 3px !important;
}

.m-4 {
    margin: 4px !important;
}

.m-5 {
    margin: 5px !important;
}

.m-6 {
    margin: 6px !important;
}

.m-7 {
    margin: 7px !important;
}

.m-8 {
    margin: 8px !important;
}

.m-9 {
    margin: 9px !important;
}

.m-10 {
    margin: 10px !important;
}

.m-13 {
    margin: 13px !important;
}

.m-15 {
    margin: 15px !important;
}

.m-18 {
    margin: 18px !important;
}

.m-20 {
    margin: 20px !important;
}

.m-25 {
    margin: 25px !important;
}

.m-30 {
    margin: 30px !important;
}

.m-35 {
    margin: 35px !important;
}

.m-40 {
    margin: 40px !important;
}

.m-45 {
    margin: 45px !important;
}

.m-50 {
    margin: 50px !important;
}

.m-55 {
    margin: 55px !important;
}

.m-60 {
    margin: 60px !important;
}

.m-70 {
    margin: 70px !important;
}

.m-80 {
    margin: 80px !important;
}

.m-90 {
    margin: 90px !important;
}

.m-100 {
    margin: 100px !important;
}

.mt-0 {
    margin-top: 0px !important;
}

.mt-1 {
    margin-top: 1px !important;
}

.mt-2 {
    margin-top: 2px !important;
}

.mt-3 {
    margin-top: 3px !important;
}

.mt-4 {
    margin-top: 4px !important;
}

.mt-5 {
    margin-top: 5px !important;
}

.mt-6 {
    margin-top: 6px !important;
}

.mt-7 {
    margin-top: 7px !important;
}

.mt-8 {
    margin-top: 8px !important;
}

.mt-9 {
    margin-top: 9px !important;
}

.mt-10 {
    margin-top: 10px !important;
}

.mt-13 {
    margin-top: 13px !important;
}

.mt-15 {
    margin-top: 15px !important;
}

.mt-18 {
    margin-top: 18px !important;
}

.mt-20 {
    margin-top: 20px !important;
}

.mt-25 {
    margin-top: 25px !important;
}

.mt-30 {
    margin-top: 30px !important;
}

.mt-35 {
    margin-top: 35px !important;
}

.mt-40 {
    margin-top: 40px !important;
}

.mt-45 {
    margin-top: 45px !important;
}

.mt-50 {
    margin-top: 50px !important;
}

.mt-55 {
    margin-top: 55px !important;
}

.mt-60 {
    margin-top: 60px !important;
}

.mt-70 {
    margin-top: 70px !important;
}

.mt-80 {
    margin-top: 80px !important;
}

.mt-90 {
    margin-top: 90px !important;
}

.mt-100 {
    margin-top: 100px !important;
}

.ml-0 {
    margin-left: 0px !important;
}

.ml-1 {
    margin-left: 1px !important;
}

.ml-2 {
    margin-left: 2px !important;
}

.ml-3 {
    margin-left: 3px !important;
}

.ml-4 {
    margin-left: 4px !important;
}

.ml-5 {
    margin-left: 5px !important;
}

.ml-6 {
    margin-left: 6px !important;
}

.ml-7 {
    margin-left: 7px !important;
}

.ml-8 {
    margin-left: 8px !important;
}

.ml-9 {
    margin-left: 9px !important;
}

.ml-10 {
    margin-left: 10px !important;
}

.ml-13 {
    margin-left: 13px !important;
}

.ml-15 {
    margin-left: 15px !important;
}

.ml-18 {
    margin-left: 18px !important;
}

.ml-20 {
    margin-left: 20px !important;
}

.ml-25 {
    margin-left: 25px !important;
}

.ml-30 {
    margin-left: 30px !important;
}

.ml-35 {
    margin-left: 35px !important;
}

.ml-40 {
    margin-left: 40px !important;
}

.ml-45 {
    margin-left: 45px !important;
}

.ml-50 {
    margin-left: 50px !important;
}

.ml-55 {
    margin-left: 55px !important;
}

.ml-60 {
    margin-left: 60px !important;
}

.ml-70 {
    margin-left: 70px !important;
}

.ml-80 {
    margin-left: 80px !important;
}

.ml-90 {
    margin-left: 90px !important;
}

.ml-100 {
    margin-left: 100px !important;
}

.mr-0 {
    margin-right: 0px !important;
}

.mr-1 {
    margin-right: 1px !important;
}

.mr-2 {
    margin-right: 2px !important;
}

.mr-3 {
    margin-right: 3px !important;
}

.mr-4 {
    margin-right: 4px !important;
}

.mr-5 {
    margin-right: 5px !important;
}

.mr-6 {
    margin-right: 6px !important;
}

.mr-7 {
    margin-right: 7px !important;
}

.mr-8 {
    margin-right: 8px !important;
}

.mr-9 {
    margin-right: 9px !important;
}

.mr-10 {
    margin-right: 10px !important;
}

.mr-13 {
    margin-right: 13px !important;
}

.mr-15 {
    margin-right: 15px !important;
}

.mr-18 {
    margin-right: 18px !important;
}

.mr-20 {
    margin-right: 20px !important;
}

.mr-25 {
    margin-right: 25px !important;
}

.mr-30 {
    margin-right: 30px !important;
}

.mr-35 {
    margin-right: 35px !important;
}

.mr-40 {
    margin-right: 40px !important;
}

.mr-45 {
    margin-right: 45px !important;
}

.mr-50 {
    margin-right: 50px !important;
}

.mr-55 {
    margin-right: 55px !important;
}

.mr-60 {
    margin-right: 60px !important;
}

.mr-70 {
    margin-right: 70px !important;
}

.mr-80 {
    margin-right: 80px !important;
}

.mr-90 {
    margin-right: 90px !important;
}

.mr-100 {
    margin-right: 100px !important;
}

.mb-0 {
    margin-bottom: 0px !important;
}

.mb-1 {
    margin-bottom: 1px !important;
}

.mb-2 {
    margin-bottom: 2px !important;
}

.mb-3 {
    margin-bottom: 3px !important;
}

.mb-4 {
    margin-bottom: 4px !important;
}

.mb-5 {
    margin-bottom: 5px !important;
}

.mb-6 {
    margin-bottom: 6px !important;
}

.mb-7 {
    margin-bottom: 7px !important;
}

.mb-8 {
    margin-bottom: 8px !important;
}

.mb-9 {
    margin-bottom: 9px !important;
}

.mb-10 {
    margin-bottom: 10px !important;
}

.mb-13 {
    margin-bottom: 13px !important;
}

.mb-15 {
    margin-bottom: 15px !important;
}

.mb-18 {
    margin-bottom: 18px !important;
}

.mb-20 {
    margin-bottom: 20px !important;
}

.mb-25 {
    margin-bottom: 25px !important;
}

.mb-30 {
    margin-bottom: 30px !important;
}

.mb-35 {
    margin-bottom: 35px !important;
}

.mb-40 {
    margin-bottom: 40px !important;
}

.mb-45 {
    margin-bottom: 45px !important;
}

.mb-50 {
    margin-bottom: 50px !important;
}

.mb-55 {
    margin-bottom: 55px !important;
}

.mb-60 {
    margin-bottom: 60px !important;
}

.mb-70 {
    margin-bottom: 70px !important;
}

.mb-80 {
    margin-bottom: 80px !important;
}

.mb-90 {
    margin-bottom: 90px !important;
}

.mb-100 {
    margin-bottom: 100px !important;
}

.my-0 {
    margin-top: 0px !important;
    margin-bottom: 0px !important;
}

.my-1 {
    margin-top: 1px !important;
    margin-bottom: 1px !important;
}

.my-2 {
    margin-top: 2px !important;
    margin-bottom: 2px !important;
}

.my-3 {
    margin-top: 3px !important;
    margin-bottom: 3px !important;
}

.my-4 {
    margin-top: 4px !important;
    margin-bottom: 4px !important;
}

.my-5 {
    margin-top: 5px !important;
    margin-bottom: 5px !important;
}

.my-6 {
    margin-top: 6px !important;
    margin-bottom: 6px !important;
}

.my-7 {
    margin-top: 7px !important;
    margin-bottom: 7px !important;
}

.my-8 {
    margin-top: 8px !important;
    margin-bottom: 8px !important;
}

.my-9 {
    margin-top: 9px !important;
    margin-bottom: 9px !important;
}

.my-10 {
    margin-top: 10px !important;
    margin-bottom: 10px !important;
}

.my-13 {
    margin-top: 13px !important;
    margin-bottom: 13px !important;
}

.my-15 {
    margin-top: 15px !important;
    margin-bottom: 15px !important;
}

.my-18 {
    margin-top: 18px !important;
    margin-bottom: 18px !important;
}

.my-20 {
    margin-top: 20px !important;
    margin-bottom: 20px !important;
}

.my-25 {
    margin-top: 25px !important;
    margin-bottom: 25px !important;
}

.my-30 {
    margin-top: 30px !important;
    margin-bottom: 30px !important;
}

.my-35 {
    margin-top: 35px !important;
    margin-bottom: 35px !important;
}

.my-40 {
    margin-top: 40px !important;
    margin-bottom: 40px !important;
}

.my-45 {
    margin-top: 45px !important;
    margin-bottom: 45px !important;
}

.my-50 {
    margin-top: 50px !important;
    margin-bottom: 50px !important;
}

.my-55 {
    margin-top: 55px !important;
    margin-bottom: 55px !important;
}

.my-60 {
    margin-top: 60px !important;
    margin-bottom: 60px !important;
}

.my-70 {
    margin-top: 70px !important;
    margin-bottom: 70px !important;
}

.my-80 {
    margin-top: 80px !important;
    margin-bottom: 80px !important;
}

.my-90 {
    margin-top: 90px !important;
    margin-bottom: 90px !important;
}

.my-100 {
    margin-top: 100px !important;
    margin-bottom: 100px !important;
}

.mx-0 {
    margin-right: 0px !important;
    margin-left: 0px !important;
}

.mx-1 {
    margin-right: 1px !important;
    margin-left: 1px !important;
}

.mx-2 {
    margin-right: 2px !important;
    margin-left: 2px !important;
}

.mx-3 {
    margin-right: 3px !important;
    margin-left: 3px !important;
}

.mx-4 {
    margin-right: 4px !important;
    margin-left: 4px !important;
}

.mx-5 {
    margin-right: 5px !important;
    margin-left: 5px !important;
}

.mx-6 {
    margin-right: 6px !important;
    margin-left: 6px !important;
}

.mx-7 {
    margin-right: 7px !important;
    margin-left: 7px !important;
}

.mx-8 {
    margin-right: 8px !important;
    margin-left: 8px !important;
}

.mx-9 {
    margin-right: 9px !important;
    margin-left: 9px !important;
}

.mx-10 {
    margin-right: 10px !important;
    margin-left: 10px !important;
}

.mx-13 {
    margin-right: 13px !important;
    margin-left: 13px !important;
}

.mx-15 {
    margin-right: 15px !important;
    margin-left: 15px !important;
}

.mx-18 {
    margin-right: 18px !important;
    margin-left: 18px !important;
}

.mx-20 {
    margin-right: 20px !important;
    margin-left: 20px !important;
}

.mx-25 {
    margin-right: 25px !important;
    margin-left: 25px !important;
}

.mx-30 {
    margin-right: 30px !important;
    margin-left: 30px !important;
}

.mx-35 {
    margin-right: 35px !important;
    margin-left: 35px !important;
}

.mx-40 {
    margin-right: 40px !important;
    margin-left: 40px !important;
}

.mx-45 {
    margin-right: 45px !important;
    margin-left: 45px !important;
}

.mx-50 {
    margin-right: 50px !important;
    margin-left: 50px !important;
}

.mx-55 {
    margin-right: 55px !important;
    margin-left: 55px !important;
}

.mx-60 {
    margin-right: 60px !important;
    margin-left: 60px !important;
}

.mx-70 {
    margin-right: 70px !important;
    margin-left: 70px !important;
}

.mx-80 {
    margin-right: 80px !important;
    margin-left: 80px !important;
}

.mx-90 {
    margin-right: 90px !important;
    margin-left: 90px !important;
}

.mx-100 {
    margin-right: 100px !important;
    margin-left: 100px !important;
}

/*
 * Negative Margins Helper
 */
.mn-0 {
    margin: 0px !important;
}

.mn-1 {
    margin: -1px !important;
}

.mn-2 {
    margin: -2px !important;
}

.mn-3 {
    margin: -3px !important;
}

.mn-4 {
    margin: -4px !important;
}

.mn-5 {
    margin: -5px !important;
}

.mn-6 {
    margin: -6px !important;
}

.mn-7 {
    margin: -7px !important;
}

.mn-8 {
    margin: -8px !important;
}

.mn-9 {
    margin: -9px !important;
}

.mn-10 {
    margin: -10px !important;
}

.mn-13 {
    margin: -13px !important;
}

.mn-15 {
    margin: -15px !important;
}

.mn-18 {
    margin: -18px !important;
}

.mn-20 {
    margin: -20px !important;
}

.mn-25 {
    margin: -25px !important;
}

.mn-30 {
    margin: -30px !important;
}

.mn-35 {
    margin: -35px !important;
}

.mn-40 {
    margin: -40px !important;
}

.mn-45 {
    margin: -45px !important;
}

.mn-50 {
    margin: -50px !important;
}

.mn-55 {
    margin: -55px !important;
}

.mn-60 {
    margin: -60px !important;
}

.mn-70 {
    margin: -70px !important;
}

.mn-80 {
    margin: -80px !important;
}

.mn-90 {
    margin: -90px !important;
}

.mn-100 {
    margin: -100px !important;
}

.mnt-0 {
    margin-top: 0px !important;
}

.mnt-1 {
    margin-top: -1px !important;
}

.mnt-2 {
    margin-top: -2px !important;
}

.mnt-3 {
    margin-top: -3px !important;
}

.mnt-4 {
    margin-top: -4px !important;
}

.mnt-5 {
    margin-top: -5px !important;
}

.mnt-6 {
    margin-top: -6px !important;
}

.mnt-7 {
    margin-top: -7px !important;
}

.mnt-8 {
    margin-top: -8px !important;
}

.mnt-9 {
    margin-top: -9px !important;
}

.mnt-10 {
    margin-top: -10px !important;
}

.mnt-13 {
    margin-top: -13px !important;
}

.mnt-15 {
    margin-top: -15px !important;
}

.mnt-18 {
    margin-top: -18px !important;
}

.mnt-20 {
    margin-top: -20px !important;
}

.mnt-25 {
    margin-top: -25px !important;
}

.mnt-30 {
    margin-top: -30px !important;
}

.mnt-35 {
    margin-top: -35px !important;
}

.mnt-40 {
    margin-top: -40px !important;
}

.mnt-45 {
    margin-top: -45px !important;
}

.mnt-50 {
    margin-top: -50px !important;
}

.mnt-55 {
    margin-top: -55px !important;
}

.mnt-60 {
    margin-top: -60px !important;
}

.mnt-70 {
    margin-top: -70px !important;
}

.mnt-80 {
    margin-top: -80px !important;
}

.mnt-90 {
    margin-top: -90px !important;
}

.mnt-100 {
    margin-top: -100px !important;
}

.mnl-0 {
    margin-left: 0px !important;
}

.mnl-1 {
    margin-left: -1px !important;
}

.mnl-2 {
    margin-left: -2px !important;
}

.mnl-3 {
    margin-left: -3px !important;
}

.mnl-4 {
    margin-left: -4px !important;
}

.mnl-5 {
    margin-left: -5px !important;
}

.mnl-6 {
    margin-left: -6px !important;
}

.mnl-7 {
    margin-left: -7px !important;
}

.mnl-8 {
    margin-left: -8px !important;
}

.mnl-9 {
    margin-left: -9px !important;
}

.mnl-10 {
    margin-left: -10px !important;
}

.mnl-13 {
    margin-left: -13px !important;
}

.mnl-15 {
    margin-left: -15px !important;
}

.mnl-18 {
    margin-left: -18px !important;
}

.mnl-20 {
    margin-left: -20px !important;
}

.mnl-25 {
    margin-left: -25px !important;
}

.mnl-30 {
    margin-left: -30px !important;
}

.mnl-35 {
    margin-left: -35px !important;
}

.mnl-40 {
    margin-left: -40px !important;
}

.mnl-45 {
    margin-left: -45px !important;
}

.mnl-50 {
    margin-left: -50px !important;
}

.mnl-55 {
    margin-left: -55px !important;
}

.mnl-60 {
    margin-left: -60px !important;
}

.mnl-70 {
    margin-left: -70px !important;
}

.mnl-80 {
    margin-left: -80px !important;
}

.mnl-90 {
    margin-left: -90px !important;
}

.mnl-100 {
    margin-left: -100px !important;
}

.mnr-0 {
    margin-right: 0px !important;
}

.mnr-1 {
    margin-right: -1px !important;
}

.mnr-2 {
    margin-right: -2px !important;
}

.mnr-3 {
    margin-right: -3px !important;
}

.mnr-4 {
    margin-right: -4px !important;
}

.mnr-5 {
    margin-right: -5px !important;
}

.mnr-6 {
    margin-right: -6px !important;
}

.mnr-7 {
    margin-right: -7px !important;
}

.mnr-8 {
    margin-right: -8px !important;
}

.mnr-9 {
    margin-right: -9px !important;
}

.mnr-10 {
    margin-right: -10px !important;
}

.mnr-13 {
    margin-right: -13px !important;
}

.mnr-15 {
    margin-right: -15px !important;
}

.mnr-18 {
    margin-right: -18px !important;
}

.mnr-20 {
    margin-right: -20px !important;
}

.mnr-25 {
    margin-right: -25px !important;
}

.mnr-30 {
    margin-right: -30px !important;
}

.mnr-35 {
    margin-right: -35px !important;
}

.mnr-40 {
    margin-right: -40px !important;
}

.mnr-45 {
    margin-right: -45px !important;
}

.mnr-50 {
    margin-right: -50px !important;
}

.mnr-55 {
    margin-right: -55px !important;
}

.mnr-60 {
    margin-right: -60px !important;
}

.mnr-70 {
    margin-right: -70px !important;
}

.mnr-80 {
    margin-right: -80px !important;
}

.mnr-90 {
    margin-right: -90px !important;
}

.mnr-100 {
    margin-right: -100px !important;
}

.mnb-0 {
    margin-bottom: 0px !important;
}

.mnb-1 {
    margin-bottom: -1px !important;
}

.mnb-2 {
    margin-bottom: -2px !important;
}

.mnb-3 {
    margin-bottom: -3px !important;
}

.mnb-4 {
    margin-bottom: -4px !important;
}

.mnb-5 {
    margin-bottom: -5px !important;
}

.mnb-6 {
    margin-bottom: -6px !important;
}

.mnb-7 {
    margin-bottom: -7px !important;
}

.mnb-8 {
    margin-bottom: -8px !important;
}

.mnb-9 {
    margin-bottom: -9px !important;
}

.mnb-10 {
    margin-bottom: -10px !important;
}

.mnb-13 {
    margin-bottom: -13px !important;
}

.mnb-15 {
    margin-bottom: -15px !important;
}

.mnb-18 {
    margin-bottom: -18px !important;
}

.mnb-20 {
    margin-bottom: -20px !important;
}

.mnb-25 {
    margin-bottom: -25px !important;
}

.mnb-30 {
    margin-bottom: -30px !important;
}

.mnb-35 {
    margin-bottom: -35px !important;
}

.mnb-40 {
    margin-bottom: -40px !important;
}

.mnb-45 {
    margin-bottom: -45px !important;
}

.mnb-50 {
    margin-bottom: -50px !important;
}

.mnb-55 {
    margin-bottom: -55px !important;
}

.mnb-60 {
    margin-bottom: -60px !important;
}

.mnb-70 {
    margin-bottom: -70px !important;
}

.mnb-80 {
    margin-bottom: -80px !important;
}

.mnb-90 {
    margin-bottom: -90px !important;
}

.mnb-100 {
    margin-bottom: -100px !important;
}

.mny-0 {
    margin-top: 0px !important;
    margin-bottom: 0px !important;
}

.mny-1 {
    margin-top: -1px !important;
    margin-bottom: -1px !important;
}

.mny-2 {
    margin-top: -2px !important;
    margin-bottom: -2px !important;
}

.mny-3 {
    margin-top: -3px !important;
    margin-bottom: -3px !important;
}

.mny-4 {
    margin-top: -4px !important;
    margin-bottom: -4px !important;
}

.mny-5 {
    margin-top: -5px !important;
    margin-bottom: -5px !important;
}

.mny-6 {
    margin-top: -6px !important;
    margin-bottom: -6px !important;
}

.mny-7 {
    margin-top: -7px !important;
    margin-bottom: -7px !important;
}

.mny-8 {
    margin-top: -8px !important;
    margin-bottom: -8px !important;
}

.mny-9 {
    margin-top: -9px !important;
    margin-bottom: -9px !important;
}

.mny-10 {
    margin-top: -10px !important;
    margin-bottom: -10px !important;
}

.mny-13 {
    margin-top: -13px !important;
    margin-bottom: -13px !important;
}

.mny-15 {
    margin-top: -15px !important;
    margin-bottom: -15px !important;
}

.mny-18 {
    margin-top: -18px !important;
    margin-bottom: -18px !important;
}

.mny-20 {
    margin-top: -20px !important;
    margin-bottom: -20px !important;
}

.mny-25 {
    margin-top: -25px !important;
    margin-bottom: -25px !important;
}

.mny-30 {
    margin-top: -30px !important;
    margin-bottom: -30px !important;
}

.mny-35 {
    margin-top: -35px !important;
    margin-bottom: -35px !important;
}

.mny-40 {
    margin-top: -40px !important;
    margin-bottom: -40px !important;
}

.mny-45 {
    margin-top: -45px !important;
    margin-bottom: -45px !important;
}

.mny-50 {
    margin-top: -50px !important;
    margin-bottom: -50px !important;
}

.mny-55 {
    margin-top: -55px !important;
    margin-bottom: -55px !important;
}

.mny-60 {
    margin-top: -60px !important;
    margin-bottom: -60px !important;
}

.mny-70 {
    margin-top: -70px !important;
    margin-bottom: -70px !important;
}

.mny-80 {
    margin-top: -80px !important;
    margin-bottom: -80px !important;
}

.mny-90 {
    margin-top: -90px !important;
    margin-bottom: -90px !important;
}

.mny-100 {
    margin-top: -100px !important;
    margin-bottom: -100px !important;
}

.mnx-0 {
    margin-right: 0px !important;
    margin-left: 0px !important;
}

.mnx-1 {
    margin-right: -1px !important;
    margin-left: -1px !important;
}

.mnx-2 {
    margin-right: -2px !important;
    margin-left: -2px !important;
}

.mnx-3 {
    margin-right: -3px !important;
    margin-left: -3px !important;
}

.mnx-4 {
    margin-right: -4px !important;
    margin-left: -4px !important;
}

.mnx-5 {
    margin-right: -5px !important;
    margin-left: -5px !important;
}

.mnx-6 {
    margin-right: -6px !important;
    margin-left: -6px !important;
}

.mnx-7 {
    margin-right: -7px !important;
    margin-left: -7px !important;
}

.mnx-8 {
    margin-right: -8px !important;
    margin-left: -8px !important;
}

.mnx-9 {
    margin-right: -9px !important;
    margin-left: -9px !important;
}

.mnx-10 {
    margin-right: -10px !important;
    margin-left: -10px !important;
}

.mnx-13 {
    margin-right: -13px !important;
    margin-left: -13px !important;
}

.mnx-15 {
    margin-right: -15px !important;
    margin-left: -15px !important;
}

.mnx-18 {
    margin-right: -18px !important;
    margin-left: -18px !important;
}

.mnx-20 {
    margin-right: -20px !important;
    margin-left: -20px !important;
}

.mnx-25 {
    margin-right: -25px !important;
    margin-left: -25px !important;
}

.mnx-30 {
    margin-right: -30px !important;
    margin-left: -30px !important;
}

.mnx-35 {
    margin-right: -35px !important;
    margin-left: -35px !important;
}

.mnx-40 {
    margin-right: -40px !important;
    margin-left: -40px !important;
}

.mnx-45 {
    margin-right: -45px !important;
    margin-left: -45px !important;
}

.mnx-50 {
    margin-right: -50px !important;
    margin-left: -50px !important;
}

.mnx-55 {
    margin-right: -55px !important;
    margin-left: -55px !important;
}

.mnx-60 {
    margin-right: -60px !important;
    margin-left: -60px !important;
}

.mnx-70 {
    margin-right: -70px !important;
    margin-left: -70px !important;
}

.mnx-80 {
    margin-right: -80px !important;
    margin-left: -80px !important;
}

.mnx-90 {
    margin-right: -90px !important;
    margin-left: -90px !important;
}

.mnx-100 {
    margin-right: -100px !important;
    margin-left: -100px !important;
}

/*
 * Opacity
 */
.op-0 {
    opacity: 0 !important;
}

.op-1 {
    opacity: 0.1 !important;
}

.op-2 {
    opacity: 0.2 !important;
}

.op-3 {
    opacity: 0.3 !important;
}

.op-4 {
    opacity: 0.4 !important;
}

.op-5 {
    opacity: 0.5 !important;
}

.op-6 {
    opacity: 0.6 !important;
}

.op-7 {
    opacity: 0.7 !important;
}

.op-8 {
    opacity: 0.8 !important;
}

.op-9 {
    opacity: 0.9 !important;
}

.op-10 {
    opacity: 1 !important;
}

/*------------------------------------------------------------------

  Navbar

 -------------------------------------------------------------------*/
.yay-hide .rui-navbar-top .rui-navbar-brand {
    -webkit-box-flex: 0;
    -webkit-flex: 0 0 60px;
        -ms-flex: 0 0 60px;
            flex: 0 0 60px;
    width: 60px;
    padding-left: 15px;
}

.yay-hide .rui-navbar-top .rui-navbar-brand .rui-navbar-logo {
    display: none;
}

@media (max-width: 1199.98px) {
    .yay-hide .rui-navbar-top .rui-navbar-brand .rui-navbar-logo {
        display: -webkit-inline-box;
        display: -webkit-inline-flex;
        display: -ms-inline-flexbox;
        display: inline-flex;
    }
}

.yay-hide .rui-navbar-top .rui-yaybar-toggle > span::before,
.yay-hide .rui-navbar-top .rui-yaybar-toggle > span::after {
    -webkit-transform: translateX(0);
        -ms-transform: translateX(0);
            transform: translateX(0);
}

.rui-navbar-toggle,
.rui-yaybar-toggle {
    display: -webkit-box;
    display: -webkit-flex;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-align: center;
    -webkit-align-items: center;
        -ms-flex-align: center;
            align-items: center;
    -webkit-box-pack: center;
    -webkit-justify-content: center;
        -ms-flex-pack: center;
            justify-content: center;
    width: 40px;
    height: 40px;
    padding: 0;
    color: #bcbec0;
    cursor: pointer;
    background: none;
    border: 0;
    -webkit-transition: color .15s ease;
    -o-transition: color .15s ease;
    transition: color .15s ease;
}

.rui-navbar-toggle > span,
.rui-yaybar-toggle > span {
    position: relative;
    width: 16px;
    height: 9px;
    overflow: hidden;
    border-top: 1px solid currentColor;
}

.rui-navbar-toggle > span::before, .rui-navbar-toggle > span::after,
.rui-yaybar-toggle > span::before,
.rui-yaybar-toggle > span::after {
    content: "";
    display: block;
    border-top: 1px solid currentColor;
    will-change: transform;
    -webkit-transition: -webkit-transform .15s ease;
    transition: -webkit-transform .15s ease;
    -o-transition: transform .15s ease;
    transition: transform .15s ease;
    transition: transform .15s ease, -webkit-transform .15s ease;
}

.rui-navbar-toggle > span::before,
.rui-yaybar-toggle > span::before {
    margin-top: 3px;
    -webkit-transform: translateX(3px);
        -ms-transform: translateX(3px);
            transform: translateX(3px);
}

.rui-navbar-toggle > span::after,
.rui-yaybar-toggle > span::after {
    margin-top: 3px;
    -webkit-transform: translateX(5px);
        -ms-transform: translateX(5px);
            transform: translateX(5px);
}

.rui-navbar-toggle.rui-navbar-toggle-inverse > span::before,
.rui-navbar-toggle.rui-yaybar-toggle-inverse > span::before,
.rui-yaybar-toggle.rui-navbar-toggle-inverse > span::before,
.rui-yaybar-toggle.rui-yaybar-toggle-inverse > span::before {
    -webkit-transform: translateX(-3px);
        -ms-transform: translateX(-3px);
            transform: translateX(-3px);
}

.rui-navbar-toggle.rui-navbar-toggle-inverse > span::after,
.rui-navbar-toggle.rui-yaybar-toggle-inverse > span::after,
.rui-yaybar-toggle.rui-navbar-toggle-inverse > span::after,
.rui-yaybar-toggle.rui-yaybar-toggle-inverse > span::after {
    -webkit-transform: translateX(-5px);
        -ms-transform: translateX(-5px);
            transform: translateX(-5px);
}

.rui-navbar-toggle:hover > span::before, .rui-navbar-toggle:focus > span::before,
.rui-yaybar-toggle:hover > span::before,
.rui-yaybar-toggle:focus > span::before {
    -webkit-transform: translateX(0);
        -ms-transform: translateX(0);
            transform: translateX(0);
}

.rui-navbar-toggle:hover > span::after, .rui-navbar-toggle:focus > span::after,
.rui-yaybar-toggle:hover > span::after,
.rui-yaybar-toggle:focus > span::after {
    -webkit-transform: translateX(0);
        -ms-transform: translateX(0);
            transform: translateX(0);
}

.rui-navbar-toggle:hover, .rui-navbar-toggle:focus, .rui-navbar-toggle.focus,
.rui-yaybar-toggle:hover,
.rui-yaybar-toggle:focus,
.rui-yaybar-toggle.focus {
    color: #586069;
    -webkit-box-shadow: none;
            box-shadow: none;
}

.rui-navbar-dark .rui-navbar-toggle,
.rui-night-mode .rui-navbar-toggle, .rui-navbar-dark
.rui-yaybar-toggle,
.rui-night-mode
.rui-yaybar-toggle {
    color: #586069;
}

.rui-navbar-dark .rui-navbar-toggle:hover, .rui-navbar-dark .rui-navbar-toggle:focus, .rui-navbar-dark .rui-navbar-toggle.focus,
.rui-night-mode .rui-navbar-toggle:hover,
.rui-night-mode .rui-navbar-toggle:focus,
.rui-night-mode .rui-navbar-toggle.focus, .rui-navbar-dark
.rui-yaybar-toggle:hover, .rui-navbar-dark
.rui-yaybar-toggle:focus, .rui-navbar-dark
.rui-yaybar-toggle.focus,
.rui-night-mode
.rui-yaybar-toggle:hover,
.rui-night-mode
.rui-yaybar-toggle:focus,
.rui-night-mode
.rui-yaybar-toggle.focus {
    color: #a9b1bb;
}

.rui-navbar-toggle {
    display: -webkit-inline-box;
    display: -webkit-inline-flex;
    display: -ms-inline-flexbox;
    display: inline-flex;
}

.rui-navbar {
    display: -webkit-box;
    display: -webkit-flex;
    display: -ms-flexbox;
    display: flex;
    top: 0;
    right: 0;
    left: 0;
    font-size: 13px;
}

.rui-navbar .rui-navbar-content {
    display: -webkit-box;
    display: -webkit-flex;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-pack: justify;
    -webkit-justify-content: space-between;
        -ms-flex-pack: justify;
            justify-content: space-between;
    width: 100%;
    padding: 15px 0;
}

.rui-navbar .rui-navbar-content .dropdown-hover .dropdown-hover > .dropdown-menu[x-placement="right-start"] {
    top: 0;
    right: auto;
    bottom: auto;
    left: 100%;
    margin: -17px 0 0 30px;
}

.rui-navbar .rui-navbar-content .dropdown-hover .dropdown-hover > .dropdown-menu[x-placement="right-start"] > .dropdown-menu-triangle {
    margin-top: -6px;
}

.rui-navbar .rui-navbar-content .dropdown-hover .dropdown-hover > .dropdown-menu[x-placement="right-end"] {
    top: auto;
    right: auto;
    bottom: 0;
    left: 100%;
    margin: 0 0 -17px 30px;
}

.rui-navbar .rui-navbar-content .dropdown-hover .dropdown-hover > .dropdown-menu[x-placement="right-end"] > .dropdown-menu-triangle {
    margin-top: -6px;
}

.rui-navbar .rui-navbar-content .rui-navbar-dropdown-language {
    padding: 15px 30px;
}

.rui-navbar .rui-navbar-content .rui-navbar-dropdown-language > li .rui-navbar-language {
    margin: 0 -30px;
}

.rui-navbar .rui-navbar-content .rui-navbar-dropdown-notice {
    min-width: 320px;
    padding: 30px;
}

.rui-navbar .rui-navbar-content .rui-navbar-dropdown-notice .rui-navbar-dropdown-title {
    padding: 0;
}

.rui-navbar .rui-navbar-content .rui-navbar-dropdown-notice .rui-navbar-notice {
    margin: 0 -30px;
}

.rui-navbar .rui-navbar-content .rui-navbar-dropdown-notice > li:last-of-type {
    margin-bottom: -15px;
}

.rui-navbar .rui-navbar-content .rui-navbar-dropdown-notice > li:hover .rui-navbar-notice, .rui-navbar .rui-navbar-content .rui-navbar-dropdown-notice > li.hover .rui-navbar-notice {
    background-color: #f8f9fa;
}

.rui-navbar .rui-navbar-content .rui-navbar-dropdown-notice > li:hover .rui-navbar-notice-img, .rui-navbar .rui-navbar-content .rui-navbar-dropdown-notice > li.hover .rui-navbar-notice-img {
    color: #d7d9e0;
    background-color: #f3f4f7;
}

.rui-navbar .rui-navbar-content .rui-navbar-dropdown-notice > li:hover .rui-navbar-notice-close, .rui-navbar .rui-navbar-content .rui-navbar-dropdown-notice > li.hover .rui-navbar-notice-close {
    opacity: 1;
}

.rui-navbar .rui-navbar-avatar {
    display: -webkit-box;
    display: -webkit-flex;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-align: center;
    -webkit-align-items: center;
        -ms-flex-align: center;
            align-items: center;
    -webkit-box-pack: start;
    -webkit-justify-content: flex-start;
        -ms-flex-pack: start;
            justify-content: flex-start;
    width: 40px;
    height: 40px;
    padding: 0;
    opacity: 1;
    will-change: opacity;
    -webkit-transition: opacity .15s ease-in-out;
    -o-transition: opacity .15s ease-in-out;
    transition: opacity .15s ease-in-out;
}

.rui-navbar .rui-navbar-avatar > img {
    width: 100%;
    height: 100%;
    font-family: "object-fit: cover";
    -o-object-fit: cover;
       object-fit: cover;
    border-radius: 100%;
}

.rui-navbar .rui-navbar-avatar + .rui-navbar-dropdown {
    margin-top: 15px;
}

.rui-navbar .rui-navbar-avatar + .rui-navbar-dropdown > .rui-navbar-dropdown-triangle {
    margin-left: -10px;
}

.rui-navbar .rui-navbar-language {
    display: -webkit-box;
    display: -webkit-flex;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-align: center;
    -webkit-align-items: center;
        -ms-flex-align: center;
            align-items: center;
    padding: 15px 80px 15px 30px;
    color: #393f49;
    -webkit-transition: background-color .15s ease-in-out;
    -o-transition: background-color .15s ease-in-out;
    transition: background-color .15s ease-in-out;
}

.rui-navbar .rui-navbar-language .rui-navbar-language-img {
    display: -webkit-inline-box;
    display: -webkit-inline-flex;
    display: -ms-inline-flexbox;
    display: inline-flex;
    -webkit-box-align: center;
    -webkit-align-items: center;
        -ms-flex-align: center;
            align-items: center;
    -webkit-box-pack: center;
    -webkit-justify-content: center;
        -ms-flex-pack: center;
            justify-content: center;
    width: 20px;
    height: 20px;
    margin-right: 15px;
    border-radius: 100%;
}

.rui-navbar .rui-navbar-language .rui-navbar-language-img > img {
    width: 100%;
    height: 100%;
    font-family: "object-fit: cover";
    -o-object-fit: cover;
       object-fit: cover;
    border-radius: 100%;
}

.rui-navbar .rui-navbar-language:hover, .rui-navbar .rui-navbar-language.hover, .rui-navbar .rui-navbar-language.active {
    color: #393f49;
    background-color: #f8f9fa;
}

.rui-navbar .rui-navbar-language:hover, .rui-navbar .rui-navbar-language.hover, .rui-navbar .rui-navbar-language:focus, .rui-navbar .rui-navbar-language.focus {
    text-decoration: none;
}

.rui-navbar-top {
    position: -webkit-sticky;
    position: sticky;
    -webkit-box-align: stretch;
    -webkit-align-items: stretch;
        -ms-flex-align: stretch;
            align-items: stretch;
    min-height: 70px;
    padding: 0 15px;
    background-color: #fff;
    will-change: transform, opacity;
    -webkit-transition: opacity .15s ease-in-out, -webkit-transform .15s ease-in-out;
    transition: opacity .15s ease-in-out, -webkit-transform .15s ease-in-out;
    -o-transition: transform .15s ease-in-out, opacity .15s ease-in-out;
    transition: transform .15s ease-in-out, opacity .15s ease-in-out;
    transition: transform .15s ease-in-out, opacity .15s ease-in-out, -webkit-transform .15s ease-in-out;
    z-index: 1001;
}

@media (max-width: 1199.98px) {
    .rui-navbar-top .rui-navbar-brand,
    .rui-navbar-top .container-fluid {
        display: none;
    }
}

.rui-navbar-top .rui-navbar-brand {
    display: -webkit-box;
    display: -webkit-flex;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-flex: 0;
    -webkit-flex: 0 0 260px;
        -ms-flex: 0 0 260px;
            flex: 0 0 260px;
    -webkit-box-align: center;
    -webkit-align-items: center;
        -ms-flex-align: center;
            align-items: center;
    -webkit-box-pack: justify;
    -webkit-justify-content: space-between;
        -ms-flex-pack: justify;
            justify-content: space-between;
    width: 260px;
    padding: 0 30px;
    margin-left: -15px;
    will-change: width;
    -webkit-transition: width .15s, -webkit-box-flex .15s, -webkit-flex .15s;
    transition: width .15s, -webkit-box-flex .15s, -webkit-flex .15s;
    -o-transition: width .15s, flex .15s;
    transition: width .15s, flex .15s;
    transition: width .15s, flex .15s, -webkit-box-flex .15s, -webkit-flex .15s, -ms-flex .15s;
}

.rui-navbar-top .rui-yaybar-toggle {
    margin-right: -12px;
}

.rui-navbar-top .rui-navbar-content {
    padding-left: 15px;
}

.rui-navbar-top .rui-navbar-content > .nav {
    display: -webkit-box;
    display: -webkit-flex;
    display: -ms-flexbox;
    display: flex;
    -webkit-flex-basis: 0;
        -ms-flex-preferred-size: 0;
            flex-basis: 0;
    -webkit-box-flex: 1;
    -webkit-flex-grow: 1;
        -ms-flex-positive: 1;
            flex-grow: 1;
    -webkit-box-align: center;
    -webkit-align-items: center;
        -ms-flex-align: center;
            align-items: center;
    padding: 0;
    margin: 0 -14px 0;
}

.rui-navbar-top .rui-navbar-content > .nav.rui-navbar-right {
    -webkit-box-flex: 0;
    -webkit-flex: 0 0 auto;
        -ms-flex: 0 0 auto;
            flex: 0 0 auto;
    max-width: 100%;
}

.rui-navbar-top .rui-navbar-content > .nav > li {
    display: -webkit-box;
    display: -webkit-flex;
    display: -ms-flexbox;
    display: flex;
    padding: 0 14px 0;
    list-style: none;
}

.rui-navbar-top .rui-navbar-content > .nav .nav-link {
    padding: 0;
    white-space: nowrap;
}

.rui-navbar-top .rui-navbar-content > .nav .dropdown-menu > .dropdown > .dropdown-item::after {
    content: "";
    width: 2px;
    height: 2px;
    margin-left: 20px;
    background-color: #bcbec0;
    border-radius: 2px;
    -webkit-transition: background-color .15s ease-in-out;
    -o-transition: background-color .15s ease-in-out;
    transition: background-color .15s ease-in-out;
}

.rui-navbar-top .rui-navbar-content > .nav .dropdown-menu .nav-link {
    padding: 9px 15px;
}

.rui-navbar-top .rui-navbar-content > .nav .dropdown-menu .nav-link::before {
    display: none;
}

.rui-navbar-top .rui-navbar-content > .nav .dropdown:hover > .dropdown-item::after,
.rui-navbar-top .rui-navbar-content > .nav .dropdown.hover > .dropdown-item::after {
    background-color: #393f49;
}

.rui-navbar-top.rui-navbar-sticky {
    position: -webkit-sticky;
    position: sticky;
    top: 0;
}

.rui-navbar-top.rui-navbar-fixed {
    position: fixed;
    top: 0;
    right: 0;
    left: 0;
}

.rui-navbar-mobile {
    position: fixed;
    display: none;
    -webkit-flex-wrap: wrap;
        -ms-flex-wrap: wrap;
            flex-wrap: wrap;
    -webkit-box-align: start;
    -webkit-align-items: flex-start;
        -ms-flex-align: start;
            align-items: flex-start;
    top: 5px;
    right: 5px;
    left: 5px;
    padding: 0;
    margin-bottom: 5px;
    background-color: #fff;
    -webkit-box-shadow: 0 0 0 5px #fff;
            box-shadow: 0 0 0 5px #fff;
    opacity: 0;
    will-change: transform, opacity;
    -webkit-transition: opacity .15s ease-in-out, border-radius .15s ease-in-out, -webkit-transform .15s ease-in-out, -webkit-box-shadow .15s ease-in-out;
    transition: opacity .15s ease-in-out, border-radius .15s ease-in-out, -webkit-transform .15s ease-in-out, -webkit-box-shadow .15s ease-in-out;
    -o-transition: transform .15s ease-in-out, opacity .15s ease-in-out, box-shadow .15s ease-in-out, border-radius .15s ease-in-out;
    transition: transform .15s ease-in-out, opacity .15s ease-in-out, box-shadow .15s ease-in-out, border-radius .15s ease-in-out;
    transition: transform .15s ease-in-out, opacity .15s ease-in-out, box-shadow .15s ease-in-out, border-radius .15s ease-in-out, -webkit-transform .15s ease-in-out, -webkit-box-shadow .15s ease-in-out;
    z-index: -1000;
}

.rui-navbar-mobile + .rui-navbar-bg {
    position: fixed;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    visibility: hidden;
    background-color: rgba(50, 57, 66, 0.9);
    opacity: 0;
    -webkit-transition: opacity .15s ease-in-out, visibility .15s ease-in-out;
    -o-transition: opacity .15s ease-in-out, visibility .15s ease-in-out;
    transition: opacity .15s ease-in-out, visibility .15s ease-in-out;
    z-index: 1001;
}

.rui-navbar-mobile .rui-yaybar-toggle {
    margin-right: 44px;
    margin-left: -14px;
}

@media (max-width: 575.98px) {
    .rui-navbar-mobile .rui-yaybar-toggle {
        margin-right: 10px;
        margin-left: -10px;
    }
}

.rui-navbar-mobile > .rui-navbar-collapse {
    max-height: calc(100vh - 71px);
    margin-right: 1px;
    margin-bottom: 1px;
    overflow-y: scroll;
}

.rui-navbar-mobile > .rui-navbar-collapse:not(.show)::-webkit-scrollbar-thumb {
    background-color: #fff;
}

.rui-navbar-mobile > .rui-navbar-collapse::-webkit-scrollbar-track {
    background-color: #fff;
    border-radius: 4px;
}

.rui-navbar-mobile > .rui-navbar-collapse::-webkit-scrollbar {
    width: 4px;
    background-color: #fff;
}

.rui-navbar-mobile > .rui-navbar-collapse::-webkit-scrollbar-thumb {
    background-color: #eaecf0;
    border-radius: 4px;
}

.rui-navbar-mobile .nav .nav-link > .rui-nav-circle {
    left: -10px;
}

.rui-navbar-mobile .nav.dropdown-menu .nav-link > .rui-nav-circle,
.rui-navbar-mobile .nav.dropdown-menu .nav-link > .rui-dropdown-circle,
.rui-navbar-mobile .nav.dropdown-menu .dropdown-item > .rui-nav-circle,
.rui-navbar-mobile .nav.dropdown-menu .dropdown-item > .rui-dropdown-circle {
    left: -10px;
}

.rui-navbar-mobile .dropdown .dropdown-item > .rui-dropdown-circle {
    top: 2px;
    left: -10px;
}

.rui-navbar-mobile .dropdown .dropdown .rui-dropdown-circle {
    left: -10px;
}

.rui-navbar-mobile .dropdown .dropdown-menu .nav-link > .rui-nav-circle {
    top: 2px;
    left: -10px;
}

.rui-navbar-mobile .dropdown-menu::before {
    display: none;
}

.rui-navbar-mobile .rui-navbar-logo {
    display: -webkit-inline-box;
    display: -webkit-inline-flex;
    display: -ms-inline-flexbox;
    display: inline-flex;
    visibility: hidden;
}

.rui-navbar-mobile .rui-navbar-head {
    display: -webkit-box;
    display: -webkit-flex;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-align: center;
    -webkit-align-items: center;
        -ms-flex-align: center;
            align-items: center;
    -webkit-box-pack: justify;
    -webkit-justify-content: space-between;
        -ms-flex-pack: justify;
            justify-content: space-between;
    width: 100%;
    min-height: 60px;
    padding: 0 25px;
}

@media (max-width: 575.98px) {
    .rui-navbar-mobile .rui-navbar-head {
        padding: 0 15px;
    }
}

.rui-navbar-mobile .rui-navbar-head > .dropdown > .dropdown-menu {
    margin-top: 15px;
}

.rui-navbar-mobile .rui-navbar-head .rui-navbar-toggle {
    display: -webkit-inline-box;
    display: -webkit-inline-flex;
    display: -ms-inline-flexbox;
    display: inline-flex;
    margin-right: -10px;
    margin-left: 5px;
}

.rui-navbar-mobile .dropdown .dropdown-item-switch.custom-switch .dropdown-item::before,
.rui-navbar-mobile .dropdown.show .dropdown-item-switch.custom-switch .dropdown-item::before {
    right: calc(-3rem - 2px);
}

.rui-navbar-mobile .dropdown .dropdown-item-switch.custom-switch .dropdown-item::after,
.rui-navbar-mobile .dropdown.show .dropdown-item-switch.custom-switch .dropdown-item::after {
    right: calc(-2.1rem - 2px);
}

.rui-navbar-mobile .rui-navbar-content {
    padding: 0 20px 25px;
    overflow: hidden;
}

.rui-navbar-mobile .rui-navbar-content .nav {
    -webkit-box-orient: vertical;
    -webkit-box-direction: normal;
    -webkit-flex-direction: column;
        -ms-flex-direction: column;
            flex-direction: column;
    -webkit-box-align: start;
    -webkit-align-items: flex-start;
        -ms-flex-align: start;
            align-items: flex-start;
    width: 100%;
}

.rui-navbar-mobile .rui-navbar-content .nav .dropdown-menu > .dropdown > a::after {
    content: "";
    width: 2px;
    height: 2px;
    margin-left: auto;
    background-color: #bcbec0;
    border-radius: 2px;
    -webkit-transition: background-color .15s ease-in-out;
    -o-transition: background-color .15s ease-in-out;
    transition: background-color .15s ease-in-out;
}

.rui-navbar-mobile .rui-navbar-content .nav .dropdown-menu > .dropdown.show > a::after {
    background-color: #393f49;
}

.rui-navbar-mobile .rui-navbar-content .nav > li {
    padding: 0;
}

.rui-navbar-mobile .rui-navbar-content .nav > li:last-child > .dropdown-menu {
    margin-bottom: 4px;
}

.rui-navbar-mobile .rui-navbar-content .nav .nav-link {
    padding: 7.5px 0;
}

.rui-navbar-mobile .rui-navbar-content .nav .nav-link::before {
    display: none;
}

.rui-navbar-mobile .rui-navbar-content .nav .nav-link > .badge {
    top: 2.5px;
}

.rui-navbar-mobile .rui-navbar-content .nav .nav-item {
    -webkit-box-orient: vertical;
    -webkit-box-direction: normal;
    -webkit-flex-direction: column;
        -ms-flex-direction: column;
            flex-direction: column;
    width: 100%;
}

.rui-navbar-mobile .rui-navbar-content .dropdown {
    -webkit-flex-wrap: wrap;
        -ms-flex-wrap: wrap;
            flex-wrap: wrap;
    width: 100%;
}

.rui-navbar-mobile .rui-navbar-content .dropdown > .dropdown-item {
    padding: 7.5px 0;
}

.rui-navbar-mobile .rui-navbar-content .dropdown > .dropdown-menu {
    position: static !important;
    width: 100%;
    padding: 13px 25px;
    margin: 0;
    margin-top: 3px;
    margin-bottom: 3px;
    -webkit-transform: none !important;
        -ms-transform: none !important;
            transform: none !important;
}

.rui-navbar-mobile .rui-navbar-content .dropdown > .dropdown-menu > .dropdown-menu-triangle {
    display: none;
}

.rui-navbar-mobile .rui-navbar-content .dropdown > .dropdown-menu .dropdown-item {
    padding: 7.5px 0;
}

.rui-navbar-mobile .rui-navbar-content .dropdown > .dropdown-menu > li {
    padding: 0;
}

.rui-navbar-mobile .rui-navbar-content .dropdown > .dropdown-menu > li.dropdown-menu-label {
    padding: 14px 0 8px;
}

.rui-navbar-mobile .rui-navbar-content .dropdown > .dropdown-menu > li:last-child > .dropdown-menu {
    margin-bottom: 11px;
}

.rui-navbar-mobile .rui-navbar-content .dropdown > .dropdown-menu:not(.show) {
    position: absolute;
    right: 0;
    left: 0;
}

.rui-navbar-mobile .rui-navbar-content .dropdown .rui-navbar-dropdown-notice {
    padding: 25px;
}

.rui-navbar-mobile .rui-navbar-content .dropdown .rui-navbar-dropdown-language {
    padding: 15px 25px;
}

.rui-navbar-mobile .rui-navbar-content .dropdown .rui-navbar-dropdown-language .rui-navbar-language {
    padding: 10px 25px 10px 25px;
    margin: 0 -25px;
}

.rui-navbar-mobile.rui-navbar-show .navbar-toggler.rui-navbar-toggle > span {
    border-top-color: transparent;
}

.rui-navbar-mobile.rui-navbar-show .navbar-toggler.rui-navbar-toggle > span::before {
    -webkit-transform: rotate(45deg);
        -ms-transform: rotate(45deg);
            transform: rotate(45deg);
}

.rui-navbar-mobile.rui-navbar-show .navbar-toggler.rui-navbar-toggle > span::after {
    -webkit-transform: rotate(-45deg) translateY(-2px);
        -ms-transform: rotate(-45deg) translateY(-2px);
            transform: rotate(-45deg) translateY(-2px);
}

@media (max-width: 1199.98px) {
    .rui-navbar-mobile {
        display: -webkit-box;
        display: -webkit-flex;
        display: -ms-flexbox;
        display: flex;
        opacity: 1;
        z-index: 1002;
    }
    .rui-navbar-mobile.rui-navbar-show.rui-navbar, .rui-navbar-mobile.rui-navbar-show.rui-navbar.rui-navbar-dark {
        border-color: #f3f4f7;
        border-radius: .25rem;
        -webkit-box-shadow: 0 3px 10px rgba(0, 0, 0, 0.03);
                box-shadow: 0 3px 10px rgba(0, 0, 0, 0.03);
    }
    .rui-navbar-mobile.rui-navbar-show.rui-navbar + .rui-navbar-bg, .rui-navbar-mobile.rui-navbar-show.rui-navbar.rui-navbar-dark + .rui-navbar-bg {
        visibility: visible;
        opacity: 1;
    }
    .rui-navbar-mobile .rui-navbar-logo {
        visibility: visible;
    }
}

.rui-navbar-autohide .rui-navbar {
    -webkit-transform: translateY(0);
        -ms-transform: translateY(0);
            transform: translateY(0);
}

.rui-navbar-autohide.rui-navbar-hide .rui-navbar-top,
.rui-navbar-autohide.rui-navbar-hide .rui-navbar-mobile {
    -webkit-transform: translateY(-70px);
        -ms-transform: translateY(-70px);
            transform: translateY(-70px);
}

.rui-navbar-autohide.rui-navbar-show .rui-navbar-top,
.rui-navbar-autohide.rui-navbar-show .rui-navbar-mobile {
    -webkit-transform: translateY(0);
        -ms-transform: translateY(0);
            transform: translateY(0);
}

.rui-navbar-autohide:not(.rui-spotlightmode).rui-navbar-hide .rui-navbar-top,
.rui-navbar-autohide:not(.rui-spotlightmode).rui-navbar-hide .rui-navbar-mobile {
    opacity: 0;
}

.rui-navbar-autohide:not(.rui-spotlightmode).rui-navbar-show .rui-navbar-top,
.rui-navbar-autohide:not(.rui-spotlightmode).rui-navbar-show .rui-navbar-mobile {
    opacity: 1;
}

.rui-navbar-dark.rui-navbar {
    background-color: #292f38;
}

.rui-navbar-dark.rui-navbar .rui-navbar-content .rui-navbar-dropdown-notice > li:hover .rui-navbar-notice,
.rui-navbar-dark.rui-navbar .rui-navbar-content .rui-navbar-dropdown-notice > li.hover .rui-navbar-notice {
    background-color: #323942;
}

.rui-navbar-dark.rui-navbar .rui-navbar-content .rui-navbar-dropdown-notice > li:hover .rui-navbar-notice-img,
.rui-navbar-dark.rui-navbar .rui-navbar-content .rui-navbar-dropdown-notice > li.hover .rui-navbar-notice-img {
    color: #4f565f;
    background-color: #3c424c;
}

.rui-navbar-dark.rui-navbar .rui-navbar-language {
    color: #c7d1db;
}

.rui-navbar-dark.rui-navbar .rui-navbar-language:hover, .rui-navbar-dark.rui-navbar .rui-navbar-language.hover, .rui-navbar-dark.rui-navbar .rui-navbar-language.active {
    color: #c7d1db;
    background-color: #323942;
}

.rui-navbar-dark.rui-navbar .rui-navbar-dropdown-title h2 {
    color: #c7d1db;
}

.rui-navbar-dark.rui-navbar .rui-navbar-content > .nav .rui-nav-circle,
.rui-navbar-dark.rui-navbar .rui-navbar-content > .nav .rui-dropdown-circle {
    background-color: #323942;
}

.rui-navbar-dark.rui-navbar .rui-navbar-content > .nav .dropdown-menu > .dropdown > .dropdown-item::after {
    background-color: #586069;
}

.rui-navbar-dark.rui-navbar .rui-navbar-content > .nav .dropdown:hover > .dropdown-item::after,
.rui-navbar-dark.rui-navbar .rui-navbar-content > .nav .dropdown.hover > .dropdown-item::after {
    background-color: #c7d1db;
}

.rui-navbar-dark.rui-navbar .nav .nav .nav-link::before {
    background-color: #4f565f;
}

.rui-navbar-dark.rui-navbar .nav .nav .nav-link:hover::before, .rui-navbar-dark.rui-navbar .nav .nav .nav-link.hover::before, .rui-navbar-dark.rui-navbar .nav .nav .nav-link:focus::before, .rui-navbar-dark.rui-navbar .nav .nav .nav-link.focus::before, .rui-navbar-dark.rui-navbar .nav .nav .nav-link.active::before {
    background-color: #c7d1db;
}

.rui-navbar-dark.rui-navbar .nav .nav-link {
    color: #626973;
}

.rui-navbar-dark.rui-navbar .nav .nav-link > span:not([class]) {
    color: #c7d1db;
}

.rui-navbar-dark.rui-navbar .nav .nav-link > .rui-icon {
    stroke: #586069;
}

.rui-navbar-dark.rui-navbar .nav .nav-link > .badge,
.rui-navbar-dark.rui-navbar .nav .nav-link > .badge-circle {
    -webkit-box-shadow: 0 0 0 2px #292f38;
            box-shadow: 0 0 0 2px #292f38;
}

.rui-navbar-dark.rui-navbar .nav .nav-link.show {
    color: #586069;
}

.rui-navbar-dark.rui-navbar .nav .nav-link.show > span:not([class]) {
    color: #c7d1db;
}

.rui-navbar-dark.rui-navbar .nav .nav-link.show > .rui-icon {
    stroke: #4f565f;
}

.rui-navbar-dark.rui-navbar .nav .nav-link:hover, .rui-navbar-dark.rui-navbar .nav .nav-link.hover, .rui-navbar-dark.rui-navbar .nav .nav-link:focus, .rui-navbar-dark.rui-navbar .nav .nav-link.focus {
    color: #c7d1db;
}

.rui-navbar-dark.rui-navbar .nav .nav-link:hover > span:not([class]), .rui-navbar-dark.rui-navbar .nav .nav-link.hover > span:not([class]), .rui-navbar-dark.rui-navbar .nav .nav-link:focus > span:not([class]), .rui-navbar-dark.rui-navbar .nav .nav-link.focus > span:not([class]) {
    color: #c7d1db;
}

.rui-navbar-dark.rui-navbar .nav .nav-link:hover > .rui-icon, .rui-navbar-dark.rui-navbar .nav .nav-link.hover > .rui-icon, .rui-navbar-dark.rui-navbar .nav .nav-link:focus > .rui-icon, .rui-navbar-dark.rui-navbar .nav .nav-link.focus > .rui-icon {
    stroke: #c7d1db;
}

.rui-navbar-dark.rui-navbar .nav .nav-link.active {
    color: #c7d1db;
}

.rui-navbar-dark.rui-navbar .nav .nav-link.active > span:not([class]) {
    color: #725ec3;
}

.rui-navbar-dark.rui-navbar .nav .nav-link.active > .rui-icon {
    stroke: #725ec3;
}

.rui-navbar-dark.rui-navbar .nav .nav-link.disabled {
    color: #454c55;
}

.rui-navbar-dark.rui-navbar .nav .nav-link.disabled > span:not([class]) {
    color: #454c55;
}

.rui-navbar-dark.rui-navbar .nav .nav-link.disabled > .rui-icon {
    stroke: #454c55;
}

.rui-navbar-dark.rui-navbar .btn-custom-round {
    color: #626973;
}

.rui-navbar-dark.rui-navbar .btn-custom-round > .rui-icon {
    stroke: #626973;
}

.rui-navbar-dark.rui-navbar .btn-custom-round > .badge.badge-circle {
    -webkit-box-shadow: 0 0 0 2px #292f38;
            box-shadow: 0 0 0 2px #292f38;
}

.rui-navbar-dark.rui-navbar .btn-custom-round:hover::after, .rui-navbar-dark.rui-navbar .btn-custom-round.hover::after, .rui-navbar-dark.rui-navbar .btn-custom-round:not(.disabled):not(:disabled):active::after {
    background-color: #323942;
}

.rui-navbar-dark.rui-navbar .custom-select {
    color: #626973;
    background-color: #292f38;
    border-color: #454c55;
}

.rui-navbar-dark.rui-navbar .custom-select:focus, .rui-navbar-dark.rui-navbar .custom-select.focus {
    border-color: rgba(114, 94, 195, 0.6);
    -webkit-box-shadow: 0 0 0 0.2rem rgba(114, 94, 195, 0.25);
            box-shadow: 0 0 0 0.2rem rgba(114, 94, 195, 0.25);
}

.rui-navbar-dark.rui-navbar .custom-select:disabled {
    background-color: #3c424c;
    border-color: #454c55;
}

.rui-navbar-dark.rui-navbar .custom-control-label::before {
    background-color: #4f565f;
    border-color: #4f565f;
}

.rui-navbar-dark.rui-navbar .custom-switch .custom-control-input:not(:disabled):active ~ .custom-control-label::before {
    background-color: #8271ca;
    border-color: #8271ca;
}

.rui-navbar-dark.rui-navbar .custom-switch .custom-control-input:checked ~ .custom-control-label::before {
    background-color: #725ec3;
    border-color: #725ec3;
}

.rui-navbar-dark.rui-navbar .custom-switch .custom-control-input:focus:not(:checked) ~ .custom-control-label::before {
    border-color: #4f565f;
}

.rui-navbar-dark.rui-navbar .custom-switch .custom-control-input:disabled ~ .custom-control-label {
    color: #626973;
}

.rui-navbar-dark.rui-navbar .custom-switch .custom-control-input:disabled ~ .custom-control-label::before {
    background-color: #454c55;
    border-color: #454c55;
}

.rui-navbar-dark.rui-navbar .dropdown .dropdown-item {
    color: #626973;
}

.rui-navbar-dark.rui-navbar .dropdown .dropdown-item > span:not([class]) {
    color: #c7d1db;
}

.rui-navbar-dark.rui-navbar .dropdown .dropdown-item > .rui-icon {
    stroke: #586069;
}

.rui-navbar-dark.rui-navbar .dropdown .dropdown-item:hover, .rui-navbar-dark.rui-navbar .dropdown .dropdown-item.hover {
    color: #c7d1db;
}

.rui-navbar-dark.rui-navbar .dropdown .dropdown-item:hover > span:not([class]), .rui-navbar-dark.rui-navbar .dropdown .dropdown-item.hover > span:not([class]) {
    color: #c7d1db;
}

.rui-navbar-dark.rui-navbar .dropdown .dropdown-item:hover > .rui-icon, .rui-navbar-dark.rui-navbar .dropdown .dropdown-item.hover > .rui-icon {
    stroke: #c7d1db;
}

.rui-navbar-dark.rui-navbar .dropdown:not(.dropdown-hover):focus, .rui-navbar-dark.rui-navbar .dropdown:not(.dropdown-hover).focus {
    color: #c7d1db;
}

.rui-navbar-dark.rui-navbar .dropdown:not(.dropdown-hover):focus > span:not([class]), .rui-navbar-dark.rui-navbar .dropdown:not(.dropdown-hover).focus > span:not([class]) {
    color: #c7d1db;
}

.rui-navbar-dark.rui-navbar .dropdown:not(.dropdown-hover):focus > .rui-icon, .rui-navbar-dark.rui-navbar .dropdown:not(.dropdown-hover).focus > .rui-icon {
    stroke: #c7d1db;
}

.rui-navbar-dark.rui-navbar .dropdown:not(.dropdown-hover):active {
    color: #725ec3;
}

.rui-navbar-dark.rui-navbar .dropdown:not(.dropdown-hover):active > span:not([class]) {
    color: #725ec3;
}

.rui-navbar-dark.rui-navbar .dropdown:not(.dropdown-hover):active > .rui-icon {
    stroke: #725ec3;
}

.rui-navbar-dark.rui-navbar .dropdown:not(.dropdown-hover).show > .dropdown-item {
    color: #c7d1db;
    background-color: transparent;
}

.rui-navbar-dark.rui-navbar .dropdown:not(.dropdown-hover).show > .dropdown-item > span:not([class]) {
    color: #c7d1db;
}

.rui-navbar-dark.rui-navbar .dropdown:not(.dropdown-hover).show > .dropdown-item > .rui-icon {
    stroke: #c7d1db;
}

.rui-navbar-dark.rui-navbar .dropdown .dropdown-item-switch .dropdown-item:hover::after,
.rui-navbar-dark.rui-navbar .dropdown.show .dropdown-item-switch .dropdown-item:hover::after {
    background-color: #c7d1db;
}

.rui-navbar-dark.rui-navbar .dropdown .dropdown-item-switch > input:checked ~ .dropdown-item,
.rui-navbar-dark.rui-navbar .dropdown.show .dropdown-item-switch > input:checked ~ .dropdown-item {
    color: #725ec3;
}

.rui-navbar-dark.rui-navbar .dropdown .dropdown-item-switch > input:checked ~ .dropdown-item > span:not([class]),
.rui-navbar-dark.rui-navbar .dropdown.show .dropdown-item-switch > input:checked ~ .dropdown-item > span:not([class]) {
    color: #725ec3;
}

.rui-navbar-dark.rui-navbar .dropdown .dropdown-item-switch > input:checked ~ .dropdown-item > .rui-icon,
.rui-navbar-dark.rui-navbar .dropdown.show .dropdown-item-switch > input:checked ~ .dropdown-item > .rui-icon {
    stroke: #725ec3;
}

.rui-navbar-dark.rui-navbar .dropdown-hover.hover > .dropdown-item {
    color: #c7d1db;
}

.rui-navbar-dark.rui-navbar .dropdown-hover.hover > .dropdown-item > span:not([class]) {
    color: #c7d1db;
}

.rui-navbar-dark.rui-navbar .dropdown-hover.hover > .dropdown-item > .rui-icon {
    stroke: #c7d1db;
}

.rui-navbar-dark.rui-navbar .dropdown-hover.hover > .dropdown-item.btn-custom-round,
.rui-navbar-dark.rui-navbar .dropdown-hover.hover > .dropdown-item > .btn-custom-round {
    color: #6c757d;
}

.rui-navbar-dark.rui-navbar .dropdown-hover.hover > .dropdown-item.btn-custom-round::after,
.rui-navbar-dark.rui-navbar .dropdown-hover.hover > .dropdown-item > .btn-custom-round::after {
    background-color: #323942;
}

.rui-navbar-dark.rui-navbar .dropdown-menu {
    background-color: #2b323b;
    border-color: #252c36;
    -webkit-box-shadow: 0 3px 10px rgba(36, 43, 53, 0.9);
            box-shadow: 0 3px 10px rgba(36, 43, 53, 0.9);
}

.rui-navbar-dark.rui-navbar .dropdown-triangle .dropdown-menu[x-placement="bottom-start"] > .dropdown-menu-triangle,
.rui-navbar-dark.rui-navbar .dropdown-triangle .dropdown-menu[x-placement="bottom-end"] > .dropdown-menu-triangle,
.rui-navbar-dark.rui-navbar .dropdown-triangle .dropdown-menu > .dropdown-menu-triangle {
    border-color: transparent;
    border-bottom-color: #2b323b;
}

.rui-navbar-dark.rui-navbar .dropdown-triangle .dropdown-menu[x-placement="bottom-start"] > .dropdown-menu-triangle::before,
.rui-navbar-dark.rui-navbar .dropdown-triangle .dropdown-menu[x-placement="bottom-end"] > .dropdown-menu-triangle::before,
.rui-navbar-dark.rui-navbar .dropdown-triangle .dropdown-menu > .dropdown-menu-triangle::before {
    border-color: transparent;
    border-bottom-color: #252c36;
}

.rui-navbar-dark.rui-navbar .dropdown-triangle .dropdown-menu[x-placement="top-start"] > .dropdown-menu-triangle,
.rui-navbar-dark.rui-navbar .dropdown-triangle .dropdown-menu[x-placement="top-end"] > .dropdown-menu-triangle {
    border-color: transparent;
    border-top-color: #2b323b;
}

.rui-navbar-dark.rui-navbar .dropdown-triangle .dropdown-menu[x-placement="top-start"] > .dropdown-menu-triangle::before,
.rui-navbar-dark.rui-navbar .dropdown-triangle .dropdown-menu[x-placement="top-end"] > .dropdown-menu-triangle::before {
    border-color: transparent;
    border-top-color: #252c36;
}

.rui-navbar-dark.rui-navbar .dropdown-triangle .dropdown-menu[x-placement="right-start"] > .dropdown-menu-triangle {
    border-color: transparent;
    border-right-color: #2b323b;
}

.rui-navbar-dark.rui-navbar .dropdown-triangle .dropdown-menu[x-placement="right-start"] > .dropdown-menu-triangle::before {
    border-color: transparent;
    border-right-color: #252c36;
}

.rui-navbar-dark.rui-navbar .dropdown-triangle .dropdown-menu[x-placement="right-end"] > .dropdown-menu-triangle {
    border-color: transparent;
    border-right-color: #2b323b;
}

.rui-navbar-dark.rui-navbar .dropdown-triangle .dropdown-menu[x-placement="right-end"] > .dropdown-menu-triangle::before {
    border-color: transparent;
    border-right-color: #252c36;
}

.rui-navbar-dark.rui-navbar .dropdown-triangle .dropdown-menu[x-placement="left-start"] > .dropdown-menu-triangle {
    border-color: transparent;
    border-left-color: #2b323b;
}

.rui-navbar-dark.rui-navbar .dropdown-triangle .dropdown-menu[x-placement="left-start"] > .dropdown-menu-triangle::before {
    border-color: transparent;
    border-left-color: #252c36;
}

.rui-navbar-dark.rui-navbar .dropdown-triangle .dropdown-menu[x-placement="left-end"] > .dropdown-menu-triangle {
    border-color: transparent;
    border-left-color: #2b323b;
}

.rui-navbar-dark.rui-navbar .dropdown-triangle .dropdown-menu[x-placement="left-end"] > .dropdown-menu-triangle::before {
    border-color: transparent;
    border-left-color: #252c36;
}

.rui-navbar-dark.rui-navbar .media.media-filled:hover > .media-link, .rui-navbar-dark.rui-navbar .media.media-filled.hover > .media-link, .rui-navbar-dark.rui-navbar .media.media-filled.active > .media-link {
    background-color: #323942;
}

.rui-navbar-dark.rui-navbar .media.media-filled:hover > .media-link > .media-img, .rui-navbar-dark.rui-navbar .media.media-filled.hover > .media-link > .media-img, .rui-navbar-dark.rui-navbar .media.media-filled.active > .media-link > .media-img {
    color: #586069;
    background-color: #3c424c;
}

.rui-navbar-dark.rui-navbar .media.media-brand::before {
    background-color: #725ec3;
    -webkit-box-shadow: 0 0 0 2px #292f38;
            box-shadow: 0 0 0 2px #292f38;
}

.rui-navbar-dark.rui-navbar .media.media-primary::before {
    background-color: #007bff;
    -webkit-box-shadow: 0 0 0 2px #292f38;
            box-shadow: 0 0 0 2px #292f38;
}

.rui-navbar-dark.rui-navbar .media.media-secondary::before {
    background-color: #6c757d;
    -webkit-box-shadow: 0 0 0 2px #292f38;
            box-shadow: 0 0 0 2px #292f38;
}

.rui-navbar-dark.rui-navbar .media.media-success::before {
    background-color: #2fc787;
    -webkit-box-shadow: 0 0 0 2px #292f38;
            box-shadow: 0 0 0 2px #292f38;
}

.rui-navbar-dark.rui-navbar .media.media-danger::before {
    background-color: #ef5164;
    -webkit-box-shadow: 0 0 0 2px #292f38;
            box-shadow: 0 0 0 2px #292f38;
}

.rui-navbar-dark.rui-navbar .media.media-warning::before {
    background-color: #fdbf21;
    -webkit-box-shadow: 0 0 0 2px #292f38;
            box-shadow: 0 0 0 2px #292f38;
}

.rui-navbar-dark.rui-navbar .media.media-info::before {
    background-color: #32bfc8;
    -webkit-box-shadow: 0 0 0 2px #292f38;
            box-shadow: 0 0 0 2px #292f38;
}

.rui-navbar-dark.rui-navbar .media.media-light::before {
    background-color: #f8f9fa;
    -webkit-box-shadow: 0 0 0 2px #292f38;
            box-shadow: 0 0 0 2px #292f38;
}

.rui-navbar-dark.rui-navbar .media.media-dark::before {
    background-color: #393f49;
    -webkit-box-shadow: 0 0 0 2px #292f38;
            box-shadow: 0 0 0 2px #292f38;
}

.rui-navbar-dark.rui-navbar .media > .media-icon {
    color: #4f565f;
}

.rui-navbar-dark.rui-navbar .media > .media-icon:hover, .rui-navbar-dark.rui-navbar .media > .media-icon.hover {
    color: #c7d1db;
}

.rui-navbar-dark.rui-navbar .media .media-img {
    color: #4f565f;
    background-color: #323942;
}

.rui-navbar-dark.rui-navbar .media .media-title {
    color: #c7d1db;
}

.rui-navbar-dark.rui-navbar .media .media-time {
    color: #4f565f;
}

.rui-navbar-dark.rui-navbar .media .media-subtitle {
    color: #626973;
}

.rui-navbar-dark.rui-navbar .media:hover > .media-img,
.rui-navbar-dark.rui-navbar .media.hover > .media-img,
.rui-navbar-dark.rui-navbar .media:hover > .media-link > .media-img,
.rui-navbar-dark.rui-navbar .media.hover > .media-link > .media-img,
.rui-navbar-dark.rui-navbar .media > .media-link:hover > .media-img,
.rui-navbar-dark.rui-navbar .media > .media-link.hover > .media-img {
    color: #586069;
    background-color: #3c424c;
}

.rui-navbar-dark.rui-navbar .media-country .media-title {
    color: #626973;
}

.rui-navbar-dark.rui-navbar-mobile {
    -webkit-box-shadow: 0 0 0 5px #292f38;
            box-shadow: 0 0 0 5px #292f38;
}

.rui-navbar-dark.rui-navbar-mobile + .rui-navbar-bg {
    background-color: rgba(69, 76, 85, 0.85);
}

.rui-navbar-dark.rui-navbar-mobile > .rui-navbar-collapse:not(.show)::-webkit-scrollbar-thumb {
    background-color: #292f38;
}

.rui-navbar-dark.rui-navbar-mobile > .rui-navbar-collapse::-webkit-scrollbar-track {
    background-color: #292f38;
}

.rui-navbar-dark.rui-navbar-mobile > .rui-navbar-collapse::-webkit-scrollbar {
    background-color: #292f38;
}

.rui-navbar-dark.rui-navbar-mobile > .rui-navbar-collapse::-webkit-scrollbar-thumb {
    background-color: #454c55;
}

.rui-navbar-dark.rui-navbar-mobile .rui-navbar-content .nav .dropdown-menu > .dropdown > a::after {
    background-color: #586069;
}

.rui-navbar-dark.rui-navbar-mobile .rui-navbar-content .nav .dropdown-menu > .dropdown.show > a::after {
    background-color: #c7d1db;
}

.rui-navbar-dark.rui-navbar-mobile .dropdown-menu .dropdown-item-switch .dropdown-item > .rui-dropdown-circle {
    left: -10px;
}

/*------------------------------------------------------------------

  Spotlight Mode

 -------------------------------------------------------------------*/
.rui-spotlightmode .rui-navbar,
.rui-spotlightmode .rui-yaybar {
    opacity: 0;
}

.rui-spotlightmode .rui-navbar:hover, .rui-spotlightmode .rui-navbar.hover,
.rui-spotlightmode .rui-yaybar:hover,
.rui-spotlightmode .rui-yaybar.hover {
    opacity: 1;
}

/*------------------------------------------------------------------

  Section lines

 -------------------------------------------------------------------*/
.rui-section-lines .rui-page-title {
    border-bottom: 1px solid #e6ecf0;
}

.rui-section-lines .rui-footer {
    border-top: 1px solid #e6ecf0;
}

.rui-section-lines .rui-navbar-top {
    border-bottom: 1px solid #e6ecf0;
}

.rui-section-lines .rui-navbar-mobile {
    -webkit-box-shadow: -5px 5px 0 0 #fff, 5px 5px 0 0 #fff, -5px -5px 0 0 #fff, 5px -5px 0 0 #fff, -5px 6px 0 #e6ecf0, 5px 6px 0 #e6ecf0;
            box-shadow: -5px 5px 0 0 #fff, 5px 5px 0 0 #fff, -5px -5px 0 0 #fff, 5px -5px 0 0 #fff, -5px 6px 0 #e6ecf0, 5px 6px 0 #e6ecf0;
}

.rui-section-lines .rui-navbar-dark.rui-navbar-top {
    border-bottom-color: #3c424c;
}

.rui-section-lines .rui-navbar-dark.rui-navbar-mobile {
    -webkit-box-shadow: -5px 5px 0 0 #292f38, 5px 5px 0 0 #292f38, -5px -5px 0 0 #292f38, 5px -5px 0 0 #292f38, -5px 6px 0 #3c424c, 5px 6px 0 #3c424c;
            box-shadow: -5px 5px 0 0 #292f38, 5px 5px 0 0 #292f38, -5px -5px 0 0 #292f38, 5px -5px 0 0 #292f38, -5px 6px 0 #3c424c, 5px 6px 0 #3c424c;
}

.rui-section-lines.yay-hide .yaybar.rui-yaybar.yay-overlay {
    -webkit-box-shadow: 5px 0 0 #fff, -5px 0 0 #fff, 6px 0 0 #e6ecf0;
            box-shadow: 5px 0 0 #fff, -5px 0 0 #fff, 6px 0 0 #e6ecf0;
}

.rui-section-lines .rui-yaybar {
    -webkit-box-shadow: 1px 0 0 #e6ecf0;
            box-shadow: 1px 0 0 #e6ecf0;
}

.rui-section-lines .rui-yaybar-icons {
    -webkit-box-shadow: 0 -1px 0 #e6ecf0;
            box-shadow: 0 -1px 0 #e6ecf0;
}

.rui-section-lines .rui-yaybar-dark .rui-yaybar-icons {
    -webkit-box-shadow: 0 -1px 0 #3c424c;
            box-shadow: 0 -1px 0 #3c424c;
}

/*------------------------------------------------------------------

  Header

 -------------------------------------------------------------------*/
.rui-header {
    position: relative;
    display: block;
    z-index: 1;
}

/*------------------------------------------------------------------

  Sign

 -------------------------------------------------------------------*/
.rui-sign {
    display: -webkit-box;
    display: -webkit-flex;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-orient: vertical;
    -webkit-box-direction: normal;
    -webkit-flex-direction: column;
        -ms-flex-direction: column;
            flex-direction: column;
    min-height: 100vh;
}

.rui-sign > .row {
    min-height: 100vh;
}

.rui-sign .rui-sign-form {
    max-width: 380px;
    padding: 30px;
}

.rui-sign .rui-sign-form-cloud {
    max-width: 400px;
    padding: 40px;
    background-color: #fff;
    border-radius: .25rem;
    -webkit-box-shadow: 0 3px 10px rgba(0, 0, 0, 0.03);
            box-shadow: 0 3px 10px rgba(0, 0, 0, 0.03);
}

.rui-sign .rui-sign-or {
    display: -webkit-box;
    display: -webkit-flex;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-align: center;
    -webkit-align-items: center;
        -ms-flex-align: center;
            align-items: center;
    color: #bcbec0;
}

.rui-sign .rui-sign-or::before, .rui-sign .rui-sign-or::after {
    content: "";
    display: block;
    width: 100%;
    height: 1px;
    margin-bottom: -3px;
    border-bottom: 1px solid #e6ecf0;
}

.rui-sign .rui-sign-or::before {
    margin-right: 20px;
}

.rui-sign .rui-sign-or::after {
    margin-left: 20px;
}

.rui-sign a:not(.btn) {
    color: #bcbec0;
}

.rui-sign a:not(.btn):hover {
    color: #4b515b;
    text-decoration: none;
}

/*------------------------------------------------------------------

  Error

 -------------------------------------------------------------------*/
.rui-page-error h1 {
    font-size: 180px;
}

.rui-page-error > .row {
    -webkit-box-orient: vertical;
    -webkit-box-direction: normal;
    -webkit-flex-direction: column;
        -ms-flex-direction: column;
            flex-direction: column;
    -webkit-box-align: center;
    -webkit-align-items: center;
        -ms-flex-align: center;
            align-items: center;
    -webkit-box-pack: justify;
    -webkit-justify-content: space-between;
        -ms-flex-pack: justify;
            justify-content: space-between;
    min-height: 100vh;
}

/*------------------------------------------------------------------

  Mailbox

 -------------------------------------------------------------------*/
.rui-mailbox {
    position: relative;
    display: -webkit-box;
    display: -webkit-flex;
    display: -ms-flexbox;
    display: flex;
    -webkit-flex-wrap: wrap;
        -ms-flex-wrap: wrap;
            flex-wrap: wrap;
    overflow: hidden;
    border: 1px solid #e6ecf0;
    border-radius: .25rem;
}

.rui-mailbox .rui-mailbox-head {
    display: -webkit-box;
    display: -webkit-flex;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-align: center;
    -webkit-align-items: center;
        -ms-flex-align: center;
            align-items: center;
    width: 100%;
    border-bottom: 1px solid #e6ecf0;
}

.rui-mailbox .rui-mailbox-search {
    display: -webkit-box;
    display: -webkit-flex;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-flex: 0;
    -webkit-flex: 0 0 30%;
        -ms-flex: 0 0 30%;
            flex: 0 0 30%;
    -webkit-box-align: center;
    -webkit-align-items: center;
        -ms-flex-align: center;
            align-items: center;
    max-width: 30%;
    padding: 18px 30px 18px 19px;
}

.rui-mailbox .rui-mailbox-search > form {
    width: 100%;
}

.rui-mailbox .rui-mailbox-search + .rui-mailbox-buttons {
    padding-left: 30px;
}

.rui-mailbox .rui-mailbox-buttons {
    -webkit-box-flex: 0;
    -webkit-flex: 0 0 70%;
        -ms-flex: 0 0 70%;
            flex: 0 0 70%;
    max-width: 70%;
    padding: 21px 30px;
}

.rui-mailbox .rui-mailbox-buttons > ul {
    display: -webkit-box;
    display: -webkit-flex;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-align: center;
    -webkit-align-items: center;
        -ms-flex-align: center;
            align-items: center;
    padding: 0;
    margin: -30px -10px 0;
}

.rui-mailbox .rui-mailbox-buttons > ul > li {
    display: -webkit-inline-box;
    display: -webkit-inline-flex;
    display: -ms-inline-flexbox;
    display: inline-flex;
    padding: 30px 10px 0;
    list-style: none;
}

.rui-mailbox .rui-mailbox-write {
    width: 38px;
    height: 38px;
    color: #fff;
    background-color: rgba(114, 94, 195, 0.8);
}

.rui-mailbox .rui-mailbox-footer {
    width: 100%;
    background-color: #fff;
    border-top: 1px solid #e6ecf0;
}

.rui-mailbox .rui-mailbox-list {
    -webkit-box-flex: 0;
    -webkit-flex: 0 0 30%;
        -ms-flex: 0 0 30%;
            flex: 0 0 30%;
    max-width: 30%;
    min-height: 380px;
    background-color: #fff;
    border-right: 1px solid #e6ecf0;
}

.rui-mailbox .rui-mailbox-list > ul {
    padding: 0;
    margin: 15px 0;
}

.rui-mailbox .rui-mailbox-list > ul > li {
    list-style: none;
}

.rui-mailbox .os-content > ul {
    padding: 0;
    margin: 15px 0;
}

.rui-mailbox .os-content > ul > li {
    list-style: none;
}

.rui-mailbox .rui-mailbox-content {
    -webkit-box-flex: 0;
    -webkit-flex: 0 0 70%;
        -ms-flex: 0 0 70%;
            flex: 0 0 70%;
    max-width: 70%;
}

.rui-mailbox .rui-mailbox-content-head,
.rui-mailbox .rui-mailbox-content-body,
.rui-mailbox .rui-mailbox-content-footer {
    position: relative;
}

.rui-mailbox .rui-mailbox-content-head {
    padding: 30px;
}

.rui-mailbox .rui-mailbox-content-head + .rui-mailbox-content-body {
    border-top: 1px solid #e6ecf0;
}

.rui-mailbox .rui-mailbox-content-body {
    position: relative;
    padding: 30px 30px;
}

.rui-mailbox .rui-mailbox-content-body + .rui-mailbox-content-footer {
    padding-top: 0;
}

.rui-mailbox .rui-mailbox-content-footer {
    padding: 30px;
}

.rui-mailbox .rui-mailbox-content-title {
    margin-bottom: -5px;
}

.rui-mailbox .rui-mailbox-content-title + .rui-mailbox-content-subtitle {
    margin-top: 13px;
}

.rui-mailbox .rui-mailbox-content-subtitle {
    margin-bottom: -5px;
    color: #6c757d;
}

.rui-mailbox .rui-mailbox-content-date {
    position: absolute;
    display: -webkit-box;
    display: -webkit-flex;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-orient: vertical;
    -webkit-box-direction: normal;
    -webkit-flex-direction: column;
        -ms-flex-direction: column;
            flex-direction: column;
    -webkit-box-align: end;
    -webkit-align-items: flex-end;
        -ms-flex-align: end;
            align-items: flex-end;
    top: 25px;
    right: 30px;
    color: #bcbec0;
}

.rui-mailbox .rui-mailbox-item {
    position: relative;
    display: block;
    padding: 15px 30px;
    background-color: #fff;
    -webkit-transition: background-color .1s ease-in-out;
    -o-transition: background-color .1s ease-in-out;
    transition: background-color .1s ease-in-out;
}

.rui-mailbox .rui-mailbox-item:hover, .rui-mailbox .rui-mailbox-item.hover, .rui-mailbox .rui-mailbox-item.active {
    text-decoration: none;
    background-color: #f8f9fa;
}

.rui-mailbox .rui-mailbox-item-unread .rui-mailbox-item-title::after {
    position: relative;
    display: inline-block;
    top: -2px;
    width: 6px;
    height: 6px;
    margin-left: 10px;
    background-color: #eaecf0;
    border-radius: 100%;
}

.rui-mailbox .rui-mailbox-item-title {
    display: block;
    margin-bottom: 4px;
}

.rui-mailbox .rui-mailbox-item-title::after {
    content: "";
}

.rui-mailbox .rui-mailbox-item-subtitle {
    display: block;
    margin-bottom: 4px;
    color: #393f49;
}

.rui-mailbox .rui-mailbox-item-text {
    display: block;
    height: 19px;
    margin-bottom: -5px;
    overflow: hidden;
    color: #bcbec0;
    -o-text-overflow: ellipsis;
       text-overflow: ellipsis;
    white-space: nowrap;
}

.rui-mailbox .rui-mailbox-item-date {
    position: absolute;
    display: inline-block;
    top: 14px;
    right: 30px;
    color: #bcbec0;
}

@media (max-width: 1199.98px) {
    .rui-mailbox .rui-mailbox-search,
    .rui-mailbox .rui-mailbox-list {
        -webkit-box-flex: 0;
        -webkit-flex: 0 0 40%;
            -ms-flex: 0 0 40%;
                flex: 0 0 40%;
        max-width: 40%;
    }
    .rui-mailbox .rui-mailbox-buttons,
    .rui-mailbox .rui-mailbox-content {
        -webkit-box-flex: 0;
        -webkit-flex: 0 0 60%;
            -ms-flex: 0 0 60%;
                flex: 0 0 60%;
        max-width: 60%;
    }
}

@media (max-width: 767.98px) {
    .rui-mailbox .rui-mailbox-search,
    .rui-mailbox .rui-mailbox-buttons,
    .rui-mailbox .rui-mailbox-list,
    .rui-mailbox .rui-mailbox-content {
        -webkit-box-flex: 0;
        -webkit-flex: none;
            -ms-flex: none;
                flex: none;
        max-width: none;
    }
    .rui-mailbox .rui-mailbox-head {
        background-color: #fff;
    }
    .rui-mailbox .rui-mailbox-search {
        width: 100%;
        max-width: 100%;
    }
    .rui-mailbox .rui-mailbox-list {
        width: 100%;
        height: 380px;
        border-right: 0;
    }
    .rui-mailbox .rui-mailbox-list > ul {
        margin: 15px 0;
    }
    .rui-mailbox .os-content > ul {
        margin: 15px 0;
    }
    .rui-mailbox .rui-mailbox-item.active {
        background-color: transparent;
    }
    .rui-mailbox .rui-mailbox-item-date {
        top: 14px;
    }
    .rui-mailbox .rui-mailbox-content {
        position: absolute;
        top: 0;
        right: 0;
        bottom: 0;
        left: 0;
        visibility: hidden;
        background-color: #fff;
        opacity: 0;
        -webkit-transition: visibility .15s ease-in-out, opacity .15s ease-in-out;
        -o-transition: visibility .15s ease-in-out, opacity .15s ease-in-out;
        transition: visibility .15s ease-in-out, opacity .15s ease-in-out;
        z-index: -1;
    }
    .rui-mailbox .rui-mailbox-content.open {
        visibility: visible;
        opacity: 1;
    }
    .rui-mailbox .rui-mailbox-content.show {
        z-index: 2;
    }
    .rui-mailbox .rui-mailbox-content-body {
        height: calc(100% - 104px);
    }
}

/*------------------------------------------------------------------

  Messenger

 -------------------------------------------------------------------*/
.rui-messenger {
    position: relative;
    padding-top: 76px;
    background-color: #fff;
}

.rui-messenger .media.media-filled > .media-link {
    border-radius: 0;
}

.rui-messenger .media .media-body {
    -webkit-box-flex: 0;
    -webkit-flex: 0 0 calc(100% - 55px);
        -ms-flex: 0 0 calc(100% - 55px);
            flex: 0 0 calc(100% - 55px);
    max-width: calc(100% - 55px);
}

.rui-messenger .media .media-subtitle {
    overflow: hidden;
    -o-text-overflow: ellipsis;
       text-overflow: ellipsis;
    white-space: nowrap;
}

.rui-messenger.rui-messenger-mini {
    height: 100%;
    padding-top: 75px;
    padding-bottom: 75px;
}

.rui-messenger.rui-messenger-mini.open > .rui-messenger-head,
.rui-messenger.rui-messenger-mini.open > .rui-messenger-list,
.rui-messenger.rui-messenger-mini.open > .rui-messenger-footer {
    position: absolute;
    height: 0;
    overflow: hidden;
    visibility: hidden;
    opacity: 0;
}

.rui-messenger.rui-messenger-mini.open > .rui-messenger-chat {
    position: relative;
    height: calc(100% + 150px);
    margin-top: -75px;
    visibility: visible;
    opacity: 1;
}

.rui-messenger.rui-messenger-mini .rui-messenger-head {
    -webkit-box-pack: center;
    -webkit-justify-content: center;
        -ms-flex-pack: center;
            justify-content: center;
    padding: 30px;
}

.rui-messenger.rui-messenger-mini .rui-messenger-list {
    height: 100%;
}

.rui-messenger.rui-messenger-mini .rui-messenger-footer {
    position: absolute;
    display: -webkit-box;
    display: -webkit-flex;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-align: center;
    -webkit-align-items: center;
        -ms-flex-align: center;
            align-items: center;
    -webkit-box-pack: justify;
    -webkit-justify-content: space-between;
        -ms-flex-pack: justify;
            justify-content: space-between;
    right: 0;
    bottom: 0;
    left: 0;
    min-height: 75px;
    padding: 20px 30px;
    z-index: 1;
}

.rui-messenger.rui-messenger-mini .rui-messenger-search {
    position: absolute;
    display: -webkit-box;
    display: -webkit-flex;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-align: center;
    -webkit-align-items: center;
        -ms-flex-align: center;
            align-items: center;
    top: 24px;
    left: 30px;
    width: auto;
}

.rui-messenger.rui-messenger-mini .rui-messenger-search > .btn {
    margin: -6px 0 -10px -12px;
}

.rui-messenger.rui-messenger-mini .rui-messenger-search > .btn:focus + input, .rui-messenger.rui-messenger-mini .rui-messenger-search > .btn.focus + input {
    visibility: visible;
    opacity: 1;
}

.rui-messenger.rui-messenger-mini .rui-messenger-search > input {
    position: absolute;
    top: -6px;
    right: 30px;
    left: 25px;
    width: 312px;
    visibility: hidden;
    background-color: #fff;
    opacity: 0;
    will-change: opacity;
    -webkit-transition: opacity .15s ease-in-out, visibility .15s ease-in-out;
    -o-transition: opacity .15s ease-in-out, visibility .15s ease-in-out;
    transition: opacity .15s ease-in-out, visibility .15s ease-in-out;
}

.rui-messenger.rui-messenger-mini .rui-messenger-search > input:focus, .rui-messenger.rui-messenger-mini .rui-messenger-search > input.focus {
    visibility: visible;
    -webkit-box-shadow: none;
            box-shadow: none;
    opacity: 1;
}

.rui-messenger.rui-messenger-mini .rui-messenger-chat {
    position: absolute;
    top: 0;
    right: 0;
    left: 0;
    padding: 75px 0;
    visibility: hidden;
    opacity: 0;
    will-change: opacity;
    -webkit-transition: visibility .15s ease-in-out, opacity .15s ease-in-out;
    -o-transition: visibility .15s ease-in-out, opacity .15s ease-in-out;
    transition: visibility .15s ease-in-out, opacity .15s ease-in-out;
    z-index: -1;
}

.rui-messenger.rui-messenger-mini .rui-messenger-chat .rui-messenger-head {
    position: absolute;
    -webkit-box-pack: justify;
    -webkit-justify-content: space-between;
        -ms-flex-pack: justify;
            justify-content: space-between;
    top: 0;
    right: 0;
    left: 0;
}

.rui-messenger.rui-messenger-mini .rui-messenger-chat .rui-messenger-body {
    right: 0;
    left: 0;
    height: 100%;
    max-height: initial;
}

.rui-messenger.rui-messenger-mini .rui-messenger-chat .rui-messenger-footer {
    position: absolute;
    display: block;
    right: 0;
    bottom: 0;
    left: 0;
    padding: 28px 30px;
}

.rui-messenger.rui-messenger-full {
    display: -webkit-box;
    display: -webkit-flex;
    display: -ms-flexbox;
    display: flex;
    border: 1px solid #e6ecf0;
    border-radius: .25rem;
}

.rui-messenger.rui-messenger-full .rui-messenger-head {
    height: 75px;
    border-bottom: 1px solid #e6ecf0;
}

@media (max-width: 575.98px) {
    .rui-messenger.rui-messenger-full .rui-messenger-head {
        border: 1px solid #e6ecf0;
        border-radius: .25rem;
    }
}

.rui-messenger.rui-messenger-full .rui-messenger-list {
    -webkit-box-flex: 0;
    -webkit-flex: 0 0 30%;
        -ms-flex: 0 0 30%;
            flex: 0 0 30%;
    max-width: 30%;
    border-right: 1px solid #e6ecf0;
}

@media (max-width: 575.98px) {
    .rui-messenger.rui-messenger-full .rui-messenger-list {
        padding-right: 8px;
        padding-left: 0;
    }
    .rui-messenger.rui-messenger-full .rui-messenger-list .collapse,
    .rui-messenger.rui-messenger-full .rui-messenger-list .collapsing {
        margin-right: 0;
        margin-left: 0;
    }
    .rui-messenger.rui-messenger-full .rui-messenger-list .rui-messenger-collapse-btn > .rui-icon {
        margin-right: 5px;
    }
    .rui-messenger.rui-messenger-full .rui-messenger-list .media::before {
        left: 30px;
    }
    .rui-messenger.rui-messenger-full .rui-messenger-list .media > .media-link {
        padding-right: 0;
        padding-left: 0;
    }
    .rui-messenger.rui-messenger-full .rui-messenger-list .media > .media-icon {
        right: 0;
    }
}

.rui-messenger.rui-messenger-full .rui-messenger-item {
    -webkit-flex-wrap: nowrap;
        -ms-flex-wrap: nowrap;
            flex-wrap: nowrap;
}

.rui-messenger.rui-messenger-full > .rui-messenger-footer {
    position: absolute;
    display: none;
    right: 0;
    bottom: 0;
    left: 0;
    min-height: 75px;
    padding: 20px 30px;
    border-top: 1px solid #e6ecf0;
    z-index: 1;
}

@media (max-width: 575.98px) {
    .rui-messenger.rui-messenger-full > .rui-messenger-footer {
        border: 1px solid #e6ecf0;
        border-radius: .25rem;
    }
}

.rui-messenger.rui-messenger-full .rui-messenger-head-search {
    -webkit-box-flex: 0;
    -webkit-flex: 0 0 30%;
        -ms-flex: 0 0 30%;
            flex: 0 0 30%;
    max-width: 30%;
    padding: 18px 30px 18px 17px;
}

.rui-messenger.rui-messenger-full .rui-messenger-head-buttons {
    -webkit-box-flex: 0;
    -webkit-flex: 0 0 70%;
        -ms-flex: 0 0 70%;
            flex: 0 0 70%;
    max-width: 70%;
    padding: 21px 30px;
    margin: 0;
}

.rui-messenger.rui-messenger-full .rui-messenger-head-buttons > ul {
    display: -webkit-box;
    display: -webkit-flex;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-align: center;
    -webkit-align-items: center;
        -ms-flex-align: center;
            align-items: center;
    padding: 0;
    margin: 0;
    margin: -30px -10px 0;
}

.rui-messenger.rui-messenger-full .rui-messenger-head-buttons > ul > li {
    display: -webkit-inline-box;
    display: -webkit-inline-flex;
    display: -ms-inline-flexbox;
    display: inline-flex;
    padding: 30px 10px 0;
    list-style: none;
}

.rui-messenger.rui-messenger-full .rui-messenger-chat {
    -webkit-box-flex: 0;
    -webkit-flex: 0 0 70%;
        -ms-flex: 0 0 70%;
            flex: 0 0 70%;
    max-width: 70%;
}

.rui-messenger.rui-messenger-full .rui-messenger-chat .rui-messenger-head .rui-messenger-head-buttons {
    -webkit-box-flex: 0;
    -webkit-flex: 0 0 100%;
        -ms-flex: 0 0 100%;
            flex: 0 0 100%;
    max-width: 100%;
}

.rui-messenger.rui-messenger-full .rui-messenger-chat .rui-messenger-body {
    height: 400px;
    padding: 30px 30px 43px;
}

@media (max-width: 575.98px) {
    .rui-messenger.rui-messenger-full .rui-messenger-chat .rui-messenger-body {
        padding-right: 8px;
        padding-left: 0;
    }
}

.rui-messenger.rui-messenger-full .rui-messenger-chat .rui-messenger-footer {
    border-top: 1px solid #e6ecf0;
}

@media (max-width: 575.98px) {
    .rui-messenger.rui-messenger-full .rui-messenger-chat .rui-messenger-footer {
        border: 1px solid #e6ecf0;
        border-radius: .25rem;
    }
}

@media (max-width: 1199.98px) {
    .rui-messenger.rui-messenger-full .rui-messenger-list,
    .rui-messenger.rui-messenger-full .rui-messenger-head-search {
        -webkit-box-flex: 0;
        -webkit-flex: 0 0 40%;
            -ms-flex: 0 0 40%;
                flex: 0 0 40%;
        max-width: 40%;
    }
    .rui-messenger.rui-messenger-full .rui-messenger-chat,
    .rui-messenger.rui-messenger-full .rui-messenger-head-buttons {
        -webkit-box-flex: 0;
        -webkit-flex: 0 0 60%;
            -ms-flex: 0 0 60%;
                flex: 0 0 60%;
        max-width: 60%;
    }
}

@media (max-width: 991.98px) {
    .rui-messenger.rui-messenger-full {
        display: block;
        padding-top: 75px;
        padding-bottom: 75px;
        border: 1px solid #e6ecf0;
        border-radius: .25rem;
    }
    .rui-messenger.rui-messenger-full.open > .rui-messenger-head,
    .rui-messenger.rui-messenger-full.open > .rui-messenger-list,
    .rui-messenger.rui-messenger-full.open > .rui-messenger-footer {
        position: absolute;
        height: 0;
        overflow: hidden;
        visibility: hidden;
        opacity: 0;
    }
    .rui-messenger.rui-messenger-full.open > .rui-messenger-chat {
        position: relative;
        margin-top: -75px;
        margin-bottom: -75px;
        visibility: visible;
        opacity: 1;
    }
    .rui-messenger.rui-messenger-full .rui-messenger-head {
        position: absolute;
    }
    .rui-messenger.rui-messenger-full .rui-messenger-head-search {
        -webkit-box-flex: 0;
        -webkit-flex: 0 0 100%;
            -ms-flex: 0 0 100%;
                flex: 0 0 100%;
        -webkit-flex-basis: 0;
            -ms-flex-preferred-size: 0;
                flex-basis: 0;
        -webkit-box-flex: 1;
        -webkit-flex-grow: 1;
            -ms-flex-positive: 1;
                flex-grow: 1;
        max-width: 100%;
        padding-right: 20px;
    }
    .rui-messenger.rui-messenger-full .rui-messenger-list {
        -webkit-box-flex: 0;
        -webkit-flex: none;
            -ms-flex: none;
                flex: none;
        max-width: none;
        height: 380px;
        border-right: 0;
    }
    .rui-messenger.rui-messenger-full .media.media-filled.active > .rui-messenger-item {
        background-color: transparent;
    }
    .rui-messenger.rui-messenger-full > .rui-messenger-footer {
        display: -webkit-box;
        display: -webkit-flex;
        display: -ms-flexbox;
        display: flex;
        -webkit-box-align: center;
        -webkit-align-items: center;
            -ms-flex-align: center;
                align-items: center;
        -webkit-box-pack: justify;
        -webkit-justify-content: space-between;
            -ms-flex-pack: justify;
                justify-content: space-between;
    }
    .rui-messenger.rui-messenger-full .rui-messenger-chat {
        position: absolute;
        -webkit-box-flex: 0;
        -webkit-flex: none;
            -ms-flex: none;
                flex: none;
        top: 0;
        right: 0;
        left: 0;
        max-width: none;
        padding: 75px 0;
        visibility: hidden;
        opacity: 0;
        will-change: opacity;
        -webkit-transition: visibility .15s ease-in-out, opacity .15s ease-in-out;
        -o-transition: visibility .15s ease-in-out, opacity .15s ease-in-out;
        transition: visibility .15s ease-in-out, opacity .15s ease-in-out;
        z-index: -1;
    }
    .rui-messenger.rui-messenger-full .rui-messenger-chat .rui-messenger-head {
        position: absolute;
        top: 0;
        right: 0;
        left: 0;
    }
    .rui-messenger.rui-messenger-full .rui-messenger-chat .rui-messenger-footer {
        position: absolute;
        right: 0;
        bottom: 0;
        left: 0;
        padding: 28px 30px;
    }
}

@media (max-width: 575.98px) {
    .rui-messenger.rui-messenger-full {
        border: 0;
    }
}

.rui-messenger .rui-messenger-list {
    padding: 30px 30px 43px;
}

.rui-messenger .rui-messenger-list .collapse,
.rui-messenger .rui-messenger-list .collapsing {
    margin-right: -30px;
    margin-left: -30px;
}

.rui-messenger .rui-messenger-head {
    position: absolute;
    display: -webkit-box;
    display: -webkit-flex;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-align: center;
    -webkit-align-items: center;
        -ms-flex-align: center;
            align-items: center;
    -webkit-box-pack: justify;
    -webkit-justify-content: space-between;
        -ms-flex-pack: justify;
            justify-content: space-between;
    top: 0;
    right: 0;
    left: 0;
    z-index: 2;
}

.rui-messenger .rui-messenger-head-empty {
    width: 25px;
}

.rui-messenger .rui-messenger-chat {
    position: relative;
    background-color: #fff;
}

.rui-messenger .rui-messenger-chat .rui-messenger-body.rui-scrollbar {
    opacity: 0;
    -webkit-transition: opacity .15s ease-in-out;
    -o-transition: opacity .15s ease-in-out;
    transition: opacity .15s ease-in-out;
}

.rui-messenger .rui-messenger-chat .rui-messenger-body.rui-scrollbar.os-host {
    opacity: 1;
}

.rui-messenger .rui-messenger-chat.open {
    visibility: visible;
    opacity: 1;
}

.rui-messenger .rui-messenger-chat.show {
    z-index: 2;
}

.rui-messenger .rui-messenger-chat .rui-messenger-custom-file {
    position: absolute;
    visibility: hidden;
}

.rui-messenger .rui-messenger-chat .rui-messenger-head {
    position: relative;
    padding: 30px;
}

.rui-messenger .rui-messenger-chat .rui-messenger-body {
    position: relative;
    display: -webkit-box;
    display: -webkit-flex;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-orient: vertical;
    -webkit-box-direction: normal;
    -webkit-flex-direction: column;
        -ms-flex-direction: column;
            flex-direction: column;
    -webkit-box-align: start;
    -webkit-align-items: flex-start;
        -ms-flex-align: start;
            align-items: flex-start;
    padding: 30px;
    border: 0;
}

.rui-messenger .rui-messenger-chat .rui-messenger-body::before {
    content: "";
    display: block;
    margin-bottom: auto;
}

.rui-messenger .rui-messenger-chat .rui-messenger-body .os-viewport {
    position: relative;
    display: -webkit-box;
    display: -webkit-flex;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-orient: vertical;
    -webkit-box-direction: normal;
    -webkit-flex-direction: column;
        -ms-flex-direction: column;
            flex-direction: column;
    -webkit-box-align: start;
    -webkit-align-items: flex-start;
        -ms-flex-align: start;
            align-items: flex-start;
    height: 100%;
}

.rui-messenger .rui-messenger-chat .rui-messenger-body .os-viewport::before {
    content: "";
    display: block;
    margin-bottom: auto;
}

.rui-messenger .rui-messenger-chat .rui-messenger-body > div {
    display: block;
}

.rui-messenger .rui-messenger-chat .rui-messenger-footer {
    padding: 30px;
}

.rui-messenger .rui-messenger-chat .rui-messenger-footer > .input-group {
    width: auto;
    margin: -11px;
}

.rui-messenger .rui-messenger-chat .rui-messenger-message-datetime {
    display: block;
    padding: 26px 0;
    color: #d7d9e0;
    text-align: center;
}

.rui-messenger .rui-messenger-chat .rui-messenger-message-datetime:nth-child(2) {
    padding-top: 0;
    margin-top: -4px;
}

.rui-messenger .rui-messenger-chat .rui-messenger-message {
    position: relative;
    display: -webkit-box;
    display: -webkit-flex;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-align: end;
    -webkit-align-items: flex-end;
        -ms-flex-align: end;
            align-items: flex-end;
    -webkit-box-pack: end;
    -webkit-justify-content: flex-end;
        -ms-flex-pack: end;
            justify-content: flex-end;
    margin-bottom: 30px;
}

.rui-messenger .rui-messenger-chat .rui-messenger-message:last-child {
    margin-bottom: 0;
}

.rui-messenger .rui-messenger-chat .rui-messenger-message > ul {
    display: inline-block;
    -webkit-box-ordinal-group: 2;
    -webkit-order: 1;
        -ms-flex-order: 1;
            order: 1;
    padding: 0;
    margin: 0;
    color: #fff;
    text-align: right;
    background-color: rgba(114, 94, 195, 0.8);
    border-radius: 6px 6px 0;
}

.rui-messenger .rui-messenger-chat .rui-messenger-message > ul > li {
    position: relative;
    padding: 10px 15px;
    list-style: none;
}

.rui-messenger .rui-messenger-chat .rui-messenger-message > ul > li + li {
    padding-top: 0;
}

.rui-messenger .rui-messenger-chat .rui-messenger-message .rui-messenger-message-time {
    position: absolute;
    top: 100%;
    right: 0;
    margin-top: 5px;
    color: #d7d9e0;
}

.rui-messenger .rui-messenger-chat .rui-messenger-message:not(.rui-messenger-message-incoming) > ul {
    -webkit-box-ordinal-group: 2;
    -webkit-order: 1;
        -ms-flex-order: 1;
            order: 1;
}

.rui-messenger .rui-messenger-chat .rui-messenger-message:not(.rui-messenger-message-incoming) > .rui-messenger-message-edit {
    -webkit-box-ordinal-group: 1;
    -webkit-order: 0;
        -ms-flex-order: 0;
            order: 0;
    margin-right: 7px;
    margin-bottom: 3px;
}

.rui-messenger .rui-messenger-chat .rui-messenger-message .rui-messenger-message-edit {
    display: -webkit-box;
    display: -webkit-flex;
    display: -ms-flexbox;
    display: flex;
    opacity: 0;
    will-change: opacity;
    -webkit-transition: opacity .15s ease-in-out;
    -o-transition: opacity .15s ease-in-out;
    transition: opacity .15s ease-in-out;
}

.rui-messenger .rui-messenger-chat .rui-messenger-message .rui-messenger-message-edit > .btn {
    padding: 10px 8px;
    color: #d7d9e0;
    border: 0;
}

.rui-messenger .rui-messenger-chat .rui-messenger-message .rui-messenger-message-edit > .btn:hover, .rui-messenger .rui-messenger-chat .rui-messenger-message .rui-messenger-message-edit > .btn.hover {
    color: #393f49;
}

.rui-messenger .rui-messenger-chat .rui-messenger-message .rui-messenger-message-edit > .btn:focus, .rui-messenger .rui-messenger-chat .rui-messenger-message .rui-messenger-message-edit > .btn.focus {
    -webkit-box-shadow: none;
            box-shadow: none;
}

.rui-messenger .rui-messenger-chat .rui-messenger-message.rui-messenger-message-structure {
    display: none;
}

.rui-messenger .rui-messenger-chat .rui-messenger-message.rui-messenger-message-structure + .rui-messenger-datetime {
    padding-top: 0;
    margin-top: -4px;
}

.rui-messenger .rui-messenger-chat .rui-messenger-message.rui-messenger-message-incoming {
    -webkit-box-pack: start;
    -webkit-justify-content: flex-start;
        -ms-flex-pack: start;
            justify-content: flex-start;
}

.rui-messenger .rui-messenger-chat .rui-messenger-message.rui-messenger-message-incoming .rui-messenger-message-time {
    right: auto;
    left: 0;
}

.rui-messenger .rui-messenger-chat .rui-messenger-message.rui-messenger-message-incoming > ul {
    color: #4b515b;
    text-align: left;
    background-color: #f8f9fa;
    border-radius: 6px 6px 6px 0;
}

.rui-messenger .rui-messenger-chat .rui-messenger-message:hover .rui-messenger-message-edit, .rui-messenger .rui-messenger-chat .rui-messenger-message.hover .rui-messenger-message-edit {
    opacity: 1;
}

.rui-messenger .rui-messenger-chat .rui-messenger-textarea {
    height: 38px;
    min-height: 38px;
    max-height: 38px;
    padding-top: 8px;
    padding-bottom: 8px;
    resize: none;
}

.rui-messenger .rui-messenger-back {
    margin: -12px 0 -11px -16px;
}

.rui-messenger .rui-messenger-collapse {
    padding-bottom: 15px;
}

.rui-messenger .rui-messenger-collapse-btn {
    display: -webkit-box;
    display: -webkit-flex;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-pack: justify;
    -webkit-justify-content: space-between;
        -ms-flex-pack: justify;
            justify-content: space-between;
    padding: 0;
    margin-top: -5px;
    margin-bottom: 10px;
    font-size: 12px;
    color: #d7d9e0;
    text-transform: initial;
    -webkit-transition: color .15s ease-in-out;
    -o-transition: color .15s ease-in-out;
    transition: color .15s ease-in-out;
}

.rui-messenger .rui-messenger-collapse-btn.collapsed > .rui-icon {
    -webkit-transform: rotate(0);
        -ms-transform: rotate(0);
            transform: rotate(0);
}

.rui-messenger .rui-messenger-collapse-btn > .rui-icon {
    margin-right: -4px;
    -webkit-transition: -webkit-transform .15s ease-in-out;
    transition: -webkit-transform .15s ease-in-out;
    -o-transition: transform .15s ease-in-out;
    transition: transform .15s ease-in-out;
    transition: transform .15s ease-in-out, -webkit-transform .15s ease-in-out;
    -webkit-transform: rotate(90deg);
        -ms-transform: rotate(90deg);
            transform: rotate(90deg);
}

.rui-messenger .rui-messenger-collapse-btn:hover, .rui-messenger .rui-messenger-collapse-btn.hover {
    color: #393f49;
}

.rui-messenger .rui-messenger-collapse-btn:focus, .rui-messenger .rui-messenger-collapse-btn.focus {
    -webkit-box-shadow: none;
            box-shadow: none;
}

/*------------------------------------------------------------------

  Project Management

 -------------------------------------------------------------------*/
.rui-project-activity-list {
    padding: 0;
    margin: 0 -30px -30px;
}

.rui-project-activity-list .media.media-filled > .media-link {
    padding-top: 20.5px;
    padding-bottom: 20.5px;
}

.rui-project-activity-list > li,
.rui-project-activity-list > li.list-group-item {
    padding: 0;
}

.rui-project-activity-list > li > .rui-task-list,
.rui-project-activity-list > li.list-group-item > .rui-task-list {
    padding-left: 26px;
}

.rui-project-activity-list > li > .rui-task-list > li,
.rui-project-activity-list > li.list-group-item > .rui-task-list > li {
    padding: 20px 0;
    border: 0;
}

.rui-project-activity-list > li > .rui-task-list > li + li,
.rui-project-activity-list > li.list-group-item > .rui-task-list > li + li {
    padding-top: 0;
}

.rui-project-activity-list > li > .rui-task-list > li:last-child,
.rui-project-activity-list > li.list-group-item > .rui-task-list > li:last-child {
    padding-bottom: 0;
}

.rui-project-task-search {
    padding: 12px 30px 13px;
    margin: 0 -30px;
    border-top: 1px solid #e6ecf0;
}

.rui-project-task-info {
    display: -webkit-box;
    display: -webkit-flex;
    display: -ms-flexbox;
    display: flex;
    padding: 15px 30px;
    margin: 0 -30px;
    background-color: #f8f9fa;
    border-top: 1px solid #e6ecf0;
}

.rui-project-task-info-link {
    display: -webkit-box;
    display: -webkit-flex;
    display: -ms-flexbox;
    display: flex;
    margin-right: 20px;
    color: #6c757d;
}

.rui-project-task-info-link:hover, .rui-project-task-info-link.hover {
    color: #393f49;
    text-decoration: none;
}

.rui-project-task-info-link:hover > .rui-project-task-info-icon, .rui-project-task-info-link.hover > .rui-project-task-info-icon {
    color: #393f49;
}

.rui-project-task-info-icon {
    margin-right: 9px;
    color: #bcbec0;
    -webkit-transition: color .15s ease-in-out;
    -o-transition: color .15s ease-in-out;
    transition: color .15s ease-in-out;
}

.rui-project-task-info-title {
    display: block;
    margin-top: 1px;
}

.rui-project-task-list {
    margin: 0 -30px -30px;
}

.rui-project-task-list > li,
.rui-project-task-list > li.list-group-item {
    padding: 0;
}

.rui-project-releases-list {
    margin: 0 -30px -30px;
}

.rui-project-releases-list > li,
.rui-project-releases-list > li.list-group-item {
    padding: 30px;
}

.rui-project-settings-list {
    margin: 0 -30px -30px;
}

.rui-project-settings-list > li,
.rui-project-settings-list > li.list-group-item {
    padding: 30px;
}

.rui-project-settings-list .rui-project-settings-list {
    margin: 0;
}

.rui-project-settings-list .rui-project-settings-list > li {
    padding: 4px 0;
}

.rui-project-settings-list .rui-project-settings-list > li:first-child {
    padding-top: 0;
}

.rui-project-settings-list .rui-project-settings-list > li:last-child {
    padding-bottom: 0;
}

/*------------------------------------------------------------------

  File Manager

 -------------------------------------------------------------------*/
.rui-filemanager .rui-filemanager-table {
    min-width: 880px;
}

.rui-filemanager .rui-filemanager-table th,
.rui-filemanager .rui-filemanager-table td {
    border: 0;
}

.rui-filemanager .rui-filemanager-table thead th {
    font-weight: 400;
    color: #bcbec0;
}

.rui-filemanager .rui-filemanager-table thead th.sorting_asc, .rui-filemanager .rui-filemanager-table thead th.sorting_desc {
    color: #6c757d;
}

.rui-filemanager .rui-filemanager-table thead th:first-child {
    padding-left: 0;
}

.rui-filemanager .rui-filemanager-table thead th:last-child {
    padding-right: 0;
}

.rui-filemanager .rui-filemanager-table tbody tr + tr th,
.rui-filemanager .rui-filemanager-table tbody tr + tr td {
    padding-top: 10px;
}

.rui-filemanager .rui-filemanager-table tbody th,
.rui-filemanager .rui-filemanager-table tbody td {
    padding: 0;
}

.rui-filemanager .rui-filemanager-table tbody td:last-child > .rui-filemanager-file {
    border-radius: 0 .25rem .25rem 0;
}

.rui-filemanager .rui-filemanager-table tbody th > .rui-filemanager-file {
    border-radius: .25rem 0 0 .25rem;
}

.rui-filemanager .rui-filemanager-table .rui-filemanager-table-name {
    width: 427px;
}

.rui-filemanager .rui-filemanager-table .rui-filemanager-table-size {
    width: 234px;
}

.rui-filemanager .rui-filemanager-table .rui-filemanager-table-date {
    width: 338px;
}

.rui-filemanager .rui-filemanager-table .rui-filemanager-table-icon {
    width: 141px;
}

.rui-filemanager .dataTables_wrapper {
    padding-bottom: 0;
}

.rui-filemanager-head {
    padding: 15px 30px;
    margin-bottom: 40px;
    border-top: 1px solid #e6ecf0;
    border-bottom: 1px solid #e6ecf0;
}

.rui-filemanager-file {
    display: block;
    padding: 15px 25px;
    background-color: #f8f9fa;
}

.rui-filemanager-file-link {
    display: -webkit-box;
    display: -webkit-flex;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-align: center;
    -webkit-align-items: center;
        -ms-flex-align: center;
            align-items: center;
    color: #4b515b;
    -webkit-transition: color .15s ease-in-out;
    -o-transition: color .15s ease-in-out;
    transition: color .15s ease-in-out;
}

.rui-filemanager-file-link > .rui-icon {
    margin-right: 10px;
    margin-bottom: -1px;
}

.rui-filemanager-file-link:hover {
    color: #4b515b;
    text-decoration: none;
}

.rui-filemanager-file-icon {
    color: #bcbec0;
}

.rui-filemanager-file-icon:hover {
    color: #4b515b;
}

/*------------------------------------------------------------------

  Profile

 -------------------------------------------------------------------*/
.rui-profile .rui-profile-img {
    display: block;
    max-width: 100px;
    overflow: hidden;
    border-radius: 100%;
}

.rui-profile .rui-profile-img img {
    width: 100%;
    height: 100%;
    font-family: "object-fit: cover";
    -o-object-fit: cover;
       object-fit: cover;
}

.rui-profile .rui-profile-info {
    display: -webkit-box;
    display: -webkit-flex;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-orient: vertical;
    -webkit-box-direction: normal;
    -webkit-flex-direction: column;
        -ms-flex-direction: column;
            flex-direction: column;
    -webkit-box-pack: center;
    -webkit-justify-content: center;
        -ms-flex-pack: center;
            justify-content: center;
    height: 100%;
}

.rui-profile .rui-profile-info .rui-profile-info-title {
    margin-bottom: 0;
}

.rui-profile .rui-profile-info .rui-profile-info-title + .rui-profile-info-subtitle {
    margin-top: 2px;
}

.rui-profile .rui-profile-info .rui-profile-info-subtitle {
    margin-bottom: 0;
    font-size: 12px;
    color: #bcbec0;
}

.rui-profile .rui-profile-info .rui-profile-info-subtitle + .rui-profile-info-mail,
.rui-profile .rui-profile-info .rui-profile-info-subtitle + .rui-profile-info-phone {
    margin-top: 12px;
}

.rui-profile .rui-profile-info .rui-profile-info-mail,
.rui-profile .rui-profile-info .rui-profile-info-phone {
    display: block;
    color: #4b515b;
}

.rui-profile .rui-profile-info .rui-profile-info-mail:hover,
.rui-profile .rui-profile-info .rui-profile-info-phone:hover {
    color: #393f49;
    text-decoration: none;
}

.rui-profile .rui-profile-info .rui-profile-info-mail + .rui-profile-info-phone {
    margin-top: 3px;
}

.rui-profile .rui-profile-numbers {
    margin: 25px -30px 0 -30px;
    border-top: 1px solid #e6ecf0;
}

.rui-profile .rui-profile-number {
    padding: 25px 0 0;
}

.rui-profile .rui-profile-number .rui-profile-number-title {
    margin-bottom: 0;
    color: #393f49;
}

.rui-profile .rui-profile-activity-list > li {
    padding: 20px 0;
}

.rui-profile .rui-profile-activity-list > li:first-child {
    padding-top: 10px;
}

.rui-profile .rui-profile-activity-list > li:last-child {
    padding-bottom: 5px;
}

.rui-profile .rui-profile-task-list {
    margin: 30px -30px -25px;
}

.rui-profile .rui-profile-task-list > li {
    padding: 0;
}

/*------------------------------------------------------------------

  Footer

 -------------------------------------------------------------------*/
.rui-footer {
    position: relative;
    padding: 27px 15px 25px;
    background-color: #fff;
}

/*------------------------------------------------------------------

  Elements

 -------------------------------------------------------------------*/
/*---------------------------
  Element Alert
 ----------------------------*/
.alert {
    padding: 19px 25px;
    margin: 0;
    color: #6c757d;
    background-color: #f8f9fa;
    border: 0;
    border-left: 2px solid;
}

.alert + .alert {
    margin-top: 10px;
}

.alert hr {
    border-top-color: #e6ecf0;
}

.alert .alert-link {
    color: #393f49;
}

.alert-brand {
    border-color: #725ec3;
}

.alert-primary {
    border-color: #007bff;
}

.alert-secondary {
    border-color: #6c757d;
}

.alert-success {
    border-color: #2fc787;
}

.alert-danger {
    border-color: #ef5164;
}

.alert-warning {
    border-color: #fdbf21;
}

.alert-info {
    border-color: #32bfc8;
}

.alert-light {
    border-color: #f8f9fa;
}

.alert-dark {
    border-color: #393f49;
}

.alert-link {
    font-weight: 600;
}

.alert-content {
    padding: 30px;
}

.alert-content p + hr {
    margin-top: 25px;
}

.alert-content hr {
    margin: 30px 0;
}

.alert-dismissible .close {
    display: -webkit-inline-box;
    display: -webkit-inline-flex;
    display: -ms-inline-flexbox;
    display: inline-flex;
    -webkit-box-align: center;
    -webkit-align-items: center;
        -ms-flex-align: center;
            align-items: center;
    padding: 21.5px 22px;
    -webkit-transition: color .15s ease-in-out;
    -o-transition: color .15s ease-in-out;
    transition: color .15s ease-in-out;
}

.alert-dismissible .close:hover, .alert-dismissible .close.hover {
    color: #393f49;
}

/*---------------------------
  Element Badge
 ----------------------------*/
.badge {
    padding: .35em .45em;
    font-weight: 700;
}

.badge-circle {
    min-width: 1.25rem;
    height: 1.25rem;
    border-radius: 1rem;
}

/*---------------------------
  Element Breadcrumb
 ----------------------------*/
.breadcrumb {
    position: relative;
    padding: 0;
    margin: -10px -6px 0;
    font-size: .92rem;
    background: none;
}

.breadcrumb .breadcrumb-item,
.breadcrumb .breadcrumb-item.show {
    position: relative;
    display: inline-block;
    padding: 5px 6px 0;
    color: #bcbec0;
}

.breadcrumb .breadcrumb-item .rui-icon,
.breadcrumb .breadcrumb-item.show .rui-icon {
    margin-top: -2px;
}

.breadcrumb .breadcrumb-item > a,
.breadcrumb .breadcrumb-item.show > a {
    position: relative;
    display: block;
    padding: 0;
    font-size: .92rem;
    color: #bcbec0;
    -webkit-transition: color .15s ease-in-out, background-color .15s ease-in-out;
    -o-transition: color .15s ease-in-out, background-color .15s ease-in-out;
    transition: color .15s ease-in-out, background-color .15s ease-in-out;
}

.breadcrumb .breadcrumb-item > a::after,
.breadcrumb .breadcrumb-item.show > a::after {
    content: "";
    position: absolute;
    top: -3px;
    right: -6px;
    bottom: -3px;
    left: -6px;
    background-color: #f8f9fa;
    border-radius: 3px;
    opacity: 0;
    -webkit-transition: opacity .15s ease-in-out;
    -o-transition: opacity .15s ease-in-out;
    transition: opacity .15s ease-in-out;
    z-index: -1;
}

.breadcrumb .breadcrumb-item:hover > a, .breadcrumb .breadcrumb-item.hover > a,
.breadcrumb .breadcrumb-item.show:hover > a,
.breadcrumb .breadcrumb-item.show.hover > a {
    color: #6c757d;
    text-decoration: none;
}

.breadcrumb .breadcrumb-item:hover > a::after, .breadcrumb .breadcrumb-item.hover > a::after,
.breadcrumb .breadcrumb-item.show:hover > a::after,
.breadcrumb .breadcrumb-item.show.hover > a::after {
    background-color: #f8f9fa;
    opacity: 1;
}

/*---------------------------
  Element Buttons
 ----------------------------*/
.btn {
    position: relative;
    display: -webkit-inline-box;
    display: -webkit-inline-flex;
    display: -ms-inline-flexbox;
    display: inline-flex;
    -webkit-box-align: stretch;
    -webkit-align-items: stretch;
        -ms-flex-align: stretch;
            align-items: stretch;
    padding: 9px 15px;
    font-size: 11px;
    font-weight: 700;
    text-transform: uppercase;
}

.btn.text-center {
    -webkit-box-pack: center;
    -webkit-justify-content: center;
        -ms-flex-pack: center;
            justify-content: center;
}

.btn.text-right {
    -webkit-box-pack: end;
    -webkit-justify-content: flex-end;
        -ms-flex-pack: end;
            justify-content: flex-end;
}

.btn > .icon {
    display: -webkit-inline-box;
    display: -webkit-inline-flex;
    display: -ms-inline-flexbox;
    display: inline-flex;
    -webkit-box-align: center;
    -webkit-align-items: center;
        -ms-flex-align: center;
            align-items: center;
    font-size: inherit;
}

.btn > .icon::after {
    content: ".";
    width: 0;
    color: transparent;
}

.btn > .icon + .text {
    margin-left: 10px;
}

.btn > .text + .icon {
    margin-left: 10px;
}

.btn .rui-icon {
    width: 16px;
    min-width: 16px;
    height: 16px;
}

.btn > .badge {
    -webkit-align-self: center;
        -ms-flex-item-align: center;
            align-self: center;
    top: initial;
}

.btn > .badge.badge-circle {
    min-width: 16px;
    height: 1.231rem;
}

.btn > .badge:last-child {
    margin-left: 5px;
}

.btn > .badge:first-child {
    margin-right: 5px;
}

.dropleft > .dropdown-toggle::before {
    display: none;
}

.btn-grey-1 {
    color: #6c757d;
    background-color: #f8f9fa;
    border-color: #f8f9fa;
}

.btn-grey-1:hover, .btn-grey-1.hover {
    color: #6c757d;
    background-color: #eff1f4;
    border-color: #eff1f4;
}

.btn-grey-1:active {
    color: #6c757d;
}

.btn-grey-1:focus, .btn-grey-1.focus {
    background-color: #e6eaed;
    border-color: #e6eaed;
    -webkit-box-shadow: 0 0 0 0.2rem rgba(239, 241, 244, 0.5);
            box-shadow: 0 0 0 0.2rem rgba(239, 241, 244, 0.5);
}

.btn-grey-2 {
    color: #6c757d;
    background-color: #f3f4f7;
    border-color: #f3f4f7;
}

.btn-grey-2:hover, .btn-grey-2.hover {
    color: #6c757d;
    background-color: #eaecf1;
    border-color: #eaecf1;
}

.btn-grey-2:active {
    color: #6c757d;
}

.btn-grey-2:focus, .btn-grey-2.focus {
    background-color: #e1e3eb;
    border-color: #e1e3eb;
    -webkit-box-shadow: 0 0 0 0.2rem rgba(234, 236, 241, 0.5);
            box-shadow: 0 0 0 0.2rem rgba(234, 236, 241, 0.5);
}

.btn-grey-3 {
    color: #6c757d;
    background-color: #eaecf0;
    border-color: #eaecf0;
}

.btn-grey-3:hover, .btn-grey-3.hover {
    color: #6c757d;
    background-color: #e1e4ea;
    border-color: #e1e4ea;
}

.btn-grey-3:active {
    color: #6c757d;
}

.btn-grey-3:focus, .btn-grey-3.focus {
    background-color: #d8dce3;
    border-color: #d8dce3;
    -webkit-box-shadow: 0 0 0 0.2rem rgba(225, 228, 234, 0.5);
            box-shadow: 0 0 0 0.2rem rgba(225, 228, 234, 0.5);
}

.btn-outline {
    background-color: transparent;
}

.btn-lg {
    padding-top: 10.5px;
    padding-bottom: 10.5px;
    font-size: 13px;
}

.btn-lg .rui-icon {
    width: 19px;
    min-width: 19px;
    height: 19px;
}

.btn-sm {
    padding-top: 8.5px;
    padding-bottom: 8.5px;
    font-size: 9px;
}

.btn-sm .rui-icon {
    width: 13px;
    min-width: 13px;
    height: 13px;
}

.btn-link {
    color: #725ec3;
    background: transparent;
    border: 0;
}

.btn-link:focus, .btn-link.focus {
    color: #725ec3;
}

.btn-link:hover, .btn-link.hover {
    color: #5641ad;
    background: transparent;
}

.btn-link:not(:disabled):not(.disabled):active:focus {
    color: #5641ad;
    background: transparent;
    -webkit-box-shadow: none;
            box-shadow: none;
}

.btn-round {
    border-radius: 100px;
}

.btn-long {
    padding-right: 28px;
    padding-left: 28px;
}

.btn-long > .text + .icon {
    margin-left: 15px;
}

.btn-long > .icon + .text {
    margin-left: 15px;
}

.btn-uniform {
    padding-right: 9px;
    padding-left: 9px;
}

.btn-uniform.btn-lg {
    padding-right: 10.5px;
    padding-left: 10.5px;
}

.btn-uniform.btn-sm {
    padding-right: 8.5px;
    padding-left: 8.5px;
}

.btn-clean {
    color: #bcbec0;
    background-color: transparent;
    border-color: transparent;
}

.btn-clean.btn-white {
    color: #fff;
}

.btn-clean.btn-black {
    color: #000;
}

.btn-clean.btn-1 {
    color: #393f49;
}

.btn-clean.btn-2 {
    color: #4b515b;
}

.btn-clean.btn-grey-1 {
    color: #f8f9fa;
}

.btn-clean.btn-grey-2 {
    color: #f3f4f7;
}

.btn-clean.btn-grey-3 {
    color: #eaecf0;
}

.btn-clean.btn-grey-4 {
    color: #d7d9e0;
}

.btn-clean.btn-grey-5 {
    color: #bcbec0;
}

.btn-clean.btn-grey-6 {
    color: #a4a6a8;
}

.btn-clean:hover, .btn-clean.hover {
    color: #393f49;
}

.btn-clean:focus, .btn-clean.focus {
    background-color: transparent;
    -webkit-box-shadow: none;
            box-shadow: none;
}

.btn-clean:not(.disabled):not(:disabled):active {
    color: #393f49;
    background-color: transparent;
}

.btn-hover-outline:hover, .btn-hover-outline.hover, .btn-hover-outline:focus, .btn-hover-outline.focus {
    color: inherit;
    background-color: transparent;
}

.btn-hover-outline.btn-brand:hover, .btn-hover-outline.btn-brand.hover, .btn-hover-outline.btn-brand:focus, .btn-hover-outline.btn-brand.focus {
    color: #725ec3;
}

.btn-hover-outline.btn-primary:hover, .btn-hover-outline.btn-primary.hover, .btn-hover-outline.btn-primary:focus, .btn-hover-outline.btn-primary.focus {
    color: #007bff;
}

.btn-hover-outline.btn-secondary:hover, .btn-hover-outline.btn-secondary.hover, .btn-hover-outline.btn-secondary:focus, .btn-hover-outline.btn-secondary.focus {
    color: #6c757d;
}

.btn-hover-outline.btn-success:hover, .btn-hover-outline.btn-success.hover, .btn-hover-outline.btn-success:focus, .btn-hover-outline.btn-success.focus {
    color: #2fc787;
}

.btn-hover-outline.btn-danger:hover, .btn-hover-outline.btn-danger.hover, .btn-hover-outline.btn-danger:focus, .btn-hover-outline.btn-danger.focus {
    color: #ef5164;
}

.btn-hover-outline.btn-warning:hover, .btn-hover-outline.btn-warning.hover, .btn-hover-outline.btn-warning:focus, .btn-hover-outline.btn-warning.focus {
    color: #fdbf21;
}

.btn-hover-outline.btn-info:hover, .btn-hover-outline.btn-info.hover, .btn-hover-outline.btn-info:focus, .btn-hover-outline.btn-info.focus {
    color: #32bfc8;
}

.btn-hover-outline.btn-light:hover, .btn-hover-outline.btn-light.hover, .btn-hover-outline.btn-light:focus, .btn-hover-outline.btn-light.focus {
    color: #f8f9fa;
}

.btn-hover-outline.btn-dark:hover, .btn-hover-outline.btn-dark.hover, .btn-hover-outline.btn-dark:focus, .btn-hover-outline.btn-dark.focus {
    color: #393f49;
}

.btn-hover-brand:hover, .btn-hover-brand.hover, .btn-hover-brand:focus, .btn-hover-brand.focus {
    background-color: #725ec3;
    border-color: #725ec3;
}

.btn-hover-brand:focus, .btn-hover-brand.focus {
    -webkit-box-shadow: 0 0 0 0.2rem rgba(114, 94, 195, 0.5);
            box-shadow: 0 0 0 0.2rem rgba(114, 94, 195, 0.5);
}

.btn-hover-brand:not(:disabled):not(.disabled):active:focus {
    background-color: #5641ad;
    border-color: #5641ad;
    -webkit-box-shadow: 0 0 0 0.2rem rgba(114, 94, 195, 0.5);
            box-shadow: 0 0 0 0.2rem rgba(114, 94, 195, 0.5);
}

.btn-hover-brand.btn-hover-outline:hover, .btn-hover-brand.btn-hover-outline.hover, .btn-hover-brand.btn-hover-outline:focus, .btn-hover-brand.btn-hover-outline.focus {
    color: #725ec3;
    background-color: transparent;
}

.btn-hover-brand.btn-hover-outline:focus, .btn-hover-brand.btn-hover-outline.focus {
    -webkit-box-shadow: 0 0 0 0.2rem rgba(114, 94, 195, 0.5);
            box-shadow: 0 0 0 0.2rem rgba(114, 94, 195, 0.5);
}

.btn-hover-primary:hover, .btn-hover-primary.hover, .btn-hover-primary:focus, .btn-hover-primary.focus {
    background-color: #007bff;
    border-color: #007bff;
}

.btn-hover-primary:focus, .btn-hover-primary.focus {
    -webkit-box-shadow: 0 0 0 0.2rem rgba(0, 123, 255, 0.5);
            box-shadow: 0 0 0 0.2rem rgba(0, 123, 255, 0.5);
}

.btn-hover-primary:not(:disabled):not(.disabled):active:focus {
    background-color: #0062cc;
    border-color: #0062cc;
    -webkit-box-shadow: 0 0 0 0.2rem rgba(0, 123, 255, 0.5);
            box-shadow: 0 0 0 0.2rem rgba(0, 123, 255, 0.5);
}

.btn-hover-primary.btn-hover-outline:hover, .btn-hover-primary.btn-hover-outline.hover, .btn-hover-primary.btn-hover-outline:focus, .btn-hover-primary.btn-hover-outline.focus {
    color: #007bff;
    background-color: transparent;
}

.btn-hover-primary.btn-hover-outline:focus, .btn-hover-primary.btn-hover-outline.focus {
    -webkit-box-shadow: 0 0 0 0.2rem rgba(0, 123, 255, 0.5);
            box-shadow: 0 0 0 0.2rem rgba(0, 123, 255, 0.5);
}

.btn-hover-secondary:hover, .btn-hover-secondary.hover, .btn-hover-secondary:focus, .btn-hover-secondary.focus {
    background-color: #6c757d;
    border-color: #6c757d;
}

.btn-hover-secondary:focus, .btn-hover-secondary.focus {
    -webkit-box-shadow: 0 0 0 0.2rem rgba(108, 117, 125, 0.5);
            box-shadow: 0 0 0 0.2rem rgba(108, 117, 125, 0.5);
}

.btn-hover-secondary:not(:disabled):not(.disabled):active:focus {
    background-color: #545b62;
    border-color: #545b62;
    -webkit-box-shadow: 0 0 0 0.2rem rgba(108, 117, 125, 0.5);
            box-shadow: 0 0 0 0.2rem rgba(108, 117, 125, 0.5);
}

.btn-hover-secondary.btn-hover-outline:hover, .btn-hover-secondary.btn-hover-outline.hover, .btn-hover-secondary.btn-hover-outline:focus, .btn-hover-secondary.btn-hover-outline.focus {
    color: #6c757d;
    background-color: transparent;
}

.btn-hover-secondary.btn-hover-outline:focus, .btn-hover-secondary.btn-hover-outline.focus {
    -webkit-box-shadow: 0 0 0 0.2rem rgba(108, 117, 125, 0.5);
            box-shadow: 0 0 0 0.2rem rgba(108, 117, 125, 0.5);
}

.btn-hover-success:hover, .btn-hover-success.hover, .btn-hover-success:focus, .btn-hover-success.focus {
    background-color: #2fc787;
    border-color: #2fc787;
}

.btn-hover-success:focus, .btn-hover-success.focus {
    -webkit-box-shadow: 0 0 0 0.2rem rgba(47, 199, 135, 0.5);
            box-shadow: 0 0 0 0.2rem rgba(47, 199, 135, 0.5);
}

.btn-hover-success:not(:disabled):not(.disabled):active:focus {
    background-color: #259e6b;
    border-color: #259e6b;
    -webkit-box-shadow: 0 0 0 0.2rem rgba(47, 199, 135, 0.5);
            box-shadow: 0 0 0 0.2rem rgba(47, 199, 135, 0.5);
}

.btn-hover-success.btn-hover-outline:hover, .btn-hover-success.btn-hover-outline.hover, .btn-hover-success.btn-hover-outline:focus, .btn-hover-success.btn-hover-outline.focus {
    color: #2fc787;
    background-color: transparent;
}

.btn-hover-success.btn-hover-outline:focus, .btn-hover-success.btn-hover-outline.focus {
    -webkit-box-shadow: 0 0 0 0.2rem rgba(47, 199, 135, 0.5);
            box-shadow: 0 0 0 0.2rem rgba(47, 199, 135, 0.5);
}

.btn-hover-danger:hover, .btn-hover-danger.hover, .btn-hover-danger:focus, .btn-hover-danger.focus {
    background-color: #ef5164;
    border-color: #ef5164;
}

.btn-hover-danger:focus, .btn-hover-danger.focus {
    -webkit-box-shadow: 0 0 0 0.2rem rgba(239, 81, 100, 0.5);
            box-shadow: 0 0 0 0.2rem rgba(239, 81, 100, 0.5);
}

.btn-hover-danger:not(:disabled):not(.disabled):active:focus {
    background-color: #eb223a;
    border-color: #eb223a;
    -webkit-box-shadow: 0 0 0 0.2rem rgba(239, 81, 100, 0.5);
            box-shadow: 0 0 0 0.2rem rgba(239, 81, 100, 0.5);
}

.btn-hover-danger.btn-hover-outline:hover, .btn-hover-danger.btn-hover-outline.hover, .btn-hover-danger.btn-hover-outline:focus, .btn-hover-danger.btn-hover-outline.focus {
    color: #ef5164;
    background-color: transparent;
}

.btn-hover-danger.btn-hover-outline:focus, .btn-hover-danger.btn-hover-outline.focus {
    -webkit-box-shadow: 0 0 0 0.2rem rgba(239, 81, 100, 0.5);
            box-shadow: 0 0 0 0.2rem rgba(239, 81, 100, 0.5);
}

.btn-hover-warning:hover, .btn-hover-warning.hover, .btn-hover-warning:focus, .btn-hover-warning.focus {
    background-color: #fdbf21;
    border-color: #fdbf21;
}

.btn-hover-warning:focus, .btn-hover-warning.focus {
    -webkit-box-shadow: 0 0 0 0.2rem rgba(253, 191, 33, 0.5);
            box-shadow: 0 0 0 0.2rem rgba(253, 191, 33, 0.5);
}

.btn-hover-warning:not(:disabled):not(.disabled):active:focus {
    background-color: #e9a802;
    border-color: #e9a802;
    -webkit-box-shadow: 0 0 0 0.2rem rgba(253, 191, 33, 0.5);
            box-shadow: 0 0 0 0.2rem rgba(253, 191, 33, 0.5);
}

.btn-hover-warning.btn-hover-outline:hover, .btn-hover-warning.btn-hover-outline.hover, .btn-hover-warning.btn-hover-outline:focus, .btn-hover-warning.btn-hover-outline.focus {
    color: #fdbf21;
    background-color: transparent;
}

.btn-hover-warning.btn-hover-outline:focus, .btn-hover-warning.btn-hover-outline.focus {
    -webkit-box-shadow: 0 0 0 0.2rem rgba(253, 191, 33, 0.5);
            box-shadow: 0 0 0 0.2rem rgba(253, 191, 33, 0.5);
}

.btn-hover-info:hover, .btn-hover-info.hover, .btn-hover-info:focus, .btn-hover-info.focus {
    background-color: #32bfc8;
    border-color: #32bfc8;
}

.btn-hover-info:focus, .btn-hover-info.focus {
    -webkit-box-shadow: 0 0 0 0.2rem rgba(50, 191, 200, 0.5);
            box-shadow: 0 0 0 0.2rem rgba(50, 191, 200, 0.5);
}

.btn-hover-info:not(:disabled):not(.disabled):active:focus {
    background-color: #28989f;
    border-color: #28989f;
    -webkit-box-shadow: 0 0 0 0.2rem rgba(50, 191, 200, 0.5);
            box-shadow: 0 0 0 0.2rem rgba(50, 191, 200, 0.5);
}

.btn-hover-info.btn-hover-outline:hover, .btn-hover-info.btn-hover-outline.hover, .btn-hover-info.btn-hover-outline:focus, .btn-hover-info.btn-hover-outline.focus {
    color: #32bfc8;
    background-color: transparent;
}

.btn-hover-info.btn-hover-outline:focus, .btn-hover-info.btn-hover-outline.focus {
    -webkit-box-shadow: 0 0 0 0.2rem rgba(50, 191, 200, 0.5);
            box-shadow: 0 0 0 0.2rem rgba(50, 191, 200, 0.5);
}

.btn-hover-light:hover, .btn-hover-light.hover, .btn-hover-light:focus, .btn-hover-light.focus {
    background-color: #f8f9fa;
    border-color: #f8f9fa;
}

.btn-hover-light:focus, .btn-hover-light.focus {
    -webkit-box-shadow: 0 0 0 0.2rem rgba(248, 249, 250, 0.5);
            box-shadow: 0 0 0 0.2rem rgba(248, 249, 250, 0.5);
}

.btn-hover-light:not(:disabled):not(.disabled):active:focus {
    background-color: #dae0e5;
    border-color: #dae0e5;
    -webkit-box-shadow: 0 0 0 0.2rem rgba(248, 249, 250, 0.5);
            box-shadow: 0 0 0 0.2rem rgba(248, 249, 250, 0.5);
}

.btn-hover-light.btn-hover-outline:hover, .btn-hover-light.btn-hover-outline.hover, .btn-hover-light.btn-hover-outline:focus, .btn-hover-light.btn-hover-outline.focus {
    color: #f8f9fa;
    background-color: transparent;
}

.btn-hover-light.btn-hover-outline:focus, .btn-hover-light.btn-hover-outline.focus {
    -webkit-box-shadow: 0 0 0 0.2rem rgba(248, 249, 250, 0.5);
            box-shadow: 0 0 0 0.2rem rgba(248, 249, 250, 0.5);
}

.btn-hover-dark:hover, .btn-hover-dark.hover, .btn-hover-dark:focus, .btn-hover-dark.focus {
    background-color: #393f49;
    border-color: #393f49;
}

.btn-hover-dark:focus, .btn-hover-dark.focus {
    -webkit-box-shadow: 0 0 0 0.2rem rgba(57, 63, 73, 0.5);
            box-shadow: 0 0 0 0.2rem rgba(57, 63, 73, 0.5);
}

.btn-hover-dark:not(:disabled):not(.disabled):active:focus {
    background-color: #23262c;
    border-color: #23262c;
    -webkit-box-shadow: 0 0 0 0.2rem rgba(57, 63, 73, 0.5);
            box-shadow: 0 0 0 0.2rem rgba(57, 63, 73, 0.5);
}

.btn-hover-dark.btn-hover-outline:hover, .btn-hover-dark.btn-hover-outline.hover, .btn-hover-dark.btn-hover-outline:focus, .btn-hover-dark.btn-hover-outline.focus {
    color: #393f49;
    background-color: transparent;
}

.btn-hover-dark.btn-hover-outline:focus, .btn-hover-dark.btn-hover-outline.focus {
    -webkit-box-shadow: 0 0 0 0.2rem rgba(57, 63, 73, 0.5);
            box-shadow: 0 0 0 0.2rem rgba(57, 63, 73, 0.5);
}

.btn-hover-light:hover, .btn-hover-light.hover, .btn-hover-light:focus, .btn-hover-light.focus {
    color: #393f49;
}

.btn-hover-light.focus, .btn-hover-light:focus {
    background-color: #eaecf0;
    border-color: #eaecf0;
}

.btn-custom-round {
    padding: 0;
    color: #bcbec0;
    background-color: transparent;
    border: 0;
    -webkit-transition: color .15s ease-in-out;
    -o-transition: color .15s ease-in-out;
    transition: color .15s ease-in-out;
}

.btn-custom-round > .text,
.btn-custom-round > .icon,
.btn-custom-round > .rui-icon {
    position: relative;
    z-index: 1;
}

.btn-custom-round > .badge,
.btn-custom-round > .badge.badge-circle {
    position: absolute;
    top: -6px;
    right: -6px;
    min-width: 11px;
    height: 11px;
    padding: .25em .45em;
    font-size: 7px;
    -webkit-box-shadow: 0 0 0 2px #fff;
            box-shadow: 0 0 0 2px #fff;
    -webkit-transition: -webkit-box-shadow .15s ease-in-out;
    transition: -webkit-box-shadow .15s ease-in-out;
    -o-transition: box-shadow .15s ease-in-out;
    transition: box-shadow .15s ease-in-out;
    transition: box-shadow .15s ease-in-out, -webkit-box-shadow .15s ease-in-out;
    z-index: 1;
}

.btn-custom-round::after {
    content: "";
    position: absolute;
    top: 50%;
    left: 50%;
    width: 36px;
    height: 36px;
    margin-top: -18px;
    margin-left: -18px;
    background-color: transparent;
    border-radius: 100%;
    -webkit-transition: background-color .15s ease-in-out;
    -o-transition: background-color .15s ease-in-out;
    transition: background-color .15s ease-in-out;
    z-index: 0;
}

.btn-custom-round:focus, .btn-custom-round.focus {
    color: #6c757d;
    background-color: transparent;
    -webkit-box-shadow: none;
            box-shadow: none;
}

.btn-custom-round:hover, .btn-custom-round.hover {
    color: #6c757d;
    background-color: transparent;
}

.btn-custom-round:hover > .badge, .btn-custom-round.hover > .badge {
    -webkit-box-shadow: 0 0 0 2px #f8f9fa;
            box-shadow: 0 0 0 2px #f8f9fa;
}

.btn-custom-round:hover::after, .btn-custom-round.hover::after {
    background-color: #f8f9fa;
}

.btn-custom-round:not(.disabled):not(:disabled):active {
    color: #393f49;
    background-color: transparent;
}

.btn-custom-round:not(.disabled):not(:disabled):active::after {
    background-color: #f8f9fa;
}

.btn-custom-round:not(.disabled):not(:disabled):active:focus {
    -webkit-box-shadow: none;
            box-shadow: none;
}

.btn-group > .btn:not(:last-child) {
    border-right: 0;
}

.btn-group > .btn:not(:first-child) {
    margin-left: 0;
}

.btn-group > .btn.dropdown-toggle::after {
    display: none;
}

.btn-group > .btn + .btn, .btn-group > .btn:last-child {
    margin-left: 0;
    border-left: 1px solid rgba(255, 255, 255, 0.1);
}

.btn-group > .btn + .btn:hover, .btn-group > .btn + .btn.hover, .btn-group > .btn + .btn:not(:disabled):not(.disabled).active, .btn-group > .btn:last-child:hover, .btn-group > .btn:last-child.hover, .btn-group > .btn:last-child:not(:disabled):not(.disabled).active {
    border-left: 1px solid rgba(255, 255, 255, 0.2);
}

.btn-group-vertical > .btn {
    -webkit-box-pack: center;
    -webkit-justify-content: center;
        -ms-flex-pack: center;
            justify-content: center;
}

.btn-group-vertical > .btn:not(:last-child) {
    border-bottom: 0;
}

.btn-group-vertical > .btn + .btn {
    margin-top: 0;
    border-top: 1px solid rgba(255, 255, 255, 0.1);
}

.btn-group-vertical > .btn + .btn:hover, .btn-group-vertical > .btn + .btn.hover, .btn-group-vertical > .btn + .btn:not(:disabled):not(.disabled).active {
    border-top: 1px solid rgba(255, 255, 255, 0.2);
}

/*---------------------------
  Element Card
 ----------------------------*/
.card {
    border: 1px solid #e6ecf0;
}

.card .list-group-item {
    padding-right: 2.3rem;
    padding-left: 2.3rem;
}

.card-header,
.card-footer {
    padding: 19px 30px;
    margin-bottom: 0;
    background-color: #f8f9fa;
    border-color: #e6ecf0;
}

.card-body {
    padding: 25px 30px;
}

.card-body .card-title {
    margin-bottom: 1.35rem;
}

.card-body p {
    margin-bottom: 1.4rem;
}

.card-title + .card-subtitle {
    margin-top: -10px;
}

.card-text:last-child {
    margin-bottom: -5px;
}

.card-link {
    display: inline-block;
}

.card-btn-collapse {
    position: absolute;
    display: -webkit-inline-box;
    display: -webkit-inline-flex;
    display: -ms-inline-flexbox;
    display: inline-flex;
    -webkit-box-align: center;
    -webkit-align-items: center;
        -ms-flex-align: center;
            align-items: center;
    -webkit-box-pack: center;
    -webkit-justify-content: center;
        -ms-flex-pack: center;
            justify-content: center;
    top: 2.15rem;
    right: 30px;
    z-index: 1;
}

.card-btn-collapse:not(.collapsed) {
    color: #393f49;
}

.card-btn-collapse:not(.collapsed)::after {
    background-color: #fff;
}

/*---------------------------
  Element Carousel
 ----------------------------*/
.carousel-light .carousel-control-prev,
.carousel-light .carousel-control-next {
    width: 70px;
    max-width: 30%;
    color: #bcbec0;
    -webkit-transition: color .15s ease-in-out;
    -o-transition: color .15s ease-in-out;
    transition: color .15s ease-in-out;
}

.carousel-light .carousel-control-prev:hover, .carousel-light .carousel-control-prev.hover,
.carousel-light .carousel-control-next:hover,
.carousel-light .carousel-control-next.hover {
    color: #6c757d;
}

.carousel-light .carousel-indicators {
    margin-bottom: 20px;
}

.carousel-light .carousel-indicators li {
    background-color: #bcbec0;
    -webkit-transition: opacity .15s ease-in-out;
    -o-transition: opacity .15s ease-in-out;
    transition: opacity .15s ease-in-out;
}

.carousel-light .carousel-caption {
    color: #bcbec0;
}

/*---------------------------
  Element Collapse
 ----------------------------*/
.accordion-group + .accordion-group {
    margin-top: 10px;
}

.collapse-link {
    display: block;
    padding: 14px 20px;
    color: #6c757d;
    background-color: #f8f9fa;
    border-radius: .25rem;
}

.collapse-link:hover, .collapse-link.hover {
    color: #393f49;
    text-decoration: none;
}

.collapse-link:focus, .collapse-link.focus {
    outline: none;
}

.collapse-link:not(.collapsed) {
    color: #393f49;
}

.collapse-content {
    padding: 25px 20px 15px;
}

/*---------------------------
  Element Table
 ----------------------------*/
.table thead th {
    padding: 20px 25px;
    font-weight: 600;
    color: #4b515b;
    border-color: #e6ecf0;
    border-bottom-width: 1px;
}

.table th,
.table td {
    padding: 15px 25px;
    font-weight: 400;
    color: #6c757d;
    border-color: #e6ecf0;
}

.table .thead-light th {
    background-color: #f8f9fa;
    border-color: #e6ecf0;
}

.table .thead-dark th {
    background-color: #393f49;
    border-color: #404652;
}

.table-dark {
    background-color: #393f49;
}

.table-dark thead th {
    color: #f3f4f7;
    border-color: #404652;
}

.table-dark th,
.table-dark td {
    color: #bcbec0;
    border-color: #404652;
}

.table-dark.table-hover tbody tr:hover {
    background-color: #3d444f;
}

.table-striped.table-dark tbody tr:nth-of-type(odd) {
    background-color: #3d444f;
}

.table-striped tbody tr:nth-of-type(odd) {
    background-color: #f8f9fa;
}

.table-hover.table-dark tbody tr:hover {
    background-color: #3d444f;
}

.table-hover tbody tr {
    -webkit-transition: background-color .1s ease-in-out;
    -o-transition: background-color .1s ease-in-out;
    transition: background-color .1s ease-in-out;
}

.table-hover tbody tr:hover {
    background-color: #f8f9fa;
}

.table-sm thead th {
    padding: 15px 20px;
}

.table-sm th,
.table-sm td {
    padding: 10px 20px;
}

/*---------------------------
  Element Pagination
 ----------------------------*/
.pagination {
    margin: -5px;
}

.page-item {
    margin: 5px;
}

.page-item.disabled .page-link {
    color: #d7d9e0;
    background-color: #f8f9fa;
}

.page-item.active .page-link {
    background-color: #725ec3;
}

.page-link {
    display: -webkit-inline-box;
    display: -webkit-inline-flex;
    display: -ms-inline-flexbox;
    display: inline-flex;
    -webkit-box-align: center;
    -webkit-align-items: center;
        -ms-flex-align: center;
            align-items: center;
    -webkit-box-pack: center;
    -webkit-justify-content: center;
        -ms-flex-pack: center;
            justify-content: center;
    min-width: 35px;
    padding: 11.5px 14px;
    margin: 0;
    font-size: 10px;
    font-weight: 700;
    color: #a4a6a8;
    text-transform: uppercase;
    background-color: #f8f9fa;
    border: 0;
    border-radius: .25rem;
    -webkit-transition: background-color .15s ease-in-out, color .15s ease-in-out;
    -o-transition: background-color .15s ease-in-out, color .15s ease-in-out;
    transition: background-color .15s ease-in-out, color .15s ease-in-out;
}

.page-link .rui-icon {
    margin: -2px -3px;
}

.page-link:hover, .page-link.hover {
    color: #6c757d;
    background-color: #f3f4f7;
}

.page-link:focus, .page-link.focus {
    color: #6c757d;
    background-color: #f3f4f7;
    -webkit-box-shadow: none;
            box-shadow: none;
}

.page-link:active {
    background-color: #eaecf0;
}

.pagination-lg .page-link {
    min-width: 41px;
    padding: 11px 12px;
    font-size: 13px;
    border-radius: .3rem;
}

.pagination-lg .page-link > .rui-icon {
    width: 18px;
    height: 18px;
    margin-top: .5px;
    margin-bottom: .5px;
}

.pagination-sm .page-link {
    min-width: 31px;
    padding: 9px 12px;
    font-size: 9px;
    border-radius: .2rem;
}

.pagination-sm .page-link > .rui-icon {
    width: 14px;
    height: 14px;
    margin: -.5px -4px;
}

/*---------------------------
  Element Dropdown
 ----------------------------*/
.dropdown {
    position: relative;
    display: -webkit-inline-box;
    display: -webkit-inline-flex;
    display: -ms-inline-flexbox;
    display: inline-flex;
}

.dropdown .dropdown-item {
    position: relative;
    display: -webkit-box;
    display: -webkit-flex;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-align: center;
    -webkit-align-items: center;
        -ms-flex-align: center;
            align-items: center;
    padding: 0;
    font-size: 13px;
    color: #a4a6a8;
    white-space: nowrap;
}

.dropdown .dropdown-item > span:not([class]) {
    color: #393f49;
}

.dropdown .dropdown-item > .rui-dropdown-circle {
    position: absolute;
    top: -25%;
    left: -10px;
    width: 36px;
    height: 36px;
    background-color: #f8f9fa;
    border-radius: 100%;
    opacity: 0;
    -webkit-transition: opacity .1s ease-in-out;
    -o-transition: opacity .1s ease-in-out;
    transition: opacity .1s ease-in-out;
    z-index: -1;
}

.dropdown .dropdown-item > .rui-icon {
    display: inline-block;
    margin: 0 14px -1px;
    stroke: #bcbec0;
    -webkit-transition: stroke .1s ease-in-out;
    -o-transition: stroke .1s ease-in-out;
    transition: stroke .1s ease-in-out;
}

.dropdown .dropdown-item > .rui-icon:first-child {
    margin-left: 0;
}

.dropdown .dropdown-item > .rui-icon:last-child {
    margin-right: 0;
}

.dropdown .dropdown-item:hover, .dropdown .dropdown-item.hover {
    color: #393f49;
    background-color: transparent;
}

.dropdown .dropdown-item:hover > span:not([class]), .dropdown .dropdown-item.hover > span:not([class]) {
    color: #393f49;
}

.dropdown .dropdown-item:hover > .rui-dropdown-circle, .dropdown .dropdown-item.hover > .rui-dropdown-circle {
    opacity: 1;
}

.dropdown .dropdown-item:hover > .rui-icon, .dropdown .dropdown-item.hover > .rui-icon {
    stroke: #393f49;
}

.dropdown .dropdown-item:focus, .dropdown .dropdown-item.focus {
    background-color: transparent;
    outline: none;
}

.dropdown .dropdown-item.active {
    color: #393f49;
}

.dropdown .dropdown-item.active > span:not([class]) {
    color: #393f49;
}

.dropdown .dropdown-item.active > .rui-icon {
    stroke: #393f49;
}

.dropdown .dropdown-item:active, .dropdown .dropdown-item.active {
    background-color: transparent;
}

.dropdown:not(.dropdown-hover):focus, .dropdown:not(.dropdown-hover).focus {
    color: #393f49;
}

.dropdown:not(.dropdown-hover):focus > span:not([class]), .dropdown:not(.dropdown-hover).focus > span:not([class]) {
    color: #393f49;
}

.dropdown:not(.dropdown-hover):focus > .rui-icon, .dropdown:not(.dropdown-hover).focus > .rui-icon {
    stroke: #393f49;
}

.dropdown:not(.dropdown-hover):active {
    color: #725ec3;
}

.dropdown:not(.dropdown-hover):active > span:not([class]) {
    color: #725ec3;
}

.dropdown:not(.dropdown-hover):active > .rui-icon {
    stroke: #725ec3;
}

.dropdown:not(.dropdown-hover).show > .dropdown-item {
    color: #393f49;
    background-color: transparent;
}

.dropdown:not(.dropdown-hover).show > .dropdown-item > span:not([class]) {
    color: #393f49;
}

.dropdown:not(.dropdown-hover).show > .dropdown-item > .rui-dropdown-circle {
    opacity: 1;
}

.dropdown:not(.dropdown-hover).show > .dropdown-item > .rui-icon {
    stroke: #393f49;
}

.dropdown .dropdown-item-switch.custom-switch,
.dropdown.show .dropdown-item-switch.custom-switch {
    padding-right: calc(3rem + 5px);
    padding-left: 0;
}

.dropdown .dropdown-item-switch.custom-switch .dropdown-item,
.dropdown.show .dropdown-item-switch.custom-switch .dropdown-item {
    cursor: pointer;
}

.dropdown .dropdown-item-switch.custom-switch .dropdown-item::before,
.dropdown.show .dropdown-item-switch.custom-switch .dropdown-item::before {
    top: calc(.15rem + 8px);
    right: calc(-3rem + 10px);
    left: auto;
}

.dropdown .dropdown-item-switch.custom-switch .dropdown-item::after,
.dropdown.show .dropdown-item-switch.custom-switch .dropdown-item::after {
    top: calc(.15rem + 9px);
    right: calc(-2.1rem + 10px);
    left: auto;
}

.dropdown .dropdown-item-switch.custom-switch .dropdown-item:hover::after,
.dropdown.show .dropdown-item-switch.custom-switch .dropdown-item:hover::after {
    background-color: #fff;
}

.dropdown .dropdown-item-switch.custom-switch > input:checked ~ .dropdown-item,
.dropdown.show .dropdown-item-switch.custom-switch > input:checked ~ .dropdown-item {
    color: #725ec3;
}

.dropdown .dropdown-item-switch.custom-switch > input:checked ~ .dropdown-item > span:not([class]),
.dropdown.show .dropdown-item-switch.custom-switch > input:checked ~ .dropdown-item > span:not([class]) {
    color: #725ec3;
}

.dropdown .dropdown-item-switch.custom-switch > input:checked ~ .dropdown-item > .rui-icon,
.dropdown.show .dropdown-item-switch.custom-switch > input:checked ~ .dropdown-item > .rui-icon {
    stroke: #725ec3;
}

.dropdown-hover.hover > .dropdown-menu::before {
    visibility: visible;
}

.dropdown-hover.hover > .dropdown-item {
    color: #393f49;
    z-index: 4;
}

.dropdown-hover.hover > .dropdown-item > span:not([class]) {
    color: #393f49;
}

.dropdown-hover.hover > .dropdown-item > .rui-dropdown-circle {
    opacity: 1;
}

.dropdown-hover.hover > .dropdown-item > .rui-icon {
    stroke: #393f49;
}

.dropdown-hover.hover > .dropdown-item.btn-custom-round,
.dropdown-hover.hover > .dropdown-item > .btn-custom-round {
    color: #6c757d;
}

.dropdown-hover.hover > .dropdown-item.btn-custom-round::after,
.dropdown-hover.hover > .dropdown-item > .btn-custom-round::after {
    background-color: #f8f9fa;
}

.dropdown-menu {
    display: none;
    padding: 16px 15px;
    visibility: hidden;
    border: 1px solid #e6ecf0;
    border-radius: .25rem;
    -webkit-box-shadow: 0 3px 10px rgba(0, 0, 0, 0.03);
            box-shadow: 0 3px 10px rgba(0, 0, 0, 0.03);
    opacity: 0;
    z-index: 3;
}

.dropdown-menu.show {
    visibility: visible;
    opacity: 1;
}

.dropdown-menu.hover, .dropdown-menu.focus {
    display: block;
    will-change: opacity;
    -webkit-transition: visibility .15s ease-in-out, opacity .15s ease-in-out;
    -o-transition: visibility .15s ease-in-out, opacity .15s ease-in-out;
    transition: visibility .15s ease-in-out, opacity .15s ease-in-out;
}

.dropdown-menu.nav .nav-link {
    white-space: nowrap;
}

.dropdown-menu.nav .nav-link > .rui-nav-circle {
    top: 3px;
    left: -1px;
}

.dropdown-menu .dropdown-item {
    padding: 9px 14px;
}

.dropdown-menu .dropdown-item > .rui-dropdown-circle {
    top: 3px;
    left: -1px;
}

.dropdown-menu > .dropdown-menu-scroll {
    padding: 15px 16px 15px 15px;
    margin: -15px -14px -15px -15px;
}

.dropdown-menu > .dropdown-menu-scroll > li {
    list-style: none;
}

.dropdown-menu .dropdown-menu-label {
    padding: 14px 10px 8px;
    color: #bcbec0;
}

.dropdown-menu::before {
    content: "";
    position: absolute;
    top: 0;
    right: 0;
    left: 0;
    min-width: 30px;
    min-height: 30px;
    margin: -30px 0 0;
    z-index: 0;
}

.dropdown-menu[x-placement="top-start"]::before, .dropdown-menu[x-placement="top-end"]::before {
    top: auto;
    right: 0;
    bottom: 0;
    left: 0;
    margin: 0 0 -30px;
}

.dropdown-menu[x-placement="right-start"]::before, .dropdown-menu[x-placement="right-end"]::before {
    top: 0;
    right: auto;
    bottom: 0;
    left: 0;
    margin: 0 0 0 -30px;
}

.dropdown-menu[x-placement="left-start"]::before, .dropdown-menu[x-placement="left-end"]::before {
    top: 0;
    right: 0;
    bottom: 0;
    left: auto;
    margin: 0 -30px 0 0;
}

.dropdown-menu[x-placement="top-start"] {
    margin-bottom: 20px;
    margin-left: -30px;
}

.dropdown-menu[x-placement="top-end"] {
    margin-right: -30px;
    margin-bottom: 20px;
}

.dropdown-menu[x-placement="bottom-start"] {
    margin-top: 20px;
    margin-left: -30px;
}

.dropdown-menu[x-placement="bottom-end"] {
    margin-top: 20px;
    margin-right: -30px;
}

.dropdown-menu[x-placement="right-start"] {
    margin-top: -30px;
    margin-left: 20px;
}

.dropdown-menu[x-placement="right-end"] {
    margin-bottom: -30px;
    margin-left: 20px;
}

.dropdown-menu[x-placement="left-start"] {
    margin-top: -30px;
    margin-right: 20px;
}

.dropdown-menu[x-placement="left-end"] {
    margin-right: 20px;
    margin-bottom: -30px;
}

.btn + .dropdown-menu[x-placement="top-start"] {
    margin-left: 0;
}

.btn + .dropdown-menu[x-placement="top-end"] {
    margin-right: 0;
}

.btn + .dropdown-menu[x-placement="bottom-start"] {
    margin-left: 0;
}

.btn + .dropdown-menu[x-placement="bottom-end"] {
    margin-right: 0;
}

.btn + .dropdown-menu[x-placement="right-start"] {
    margin-top: 0;
}

.btn + .dropdown-menu[x-placement="right-end"] {
    margin-bottom: 0;
}

.btn + .dropdown-menu[x-placement="left-start"] {
    margin-top: 0;
}

.btn + .dropdown-menu[x-placement="left-end"] {
    margin-bottom: 0;
}

.dropdown-menu-scroll {
    max-height: 200px;
    overflow-y: scroll;
}

.dropdown-menu-scroll::-webkit-scrollbar-track {
    background-color: #fff;
    border-radius: 4px;
}

.dropdown-menu-scroll::-webkit-scrollbar {
    width: 4px;
    background-color: #fff;
}

.dropdown-menu-scroll::-webkit-scrollbar-thumb {
    background-color: #eaecf0;
    border-radius: 4px;
}

.dropdown-menu-sm {
    padding: 11px 10px;
}

.dropdown-menu-sm .dropdown-item {
    padding: 5px 14px;
}

.dropdown-menu-sm > .dropdown-menu-scroll {
    padding: 14px 11px 14px 10px;
    margin: -10px -9px -10px -10px;
}

.dropdown-triangle .dropdown-menu-triangle {
    position: absolute;
}

.dropdown-triangle .dropdown-menu-triangle::before {
    content: "";
    position: absolute;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    z-index: -1;
}

.dropdown-triangle .dropdown-menu[x-placement="bottom-start"] > .dropdown-menu-triangle,
.dropdown-triangle .dropdown-menu[x-placement="bottom-end"] > .dropdown-menu-triangle,
.dropdown-triangle .dropdown-menu > .dropdown-menu-triangle {
    top: 0;
    right: auto;
    bottom: auto;
    left: 30px;
    margin: -19px 0 0 -3px;
    border: 10px solid transparent;
    border-bottom: 9px solid #fff;
}

.dropdown-triangle .dropdown-menu[x-placement="bottom-start"] > .dropdown-menu-triangle::before,
.dropdown-triangle .dropdown-menu[x-placement="bottom-end"] > .dropdown-menu-triangle::before,
.dropdown-triangle .dropdown-menu > .dropdown-menu-triangle::before {
    margin: -12px 0 0 -11px;
    border: 11px solid transparent;
    border-bottom: 10px solid #e6ecf0;
}

.dropdown-triangle .dropdown-menu[x-placement="bottom-end"] > .dropdown-menu-triangle {
    right: 30px;
    left: auto;
}

.dropdown-triangle .dropdown-menu[x-placement="top-start"] > .dropdown-menu-triangle,
.dropdown-triangle .dropdown-menu[x-placement="top-end"] > .dropdown-menu-triangle {
    top: auto;
    right: auto;
    bottom: 0;
    left: 30px;
    margin: 0 0 -19px -3px;
    border: 10px solid transparent;
    border-top: 9px solid #fff;
}

.dropdown-triangle .dropdown-menu[x-placement="top-start"] > .dropdown-menu-triangle::before,
.dropdown-triangle .dropdown-menu[x-placement="top-end"] > .dropdown-menu-triangle::before {
    margin: -9px 0 0 -11px;
    border: 11px solid transparent;
    border-top: 10px solid #e6ecf0;
}

.dropdown-triangle .dropdown-menu[x-placement="top-end"] > .dropdown-menu-triangle {
    right: 30px;
    left: auto;
}

.dropdown-triangle .dropdown-menu[x-placement="right-start"] > .dropdown-menu-triangle {
    top: 30px;
    right: auto;
    bottom: auto;
    left: 0;
    margin: -3px 0 0 -19px;
    border: 10px solid transparent;
    border-right: 9px solid #fff;
}

.dropdown-triangle .dropdown-menu[x-placement="right-start"] > .dropdown-menu-triangle::before {
    margin: -11px 0 0 -12px;
    border: 11px solid transparent;
    border-right: 10px solid #e6ecf0;
}

.dropdown-triangle .dropdown-menu[x-placement="right-end"] > .dropdown-menu-triangle {
    top: auto;
    right: auto;
    bottom: 30px;
    left: 0;
    margin: 0 0 -3px -19px;
    border: 10px solid transparent;
    border-right: 9px solid #fff;
}

.dropdown-triangle .dropdown-menu[x-placement="right-end"] > .dropdown-menu-triangle::before {
    margin: -11px 0 0 -12px;
    border: 11px solid transparent;
    border-right: 10px solid #e6ecf0;
}

.dropdown-triangle .dropdown-menu[x-placement="left-start"] > .dropdown-menu-triangle {
    top: 30px;
    right: 0;
    bottom: auto;
    left: auto;
    margin: -3px -19px 0 0;
    border: 10px solid transparent;
    border-left: 9px solid #fff;
}

.dropdown-triangle .dropdown-menu[x-placement="left-start"] > .dropdown-menu-triangle::before {
    margin: -11px 0 2px -9px;
    border: 11px solid transparent;
    border-left: 10px solid #e6ecf0;
}

.dropdown-triangle .dropdown-menu[x-placement="left-end"] > .dropdown-menu-triangle {
    top: auto;
    right: 0;
    bottom: 30px;
    left: auto;
    margin: 0 -19px -3px 0;
    border: 10px solid transparent;
    border-left: 9px solid #fff;
}

.dropdown-triangle .dropdown-menu[x-placement="left-end"] > .dropdown-menu-triangle::before {
    margin: -11px 0 2px -9px;
    border: 11px solid transparent;
    border-left: 10px solid #e6ecf0;
}

/*---------------------------
  Element Forms
 ----------------------------*/
/* Placeholders */
::-webkit-input-placeholder, .form-control::-webkit-input-placeholder {
    color: #a4a6a8;
    opacity: 1;
    will-change: opacity;
    -webkit-transition: opacity .15s ease-in-out;
    transition: opacity .15s ease-in-out;
}
::-moz-placeholder, .form-control::-moz-placeholder {
    color: #a4a6a8;
    opacity: 1;
    will-change: opacity;
    -moz-transition: opacity .15s ease-in-out;
    transition: opacity .15s ease-in-out;
}
:-ms-input-placeholder, .form-control:-ms-input-placeholder {
    color: #a4a6a8;
    opacity: 1;
    will-change: opacity;
    -ms-transition: opacity .15s ease-in-out;
    transition: opacity .15s ease-in-out;
}
::-ms-input-placeholder, .form-control::-ms-input-placeholder {
    color: #a4a6a8;
    opacity: 1;
    will-change: opacity;
    -ms-transition: opacity .15s ease-in-out;
    transition: opacity .15s ease-in-out;
}
::placeholder,
.form-control::placeholder {
    color: #a4a6a8;
    opacity: 1;
    will-change: opacity;
    -webkit-transition: opacity .15s ease-in-out;
    -o-transition: opacity .15s ease-in-out;
    transition: opacity .15s ease-in-out;
}

/* Remove outline from focused buttons */
button:focus {
    outline: 0;
}

.form-control {
    min-height: 36px;
    padding: 7.5px 17px 9px;
    background-color: #fbfcfc;
    border-color: #e6ecf0;
}

.form-control:focus, .form-control.focus {
    background-color: #fbfcfc;
    border-color: rgba(114, 94, 195, 0.6);
    -webkit-box-shadow: 0 0 0 0.2rem rgba(114, 94, 195, 0.2);
            box-shadow: 0 0 0 0.2rem rgba(114, 94, 195, 0.2);
}

.form-control:disabled {
    background-color: #f3f4f7;
    border-color: #e6ecf0;
}

.form-control-clean {
    padding: 10px 15px;
    background-color: transparent;
    border: 0;
}

.form-control-clean::-webkit-input-placeholder {
    color: #bcbec0;
}

.form-control-clean::-moz-placeholder {
    color: #bcbec0;
}

.form-control-clean:-ms-input-placeholder {
    color: #bcbec0;
}

.form-control-clean::-ms-input-placeholder {
    color: #bcbec0;
}

.form-control-clean::placeholder {
    color: #bcbec0;
}

.form-control-clean:hover, .form-control-clean.hover {
    color: #393f49;
}

.form-control-clean:focus, .form-control-clean.focus {
    background-color: transparent;
    -webkit-box-shadow: none;
            box-shadow: none;
}

.form-control-clean:not(.disabled):not(:disabled):active {
    color: #393f49;
}

textarea.form-control {
    height: 200px;
    min-height: 44px;
    max-height: 300px;
    padding: 10px 19px 11px;
}

.form-group {
    margin-bottom: 20px;
}

.form-group .col-form-label {
    padding-top: 0;
    padding-bottom: 0;
}

.row .col-form-label {
    display: -webkit-box;
    display: -webkit-flex;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-align: center;
    -webkit-align-items: center;
        -ms-flex-align: center;
            align-items: center;
    padding-bottom: 0;
}

.input-group .input-group-append,
.input-group .input-group-prepend {
    -webkit-box-align: center;
    -webkit-align-items: center;
        -ms-flex-align: center;
            align-items: center;
    background-color: transparent;
}

.input-group-text {
    min-height: 36px;
    color: #6c757d;
    background-color: #f8f9fa;
    border-color: #e6ecf0;
}

.input-group-text > .rui-icon {
    min-width: 16px;
}

.custom-control {
    padding-left: 2rem;
}

.custom-control-label::before, .custom-control-label::after {
    top: .3rem;
    left: -2rem;
    width: calc(1rem + 3px);
    height: calc(1rem + 3px);
}

.custom-control-label::before {
    background-color: #d7d9e0;
    border-color: #d7d9e0;
}

.custom-checkbox .custom-control-input:not(:disabled):active ~ .custom-control-label::before,
.custom-radio .custom-control-input:not(:disabled):active ~ .custom-control-label::before,
.custom-switch .custom-control-input:not(:disabled):active ~ .custom-control-label::before {
    background-color: #d4cded;
    border-color: #d4cded;
}

.custom-checkbox .custom-control-input:checked ~ .custom-control-label::before,
.custom-radio .custom-control-input:checked ~ .custom-control-label::before,
.custom-switch .custom-control-input:checked ~ .custom-control-label::before {
    background-color: #725ec3;
    border-color: #725ec3;
}

.custom-checkbox .custom-control-input:focus ~ .custom-control-label::before,
.custom-radio .custom-control-input:focus ~ .custom-control-label::before,
.custom-switch .custom-control-input:focus ~ .custom-control-label::before {
    -webkit-box-shadow: none;
            box-shadow: none;
}

.custom-checkbox .custom-control-input:focus:not(:checked) ~ .custom-control-label::before,
.custom-radio .custom-control-input:focus:not(:checked) ~ .custom-control-label::before,
.custom-switch .custom-control-input:focus:not(:checked) ~ .custom-control-label::before {
    border-color: #d7d9e0;
    -webkit-box-shadow: none;
            box-shadow: none;
}

.custom-checkbox .custom-control-input:disabled ~ .custom-control-label,
.custom-radio .custom-control-input:disabled ~ .custom-control-label,
.custom-switch .custom-control-input:disabled ~ .custom-control-label {
    color: #a4a6a8;
}

.custom-checkbox .custom-control-input:disabled ~ .custom-control-label::before,
.custom-radio .custom-control-input:disabled ~ .custom-control-label::before,
.custom-switch .custom-control-input:disabled ~ .custom-control-label::before {
    background-color: #eaecf0;
    border-color: #eaecf0;
}

.custom-switch {
    padding-left: calc(3rem - 2px);
}

.custom-switch .custom-control-label::before {
    left: calc(-3rem + 2px);
    width: calc(2rem + 1px);
    border-radius: 1rem;
}

.custom-switch .custom-control-label::after {
    top: calc(.3rem + 1px);
    left: calc(-3rem + 3px);
    width: calc(1rem + 1px);
    height: calc(1rem + 1px);
    background-color: #fff;
}

.custom-switch .custom-control-input:checked ~ .custom-control-label::after {
    -webkit-transform: translateX(11px);
        -ms-transform: translateX(11px);
            transform: translateX(11px);
}

.custom-select {
    min-height: 36px;
    padding: 7px 17px;
    color: #4b515b;
    background-color: #fbfcfc;
    background-position: calc(100% - 19px) 50%;
    border-color: #e6ecf0;
}

.custom-select:focus, .custom-select.focus {
    background-color: #fbfcfc;
    border-color: rgba(114, 94, 195, 0.6);
    -webkit-box-shadow: 0 0 0 0.2rem rgba(114, 94, 195, 0.25);
            box-shadow: 0 0 0 0.2rem rgba(114, 94, 195, 0.25);
}

.custom-select:disabled {
    background-color: #f3f4f7;
    border-color: #e6ecf0;
}

.custom-range {
    height: 20px;
}

.custom-range::-webkit-slider-runnable-track {
    background-color: #eaecf0;
}

.custom-range::-moz-range-track {
    background-color: #eaecf0;
}

.custom-range::-ms-track {
    color: transparent;
    background: none;
    border: none;
}

.custom-range::-ms-fill-lower, .custom-range::-ms-fill-upper {
    background-color: #f3f4f7;
}

.custom-range::-webkit-slider-thumb {
    width: 16px;
    height: 16px;
    margin-top: -4.5px;
    background: #725ec3;
    border: 0;
    border-radius: 8px;
}

.custom-range::-webkit-slider-thumb:active {
    background: #d4cded;
}

.custom-range::-moz-range-thumb {
    width: 16px;
    height: 16px;
    margin-top: -4.5px;
    cursor: pointer;
    background: #725ec3;
    border: 0;
    border-radius: 8px;
}

.custom-range::-moz-range-thumb:active {
    background: #d4cded;
}

.custom-range::-ms-thumb {
    width: 16px;
    height: 16px;
    margin-top: 0;
    cursor: pointer;
    background-color: #725ec3;
    border: 0;
    border-radius: 8px;
}

.custom-range::-ms-thumb:active {
    background-color: #d4cded;
}

.custom-range:focus::-webkit-slider-thumb {
    -webkit-box-shadow: 0 0 0 1px #fff, 0 0 0 0.2rem rgba(114, 94, 195, 0.25);
            box-shadow: 0 0 0 1px #fff, 0 0 0 0.2rem rgba(114, 94, 195, 0.25);
}

.custom-range:focus::-moz-range-thumb {
    box-shadow: 0 0 0 1px #fff, 0 0 0 0.2rem rgba(114, 94, 195, 0.25);
}

.custom-range:focus::-ms-thumb {
    box-shadow: 0 0 0 1px #fff, 0 0 0 0.2rem rgba(114, 94, 195, 0.25);
}

.was-validated .form-control:valid,
.was-validated .custom-select:valid,
.form-control.is-valid,
.custom-select.is-valid {
    background-color: #effbf6;
    border-color: #91e5c1;
}

.was-validated .form-control:valid:focus,
.was-validated .custom-select:valid:focus,
.form-control.is-valid:focus,
.custom-select.is-valid:focus {
    border-color: #7ce0b6;
    -webkit-box-shadow: 0 0 0 0.2rem rgba(47, 199, 135, 0.15);
            box-shadow: 0 0 0 0.2rem rgba(47, 199, 135, 0.15);
}

.was-validated .form-control:invalid,
.was-validated .custom-select:invalid,
.form-control.is-invalid,
.custom-select.is-invalid {
    background-color: #fef9fa;
    border-color: #fac6cc;
}

.was-validated .form-control:invalid:focus,
.was-validated .custom-select:invalid:focus,
.form-control.is-invalid:focus,
.custom-select.is-invalid:focus {
    border-color: #f8aeb7;
    -webkit-box-shadow: 0 0 0 0.2rem rgba(239, 81, 100, 0.15);
            box-shadow: 0 0 0 0.2rem rgba(239, 81, 100, 0.15);
}

.was-validated .form-control:invalid,
.was-validated .form-control:valid,
.form-control.is-invalid,
.form-control.is-valid {
    background-image: none;
}

.was-validated .custom-select:valid,
.was-validated .custom-select:invalid,
.custom-select.is-valid,
.custom-select.is-invalid {
    background-position: calc(100% - 19px) 50%;
    background-size: 8px 10px, 0 0;
}

.was-validated .custom-control-input:valid ~ .custom-control-label::before,
.custom-control-input.is-valid ~ .custom-control-label::before {
    background-color: #91e5c1;
    border-color: #91e5c1;
}

.was-validated .custom-control-input:invalid ~ .custom-control-label::before,
.custom-control-input.is-invalid ~ .custom-control-label::before {
    background-color: #fac6cc;
    border-color: #fac6cc;
}

.was-validated .custom-control-input:valid:focus:not(:checked) ~ .custom-control-label::before,
.custom-control-input.is-valid:focus:not(:checked) ~ .custom-control-label::before {
    background-color: #7ce0b6;
    border-color: #7ce0b6;
}

.was-validated .custom-control-input:invalid:focus:not(:checked) ~ .custom-control-label::before,
.custom-control-input.is-invalid:focus:not(:checked) ~ .custom-control-label::before {
    background-color: #f8aeb7;
    border-color: #f8aeb7;
}

.was-validated .custom-control-input:invalid ~ .custom-control-label,
.was-validated .custom-control-input:valid ~ .custom-control-label,
.custom-control-input.is-invalid ~ .custom-control-label,
.custom-control-input.is-valid ~ .custom-control-label {
    color: #393f49;
}

.was-validated .invalid-feedback,
.was-validated .valid-feedback,
.invalid-feedback,
.valid-feedback {
    margin-top: 6px;
}

.was-validated .valid-feedback,
.valid-feedback {
    color: #67dbaa;
}

.was-validated .invalid-feedback,
.invalid-feedback {
    color: #f597a2;
}

.was-validated .custom-checkbox .invalid-feedback,
.was-validated .custom-checkbox .valid-feedback,
.custom-checkbox .invalid-feedback,
.custom-checkbox .valid-feedback {
    margin-top: 2px;
    margin-left: -26px;
}

/*---------------------------
  Element List group
 ----------------------------*/
.list-group-item {
    padding: .75rem 1.45rem;
    border-color: #e6ecf0;
}

.list-group-item.active {
    background-color: #725ec3;
    border-color: #725ec3;
}

.list-group-item-action {
    -webkit-transition: background-color .1s ease-in-out, color .1s ease-in-out;
    -o-transition: background-color .1s ease-in-out, color .1s ease-in-out;
    transition: background-color .1s ease-in-out, color .1s ease-in-out;
}

.list-group-item-action:hover, .list-group-item-action.hover, .list-group-item-action:focus, .list-group-item-action.focus {
    color: #393f49;
    background-color: #f8f9fa;
}

.list-group-item-action:active {
    color: #393f49;
    background-color: #f3f4f7;
}

.list-group-item-action.active {
    background-color: #725ec3;
}

.list-group-item-action.active:hover, .list-group-item-action.active.hover, .list-group-item-action.active:focus, .list-group-item-action.active.focus, .list-group-item-action.active:active {
    color: #fff;
}

.list-group-item-brand,
.list-group-item-primary,
.list-group-item-secondary,
.list-group-item-success,
.list-group-item-danger,
.list-group-item-warning,
.list-group-item-info,
.list-group-item-light,
.list-group-item-dark {
    color: #6c757d;
    background-color: #fff;
    border-left: 0;
}

.list-group-item-brand:first-child,
.list-group-item-primary:first-child,
.list-group-item-secondary:first-child,
.list-group-item-success:first-child,
.list-group-item-danger:first-child,
.list-group-item-warning:first-child,
.list-group-item-info:first-child,
.list-group-item-light:first-child,
.list-group-item-dark:first-child {
    border-top-left-radius: 0;
}

.list-group-item-brand:last-child,
.list-group-item-primary:last-child,
.list-group-item-secondary:last-child,
.list-group-item-success:last-child,
.list-group-item-danger:last-child,
.list-group-item-warning:last-child,
.list-group-item-info:last-child,
.list-group-item-light:last-child,
.list-group-item-dark:last-child {
    border-bottom-left-radius: 0;
}

.list-group-item-brand.list-group-item-action:hover, .list-group-item-brand.list-group-item-action.hover, .list-group-item-brand.list-group-item-action:focus, .list-group-item-brand.list-group-item-action.focus,
.list-group-item-primary.list-group-item-action:hover,
.list-group-item-primary.list-group-item-action.hover,
.list-group-item-primary.list-group-item-action:focus,
.list-group-item-primary.list-group-item-action.focus,
.list-group-item-secondary.list-group-item-action:hover,
.list-group-item-secondary.list-group-item-action.hover,
.list-group-item-secondary.list-group-item-action:focus,
.list-group-item-secondary.list-group-item-action.focus,
.list-group-item-success.list-group-item-action:hover,
.list-group-item-success.list-group-item-action.hover,
.list-group-item-success.list-group-item-action:focus,
.list-group-item-success.list-group-item-action.focus,
.list-group-item-danger.list-group-item-action:hover,
.list-group-item-danger.list-group-item-action.hover,
.list-group-item-danger.list-group-item-action:focus,
.list-group-item-danger.list-group-item-action.focus,
.list-group-item-warning.list-group-item-action:hover,
.list-group-item-warning.list-group-item-action.hover,
.list-group-item-warning.list-group-item-action:focus,
.list-group-item-warning.list-group-item-action.focus,
.list-group-item-info.list-group-item-action:hover,
.list-group-item-info.list-group-item-action.hover,
.list-group-item-info.list-group-item-action:focus,
.list-group-item-info.list-group-item-action.focus,
.list-group-item-light.list-group-item-action:hover,
.list-group-item-light.list-group-item-action.hover,
.list-group-item-light.list-group-item-action:focus,
.list-group-item-light.list-group-item-action.focus,
.list-group-item-dark.list-group-item-action:hover,
.list-group-item-dark.list-group-item-action.hover,
.list-group-item-dark.list-group-item-action:focus,
.list-group-item-dark.list-group-item-action.focus {
    color: #393f49;
    background-color: #f8f9fa;
}

.list-group-item-brand.list-group-item-action:active,
.list-group-item-primary.list-group-item-action:active,
.list-group-item-secondary.list-group-item-action:active,
.list-group-item-success.list-group-item-action:active,
.list-group-item-danger.list-group-item-action:active,
.list-group-item-warning.list-group-item-action:active,
.list-group-item-info.list-group-item-action:active,
.list-group-item-light.list-group-item-action:active,
.list-group-item-dark.list-group-item-action:active {
    color: #393f49;
    background-color: #f3f4f7;
}

.list-group-item-brand {
    -webkit-box-shadow: inset 2px 0 0 #725ec3;
            box-shadow: inset 2px 0 0 #725ec3;
}

.list-group-item-primary {
    -webkit-box-shadow: inset 2px 0 0 #007bff;
            box-shadow: inset 2px 0 0 #007bff;
}

.list-group-item-secondary {
    -webkit-box-shadow: inset 2px 0 0 #6c757d;
            box-shadow: inset 2px 0 0 #6c757d;
}

.list-group-item-success {
    -webkit-box-shadow: inset 2px 0 0 #2fc787;
            box-shadow: inset 2px 0 0 #2fc787;
}

.list-group-item-danger {
    -webkit-box-shadow: inset 2px 0 0 #ef5164;
            box-shadow: inset 2px 0 0 #ef5164;
}

.list-group-item-warning {
    -webkit-box-shadow: inset 2px 0 0 #fdbf21;
            box-shadow: inset 2px 0 0 #fdbf21;
}

.list-group-item-info {
    -webkit-box-shadow: inset 2px 0 0 #32bfc8;
            box-shadow: inset 2px 0 0 #32bfc8;
}

.list-group-item-light {
    -webkit-box-shadow: inset 2px 0 0 #f8f9fa;
            box-shadow: inset 2px 0 0 #f8f9fa;
}

.list-group-item-dark {
    -webkit-box-shadow: inset 2px 0 0 #393f49;
            box-shadow: inset 2px 0 0 #393f49;
}

/*---------------------------
  Element Media object
 ----------------------------*/
.media {
    position: relative;
    -webkit-flex-wrap: wrap;
        -ms-flex-wrap: wrap;
            flex-wrap: wrap;
}

.media.media-show > .media-icon {
    visibility: visible;
    opacity: 1;
}

.media + .media {
    margin-top: 30px;
}

.media .media {
    margin-top: 30px;
}

.media.media-retiring .media-title {
    -webkit-box-pack: start;
    -webkit-justify-content: flex-start;
        -ms-flex-pack: start;
            justify-content: flex-start;
}

.media.media-filled + .media-filled {
    margin-top: 0;
}

.media.media-filled > .media-icon {
    top: 10px;
    right: 20px;
}

.media.media-filled::before {
    top: 49px;
    left: 60px;
}

.media.media-filled > .media-link {
    display: -webkit-box;
    display: -webkit-flex;
    display: -ms-flexbox;
    display: flex;
    width: 100%;
    padding: 15px 30px;
    border-radius: .25rem;
    -webkit-transition: background-color .15s ease-in-out;
    -o-transition: background-color .15s ease-in-out;
    transition: background-color .15s ease-in-out;
}

.media.media-filled:hover > .media-link, .media.media-filled.hover > .media-link, .media.media-filled.active > .media-link {
    background-color: #f8f9fa;
}

.media.media-filled:hover > .media-link > .media-img, .media.media-filled.hover > .media-link > .media-img, .media.media-filled.active > .media-link > .media-img {
    color: #bcbec0;
    background-color: #f3f4f7;
}

.media.media-filled:hover > .media-link > .media-img > img, .media.media-filled.hover > .media-link > .media-img > img, .media.media-filled.active > .media-link > .media-img > img {
    opacity: .8;
}

.media.media-filled:hover > .media-icon,
.media.media-filled.hover > .media-icon {
    visibility: visible;
    opacity: 1;
}

.media > .media-link {
    display: -webkit-box;
    display: -webkit-flex;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-align: start;
    -webkit-align-items: flex-start;
        -ms-flex-align: start;
            align-items: flex-start;
    width: 100%;
}

.media > .media-link + .media-content {
    margin-top: 20px;
    overflow: hidden;
}

.media > .media-icon {
    position: absolute;
    display: -webkit-box;
    display: -webkit-flex;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-align: center;
    -webkit-align-items: center;
        -ms-flex-align: center;
            align-items: center;
    -webkit-box-pack: center;
    -webkit-justify-content: center;
        -ms-flex-pack: center;
            justify-content: center;
    top: -5px;
    right: -5px;
    width: 30px;
    height: 30px;
    color: #d7d9e0;
    visibility: hidden;
    opacity: 0;
    -webkit-transition: visibility .15s ease-in-out, opacity .15s ease-in-out, color .15s ease-in-out;
    -o-transition: visibility .15s ease-in-out, opacity .15s ease-in-out, color .15s ease-in-out;
    transition: visibility .15s ease-in-out, opacity .15s ease-in-out, color .15s ease-in-out;
}

.media > .media-icon:hover, .media > .media-icon.hover {
    color: #393f49;
}

.media .media-content {
    width: 100%;
}

.media.media-brand .media-img::before {
    background-color: #725ec3;
    -webkit-box-shadow: 0 0 0 2px #fff;
            box-shadow: 0 0 0 2px #fff;
}

.media.media-primary .media-img::before {
    background-color: #007bff;
    -webkit-box-shadow: 0 0 0 2px #fff;
            box-shadow: 0 0 0 2px #fff;
}

.media.media-secondary .media-img::before {
    background-color: #6c757d;
    -webkit-box-shadow: 0 0 0 2px #fff;
            box-shadow: 0 0 0 2px #fff;
}

.media.media-success .media-img::before {
    background-color: #2fc787;
    -webkit-box-shadow: 0 0 0 2px #fff;
            box-shadow: 0 0 0 2px #fff;
}

.media.media-danger .media-img::before {
    background-color: #ef5164;
    -webkit-box-shadow: 0 0 0 2px #fff;
            box-shadow: 0 0 0 2px #fff;
}

.media.media-warning .media-img::before {
    background-color: #fdbf21;
    -webkit-box-shadow: 0 0 0 2px #fff;
            box-shadow: 0 0 0 2px #fff;
}

.media.media-info .media-img::before {
    background-color: #32bfc8;
    -webkit-box-shadow: 0 0 0 2px #fff;
            box-shadow: 0 0 0 2px #fff;
}

.media.media-light .media-img::before {
    background-color: #f8f9fa;
    -webkit-box-shadow: 0 0 0 2px #fff;
            box-shadow: 0 0 0 2px #fff;
}

.media.media-dark .media-img::before {
    background-color: #393f49;
    -webkit-box-shadow: 0 0 0 2px #fff;
            box-shadow: 0 0 0 2px #fff;
}

.media .media-img {
    position: relative;
    display: -webkit-inline-box;
    display: -webkit-inline-flex;
    display: -ms-inline-flexbox;
    display: inline-flex;
    -webkit-box-flex: 0;
    -webkit-flex: 0 0 45px;
        -ms-flex: 0 0 45px;
            flex: 0 0 45px;
    -webkit-box-align: center;
    -webkit-align-items: center;
        -ms-flex-align: center;
            align-items: center;
    -webkit-box-pack: center;
    -webkit-justify-content: center;
        -ms-flex-pack: center;
            justify-content: center;
    width: 45px;
    height: 45px;
    margin-top: 0;
    margin-bottom: 0;
    font-size: 1.145rem;
    line-height: 0;
    color: #d7d9e0;
    background-color: #f8f9fa;
    border-radius: 100%;
    -webkit-transition: opacity .15s ease-in-out, color .15s ease-in-out, background-color .15s ease-in-out;
    -o-transition: opacity .15s ease-in-out, color .15s ease-in-out, background-color .15s ease-in-out;
    transition: opacity .15s ease-in-out, color .15s ease-in-out, background-color .15s ease-in-out;
}

.media .media-img::before {
    content: "";
    position: absolute;
    top: 40px;
    left: 34px;
    width: 5px;
    height: 5px;
    border-radius: 100%;
    z-index: 1;
}

.media .media-img + .media-body {
    margin-left: 15px;
}

.media .media-img > img {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    font-family: "object-fit: cover";
    -o-object-fit: cover;
       object-fit: cover;
    border-radius: 100%;
    opacity: 1;
    will-change: opacity;
    -webkit-transition: opacity .15s ease-in-out;
    -o-transition: opacity .15s ease-in-out;
    transition: opacity .15s ease-in-out;
}

.media .media-img > .icon-file {
    width: auto;
    border-radius: 0;
}

.media .media-img + .rui-search-item-content {
    padding-top: 4px;
    margin-left: 15px;
}

.media .media-title {
    display: -webkit-box;
    display: -webkit-flex;
    display: -ms-flexbox;
    display: flex;
    -webkit-flex-wrap: wrap;
        -ms-flex-wrap: wrap;
            flex-wrap: wrap;
    -webkit-box-align: center;
    -webkit-align-items: center;
        -ms-flex-align: center;
            align-items: center;
    -webkit-box-pack: justify;
    -webkit-justify-content: space-between;
        -ms-flex-pack: justify;
            justify-content: space-between;
    margin-top: -4px;
    font-size: 1.08rem;
    color: #393f49;
}

.media .media-title + .media-subtitle {
    margin-top: 5px;
}

.media .media-title .media-time {
    margin-bottom: -1px;
    margin-left: 15px;
}

.media .media-time {
    font-size: 12px;
    color: #d7d9e0;
}

.media .media-subtitle {
    display: block;
    margin-bottom: -4px;
    color: #a4a6a8;
}

.media:hover > .media-icon, .media.hover > .media-icon,
.media:hover > .media-link > .media-icon,
.media.hover > .media-link > .media-icon,
.media > .media-link:hover > .media-icon,
.media > .media-link.hover > .media-icon {
    visibility: visible;
    opacity: 1;
}

.media:hover > .media-img, .media.hover > .media-img,
.media:hover > .media-link > .media-img,
.media.hover > .media-link > .media-img,
.media > .media-link:hover > .media-img,
.media > .media-link.hover > .media-img {
    color: #bcbec0;
    background-color: #f3f4f7;
}

.media:hover > .media-img > img, .media.hover > .media-img > img,
.media:hover > .media-link > .media-img > img,
.media.hover > .media-link > .media-img > img,
.media > .media-link:hover > .media-img > img,
.media > .media-link.hover > .media-img > img {
    opacity: .8;
}

.media:hover, .media.hover, .media:focus, .media.focus,
.media > .media-link:hover,
.media > .media-link.hover,
.media > .media-link:focus,
.media > .media-link.focus {
    text-decoration: none;
}

.media-country .media-img {
    -webkit-box-flex: 0;
    -webkit-flex: 0 0 20px;
        -ms-flex: 0 0 20px;
            flex: 0 0 20px;
    width: 20px;
    height: 20px;
}

.media-country .media-body {
    display: -webkit-box;
    display: -webkit-flex;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-pack: justify;
    -webkit-justify-content: space-between;
        -ms-flex-pack: justify;
            justify-content: space-between;
}

.media-country .media-country-price {
    color: #a4a6a8;
}

/*---------------------------
  Element Modal
 ----------------------------*/
.modal .close {
    display: -webkit-inline-box;
    display: -webkit-inline-flex;
    display: -ms-inline-flexbox;
    display: inline-flex;
    color: #bcbec0;
    -webkit-transition: color .15s ease-in-out;
    -o-transition: color .15s ease-in-out;
    transition: color .15s ease-in-out;
}

.modal .close:hover, .modal .close.hover {
    color: #393f49;
}

.modal-content {
    border: 0;
    -webkit-box-shadow: 0 3px 10px rgba(0, 0, 0, 0.03);
            box-shadow: 0 3px 10px rgba(0, 0, 0, 0.03);
}

.modal-header,
.modal-body,
.modal-footer {
    border: 0;
}

.modal-header {
    padding: 30px;
}

.modal-header + .modal-body,
.modal-header + .modal-footer {
    padding-top: 0;
}

.modal-body {
    padding: 30px;
}

.modal-body + .modal-footer {
    padding-top: 0;
}

.modal-footer {
    padding: 30px;
}

.modal-footer > :not(:last-child) {
    margin-right: 5px;
}

.modal-footer > :not(:first-child) {
    margin-left: 5px;
}

.modal-title {
    margin-bottom: -4px;
}

.modal-backdrop {
    background-color: rgba(50, 57, 66, 0.9);
}

.modal-backdrop.show {
    opacity: 1;
}

/*---------------------------
  Element Navs
 ----------------------------*/
.nav .nav:not(.dropdown-menu) {
    padding-left: 20px;
}

.nav .nav .nav-link {
    position: relative;
}

.nav .nav .nav-link::before {
    content: "";
    position: absolute;
    top: 50%;
    left: 0;
    width: 2px;
    height: 2px;
    margin-top: -1px;
    background-color: #d7d9e0;
    border-radius: 2px;
}

.nav .nav .nav-link:hover::before, .nav .nav .nav-link.hover::before, .nav .nav .nav-link:focus::before, .nav .nav .nav-link.focus::before, .nav .nav .nav-link.active::before {
    background-color: #393f49;
}

.nav.dropdown-menu .nav-link,
.nav.dropdown-menu .dropdown-item {
    padding: 9px 15px 9px 15px;
}

.nav.dropdown-menu .nav-link > .rui-nav-circle,
.nav.dropdown-menu .nav-link > .rui-dropdown-circle,
.nav.dropdown-menu .dropdown-item > .rui-nav-circle,
.nav.dropdown-menu .dropdown-item > .rui-dropdown-circle {
    left: 4px;
}

.nav .nav-link {
    position: relative;
    display: -webkit-box;
    display: -webkit-flex;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-align: center;
    -webkit-align-items: center;
        -ms-flex-align: center;
            align-items: center;
    padding: 8.5px 20px;
    color: #a4a6a8;
    -webkit-transition: color .1s ease-in-out, border-color .1s ease-in-out, background-color .15s ease-in-out;
    -o-transition: color .1s ease-in-out, border-color .1s ease-in-out, background-color .15s ease-in-out;
    transition: color .1s ease-in-out, border-color .1s ease-in-out, background-color .15s ease-in-out;
}

.nav .nav-link > span,
.nav .nav-link > svg {
    z-index: 1;
}

.nav .nav-link > span:not([class]) {
    color: #393f49;
}

.nav .nav-link > .rui-nav-circle {
    position: absolute;
    top: 0;
    left: -2px;
    width: 36px;
    height: 36px;
    background-color: #f8f9fa;
    border-radius: 100%;
    opacity: 0;
    -webkit-transition: opacity .1s ease-in-out;
    -o-transition: opacity .1s ease-in-out;
    transition: opacity .1s ease-in-out;
    z-index: -1;
}

.nav .nav-link > .rui-icon {
    display: inline-block;
    margin: 0 14px -1px;
    stroke: #bcbec0;
    -webkit-transition: stroke .1s ease-in-out;
    -o-transition: stroke .1s ease-in-out;
    transition: stroke .1s ease-in-out;
}

.nav .nav-link > .rui-icon:first-child {
    margin-left: 0;
}

.nav .nav-link > .rui-icon:last-child {
    margin-right: 0;
}

.nav .nav-link > .badge,
.nav .nav-link > .badge-circle {
    position: absolute;
    top: -5px;
    left: 10px;
    min-width: 11px;
    height: 11px;
    font-size: 7px;
    -webkit-box-shadow: 0 0 0 2px #fff;
            box-shadow: 0 0 0 2px #fff;
}

.nav .nav-link.show {
    color: #bcbec0;
}

.nav .nav-link.show > span:not([class]) {
    color: #393f49;
}

.nav .nav-link.show > .rui-icon {
    stroke: #d7d9e0;
}

.nav .nav-link:hover, .nav .nav-link.hover, .nav .nav-link:focus, .nav .nav-link.focus {
    color: #393f49;
}

.nav .nav-link:hover > span:not([class]), .nav .nav-link.hover > span:not([class]), .nav .nav-link:focus > span:not([class]), .nav .nav-link.focus > span:not([class]) {
    color: #393f49;
}

.nav .nav-link:hover > .rui-nav-circle, .nav .nav-link.hover > .rui-nav-circle, .nav .nav-link:focus > .rui-nav-circle, .nav .nav-link.focus > .rui-nav-circle {
    opacity: 1;
}

.nav .nav-link:hover > .rui-icon, .nav .nav-link.hover > .rui-icon, .nav .nav-link:focus > .rui-icon, .nav .nav-link.focus > .rui-icon {
    stroke: #393f49;
}

.nav .nav-link.active {
    color: #393f49;
}

.nav .nav-link.active > span:not([class]) {
    color: #725ec3;
}

.nav .nav-link.active > .rui-icon {
    stroke: #725ec3;
}

.nav .nav-link.disabled {
    color: #eaecf0;
}

.nav .nav-link.disabled > span:not([class]) {
    color: #eaecf0;
}

.nav .nav-link.disabled > .rui-icon {
    stroke: #eaecf0;
}

.nav-tabs {
    border-color: #e6ecf0;
}

.nav-tabs + .tab-content {
    margin-top: 20px;
}

.nav-tabs .nav-link {
    position: relative;
    padding: 0 15px 10px;
    border: 0;
    border-bottom: 2px solid transparent;
    z-index: 1;
}

.nav-tabs .nav-link.show {
    color: #bcbec0;
}

.nav-tabs .nav-link:hover, .nav-tabs .nav-link.hover, .nav-tabs .nav-link:focus, .nav-tabs .nav-link.focus {
    color: #393f49;
    border-bottom-color: transparent;
}

.nav-tabs .nav-link.active {
    color: #725ec3;
    border-bottom-color: rgba(114, 94, 195, 0.8);
}

.nav-pills {
    position: relative;
    display: -webkit-inline-box;
    display: -webkit-inline-flex;
    display: -ms-inline-flexbox;
    display: inline-flex;
    padding: 2px;
    background-color: #f3f4f7;
    border-radius: .25rem;
}

.nav-pills + .tab-content {
    margin-top: 20px;
}

.nav-pills .nav-link {
    position: relative;
    padding: 10px 15px;
    font-size: 11px;
    font-weight: 700;
    color: #a4a6a8;
    text-transform: uppercase;
}

.nav-pills .nav-link.show {
    color: #a4a6a8;
}

.nav-pills .nav-link:hover, .nav-pills .nav-link.hover, .nav-pills .nav-link:focus, .nav-pills .nav-link.focus {
    color: #6c757d;
    border-color: transparent;
}

.nav-pills .nav-link.active {
    color: #6c757d;
    background-color: #fff;
}

.nav-pills .nav-link.disabled {
    color: #d7d9e0;
}

.rui-tabs-sliding {
    position: relative;
}

.rui-tabs-sliding.nav-pills .rui-tabs-link.active {
    background-color: #fff;
}

.rui-tabs-sliding.nav-pills .rui-tabs-slide {
    top: 0;
    left: 0;
    height: 36.4px;
    background-color: #fff;
    border-radius: .15rem;
    z-index: 0;
}

.rui-tabs-sliding.nav-tabs .rui-tabs-link.active {
    border-bottom-color: rgba(114, 94, 195, 0.8);
}

.rui-tabs-sliding.nav-tabs .rui-tabs-slide {
    top: 0;
    left: 0;
    height: 31px;
    border-bottom: 2px solid rgba(114, 94, 195, 0.8);
    z-index: 1;
}

.rui-tabs-sliding .rui-tabs-slide {
    position: absolute;
    opacity: 0;
    will-change: opacity;
    -webkit-transition: opacity .15s ease-in-out;
    -o-transition: opacity .15s ease-in-out;
    transition: opacity .15s ease-in-out;
    -webkit-transform: translate(2px, 2px);
        -ms-transform: translate(2px, 2px);
            transform: translate(2px, 2px);
}

.rui-tabs-sliding-ready.nav-pills .rui-tabs-link.active {
    background-color: transparent;
}

.rui-tabs-sliding-ready.nav-tabs .rui-tabs-link.active {
    background-color: transparent;
    border-bottom-color: transparent;
}

.rui-tabs-sliding-ready .rui-tabs-slide {
    opacity: 1;
    will-change: transform, opacity;
    -webkit-transition: width .15s ease-in-out, opacity .15s ease-in-out, -webkit-transform .15s ease-in-out;
    transition: width .15s ease-in-out, opacity .15s ease-in-out, -webkit-transform .15s ease-in-out;
    -o-transition: transform .15s ease-in-out, width .15s ease-in-out, opacity .15s ease-in-out;
    transition: transform .15s ease-in-out, width .15s ease-in-out, opacity .15s ease-in-out;
    transition: transform .15s ease-in-out, width .15s ease-in-out, opacity .15s ease-in-out, -webkit-transform .15s ease-in-out;
}

/*---------------------------
  Element Popovers
 ----------------------------*/
.popover {
    font-family: "Open Sans", sans-serif;
    border: 1px solid #e6ecf0;
    -webkit-box-shadow: 0 3px 10px rgba(0, 0, 0, 0.03);
            box-shadow: 0 3px 10px rgba(0, 0, 0, 0.03);
}

.popover > .arrow::after {
    display: none;
}

.popover > .arrow::before {
    content: "";
    position: absolute;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    border: 0;
    z-index: -1;
}

.bs-popover-top,
.bs-popover-auto[x-placement^="top"] {
    margin: 0 0 15px;
}

.bs-popover-top > .arrow,
.bs-popover-auto[x-placement^="top"] > .arrow {
    margin: 0 0 -11px;
    border: 10px solid transparent;
    border-top: 9px solid #fff;
}

.bs-popover-top > .arrow::before,
.bs-popover-auto[x-placement^="top"] > .arrow::before {
    margin: -9px 0 0 -11px;
    border: 11px solid transparent;
    border-top: 10px solid #e6ecf0;
}

.bs-popover-right,
.bs-popover-auto[x-placement^="right"] {
    margin: 0 0 0 15px;
}

.bs-popover-right > .arrow,
.bs-popover-auto[x-placement^="right"] > .arrow {
    margin: 0 0 0 -11px;
    border: 10px solid transparent;
    border-right: 9px solid #fff;
}

.bs-popover-right > .arrow::before,
.bs-popover-auto[x-placement^="right"] > .arrow::before {
    margin: -11px 0 0 -12px;
    border: 11px solid transparent;
    border-right: 10px solid #e6ecf0;
}

.bs-popover-bottom,
.bs-popover-auto[x-placement^="bottom"] {
    margin: 15px 0 0;
}

.bs-popover-bottom > .arrow,
.bs-popover-auto[x-placement^="bottom"] > .arrow {
    margin: -11px 0;
    border: 10px solid transparent;
    border-bottom: 9px solid #fff;
}

.bs-popover-bottom > .arrow::before,
.bs-popover-auto[x-placement^="bottom"] > .arrow::before {
    margin: -12px 0 0 -11px;
    border: 11px solid transparent;
    border-bottom: 10px solid #e6ecf0;
}

.bs-popover-left,
.bs-popover-auto[x-placement^="left"] {
    margin: 0 15px 0 0;
}

.bs-popover-left > .arrow,
.bs-popover-auto[x-placement^="left"] > .arrow {
    margin: 0 -11px 0 0;
    border: 10px solid transparent;
    border-left: 9px solid #fff;
}

.bs-popover-left > .arrow::before,
.bs-popover-auto[x-placement^="left"] > .arrow::before {
    margin: -11px 0 2px -9px;
    border: 11px solid transparent;
    border-left: 10px solid #e6ecf0;
}

.popover-header {
    padding: 20px;
    margin-top: 0;
    margin-bottom: -20px;
    font-size: 1.145rem;
    background-color: transparent;
    border: 0;
}

.popover-body {
    padding: 20px;
    color: #4b515b;
}

/*---------------------------
  Element Progress
 ----------------------------*/
.progress {
    height: 1.231rem;
    background-color: #f3f4f7;
}

.progress-sm {
    height: 1rem;
    border-radius: .2rem;
}

.progress-bar {
    background-size: 2rem 2rem;
}

@-webkit-keyframes progress-bar-stripes {
    from {
        background-position: 2rem 0;
    }
    to {
        background-position: 0 0;
    }
}

@keyframes progress-bar-stripes {
    from {
        background-position: 2rem 0;
    }
    to {
        background-position: 0 0;
    }
}

/*---------------------------
  Element Spinners
 ----------------------------*/
.spinner-border {
    border-width: .15em;
}

.btn > .spinner-border {
    -webkit-align-self: center;
        -ms-flex-item-align: center;
            align-self: center;
    width: 1em;
    height: 1em;
    margin-left: 1em;
}

/*---------------------------
  Element Toast
 ----------------------------*/
.toast {
    border-color: #e6ecf0;
    border-left: 2px solid #725ec3;
    -webkit-box-shadow: 0 0 10px 3px rgba(0, 0, 0, 0.03);
            box-shadow: 0 0 10px 3px rgba(0, 0, 0, 0.03);
}

.toast-date {
    margin-left: 20px;
    color: #bcbec0;
}

.toast-close {
    color: #bcbec0;
    -webkit-transition: color .1s ease-in-out;
    -o-transition: color .1s ease-in-out;
    transition: color .1s ease-in-out;
}

.toast-close:hover {
    color: #393f49;
}

.toast-header {
    padding: 16px 20px;
    border: 0;
}

.toast-header + .toast-body {
    padding-top: 0;
    margin-top: -4px;
}

.toast-body {
    padding: 16px 20px;
}

.rui-toast.hide {
    display: none;
}

.rui-toast-container {
    position: fixed;
    display: -webkit-box;
    display: -webkit-flex;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-orient: vertical;
    -webkit-box-direction: normal;
    -webkit-flex-direction: column;
        -ms-flex-direction: column;
            flex-direction: column;
    top: 46px;
    right: 0;
    margin: 30px 30px 10px;
    z-index: 1000;
}

.rui-toast-container .rui-toast {
    visibility: hidden;
    background-color: #fff;
    opacity: 0;
    -webkit-transition: opacity .25s ease-in-out, visibility .25s ease-in-out, -webkit-transform .25s ease-in-out;
    transition: opacity .25s ease-in-out, visibility .25s ease-in-out, -webkit-transform .25s ease-in-out;
    -o-transition: opacity .25s ease-in-out, visibility .25s ease-in-out, transform .25s ease-in-out;
    transition: opacity .25s ease-in-out, visibility .25s ease-in-out, transform .25s ease-in-out;
    transition: opacity .25s ease-in-out, visibility .25s ease-in-out, transform .25s ease-in-out, -webkit-transform .25s ease-in-out;
    -webkit-transform: translateX(20px);
        -ms-transform: translateX(20px);
            transform: translateX(20px);
}

@media (max-width: 575.98px) {
    .rui-toast-container .rui-toast {
        max-width: 300px;
    }
}

.rui-toast-container .rui-toast.show {
    visibility: visible;
    opacity: 1;
    -webkit-transform: translateX(0);
        -ms-transform: translateX(0);
            transform: translateX(0);
}

.rui-toast-container .rui-toast.hide {
    visibility: hidden;
    opacity: 0;
}

@media (min-width: 576px) and (max-width: 767.98px) {
    .rui-toast-container {
        top: auto;
        bottom: 20px;
    }
    .rui-toast-container .rui-toast {
        max-width: 400px;
    }
}

@media (max-width: 575.98px) {
    .rui-toast-container {
        top: auto;
        bottom: 10px;
        left: 0;
        margin: 20px 20px 10px;
        z-index: 1010;
    }
    .rui-toast-container .rui-toast {
        max-width: none;
    }
}

.toast-brand {
    border-left-color: #725ec3;
}

.toast-primary {
    border-left-color: #007bff;
}

.toast-secondary {
    border-left-color: #6c757d;
}

.toast-success {
    border-left-color: #2fc787;
}

.toast-danger {
    border-left-color: #ef5164;
}

.toast-warning {
    border-left-color: #fdbf21;
}

.toast-info {
    border-left-color: #32bfc8;
}

.toast-light {
    border-left-color: #f8f9fa;
}

.toast-dark {
    border-left-color: #393f49;
}

/*---------------------------
  Element Blockquote
 ----------------------------*/
.blockquote {
    padding: 18px 30px;
    font-family: "PT Serif", serif;
    font-size: 1.15rem;
    color: #6c757d;
    background-color: #f8f9fa;
    border-radius: .25rem;
}

.blockquote p + .blockquote-footer {
    margin-top: 8px;
}

.blockquote-footer {
    margin-bottom: -3px;
    font-size: 14px;
    color: #a4a6a8;
}

.blockquote-footer::before {
    display: none;
}

.blockquote-style-2 {
    padding: 13px 30px 13px 26px;
    background-color: #fff;
    border-left: 4px solid #f3f4f7;
    border-radius: 0;
}

.blockquote-style-2 .blockquote-footer {
    color: #bcbec0;
}

/*---------------------------
  Element Search
 ----------------------------*/
.rui-search .rui-search-head {
    position: relative;
    padding-bottom: 30px;
    margin-top: -12px;
    margin-bottom: -15px;
    z-index: 1;
}

.rui-search .rui-search-body {
    padding-top: 30px;
}

/*---------------------------
  Element Popup
 ----------------------------*/
.rui-popup {
    display: none;
    padding: 30px;
    border-radius: .25rem;
    -webkit-box-shadow: 0 3px 10px rgba(0, 0, 0, 0.03);
            box-shadow: 0 3px 10px rgba(0, 0, 0, 0.03);
}

@media (min-width: 992px) {
    .rui-popup::-webkit-scrollbar-track {
        background-color: #fff;
        border-radius: .25rem;
    }
    .rui-popup::-webkit-scrollbar {
        width: 4px;
        background-color: #fff;
        border-radius: .25rem;
    }
    .rui-popup::-webkit-scrollbar-thumb {
        background-color: #d7d9e0;
        border-radius: .25rem;
    }
}

.rui-popup .fancybox-close-small {
    display: -webkit-inline-box;
    display: -webkit-inline-flex;
    display: -ms-inline-flexbox;
    display: inline-flex;
    -webkit-box-align: center;
    -webkit-align-items: center;
        -ms-flex-align: center;
            align-items: center;
    -webkit-box-pack: center;
    -webkit-justify-content: center;
        -ms-flex-pack: center;
            justify-content: center;
    top: 18px;
    right: 16px;
    width: 38px;
    height: 38px;
    padding: 0;
    color: #bcbec0;
    -webkit-transition: color .15s ease-in-out;
    -o-transition: color .15s ease-in-out;
    transition: color .15s ease-in-out;
}

.rui-popup .fancybox-close-small > svg {
    position: relative;
    width: 16px;
    height: 16px;
    z-index: 1;
}

.rui-popup .fancybox-close-small:hover, .rui-popup .fancybox-close-small.hover {
    color: #393f49;
}

.rui-popup .fancybox-close-small.fancybox-focus {
    outline: 0;
}

.rui-popup-search {
    position: absolute;
    top: 100px;
    margin-bottom: 100px;
    will-change: transform;
    -webkit-transition: -webkit-transform .15s ease-in-out;
    transition: -webkit-transform .15s ease-in-out;
    -o-transition: transform .15s ease-in-out;
    transition: transform .15s ease-in-out;
    transition: transform .15s ease-in-out, -webkit-transform .15s ease-in-out;
    -webkit-transform: scale(0.9) translateY(-30px) translateX(100px);
        -ms-transform: scale(0.9) translateY(-30px) translateX(100px);
            transform: scale(0.9) translateY(-30px) translateX(100px);
}

@media (max-width: 1199.98px) {
    .rui-popup-search {
        top: 30px;
        width: calc(100% - 60px);
        margin-right: 30px;
        margin-bottom: 30px;
    }
}

@media (max-width: 767.98px) {
    .rui-popup-search {
        top: 10px;
        width: calc(100% - 20px);
        margin-right: 10px;
        margin-bottom: 10px;
    }
}

@media (max-width: 575.98px) {
    .rui-popup-search {
        top: 5px;
        width: calc(100% - 10px);
        margin-right: 5px;
        margin-bottom: 5px;
    }
}

.fancybox-slide--current .rui-popup-search,
.fancybox-is-closing .rui-popup-search {
    -webkit-transform: scale(1) translateY(0) translateX(0);
        -ms-transform: scale(1) translateY(0) translateX(0);
            transform: scale(1) translateY(0) translateX(0);
}

.rui-popup-messenger {
    position: absolute;
    top: 20px;
    right: 20px;
    bottom: 20px;
    width: 420px;
    padding: 0;
    overflow: hidden;
}

@media (max-width: 767.98px) {
    .rui-popup-messenger {
        top: 10px;
        right: 10px;
        bottom: 10px;
        max-width: calc(100% - 20px);
    }
}

@media (max-width: 575.98px) {
    .rui-popup-messenger {
        top: 5px;
        right: 5px;
        bottom: 5px;
        width: calc(100% - 10px);
        max-width: none;
    }
}

/*---------------------------
  Element Snippet
 ----------------------------*/
.rui-snippet,
.rui-snippet-single {
    position: relative;
}

.rui-snippet pre,
.rui-snippet-single pre {
    max-height: 350px;
    padding: 27px 0 0 30px;
    margin: -29px;
    overflow: auto;
}

.rui-snippet pre::-webkit-scrollbar-track,
.rui-snippet-single pre::-webkit-scrollbar-track {
    background-color: #fff;
    border-radius: 4px;
}

.rui-snippet pre::-webkit-scrollbar,
.rui-snippet-single pre::-webkit-scrollbar {
    width: 4px;
    height: 4px;
    background-color: #fff;
}

.rui-snippet pre::-webkit-scrollbar-thumb,
.rui-snippet-single pre::-webkit-scrollbar-thumb {
    background-color: #eaecf0;
    border-radius: 4px;
}

.rui-snippet code,
.rui-snippet-single code {
    width: auto;
    padding-right: 30px;
    margin-bottom: 24px;
    overflow: initial;
}

.rui-snippet + .rui-snippet {
    margin-top: 30px;
}

.rui-snippet.rui-snippet-frame .rui-snippet-preview,
.rui-snippet.rui-snippet-frame .rui-snippet-html,
.rui-snippet.rui-snippet-frame .rui-snippet-js {
    padding: 30px;
    border: 2px solid #f3f4f7;
    border-radius: .25rem;
}

@media (max-width: 575.98px) {
    .rui-snippet.rui-snippet-frame .rui-snippet-preview,
    .rui-snippet.rui-snippet-frame .rui-snippet-html,
    .rui-snippet.rui-snippet-frame .rui-snippet-js {
        padding: 20px;
    }
}

.rui-snippet.rui-snippet-frame-preview .rui-snippet-html,
.rui-snippet.rui-snippet-frame-preview .rui-snippet-js {
    padding: 30px;
    border: 2px solid #f3f4f7;
    border-radius: .25rem;
}

.rui-snippet .rui-snippet-html + .rui-snippet-html {
    margin-top: 10px;
}

.rui-snippet-single.rui-snippet-filled {
    padding: 30px;
    background-color: #f8f9fa;
    border-radius: .25rem;
}

.rui-snippet-single.rui-snippet-frame {
    padding: 30px;
    background-color: #fff;
    border: 2px solid #f3f4f7;
    border-radius: .25rem;
}

/*---------------------------
  Element Timeline
 ----------------------------*/
.rui-timeline {
    position: relative;
}

.rui-timeline.rui-timeline-left .rui-timeline-item + .rui-timeline-item, .rui-timeline.rui-timeline-right .rui-timeline-item + .rui-timeline-item {
    margin-top: 20px;
}

.rui-timeline.rui-timeline-left .rui-timeline-item-swap > .rui-timeline-content,
.rui-timeline.rui-timeline-left .rui-timeline-content {
    -webkit-box-ordinal-group: 2;
    -webkit-order: 1;
        -ms-flex-order: 1;
            order: 1;
}

.rui-timeline.rui-timeline-left .rui-timeline-item-swap > .rui-timeline-date,
.rui-timeline.rui-timeline-left .rui-timeline-date {
    -webkit-box-ordinal-group: 13;
    -webkit-order: 12;
        -ms-flex-order: 12;
            order: 12;
    text-align: left;
}

.rui-timeline.rui-timeline-right .rui-timeline-item-swap > .rui-timeline-content,
.rui-timeline.rui-timeline-right .rui-timeline-content {
    -webkit-box-ordinal-group: 13;
    -webkit-order: 12;
        -ms-flex-order: 12;
            order: 12;
}

.rui-timeline.rui-timeline-right .rui-timeline-item-swap > .rui-timeline-date,
.rui-timeline.rui-timeline-right .rui-timeline-date {
    -webkit-box-ordinal-group: 2;
    -webkit-order: 1;
        -ms-flex-order: 1;
            order: 1;
    text-align: right;
}

.rui-timeline .rui-timeline-line {
    position: absolute;
    top: 0;
    bottom: 0;
    left: 50%;
    width: 1px;
    margin-left: -.5px;
    background-color: #f3f4f7;
}

.rui-timeline .rui-timeline-item {
    position: relative;
    display: -webkit-box;
    display: -webkit-flex;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-pack: justify;
    -webkit-justify-content: space-between;
        -ms-flex-pack: justify;
            justify-content: space-between;
}

.rui-timeline .rui-timeline-item:hover .rui-timeline-icon, .rui-timeline .rui-timeline-item.hover .rui-timeline-icon {
    border-color: #d3dde5;
}

.rui-timeline .rui-timeline-item:hover .rui-timeline-date, .rui-timeline .rui-timeline-item.hover .rui-timeline-date {
    color: #6c757d;
}

.rui-timeline .rui-timeline-item-swap > .rui-timeline-content {
    -webkit-box-ordinal-group: 13;
    -webkit-order: 12;
        -ms-flex-order: 12;
            order: 12;
}

.rui-timeline .rui-timeline-item-swap > .rui-timeline-date {
    -webkit-box-ordinal-group: 2;
    -webkit-order: 1;
        -ms-flex-order: 1;
            order: 1;
    text-align: right;
}

.rui-timeline .rui-timeline-icon {
    position: absolute;
    display: -webkit-inline-box;
    display: -webkit-inline-flex;
    display: -ms-inline-flexbox;
    display: inline-flex;
    -webkit-box-align: center;
    -webkit-align-items: center;
        -ms-flex-align: center;
            align-items: center;
    -webkit-box-pack: center;
    -webkit-justify-content: center;
        -ms-flex-pack: center;
            justify-content: center;
    top: 30px;
    left: 50%;
    width: 50px;
    height: 50px;
    margin-left: -25px;
    color: #6c757d;
    background-color: #f8f9fa;
    border: 1px solid #e6ecf0;
    border-radius: 100%;
    -webkit-transition: border-color .15s ease-in-out;
    -o-transition: border-color .15s ease-in-out;
    transition: border-color .15s ease-in-out;
}

.rui-timeline .rui-timeline-content {
    width: calc(50% - 55px);
    padding: 30px;
    border: 1px solid #e6ecf0;
    border-radius: .25rem;
}

.rui-timeline .rui-timeline-date {
    width: calc(50% - 55px);
    padding-top: 12px;
    margin-top: 30px;
    color: #bcbec0;
    -webkit-transition: color .1s ease-in-out;
    -o-transition: color .1s ease-in-out;
    transition: color .1s ease-in-out;
}

@media (max-width: 1199.98px) {
    .rui-timeline.rui-timeline-left-xl .rui-timeline-item + .rui-timeline-item {
        margin-top: 20px;
    }
    .rui-timeline.rui-timeline-left-xl .rui-timeline-content {
        -webkit-box-ordinal-group: 2;
        -webkit-order: 1;
            -ms-flex-order: 1;
                order: 1;
    }
    .rui-timeline.rui-timeline-left-xl .rui-timeline-date {
        -webkit-box-ordinal-group: 13;
        -webkit-order: 12;
            -ms-flex-order: 12;
                order: 12;
    }
    .rui-timeline.rui-timeline-left-xl .rui-timeline-item-swap .rui-timeline-date {
        text-align: left;
    }
    .rui-timeline.rui-timeline-right-xl .rui-timeline-item + .rui-timeline-item {
        margin-top: 20px;
    }
    .rui-timeline.rui-timeline-right-xl .rui-timeline-content {
        -webkit-box-ordinal-group: 13;
        -webkit-order: 12;
            -ms-flex-order: 12;
                order: 12;
    }
    .rui-timeline.rui-timeline-right-xl .rui-timeline-date {
        -webkit-box-ordinal-group: 2;
        -webkit-order: 1;
            -ms-flex-order: 1;
                order: 1;
        text-align: right;
    }
    .rui-timeline.rui-timeline-right-xl .rui-timeline-item-swap .rui-timeline-date {
        text-align: right;
    }
}

@media (max-width: 991.98px) {
    .rui-timeline .rui-timeline-item + .rui-timeline-item {
        margin-top: 20px;
    }
    .rui-timeline .rui-timeline-line {
        right: 95px;
        left: 95px;
        width: auto;
        background-color: transparent;
        border-right: 1px solid #e6ecf0;
        border-left: 1px solid #e6ecf0;
    }
    .rui-timeline .rui-timeline-icon {
        right: 70px;
        left: auto;
        margin-left: 0;
    }
    .rui-timeline .rui-timeline-date {
        width: 40px;
        min-width: 40px;
        margin-left: 110px;
    }
    .rui-timeline .rui-timeline-content {
        width: 100%;
        background-color: #fff;
    }
    .rui-timeline .rui-timeline-item-swap .rui-timeline-date {
        margin-right: 110px;
        margin-left: 0;
        text-align: left;
    }
    .rui-timeline .rui-timeline-item-swap .rui-timeline-icon {
        right: auto;
        left: 70px;
    }
    .rui-timeline.rui-timeline-left .rui-timeline-line, .rui-timeline.rui-timeline-left-xl .rui-timeline-line, .rui-timeline.rui-timeline-left-lg .rui-timeline-line {
        display: block;
        right: 95px;
        left: auto;
        width: 1px;
        background-color: #f3f4f7;
        border: 0;
    }
    .rui-timeline.rui-timeline-left .rui-timeline-content, .rui-timeline.rui-timeline-left-xl .rui-timeline-content, .rui-timeline.rui-timeline-left-lg .rui-timeline-content {
        -webkit-box-ordinal-group: 2;
        -webkit-order: 1;
            -ms-flex-order: 1;
                order: 1;
    }
    .rui-timeline.rui-timeline-left .rui-timeline-date, .rui-timeline.rui-timeline-left-xl .rui-timeline-date, .rui-timeline.rui-timeline-left-lg .rui-timeline-date {
        -webkit-box-ordinal-group: 13;
        -webkit-order: 12;
            -ms-flex-order: 12;
                order: 12;
        margin-right: 0;
        margin-left: 110px;
    }
    .rui-timeline.rui-timeline-left .rui-timeline-icon, .rui-timeline.rui-timeline-left-xl .rui-timeline-icon, .rui-timeline.rui-timeline-left-lg .rui-timeline-icon {
        right: 70px;
        left: auto;
    }
    .rui-timeline.rui-timeline-right .rui-timeline-content, .rui-timeline.rui-timeline-right-xl .rui-timeline-content, .rui-timeline.rui-timeline-right-lg .rui-timeline-content {
        -webkit-box-ordinal-group: 13;
        -webkit-order: 12;
            -ms-flex-order: 12;
                order: 12;
    }
    .rui-timeline.rui-timeline-right .rui-timeline-date, .rui-timeline.rui-timeline-right-xl .rui-timeline-date, .rui-timeline.rui-timeline-right-lg .rui-timeline-date {
        -webkit-box-ordinal-group: 2;
        -webkit-order: 1;
            -ms-flex-order: 1;
                order: 1;
        margin-right: 110px;
        margin-left: 0;
        text-align: left;
    }
    .rui-timeline.rui-timeline-right .rui-timeline-icon, .rui-timeline.rui-timeline-right-xl .rui-timeline-icon, .rui-timeline.rui-timeline-right-lg .rui-timeline-icon {
        right: auto;
        left: 70px;
    }
}

@media (max-width: 767.98px) {
    .rui-timeline .rui-timeline-line {
        display: none;
    }
    .rui-timeline .rui-timeline-date {
        margin-left: 55px;
    }
    .rui-timeline .rui-timeline-item-swap .rui-timeline-date {
        margin-right: 55px;
        margin-left: 0;
    }
    .rui-timeline.rui-timeline-left .rui-timeline-line, .rui-timeline.rui-timeline-left-xl .rui-timeline-line, .rui-timeline.rui-timeline-left-lg .rui-timeline-line, .rui-timeline.rui-timeline-left-md .rui-timeline-line {
        display: none;
    }
    .rui-timeline.rui-timeline-left .rui-timeline-icon, .rui-timeline.rui-timeline-left-xl .rui-timeline-icon, .rui-timeline.rui-timeline-left-lg .rui-timeline-icon, .rui-timeline.rui-timeline-left-md .rui-timeline-icon {
        right: 70px;
        left: auto;
    }
    .rui-timeline.rui-timeline-left .rui-timeline-content, .rui-timeline.rui-timeline-left-xl .rui-timeline-content, .rui-timeline.rui-timeline-left-lg .rui-timeline-content, .rui-timeline.rui-timeline-left-md .rui-timeline-content {
        -webkit-box-ordinal-group: 2;
        -webkit-order: 1;
            -ms-flex-order: 1;
                order: 1;
    }
    .rui-timeline.rui-timeline-left .rui-timeline-date, .rui-timeline.rui-timeline-left-xl .rui-timeline-date, .rui-timeline.rui-timeline-left-lg .rui-timeline-date, .rui-timeline.rui-timeline-left-md .rui-timeline-date {
        -webkit-box-ordinal-group: 13;
        -webkit-order: 12;
            -ms-flex-order: 12;
                order: 12;
        margin-right: auto;
        margin-left: 55px;
    }
    .rui-timeline.rui-timeline-left .rui-timeline-item-swap .rui-timeline-content, .rui-timeline.rui-timeline-left-xl .rui-timeline-item-swap .rui-timeline-content, .rui-timeline.rui-timeline-left-lg .rui-timeline-item-swap .rui-timeline-content, .rui-timeline.rui-timeline-left-md .rui-timeline-item-swap .rui-timeline-content {
        -webkit-box-ordinal-group: 2;
        -webkit-order: 1;
            -ms-flex-order: 1;
                order: 1;
    }
    .rui-timeline.rui-timeline-left .rui-timeline-item-swap .rui-timeline-date, .rui-timeline.rui-timeline-left-xl .rui-timeline-item-swap .rui-timeline-date, .rui-timeline.rui-timeline-left-lg .rui-timeline-item-swap .rui-timeline-date, .rui-timeline.rui-timeline-left-md .rui-timeline-item-swap .rui-timeline-date {
        -webkit-box-ordinal-group: 13;
        -webkit-order: 12;
            -ms-flex-order: 12;
                order: 12;
    }
    .rui-timeline.rui-timeline-right .rui-timeline-content, .rui-timeline.rui-timeline-right-xl .rui-timeline-content, .rui-timeline.rui-timeline-right-lg .rui-timeline-content, .rui-timeline.rui-timeline-right-md .rui-timeline-content {
        -webkit-box-ordinal-group: 13;
        -webkit-order: 12;
            -ms-flex-order: 12;
                order: 12;
    }
    .rui-timeline.rui-timeline-right .rui-timeline-date, .rui-timeline.rui-timeline-right-xl .rui-timeline-date, .rui-timeline.rui-timeline-right-lg .rui-timeline-date, .rui-timeline.rui-timeline-right-md .rui-timeline-date {
        -webkit-box-ordinal-group: 2;
        -webkit-order: 1;
            -ms-flex-order: 1;
                order: 1;
        margin-right: 55px;
        margin-left: 0;
        text-align: left;
    }
    .rui-timeline.rui-timeline-right .rui-timeline-icon, .rui-timeline.rui-timeline-right-xl .rui-timeline-icon, .rui-timeline.rui-timeline-right-lg .rui-timeline-icon, .rui-timeline.rui-timeline-right-md .rui-timeline-icon {
        right: auto;
        left: 70px;
    }
}

@media (max-width: 575.98px) {
    .rui-timeline .rui-timeline-item {
        -webkit-box-orient: vertical;
        -webkit-box-direction: normal;
        -webkit-flex-direction: column;
            -ms-flex-direction: column;
                flex-direction: column;
        -webkit-flex-wrap: wrap;
            -ms-flex-wrap: wrap;
                flex-wrap: wrap;
    }
    .rui-timeline .rui-timeline-item + .rui-timeline-item {
        margin-top: 30px;
    }
    .rui-timeline .rui-timeline-item:hover .rui-timeline-icon, .rui-timeline .rui-timeline-item.hover .rui-timeline-icon {
        background-color: transparent;
    }
    .rui-timeline .rui-timeline-icon {
        top: 0;
        right: auto;
        left: 0;
        width: auto;
        height: auto;
        border: 0;
    }
    .rui-timeline .rui-timeline-date {
        -webkit-box-ordinal-group: 2;
        -webkit-order: 1;
            -ms-flex-order: 1;
                order: 1;
        width: auto;
        min-width: none;
        padding-top: 0;
        margin: -4px 0 10px 30px;
    }
    .rui-timeline .rui-timeline-content {
        -webkit-box-ordinal-group: 13;
        -webkit-order: 12;
            -ms-flex-order: 12;
                order: 12;
    }
    .rui-timeline .rui-timeline-item-swap .rui-timeline-date {
        margin: -4px 0 10px 30px;
    }
    .rui-timeline .rui-timeline-item-swap .rui-timeline-icon {
        left: 0;
    }
    .rui-timeline .rui-timeline-item-swap > .rui-timeline-date {
        -webkit-box-ordinal-group: 2;
        -webkit-order: 1;
            -ms-flex-order: 1;
                order: 1;
    }
    .rui-timeline .rui-timeline-item-swap > .rui-timeline-content {
        -webkit-box-ordinal-group: 13;
        -webkit-order: 12;
            -ms-flex-order: 12;
                order: 12;
    }
    .rui-timeline.rui-timeline-left .rui-timeline-item + .rui-timeline-item, .rui-timeline.rui-timeline-left-xl .rui-timeline-item + .rui-timeline-item, .rui-timeline.rui-timeline-left-lg .rui-timeline-item + .rui-timeline-item, .rui-timeline.rui-timeline-left-md .rui-timeline-item + .rui-timeline-item {
        margin-top: 30px;
    }
    .rui-timeline.rui-timeline-left .rui-timeline-icon, .rui-timeline.rui-timeline-left-xl .rui-timeline-icon, .rui-timeline.rui-timeline-left-lg .rui-timeline-icon, .rui-timeline.rui-timeline-left-md .rui-timeline-icon {
        right: auto;
        left: 0;
    }
    .rui-timeline.rui-timeline-left .rui-timeline-content, .rui-timeline.rui-timeline-left-xl .rui-timeline-content, .rui-timeline.rui-timeline-left-lg .rui-timeline-content, .rui-timeline.rui-timeline-left-md .rui-timeline-content {
        -webkit-box-ordinal-group: 13;
        -webkit-order: 12;
            -ms-flex-order: 12;
                order: 12;
    }
    .rui-timeline.rui-timeline-left .rui-timeline-date, .rui-timeline.rui-timeline-left-xl .rui-timeline-date, .rui-timeline.rui-timeline-left-lg .rui-timeline-date, .rui-timeline.rui-timeline-left-md .rui-timeline-date {
        -webkit-box-ordinal-group: 2;
        -webkit-order: 1;
            -ms-flex-order: 1;
                order: 1;
        margin-right: auto;
        margin-left: 30px;
    }
    .rui-timeline.rui-timeline-left .rui-timeline-item-swap .rui-timeline-content, .rui-timeline.rui-timeline-left-xl .rui-timeline-item-swap .rui-timeline-content, .rui-timeline.rui-timeline-left-lg .rui-timeline-item-swap .rui-timeline-content, .rui-timeline.rui-timeline-left-md .rui-timeline-item-swap .rui-timeline-content {
        -webkit-box-ordinal-group: 13;
        -webkit-order: 12;
            -ms-flex-order: 12;
                order: 12;
    }
    .rui-timeline.rui-timeline-left .rui-timeline-item-swap .rui-timeline-date, .rui-timeline.rui-timeline-left-xl .rui-timeline-item-swap .rui-timeline-date, .rui-timeline.rui-timeline-left-lg .rui-timeline-item-swap .rui-timeline-date, .rui-timeline.rui-timeline-left-md .rui-timeline-item-swap .rui-timeline-date {
        -webkit-box-ordinal-group: 2;
        -webkit-order: 1;
            -ms-flex-order: 1;
                order: 1;
    }
    .rui-timeline.rui-timeline-right .rui-timeline-item + .rui-timeline-item, .rui-timeline.rui-timeline-right-xl .rui-timeline-item + .rui-timeline-item, .rui-timeline.rui-timeline-right-lg .rui-timeline-item + .rui-timeline-item, .rui-timeline.rui-timeline-right-md .rui-timeline-item + .rui-timeline-item {
        margin-top: 30px;
    }
    .rui-timeline.rui-timeline-right .rui-timeline-date, .rui-timeline.rui-timeline-right-xl .rui-timeline-date, .rui-timeline.rui-timeline-right-lg .rui-timeline-date, .rui-timeline.rui-timeline-right-md .rui-timeline-date {
        margin-right: auto;
        margin-left: 30px;
    }
    .rui-timeline.rui-timeline-right .rui-timeline-icon, .rui-timeline.rui-timeline-right-xl .rui-timeline-icon, .rui-timeline.rui-timeline-right-lg .rui-timeline-icon, .rui-timeline.rui-timeline-right-md .rui-timeline-icon {
        right: auto;
        left: 0;
    }
}

/*---------------------------
  Element Changelog
 ----------------------------*/
.rui-changelog > ul {
    margin-bottom: 20px;
}

.rui-changelog > ul:last-of-type {
    margin-bottom: 0;
}

.rui-changelog > ul > li {
    padding: 4px 0;
}

.rui-changelog > ul > li:first-child {
    padding-top: 0;
}

.rui-changelog > ul > li:last-child {
    padding-bottom: 0;
}

.rui-changelog .rui-changelog-title {
    margin-bottom: 0;
}

.rui-changelog .rui-changelog-subtitle {
    margin-top: 5px;
    margin-bottom: 20px;
    color: #a4a6a8;
}

.rui-changelog .rui-changelog-subtitle a {
    color: #6c757d;
}

.rui-changelog .rui-changelog-subtitle a:hover, .rui-changelog .rui-changelog-subtitle a.hover {
    color: #393f49;
    text-decoration: none;
}

.rui-changelog .rui-changelog-item {
    position: relative;
    padding-left: 12px;
    color: #4b515b;
}

.rui-changelog .rui-changelog-item::before {
    content: "";
    position: absolute;
    display: inline-block;
    top: 2px;
    left: 0;
    width: 2px;
    height: 16px;
    background-color: #d7d9e0;
}

.rui-changelog .rui-changelog-item .rui-changelog-item-type {
    margin-right: 4px;
    color: #4b515b;
}

.rui-changelog .rui-changelog-brand::before {
    background-color: #725ec3;
}

.rui-changelog .rui-changelog-primary::before {
    background-color: #007bff;
}

.rui-changelog .rui-changelog-secondary::before {
    background-color: #6c757d;
}

.rui-changelog .rui-changelog-success::before {
    background-color: #2fc787;
}

.rui-changelog .rui-changelog-danger::before {
    background-color: #ef5164;
}

.rui-changelog .rui-changelog-warning::before {
    background-color: #fdbf21;
}

.rui-changelog .rui-changelog-info::before {
    background-color: #32bfc8;
}

.rui-changelog .rui-changelog-light::before {
    background-color: #f8f9fa;
}

.rui-changelog .rui-changelog-dark::before {
    background-color: #393f49;
}

.rui-changelog .rui-changelog-file {
    display: -webkit-box;
    display: -webkit-flex;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-align: center;
    -webkit-align-items: center;
        -ms-flex-align: center;
            align-items: center;
    color: #6c757d;
}

.rui-changelog .rui-changelog-file > .rui-icon {
    margin-right: 10px;
}

.rui-changelog .rui-changelog-file:hover, .rui-changelog .rui-changelog-file.hover {
    color: #393f49;
    text-decoration: none;
}

/*---------------------------
  Element Kanban
 ----------------------------*/
.rui-kanban {
    padding-bottom: 10px;
}

.rui-kanban-col {
    display: -webkit-box;
    display: -webkit-flex;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-orient: vertical;
    -webkit-box-direction: normal;
    -webkit-flex-direction: column;
        -ms-flex-direction: column;
            flex-direction: column;
    min-width: 360px;
    height: 100%;
    max-height: calc(100vh - 200px);
    padding: 30px 26px 30px 30px;
    overflow-y: scroll;
    background-color: #f8f9fa;
    border-radius: .25rem;
}

.rui-kanban-col::-webkit-scrollbar-track {
    background-color: #f8f9fa;
    border-radius: 4px;
}

.rui-kanban-col::-webkit-scrollbar {
    width: 4px;
    background-color: #f8f9fa;
}

.rui-kanban-col::-webkit-scrollbar-thumb {
    background-color: #eaecf0;
    border-radius: 4px;
}

.rui-kanban-col .rui-sortable {
    height: 100%;
    margin-bottom: 0;
}

.rui-kanban-item {
    cursor: pointer;
    border: 1px solid #f3f4f7;
    border-radius: .25rem;
    -webkit-transition: background-color .15s ease-in-out, border-color .15s ease-in-out;
    -o-transition: background-color .15s ease-in-out, border-color .15s ease-in-out;
    transition: background-color .15s ease-in-out, border-color .15s ease-in-out;
}

.rui-kanban-item + .rui-kanban-item {
    margin-top: 10px;
}

.rui-kanban-item > .rui-task {
    padding-right: 20px;
    padding-left: 20px;
    background-color: #fff;
    border-radius: .25rem;
}

.rui-kanban-item:hover, .rui-kanban-item.hover {
    background-color: #fff;
    border-color: #eaecf0;
}

.rui-kanban-item:hover > .rui-task, .rui-kanban-item.hover > .rui-task {
    background-color: #fff;
}

.rui-kanban-item.sortable-chosen {
    background-color: #fff;
    border-color: #d7d9e0;
}

/*---------------------------
  Element Task
 ----------------------------*/
.rui-task {
    position: relative;
    display: -webkit-box;
    display: -webkit-flex;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-align: start;
    -webkit-align-items: flex-start;
        -ms-flex-align: start;
            align-items: flex-start;
    padding: 17px 30px 16px;
    -webkit-transition: background-color .15s ease-in-out;
    -o-transition: background-color .15s ease-in-out;
    transition: background-color .15s ease-in-out;
}

.rui-task:hover, .rui-task.hover {
    background-color: #f8f9fa;
}

.rui-task .rui-task-title {
    display: block;
    margin-top: -4px;
    color: #6c757d;
}

.rui-task .rui-task-title:hover, .rui-task .rui-task-title.hover {
    color: #393f49;
    text-decoration: none;
}

.rui-task .rui-task-subtitle {
    display: block;
    margin-top: 5px;
    color: #a4a6a8;
}

.rui-task .rui-task-subtitle > a {
    color: #6c757d;
}

.rui-task .rui-task-subtitle > a:hover, .rui-task .rui-task-subtitle > a.hover {
    color: #393f49;
    text-decoration: none;
}

.rui-task .rui-task-content {
    display: -webkit-box;
    display: -webkit-flex;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-flex: 0;
    -webkit-flex: 0 0 100%;
        -ms-flex: 0 0 100%;
            flex: 0 0 100%;
    -webkit-flex-basis: 0;
        -ms-flex-preferred-size: 0;
            flex-basis: 0;
    -webkit-box-orient: vertical;
    -webkit-box-direction: normal;
    -webkit-flex-direction: column;
        -ms-flex-direction: column;
            flex-direction: column;
    -webkit-box-flex: 1;
    -webkit-flex-grow: 1;
        -ms-flex-positive: 1;
            flex-grow: 1;
    max-width: 100%;
    margin-right: 10px;
}

.rui-task .rui-task-icon {
    display: -webkit-box;
    display: -webkit-flex;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-align: center;
    -webkit-align-items: center;
        -ms-flex-align: center;
            align-items: center;
    color: #bcbec0;
}

.rui-task .rui-task-icon:first-child {
    margin-right: 10px;
}

.rui-task .rui-task-icon:not(:first-child) > .rui-icon {
    margin-right: 5px;
}

.rui-task .rui-task-icon:not([href]):hover, .rui-task .rui-task-icon:not([href]).hover {
    color: #bcbec0;
}

.rui-task .rui-task-icon:hover, .rui-task .rui-task-icon.hover {
    color: #393f49;
    text-decoration: none;
}

.rui-task.rui-task-brand > .rui-task-icon:first-child {
    color: #725ec3;
}

.rui-task.rui-task-primary > .rui-task-icon:first-child {
    color: #007bff;
}

.rui-task.rui-task-secondary > .rui-task-icon:first-child {
    color: #6c757d;
}

.rui-task.rui-task-success > .rui-task-icon:first-child {
    color: #2fc787;
}

.rui-task.rui-task-danger > .rui-task-icon:first-child {
    color: #ef5164;
}

.rui-task.rui-task-warning > .rui-task-icon:first-child {
    color: #fdbf21;
}

.rui-task.rui-task-info > .rui-task-icon:first-child {
    color: #32bfc8;
}

.rui-task.rui-task-light > .rui-task-icon:first-child {
    color: #f8f9fa;
}

.rui-task.rui-task-dark > .rui-task-icon:first-child {
    color: #393f49;
}

.rui-task-status {
    margin-bottom: 30px;
    color: #a4a6a8;
}

.rui-task-status a {
    color: #6c757d;
}

.rui-task-status a:hover, .rui-task-status a.hover {
    color: #393f49;
    text-decoration: none;
}

.rui-task-status.rui-task-status-brand .rui-task-status-item-icon {
    color: #fff;
    background-color: #725ec3;
}

.rui-task-status.rui-task-status-primary .rui-task-status-item-icon {
    color: #fff;
    background-color: #007bff;
}

.rui-task-status.rui-task-status-secondary .rui-task-status-item-icon {
    color: #fff;
    background-color: #6c757d;
}

.rui-task-status.rui-task-status-success .rui-task-status-item-icon {
    color: #fff;
    background-color: #2fc787;
}

.rui-task-status.rui-task-status-danger .rui-task-status-item-icon {
    color: #fff;
    background-color: #ef5164;
}

.rui-task-status.rui-task-status-warning .rui-task-status-item-icon {
    color: #fff;
    background-color: #fdbf21;
}

.rui-task-status.rui-task-status-info .rui-task-status-item-icon {
    color: #fff;
    background-color: #32bfc8;
}

.rui-task-status.rui-task-status-light .rui-task-status-item-icon {
    color: #fff;
    background-color: #f8f9fa;
}

.rui-task-status.rui-task-status-dark .rui-task-status-item-icon {
    color: #fff;
    background-color: #393f49;
}

.rui-task-status .rui-task-status-item {
    display: -webkit-box;
    display: -webkit-flex;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-align: center;
    -webkit-align-items: center;
        -ms-flex-align: center;
            align-items: center;
}

.rui-task-status .rui-task-status-item-icon {
    display: -webkit-inline-box;
    display: -webkit-inline-flex;
    display: -ms-inline-flexbox;
    display: inline-flex;
    -webkit-box-align: center;
    -webkit-align-items: center;
        -ms-flex-align: center;
            align-items: center;
    -webkit-box-pack: center;
    -webkit-justify-content: center;
        -ms-flex-pack: center;
            justify-content: center;
    width: 32px;
    height: 32px;
    margin-right: 15px;
    color: #6c757d;
    background-color: #f8f9fa;
    border-radius: 32px;
}

.rui-task-comment {
    padding: 30px;
    border: 1px solid #f3f4f7;
    border-radius: .25rem;
}

.rui-task-comment + .rui-task-comment,
.rui-task-comment + .rui-task-send {
    margin-top: 30px;
}

.rui-task-comment .rui-task-comment-text {
    margin-top: 30px;
}

.rui-task-comment .rui-task-comment-text p:last-child {
    margin-bottom: -5px;
}

.rui-task-comment .rui-task-comment-text .rui-snippet,
.rui-task-comment .rui-task-comment-text .rui-snippet-single {
    margin-bottom: 1.8rem;
}

.rui-task-sidebar a {
    color: #6c757d;
}

.rui-task-sidebar a:hover, .rui-task-sidebar a.hover {
    color: #393f49;
    text-decoration: none;
}

.rui-task-sidebar .rui-task-sidebar-title {
    margin-bottom: 1rem;
}

.rui-task-sidebar .rui-task-sidebar-list {
    margin-right: -30px;
    margin-left: -30px;
}

.rui-task-sidebar .rui-task-sidebar-list > li {
    padding: 30px 30px;
    color: #a4a6a8;
}

.rui-task-sidebar .rui-task-sidebar-list > li:first-child {
    padding-top: 0;
}

.rui-task-sidebar .rui-task-sidebar-list > li:last-child {
    padding-bottom: 0;
}

.rui-task-sidebar .rui-task-info-list > li {
    padding: 4px 0;
}

.rui-task-sidebar .rui-task-info-list > li:first-child {
    padding-top: 0;
}

.rui-task-sidebar .rui-task-info-list > li:last-child {
    padding-bottom: 0;
}

.rui-task-sidebar .rui-task-info-item {
    display: -webkit-box;
    display: -webkit-flex;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-align: center;
    -webkit-align-items: center;
        -ms-flex-align: center;
            align-items: center;
}

.rui-task-sidebar .rui-task-info-item > .rui-icon {
    margin-right: 5px;
}

.rui-task-sidebar .rui-task-project-list > li {
    padding: 7px 0;
}

.rui-task-sidebar .rui-task-project-list > li:first-child {
    padding-top: 0;
}

.rui-task-sidebar .rui-task-project-list > li:last-child {
    padding-bottom: 0;
}

/*---------------------------
  Element Icons
 ----------------------------*/
.rui-icon {
    width: 16px;
    min-width: 16px;
    height: 16px;
}

.rui-icon.rui-icon-stroke-1_5 {
    stroke-width: 1.5px;
}

.rui-icon.rui-icon-stroke-1 {
    stroke-width: 1px;
}

/*---------------------------
  Element Content Boxes
 ----------------------------*/
.rui-box,
.rui-box-1,
.rui-box-2,
.rui-box-3,
.rui-box-4,
.rui-box-5 {
    position: relative;
    display: block;
    z-index: 1;
}

.rui-box-1 {
    padding: 30px 15px;
}

/*---------------------------
  Element Image Boxes
 ----------------------------*/
.rui-gallery .rui-gallery-item {
    position: relative;
    display: block;
    height: 100%;
    overflow: hidden;
    border-radius: .25rem;
}

.rui-gallery .rui-gallery-item:focus {
    outline: none;
}

.rui-gallery .rui-gallery-item .rui-gallery-item-overlay {
    position: absolute;
    display: -webkit-box;
    display: -webkit-flex;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-align: center;
    -webkit-align-items: center;
        -ms-flex-align: center;
            align-items: center;
    -webkit-box-pack: center;
    -webkit-justify-content: center;
        -ms-flex-pack: center;
            justify-content: center;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    font-size: 5rem;
    line-height: 0;
    color: #4b515b;
    background-color: rgba(248, 249, 250, 0.85);
    opacity: 0;
    will-change: opacity;
    -webkit-transition: opacity .15s ease-in-out;
    -o-transition: opacity .15s ease-in-out;
    transition: opacity .15s ease-in-out;
    z-index: 1;
}

.rui-gallery .rui-gallery-item .rui-gallery-item-overlay > .rui-icon {
    width: 26px;
    height: 26px;
    opacity: 0;
    will-change: opacity;
    -webkit-transition: opacity .15s ease-in-out;
    -o-transition: opacity .15s ease-in-out;
    transition: opacity .15s ease-in-out;
    -webkit-transition-delay: .1s;
         -o-transition-delay: .1s;
            transition-delay: .1s;
}

.rui-gallery .rui-gallery-item > img {
    width: 100%;
    height: 100%;
    font-family: "object-fit: cover";
    -o-object-fit: cover;
       object-fit: cover;
    will-change: transform;
    -webkit-transition: -webkit-transform .4s ease-in-out;
    transition: -webkit-transform .4s ease-in-out;
    -o-transition: transform .4s ease-in-out;
    transition: transform .4s ease-in-out;
    transition: transform .4s ease-in-out, -webkit-transform .4s ease-in-out;
    -webkit-transform: scale(1);
        -ms-transform: scale(1);
            transform: scale(1);
}

.rui-gallery .rui-gallery-item:hover .rui-gallery-item-overlay {
    opacity: 1;
}

.rui-gallery .rui-gallery-item:hover .rui-gallery-item-overlay > .rui-icon {
    opacity: 1;
}

/*---------------------------
  Element Widget
 ----------------------------*/
.rui-widget {
    position: relative;
    padding: 0 30px 20px;
    border: 1px solid #e6ecf0;
    border-radius: .25rem;
}

.rui-widget .rui-widget-head {
    display: -webkit-box;
    display: -webkit-flex;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-align: center;
    -webkit-align-items: center;
        -ms-flex-align: center;
            align-items: center;
    padding: 20px 30px 20px;
    margin: 0 -30px 20px;
    background-color: #f8f9fa;
    border-bottom: 1px solid #e6ecf0;
}

.rui-widget .rui-widget-head .rui-widget-icon {
    margin-top: -14px;
    margin-bottom: -14px;
    margin-left: auto;
}

.rui-widget .rui-widget-head .rui-widget-title {
    margin-bottom: -8px;
}

.rui-widget .rui-widget-content {
    display: -webkit-box;
    display: -webkit-flex;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-orient: vertical;
    -webkit-box-direction: normal;
    -webkit-flex-direction: column;
        -ms-flex-direction: column;
            flex-direction: column;
    -webkit-box-align: stretch;
    -webkit-align-items: stretch;
        -ms-flex-align: stretch;
            align-items: stretch;
    -webkit-box-pack: center;
    -webkit-justify-content: center;
        -ms-flex-pack: center;
            justify-content: center;
}

.rui-widget .rui-widget-content:not(:last-child) {
    padding-bottom: 25px;
}

.rui-widget .rui-widget-content > .rui-icon {
    margin-right: 10px;
    stroke: #a4a6a8;
}

.rui-widget .rui-widget-list {
    margin-right: -30px;
    margin-bottom: -20px;
    margin-left: -30px;
}

.rui-widget .rui-widget-list li {
    padding: 0;
}

.rui-widget .rui-widget-list .media-filled .media-link {
    padding-top: 20.5px;
    padding-bottom: 20.5px;
}

.rui-widget .rui-widget-head ~ .rui-widget-content .rui-widget-list {
    margin-top: -20px;
}

.rui-widget .rui-widget-task-list > li {
    padding: 20px 30px;
}

.rui-widget .rui-widget-task-list > li > .rui-task {
    padding: 22px 30px 21px;
    margin: -20px -30px;
}

.rui-widget .rui-widget-country-list {
    max-height: 389px;
    overflow: auto;
}

.rui-widget.rui-widget-chart {
    display: -webkit-box;
    display: -webkit-flex;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-align: center;
    -webkit-align-items: center;
        -ms-flex-align: center;
            align-items: center;
    -webkit-box-pack: center;
    -webkit-justify-content: center;
        -ms-flex-pack: center;
            justify-content: center;
    height: 100%;
    min-height: 200px;
    padding: 30px;
}

.rui-widget.rui-widget-chart .rui-widget-chart-info {
    position: absolute;
    display: -webkit-box;
    display: -webkit-flex;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-orient: vertical;
    -webkit-box-direction: normal;
    -webkit-flex-direction: column;
        -ms-flex-direction: column;
            flex-direction: column;
    -webkit-box-align: center;
    -webkit-align-items: center;
        -ms-flex-align: center;
            align-items: center;
    -webkit-box-pack: center;
    -webkit-justify-content: center;
        -ms-flex-pack: center;
            justify-content: center;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
}

.rui-widget.rui-widget-chart .rui-widget-title {
    margin: 0 0 4px;
}

.rui-widget.rui-widget-chart .rui-widget-subtitle {
    margin: 0;
    color: #a4a6a8;
}

.rui-widget.rui-widget-total {
    display: -webkit-box;
    display: -webkit-flex;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-orient: vertical;
    -webkit-box-direction: normal;
    -webkit-flex-direction: column;
        -ms-flex-direction: column;
            flex-direction: column;
    -webkit-box-align: stretch;
    -webkit-align-items: stretch;
        -ms-flex-align: stretch;
            align-items: stretch;
    min-height: 240px;
}

.rui-widget.rui-widget-total .rui-widget-chart-info {
    position: static;
    height: 100%;
}

.rui-widget.rui-widget-total .rui-widget-total-chart {
    margin: 0 -30px -30px;
}

.rui-widget.rui-widget-total .rui-widget-total-chart > .rui-chartjs:not([height]) {
    height: 80px;
}

.rui-widget.rui-widget-server .rui-widget-content {
    -webkit-box-orient: horizontal;
    -webkit-box-direction: normal;
    -webkit-flex-direction: row;
        -ms-flex-direction: row;
            flex-direction: row;
    -webkit-box-align: center;
    -webkit-align-items: center;
        -ms-flex-align: center;
            align-items: center;
    -webkit-box-pack: start;
    -webkit-justify-content: flex-start;
        -ms-flex-pack: start;
            justify-content: flex-start;
}

.rui-widget.rui-widget-server .rui-widget-server-chart {
    margin: 0 -30px -25px;
}

.rui-widget.rui-widget-server .rui-widget-server-chart > .rui-chartjs:not([height]) {
    height: 80px;
}

/*---------------------------
  Element Social links
 ----------------------------*/
.rui-social-links {
    padding: 0;
    margin: -10px -8px 0;
    font-size: 0;
    list-style-type: none;
}

.rui-social-links::after {
    content: "";
    display: table;
    clear: both;
}

.rui-social-links > li {
    display: inline-block;
    padding: 10px 5px 0;
}

.rui-social-links > li > a,
.rui-social-links > li > button,
.rui-social-links > li > span,
.rui-social-links > li > div {
    display: -webkit-inline-box;
    display: -webkit-inline-flex;
    display: -ms-inline-flexbox;
    display: inline-flex;
    -webkit-box-align: center;
    -webkit-align-items: center;
        -ms-flex-align: center;
            align-items: center;
    -webkit-box-pack: center;
    -webkit-justify-content: center;
        -ms-flex-pack: center;
            justify-content: center;
    min-height: 36px;
    padding: 9.5px 15px;
    font-size: 11px;
    font-weight: 700;
    text-transform: uppercase;
    cursor: pointer;
    will-change: opacity;
    border: none;
    border-radius: .25rem;
    -webkit-transition: background-color .15s ease-in-out;
    -o-transition: background-color .15s ease-in-out;
    transition: background-color .15s ease-in-out;
}

.rui-social-links > li > a > svg,
.rui-social-links > li > button > svg,
.rui-social-links > li > span > svg,
.rui-social-links > li > div > svg {
    margin-right: 10px;
    font-size: 1rem;
}

.rui-social-links > li > a, .rui-social-links > li > a:focus, .rui-social-links > li > a:active,
.rui-social-links > li > button,
.rui-social-links > li > button:focus,
.rui-social-links > li > button:active,
.rui-social-links > li > span,
.rui-social-links > li > span:focus,
.rui-social-links > li > span:active,
.rui-social-links > li > div,
.rui-social-links > li > div:focus,
.rui-social-links > li > div:active {
    color: #fff;
}

.rui-social-links > li > a:hover, .rui-social-links > li > a.hover,
.rui-social-links > li > button:hover,
.rui-social-links > li > button.hover,
.rui-social-links > li > span:hover,
.rui-social-links > li > span.hover,
.rui-social-links > li > div:hover,
.rui-social-links > li > div.hover {
    text-decoration: none;
}

.rui-social-links > li > .rui-social-behance {
    color: #fff;
    background-color: #487cfb;
}

.rui-social-links > li > .rui-social-behance:hover, .rui-social-links > li > .rui-social-behance.hover {
    color: #fff;
    background-color: #2f6afa;
}

.rui-social-links > li > .rui-social-bitbucket {
    color: #fff;
    background-color: #36517e;
}

.rui-social-links > li > .rui-social-bitbucket:hover, .rui-social-links > li > .rui-social-bitbucket.hover {
    color: #fff;
    background-color: #2e466c;
}

.rui-social-links > li > .rui-social-dropbox {
    color: #fff;
    background-color: #4d86d9;
}

.rui-social-links > li > .rui-social-dropbox:hover, .rui-social-links > li > .rui-social-dropbox.hover {
    color: #fff;
    background-color: #3878d5;
}

.rui-social-links > li > .rui-social-dribbble {
    color: #fff;
    background-color: #cc4d86;
}

.rui-social-links > li > .rui-social-dribbble:hover, .rui-social-links > li > .rui-social-dribbble.hover {
    color: #fff;
    background-color: #c63979;
}

.rui-social-links > li > .rui-social-deviantart {
    color: #fff;
    background-color: #bac940;
}

.rui-social-links > li > .rui-social-deviantart:hover, .rui-social-links > li > .rui-social-deviantart.hover {
    color: #fff;
    background-color: #acbb35;
}

.rui-social-links > li > .rui-social-envato {
    color: #fff;
    background-color: #91b34c;
}

.rui-social-links > li > .rui-social-envato:hover, .rui-social-links > li > .rui-social-envato.hover {
    color: #fff;
    background-color: #83a144;
}

.rui-social-links > li > .rui-social-facebook {
    color: #fff;
    background-color: #475995;
}

.rui-social-links > li > .rui-social-facebook:hover, .rui-social-links > li > .rui-social-facebook.hover {
    color: #fff;
    background-color: #3f4f84;
}

.rui-social-links > li > .rui-social-flickr {
    color: #fff;
    background-color: #db0180;
}

.rui-social-links > li > .rui-social-flickr:hover, .rui-social-links > li > .rui-social-flickr.hover {
    color: #fff;
    background-color: #c20171;
}

.rui-social-links > li > .rui-social-foursquare {
    color: #fff;
    background-color: #d94a75;
}

.rui-social-links > li > .rui-social-foursquare:hover, .rui-social-links > li > .rui-social-foursquare.hover {
    color: #fff;
    background-color: #d53565;
}

.rui-social-links > li > .rui-social-github {
    color: #fff;
    background-color: #464646;
}

.rui-social-links > li > .rui-social-github:hover, .rui-social-links > li > .rui-social-github.hover {
    color: #fff;
    background-color: #393939;
}

.rui-social-links > li > .rui-social-google {
    color: #fff;
    background-color: #4285f4;
}

.rui-social-links > li > .rui-social-google:hover, .rui-social-links > li > .rui-social-google.hover {
    color: #fff;
    background-color: #2a75f3;
}

.rui-social-links > li > .rui-social-google-plus {
    color: #fff;
    background-color: #c14d3d;
}

.rui-social-links > li > .rui-social-google-plus:hover, .rui-social-links > li > .rui-social-google-plus.hover {
    color: #fff;
    background-color: #ae4537;
}

.rui-social-links > li > .rui-social-instagram {
    color: #fff;
    background-color: #d12054;
}

.rui-social-links > li > .rui-social-instagram:hover, .rui-social-links > li > .rui-social-instagram.hover {
    color: #fff;
    background-color: #bb1d4b;
}

.rui-social-links > li > .rui-social-linkedin {
    color: #fff;
    background-color: #457ab3;
}

.rui-social-links > li > .rui-social-linkedin:hover, .rui-social-links > li > .rui-social-linkedin.hover {
    color: #fff;
    background-color: #3e6da1;
}

.rui-social-links > li > .rui-social-medium {
    color: #fff;
    background-color: #71c66d;
}

.rui-social-links > li > .rui-social-medium:hover, .rui-social-links > li > .rui-social-medium.hover {
    color: #fff;
    background-color: #5fbf5b;
}

.rui-social-links > li > .rui-social-odnoklassniki {
    color: #fff;
    background-color: #d8732a;
}

.rui-social-links > li > .rui-social-odnoklassniki:hover, .rui-social-links > li > .rui-social-odnoklassniki.hover {
    color: #fff;
    background-color: #c56724;
}

.rui-social-links > li > .rui-social-paypal {
    color: #fff;
    background-color: #1d3283;
}

.rui-social-links > li > .rui-social-paypal:hover, .rui-social-links > li > .rui-social-paypal.hover {
    color: #fff;
    background-color: #182a6e;
}

.rui-social-links > li > .rui-social-pinterest {
    color: #fff;
    background-color: #ae262b;
}

.rui-social-links > li > .rui-social-pinterest:hover, .rui-social-links > li > .rui-social-pinterest.hover {
    color: #fff;
    background-color: #992126;
}

.rui-social-links > li > .rui-social-rss {
    color: #fff;
    background-color: #e06618;
}

.rui-social-links > li > .rui-social-rss:hover, .rui-social-links > li > .rui-social-rss.hover {
    color: #fff;
    background-color: #c95c16;
}

.rui-social-links > li > .rui-social-reddit {
    color: #fff;
    background-color: #de470b;
}

.rui-social-links > li > .rui-social-reddit:hover, .rui-social-links > li > .rui-social-reddit.hover {
    color: #fff;
    background-color: #c63f0a;
}

.rui-social-links > li > .rui-social-skype {
    color: #fff;
    background-color: #62aded;
}

.rui-social-links > li > .rui-social-skype:hover, .rui-social-links > li > .rui-social-skype.hover {
    color: #fff;
    background-color: #4ba1ea;
}

.rui-social-links > li > .rui-social-soundcloud {
    color: #fff;
    background-color: #df5611;
}

.rui-social-links > li > .rui-social-soundcloud:hover, .rui-social-links > li > .rui-social-soundcloud.hover {
    color: #fff;
    background-color: #c74d0f;
}

.rui-social-links > li > .rui-social-slack {
    color: #fff;
    background-color: #543b56;
}

.rui-social-links > li > .rui-social-slack:hover, .rui-social-links > li > .rui-social-slack.hover {
    color: #fff;
    background-color: #453147;
}

.rui-social-links > li > .rui-social-steam {
    color: #fff;
    background-color: #272425;
}

.rui-social-links > li > .rui-social-steam:hover, .rui-social-links > li > .rui-social-steam.hover {
    color: #fff;
    background-color: #1a1818;
}

.rui-social-links > li > .rui-social-tumblr {
    color: #fff;
    background-color: #384961;
}

.rui-social-links > li > .rui-social-tumblr:hover, .rui-social-links > li > .rui-social-tumblr.hover {
    color: #fff;
    background-color: #2f3d51;
}

.rui-social-links > li > .rui-social-twitter {
    color: #fff;
    background-color: #6da9de;
}

.rui-social-links > li > .rui-social-twitter:hover, .rui-social-links > li > .rui-social-twitter.hover {
    color: #fff;
    background-color: #589dd9;
}

.rui-social-links > li > .rui-social-vimeo {
    color: #fff;
    background-color: #69b5e7;
}

.rui-social-links > li > .rui-social-vimeo:hover, .rui-social-links > li > .rui-social-vimeo.hover {
    color: #fff;
    background-color: #53aae3;
}

.rui-social-links > li > .rui-social-vk {
    color: #fff;
    background-color: #657da0;
}

.rui-social-links > li > .rui-social-vk:hover, .rui-social-links > li > .rui-social-vk.hover {
    color: #fff;
    background-color: #5a7192;
}

.rui-social-links > li > .rui-social-wordpress {
    color: #fff;
    background-color: #4072a1;
}

.rui-social-links > li > .rui-social-wordpress:hover, .rui-social-links > li > .rui-social-wordpress.hover {
    color: #fff;
    background-color: #39658f;
}

.rui-social-links > li > .rui-social-youtube {
    color: #fff;
    background-color: #c6271e;
}

.rui-social-links > li > .rui-social-youtube:hover, .rui-social-links > li > .rui-social-youtube.hover {
    color: #fff;
    background-color: #b0231b;
}

/*---------------------------
  Element Box Window
 ----------------------------*/
.rui-box-window {
    display: block;
    border-radius: .3rem;
    -webkit-box-shadow: 0 0 0 transparent;
            box-shadow: 0 0 0 transparent;
}

.rui-box-window .rui-box-window-bar {
    display: -webkit-box;
    display: -webkit-flex;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-align: center;
    -webkit-align-items: center;
        -ms-flex-align: center;
            align-items: center;
    height: 35px;
    padding: 0 23px;
    background-color: #eaecf0;
    border-radius: .3rem .3rem 0 0;
}

.rui-box-window .rui-box-window-bar > span {
    display: block;
    width: 6px;
    height: 6px;
    background-color: #eaecf0;
    border-radius: 100%;
}

.rui-box-window .rui-box-window-bar > span + span {
    margin-left: 4px;
}

.rui-box-window .rui-box-window-image {
    display: block;
    overflow: hidden;
    border: 1px solid #f3f4f7;
    border-radius: .3rem;
    -webkit-transition: -webkit-transform .2s ease-in-out, -webkit-box-shadow .2s ease-in-out;
    transition: -webkit-transform .2s ease-in-out, -webkit-box-shadow .2s ease-in-out;
    -o-transition: transform .2s ease-in-out, box-shadow .2s ease-in-out;
    transition: transform .2s ease-in-out, box-shadow .2s ease-in-out;
    transition: transform .2s ease-in-out, box-shadow .2s ease-in-out, -webkit-transform .2s ease-in-out, -webkit-box-shadow .2s ease-in-out;
    will-change: transform;
    -webkit-transform: translateY(0);
        -ms-transform: translateY(0);
            transform: translateY(0);
}

.rui-box-window .rui-box-window-image > img {
    width: 100%;
    border-radius: .3rem;
}

.rui-box-window-bar + .rui-box-window .rui-box-window-image {
    border-top: 0;
    border-radius: 0 0 .3rem .3rem;
}

.rui-box-window-bar + .rui-box-window .rui-box-window-image > img {
    border-radius: 0 0 .3rem .3rem;
}

.rui-box-window:hover .rui-box-window-image, .rui-box-window.hover .rui-box-window-image {
    -webkit-box-shadow: 0 3px 10px rgba(0, 0, 0, 0.03);
            box-shadow: 0 3px 10px rgba(0, 0, 0, 0.03);
    -webkit-transform: translateY(-3px);
        -ms-transform: translateY(-3px);
            transform: translateY(-3px);
}

/*---------------------------
  Element Feature
 ----------------------------*/
.rui-feature {
    display: -webkit-box;
    display: -webkit-flex;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-align: center;
    -webkit-align-items: center;
        -ms-flex-align: center;
            align-items: center;
    padding: 5px;
    background-color: #fff;
    border: 1px solid #f3f4f7;
    border-radius: .25rem;
    -webkit-transition: border-color .15s ease-in-out;
    -o-transition: border-color .15s ease-in-out;
    transition: border-color .15s ease-in-out;
}

.rui-feature .rui-feature-icon {
    display: -webkit-inline-box;
    display: -webkit-inline-flex;
    display: -ms-inline-flexbox;
    display: inline-flex;
    -webkit-box-flex: 0;
    -webkit-flex: 0 0 auto;
        -ms-flex: 0 0 auto;
            flex: 0 0 auto;
    -webkit-box-align: center;
    -webkit-align-items: center;
        -ms-flex-align: center;
            align-items: center;
    -webkit-box-pack: center;
    -webkit-justify-content: center;
        -ms-flex-pack: center;
            justify-content: center;
    width: 44px;
    height: 44px;
    margin-right: 10px;
    color: #6c757d;
}

.rui-feature .rui-feature-icon > span,
.rui-feature .rui-feature-icon > svg {
    width: 24px;
    min-width: 24px;
    height: 24px;
}

.rui-feature:hover, .rui-feature.hover {
    border-color: #eaecf0;
}

/*------------------------------------------------------------------

  Plugins

 -------------------------------------------------------------------*/
/*---------------------------
    Plugin Yay
 ----------------------------*/
.rui-navbar-show .yaybar {
    margin-top: 70px;
}

@media (max-width: 1199.98px) {
    .rui-navbar-show .yaybar {
        margin-top: 65px;
    }
}

@media (max-width: 1199.98px) {
    .rui-navbar-show:not(.yay-hide) .yaybar {
        margin-top: 0;
    }
}

.yaybar.yay-overlay.yay-hide-to-small ~ .content-wrap {
    margin-left: 70px;
}

.yaybar.yay-shrink ~ .content-wrap {
    will-change: margin-left;
    -webkit-transition: margin-left .15s;
    -o-transition: margin-left .15s;
    transition: margin-left .15s;
}

.yay-hide .rui-yaybar.yaybar.yay-overlay {
    z-index: 1001;
}

.yay-hide .yaybar:hover,
.yay-hide .yaybar.rui-yaybar.yay-overlay:hover {
    z-index: 10;
}

.yay-hide .yaybar.yay-hide-to-small .yaybar-wrap > ul > li:not(:hover) > .yay-submenu {
    display: none !important;
    visibility: hidden;
}

@media (max-width: 575.98px) {
    .yay-hide .yaybar {
        -webkit-transform: translateX(-280px);
            -ms-transform: translateX(-280px);
                transform: translateX(-280px);
    }
}

.yaybar.rui-yaybar {
    display: -webkit-box;
    display: -webkit-flex;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-orient: vertical;
    -webkit-box-direction: normal;
    -webkit-flex-direction: column;
        -ms-flex-direction: column;
            flex-direction: column;
    overflow: initial;
    background-color: #fff;
    will-change: opacity, margin-top;
    -webkit-transition: opacity .15s ease-in-out, width .15s, margin-top .15s, z-index .15s step-start, -webkit-box-shadow .15s ease-in-out, -webkit-transform .15s ease-in-out;
    transition: opacity .15s ease-in-out, width .15s, margin-top .15s, z-index .15s step-start, -webkit-box-shadow .15s ease-in-out, -webkit-transform .15s ease-in-out;
    -o-transition: opacity .15s ease-in-out, box-shadow .15s ease-in-out, transform .15s ease-in-out, width .15s, margin-top .15s, z-index .15s step-start;
    transition: opacity .15s ease-in-out, box-shadow .15s ease-in-out, transform .15s ease-in-out, width .15s, margin-top .15s, z-index .15s step-start;
    transition: opacity .15s ease-in-out, box-shadow .15s ease-in-out, transform .15s ease-in-out, width .15s, margin-top .15s, z-index .15s step-start, -webkit-box-shadow .15s ease-in-out, -webkit-transform .15s ease-in-out;
    z-index: 1000;
}

@media (max-width: 575.98px) {
    .yaybar.rui-yaybar {
        -webkit-transition: opacity .15s ease-in-out, width .15s, margin-top .15s step-start, z-index .15s step-start, -webkit-box-shadow .15s ease-in-out, -webkit-transform .15s ease-in-out;
        transition: opacity .15s ease-in-out, width .15s, margin-top .15s step-start, z-index .15s step-start, -webkit-box-shadow .15s ease-in-out, -webkit-transform .15s ease-in-out;
        -o-transition: opacity .15s ease-in-out, box-shadow .15s ease-in-out, transform .15s ease-in-out, width .15s, margin-top .15s step-start, z-index .15s step-start;
        transition: opacity .15s ease-in-out, box-shadow .15s ease-in-out, transform .15s ease-in-out, width .15s, margin-top .15s step-start, z-index .15s step-start;
        transition: opacity .15s ease-in-out, box-shadow .15s ease-in-out, transform .15s ease-in-out, width .15s, margin-top .15s step-start, z-index .15s step-start, -webkit-box-shadow .15s ease-in-out, -webkit-transform .15s ease-in-out;
    }
}

.yay-hide .yaybar.rui-yaybar {
    -webkit-transition: opacity .15s ease-in-out, width .15s, margin-top .15s, z-index .15s step-end, -webkit-box-shadow .15s ease-in-out, -webkit-transform .15s ease-in-out;
    transition: opacity .15s ease-in-out, width .15s, margin-top .15s, z-index .15s step-end, -webkit-box-shadow .15s ease-in-out, -webkit-transform .15s ease-in-out;
    -o-transition: opacity .15s ease-in-out, box-shadow .15s ease-in-out, transform .15s ease-in-out, width .15s, margin-top .15s, z-index .15s step-end;
    transition: opacity .15s ease-in-out, box-shadow .15s ease-in-out, transform .15s ease-in-out, width .15s, margin-top .15s, z-index .15s step-end;
    transition: opacity .15s ease-in-out, box-shadow .15s ease-in-out, transform .15s ease-in-out, width .15s, margin-top .15s, z-index .15s step-end, -webkit-box-shadow .15s ease-in-out, -webkit-transform .15s ease-in-out;
    z-index: 1000;
}

@media (max-width: 575.98px) {
    .yay-hide .yaybar.rui-yaybar {
        -webkit-transition: opacity .15s ease-in-out, width .15s, margin-top .15s step-end, z-index .15s step-end, -webkit-box-shadow .15s ease-in-out, -webkit-transform .15s ease-in-out;
        transition: opacity .15s ease-in-out, width .15s, margin-top .15s step-end, z-index .15s step-end, -webkit-box-shadow .15s ease-in-out, -webkit-transform .15s ease-in-out;
        -o-transition: opacity .15s ease-in-out, box-shadow .15s ease-in-out, transform .15s ease-in-out, width .15s, margin-top .15s step-end, z-index .15s step-end;
        transition: opacity .15s ease-in-out, box-shadow .15s ease-in-out, transform .15s ease-in-out, width .15s, margin-top .15s step-end, z-index .15s step-end;
        transition: opacity .15s ease-in-out, box-shadow .15s ease-in-out, transform .15s ease-in-out, width .15s, margin-top .15s step-end, z-index .15s step-end, -webkit-box-shadow .15s ease-in-out, -webkit-transform .15s ease-in-out;
    }
}

.yay-hide .yaybar.rui-yaybar.yay-overlay {
    border-radius: 0;
    -webkit-box-shadow: 0 0 0 5px #fff;
            box-shadow: 0 0 0 5px #fff;
}

.yay-hide .yaybar.rui-yaybar.yay-overlay + .rui-yaybar-bg {
    visibility: hidden;
    opacity: 0;
}

.yay-hide .yaybar.rui-yaybar.yay-overlay .rui-yaybar-brand {
    top: 5px;
    left: 5px;
    -webkit-box-shadow: 0 0 0 5px #fff;
            box-shadow: 0 0 0 5px #fff;
}

.yay-hide .yaybar.rui-yaybar .rui-yaybar-icons,
.yay-hide .yaybar.rui-yaybar .rui-yaybar-icons-substrate {
    display: none;
}

.yay-hide .yaybar.rui-yaybar .rui-yaybar-logo {
    visibility: hidden;
    opacity: 0;
}

.yay-hide .yaybar.rui-yaybar .rui-yaybar-brand {
    position: -webkit-sticky;
    position: sticky;
    top: 0;
    right: 0;
    left: 0;
    background-color: #fff;
}

.yay-hide .yaybar.rui-yaybar .yaybar-wrap > ul > li > .yay-submenu {
    width: 250px;
    padding: 15px;
    margin: -15px 0 0 15px;
    background-color: #fff !important;
    border: 1px solid #f3f4f7;
    border-radius: 4px;
    -webkit-box-shadow: 0 3px 10px rgba(0, 0, 0, 0.03);
            box-shadow: 0 3px 10px rgba(0, 0, 0, 0.03);
}

.yay-hide .yaybar.rui-yaybar .yaybar-wrap > ul > li > .yay-submenu::before {
    content: "";
    position: absolute;
    top: 0;
    left: 0;
    width: 22px;
    height: 75px;
    margin-left: -22px;
}

.yay-hide .yaybar.rui-yaybar .yaybar-wrap > ul > li > .yay-submenu .dropdown-menu-triangle {
    top: 30px;
    right: auto;
    bottom: auto;
    left: 0;
    margin: -5px 0 0 -19px;
    border: 10px solid transparent;
    border-right: 9px solid #fff;
}

.yay-hide .yaybar.rui-yaybar .yaybar-wrap > ul > li > .yay-submenu .dropdown-menu-triangle::before {
    margin: -11px 0 0 -12px;
    border: 11px solid transparent;
    border-right: 10px solid #f3f4f7;
}

.yay-hide .yaybar.rui-yaybar .yaybar-wrap > ul > li > .yay-submenu > li > a {
    padding: 9px 14px 9px 30px;
}

.yay-hide .yaybar.rui-yaybar .yaybar-wrap > ul > li > a > .rui-yaybar-circle {
    top: 3px;
    left: -3px;
}

.yay-hide .yaybar.rui-yaybar.yay-right-side ul > li > .yay-submenu {
    margin: -15px 15px 0 0;
}

.yay-hide .yaybar.rui-yaybar.yay-right-side ul > li > .yay-submenu::before {
    content: "";
    position: absolute;
    top: 0;
    right: 0;
    left: auto;
    width: 22px;
    height: 75px;
    margin-right: -22px;
    margin-left: 0;
}

.yay-hide .yaybar.rui-yaybar.yay-right-side ul > li > .yay-submenu .dropdown-menu-triangle {
    top: 30px;
    right: 0;
    bottom: auto;
    left: auto;
    margin: -3px -19px 0 0;
    border: 10px solid transparent;
    border-left: 9px solid #fff;
}

.yay-hide .yaybar.rui-yaybar.yay-right-side ul > li > .yay-submenu .dropdown-menu-triangle::before {
    margin: -11px 0 2px -9px;
    border: 11px solid transparent;
    border-left: 10px solid #f3f4f7;
}

.yay-hide .yaybar.rui-yaybar.yay-right-side ul > li > .yay-submenu > li > a {
    padding: 9px 14px 9px 30px;
}

@media (max-width: 767.98px) {
    .yaybar.rui-yaybar:not(.yay-overlay) {
        opacity: 0;
    }
    .yaybar.rui-yaybar.yay-hide-to-small.yay-shrink ~ .content-wrap {
        margin-left: 70px;
    }
    .yaybar.rui-yaybar.yay-hide-to-small.yay-overlay ~ .content-wrap {
        margin-left: 70px;
    }
}

.yaybar.rui-yaybar .yay-wrap-menu {
    -webkit-box-flex: 1;
    -webkit-flex: 1;
        -ms-flex: 1;
            flex: 1;
}

.yaybar.rui-yaybar + .rui-yaybar-bg {
    position: fixed;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    visibility: hidden;
    background-color: rgba(50, 57, 66, 0.9);
    opacity: 0;
    -webkit-transition: opacity .15s ease-in-out, visibility .15s ease-in-out;
    -o-transition: opacity .15s ease-in-out, visibility .15s ease-in-out;
    transition: opacity .15s ease-in-out, visibility .15s ease-in-out;
    z-index: 1003;
}

.yaybar.rui-yaybar .dropdown-menu {
    padding: 16px 15px;
}

.yaybar.rui-yaybar .dropdown-menu .nav-link {
    padding: 9px 15px;
}

.yaybar.rui-yaybar .dropdown-menu .nav-link > .rui-nav-circle {
    left: 5px;
}

.yaybar.rui-yaybar .rui-yaybar-icons {
    position: relative;
    display: -webkit-box;
    display: -webkit-flex;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-orient: vertical;
    -webkit-box-direction: normal;
    -webkit-flex-direction: column;
        -ms-flex-direction: column;
            flex-direction: column;
    -webkit-box-align: stretch;
    -webkit-align-items: stretch;
        -ms-flex-align: stretch;
            align-items: stretch;
    -webkit-box-pack: center;
    -webkit-justify-content: center;
        -ms-flex-pack: center;
            justify-content: center;
    min-height: 70px;
    padding: 0 15px;
    z-index: 2;
}

.yaybar.rui-yaybar .rui-yaybar-brand {
    position: relative;
    display: -webkit-box;
    display: -webkit-flex;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-align: center;
    -webkit-align-items: center;
        -ms-flex-align: center;
            align-items: center;
    -webkit-box-pack: justify;
    -webkit-justify-content: space-between;
        -ms-flex-pack: justify;
            justify-content: space-between;
    min-height: 70px;
    padding: 0 30px;
    z-index: 2;
}

.yaybar.rui-yaybar.yay-overlay {
    top: 5px;
    bottom: 5px;
    left: 5px;
    border-radius: .25rem;
    -webkit-box-shadow: 0 3px 10px rgba(0, 0, 0, 0.03);
            box-shadow: 0 3px 10px rgba(0, 0, 0, 0.03);
    z-index: 1004;
}

.yaybar.rui-yaybar.yay-overlay + .rui-yaybar-bg {
    visibility: visible;
    opacity: 1;
}

.yaybar.rui-yaybar.yay-overlay .rui-yaybar-brand {
    min-height: 60px;
}

.yaybar.rui-yaybar.yay-overlay .rui-yaybar-icons {
    min-height: 60px;
}

.yaybar.rui-yaybar .os-scrollbar {
    padding-right: 0;
    background-color: #fff;
}

.yaybar.rui-yaybar .os-scrollbar > .os-scrollbar-track > .os-scrollbar-handle {
    background-color: #f3f4f7;
}

.yaybar.rui-yaybar .os-scrollbar:hover > .os-scrollbar-track > .os-scrollbar-handle,
.yaybar.rui-yaybar .os-scrollbar.active > .os-scrollbar-track > .os-scrollbar-handle {
    background-color: #d7d9e0;
}

.yaybar.rui-yaybar .os-scrollbar > .os-scrollbar-track {
    background-color: #fff;
}

.yaybar.rui-yaybar .os-scrollbar.os-scrollbar-vertical {
    width: 6px;
    margin-right: 1px;
}

.yaybar.rui-yaybar .os-scrollbar.os-scrollbar-horizontal {
    height: 6px;
}

.yaybar.rui-yaybar.os-host-scrolling .os-scrollbar > .os-scrollbar-track > .os-scrollbar-handle {
    background-color: #eaecf0;
}

.yaybar.rui-yaybar .rui-yaybar-logo {
    visibility: visible;
    opacity: 1;
    -webkit-transition: visibility .15s ease-in-out, opacity .15s ease-in-out;
    -o-transition: visibility .15s ease-in-out, opacity .15s ease-in-out;
    transition: visibility .15s ease-in-out, opacity .15s ease-in-out;
}

.yaybar.rui-yaybar .yaybar-wrap > ul {
    padding: 18px 15px 15px;
}

.yaybar.rui-yaybar .yaybar-wrap ul > li > .yay-submenu {
    background-color: transparent !important;
}

.yaybar.rui-yaybar .yaybar-wrap ul > li > a {
    position: relative;
    padding: 9px 15px;
    color: #a4a6a8;
    background-color: transparent !important;
    -webkit-transition: background-color .3s, font-size 0s, .1s color;
    -o-transition: background-color .3s, font-size 0s, .1s color;
    transition: background-color .3s, font-size 0s, .1s color;
}

.yaybar.rui-yaybar .yaybar-wrap ul > li > a > span,
.yaybar.rui-yaybar .yaybar-wrap ul > li > a > svg {
    z-index: 1;
}

.yaybar.rui-yaybar .yaybar-wrap ul > li > a::before {
    background-color: #bcbec0;
}

.yaybar.rui-yaybar .yaybar-wrap ul > li > a > span:not([class]) {
    color: #393f49;
}

.yaybar.rui-yaybar .yaybar-wrap ul > li > a > .rui-yaybar-circle {
    position: absolute;
    top: 1px;
    left: 5px;
    width: 36px;
    height: 36px;
    background-color: #f8f9fa;
    border-radius: 100%;
    opacity: 0;
    -webkit-transition: opacity .1s ease-in-out;
    -o-transition: opacity .1s ease-in-out;
    transition: opacity .1s ease-in-out;
    z-index: 0;
}

.yaybar.rui-yaybar .yaybar-wrap ul > li > a > .yay-icon {
    margin-right: 14px;
    color: #bcbec0;
    -webkit-transition: .1s color;
    -o-transition: .1s color;
    transition: .1s color;
}

.yaybar.rui-yaybar .yaybar-wrap ul > li > a > .yay-icon-collapse {
    color: #bcbec0;
    -webkit-transition: color .1s ease-in-out, -webkit-transform .15s ease-in-out;
    transition: color .1s ease-in-out, -webkit-transform .15s ease-in-out;
    -o-transition: transform .15s ease-in-out, color .1s ease-in-out;
    transition: transform .15s ease-in-out, color .1s ease-in-out;
    transition: transform .15s ease-in-out, color .1s ease-in-out, -webkit-transform .15s ease-in-out;
}

.yaybar.rui-yaybar .yaybar-wrap ul > li:hover > a,
.yaybar.rui-yaybar .yaybar-wrap ul > li.hover > a {
    color: #393f49;
}

.yaybar.rui-yaybar .yaybar-wrap ul > li:hover > a::before,
.yaybar.rui-yaybar .yaybar-wrap ul > li.hover > a::before {
    background-color: #393f49;
}

.yaybar.rui-yaybar .yaybar-wrap ul > li:hover > a > span:not([class]),
.yaybar.rui-yaybar .yaybar-wrap ul > li.hover > a > span:not([class]) {
    color: #393f49;
}

.yaybar.rui-yaybar .yaybar-wrap ul > li:hover > a > .rui-yaybar-circle,
.yaybar.rui-yaybar .yaybar-wrap ul > li.hover > a > .rui-yaybar-circle {
    opacity: 1;
}

.yaybar.rui-yaybar .yaybar-wrap ul > li:hover > a > .yay-icon,
.yaybar.rui-yaybar .yaybar-wrap ul > li.hover > a > .yay-icon {
    color: #393f49;
}

.yaybar.rui-yaybar .yaybar-wrap ul > li:hover > a > .yay-icon-collapse,
.yaybar.rui-yaybar .yaybar-wrap ul > li.hover > a > .yay-icon-collapse {
    color: #bcbec0;
}

.yaybar.rui-yaybar .yaybar-wrap ul > li.yay-label {
    display: block;
    height: auto;
    padding: 11px 15px;
    font-size: 12px;
    line-height: normal;
    color: #bcbec0;
    text-transform: none;
    opacity: 1;
}

.yaybar.rui-yaybar .yaybar-wrap ul > li + .yay-label {
    margin-top: 11px;
}

.yaybar.rui-yaybar .yaybar-wrap ul > li.yay-item-active > a {
    color: #725ec3 !important;
}

.yaybar.rui-yaybar .yaybar-wrap ul > li.yay-item-active > a::before {
    background-color: #725ec3;
}

.yaybar.rui-yaybar .yaybar-wrap ul > li.yay-item-active > a > span:not([class]) {
    color: #725ec3;
}

.yaybar.rui-yaybar .yaybar-wrap ul > li.yay-item-active > a > .rui-yaybar-circle {
    opacity: 0;
}

.yaybar.rui-yaybar .yaybar-wrap ul > li.yay-item-active > a > .yay-icon {
    color: #725ec3;
}

.yaybar.rui-yaybar .yaybar-wrap ul > li.yay-item-active > a > .yay-icon-collapse {
    color: #725ec3;
}

.yaybar.rui-yaybar .yaybar-wrap ul > li.yay-item-active.yay-submenu-open > a {
    color: #725ec3 !important;
}

.yaybar.rui-yaybar .yaybar-wrap ul > li.yay-item-active.yay-submenu-open > a::before {
    background-color: #725ec3;
}

.yaybar.rui-yaybar .yaybar-wrap ul > li.yay-item-active.yay-submenu-open > a > span:not([class]) {
    color: #725ec3;
}

.yaybar.rui-yaybar .yaybar-wrap ul > li.yay-item-active.yay-submenu-open > a > .rui-yaybar-circle {
    opacity: 0;
}

.yaybar.rui-yaybar .yaybar-wrap ul > li.yay-item-active.yay-submenu-open > a > .yay-icon {
    color: #725ec3;
}

.yaybar.rui-yaybar .yaybar-wrap ul > li.yay-item-active.yay-submenu-open > a > .yay-icon-collapse {
    color: #725ec3;
}

.yaybar.rui-yaybar .yaybar-wrap ul > li.yay-submenu-open > a {
    color: #393f49 !important;
}

.yaybar.rui-yaybar .yaybar-wrap ul > li.yay-submenu-open > a::before {
    background-color: #393f49;
}

.yaybar.rui-yaybar .yaybar-wrap ul > li.yay-submenu-open > a > span:not([class]) {
    color: #393f49;
}

.yaybar.rui-yaybar .yaybar-wrap ul > li.yay-submenu-open > a > .rui-yaybar-circle {
    opacity: 0;
}

.yaybar.rui-yaybar .yaybar-wrap ul > li.yay-submenu-open > a > .yay-icon {
    color: #393f49;
}

.yaybar.rui-yaybar .yaybar-wrap ul > li.yay-submenu-open > a > .yay-icon-collapse {
    color: #393f49;
}

.yaybar.rui-yaybar .yaybar-wrap ul .yay-submenu > li > a {
    padding-left: 45px;
    color: #a4a6a8;
}

.yaybar.rui-yaybar .yaybar-wrap ul .yay-submenu > li > a > span:not([class]) {
    color: inherit;
}

.yaybar.rui-yaybar .yaybar-wrap ul .yay-submenu > li > a::before {
    content: "";
    position: absolute;
    display: inline-block;
    top: 50%;
    width: 2px;
    height: 2px;
    margin-top: -1px;
    margin-left: -16px;
    border-radius: 2px;
    -webkit-transition: background-color .15s ease-in-out;
    -o-transition: background-color .15s ease-in-out;
    transition: background-color .15s ease-in-out;
}

.yaybar.rui-yaybar .yaybar-wrap ul .yay-submenu .yay-submenu > li > a {
    padding-left: 60px;
}

.yaybar.rui-yaybar .yaybar-wrap ul .yay-submenu .yay-submenu > li.yay-submenu-open > a {
    color: #393f49;
}

.yay-hide .rui-yaybar-dark.rui-yaybar.yay-overlay {
    -webkit-box-shadow: 0 0 0 5px #292f38;
            box-shadow: 0 0 0 5px #292f38;
}

.yay-hide .rui-yaybar-dark.rui-yaybar.yay-overlay .rui-yaybar-brand {
    -webkit-box-shadow: 0 0 0 5px #292f38;
            box-shadow: 0 0 0 5px #292f38;
}

.yay-hide .rui-yaybar-dark.rui-yaybar ~ .rui-navbar-top.rui-navbar-dark {
    -webkit-box-shadow: -5px 0 0 #292f38;
            box-shadow: -5px 0 0 #292f38;
}

.yay-hide .rui-yaybar-dark.rui-yaybar .rui-yaybar-brand {
    background-color: #292f38;
}

.yay-hide .rui-yaybar-dark.rui-yaybar .yaybar-wrap > ul > li > .yay-submenu {
    background-color: #2b323b !important;
    border-color: #252c36;
    -webkit-box-shadow: 0 3px 10px rgba(36, 43, 53, 0.9);
            box-shadow: 0 3px 10px rgba(36, 43, 53, 0.9);
}

.yay-hide .rui-yaybar-dark.rui-yaybar .yaybar-wrap > ul > li > .yay-submenu .dropdown-menu-triangle {
    border-color: transparent;
    border-right-color: #2b323b;
}

.yay-hide .rui-yaybar-dark.rui-yaybar .yaybar-wrap > ul > li > .yay-submenu .dropdown-menu-triangle::before {
    border-color: transparent;
    border-right-color: #252c36;
}

.yay-hide .rui-yaybar-dark.rui-yaybar.yay-right-side ul > li > .yay-submenu .dropdown-menu-triangle {
    border-color: transparent;
    border-left-color: #2b323b;
}

.yay-hide .rui-yaybar-dark.rui-yaybar.yay-right-side ul > li > .yay-submenu .dropdown-menu-triangle::before {
    border-color: transparent;
    border-left-color: #252c36;
}

.rui-yaybar-dark.rui-yaybar {
    background-color: #292f38;
}

.rui-yaybar-dark.rui-yaybar .yaybar-wrap {
    background-color: #292f38;
}

.rui-yaybar-dark.rui-yaybar + .rui-yaybar-bg {
    background-color: rgba(69, 76, 85, 0.85);
}

.rui-yaybar-dark.rui-yaybar .os-scrollbar {
    background-color: #292f38;
}

.rui-yaybar-dark.rui-yaybar .os-scrollbar > .os-scrollbar-track > .os-scrollbar-handle {
    background-color: #3c424c;
}

.rui-yaybar-dark.rui-yaybar .os-scrollbar:hover > .os-scrollbar-track > .os-scrollbar-handle,
.rui-yaybar-dark.rui-yaybar .os-scrollbar.active > .os-scrollbar-track > .os-scrollbar-handle {
    background-color: #4f565f;
}

.rui-yaybar-dark.rui-yaybar .os-scrollbar > .os-scrollbar-track {
    background-color: #292f38;
}

.rui-yaybar-dark.rui-yaybar.os-host-scrolling .os-scrollbar > .os-scrollbar-track > .os-scrollbar-handle {
    background-color: #454c55;
}

.rui-yaybar-dark.rui-yaybar.yay-overlay {
    -webkit-box-shadow: 0 3px 10px rgba(36, 43, 53, 0.9);
            box-shadow: 0 3px 10px rgba(36, 43, 53, 0.9);
}

.rui-yaybar-dark.rui-yaybar .rui-yaybar-icons .btn-custom-round {
    color: #626973;
}

.rui-yaybar-dark.rui-yaybar .rui-yaybar-icons .btn-custom-round > .rui-icon {
    stroke: #626973;
}

.rui-yaybar-dark.rui-yaybar .rui-yaybar-icons .btn-custom-round:hover::after, .rui-yaybar-dark.rui-yaybar .rui-yaybar-icons .btn-custom-round.hover::after {
    background-color: #323942;
}

.rui-yaybar-dark.rui-yaybar .nav .nav .nav-link::before {
    background-color: #4f565f;
}

.rui-yaybar-dark.rui-yaybar .nav .nav .nav-link:hover::before, .rui-yaybar-dark.rui-yaybar .nav .nav .nav-link.hover::before, .rui-yaybar-dark.rui-yaybar .nav .nav .nav-link:focus::before, .rui-yaybar-dark.rui-yaybar .nav .nav .nav-link.focus::before, .rui-yaybar-dark.rui-yaybar .nav .nav .nav-link.active::before {
    background-color: #c7d1db;
}

.rui-yaybar-dark.rui-yaybar .nav .nav-link {
    color: #626973;
}

.rui-yaybar-dark.rui-yaybar .nav .nav-link > span:not([class]) {
    color: #c7d1db;
}

.rui-yaybar-dark.rui-yaybar .nav .nav-link > .rui-icon {
    stroke: #586069;
}

.rui-yaybar-dark.rui-yaybar .nav .nav-link > .badge,
.rui-yaybar-dark.rui-yaybar .nav .nav-link > .badge-circle {
    -webkit-box-shadow: 0 0 0 2px #292f38;
            box-shadow: 0 0 0 2px #292f38;
}

.rui-yaybar-dark.rui-yaybar .nav .nav-link.show {
    color: #586069;
}

.rui-yaybar-dark.rui-yaybar .nav .nav-link.show > span:not([class]) {
    color: #c7d1db;
}

.rui-yaybar-dark.rui-yaybar .nav .nav-link.show > .rui-icon {
    stroke: #4f565f;
}

.rui-yaybar-dark.rui-yaybar .nav .nav-link:hover, .rui-yaybar-dark.rui-yaybar .nav .nav-link.hover, .rui-yaybar-dark.rui-yaybar .nav .nav-link:focus, .rui-yaybar-dark.rui-yaybar .nav .nav-link.focus {
    color: #c7d1db;
}

.rui-yaybar-dark.rui-yaybar .nav .nav-link:hover > span:not([class]), .rui-yaybar-dark.rui-yaybar .nav .nav-link.hover > span:not([class]), .rui-yaybar-dark.rui-yaybar .nav .nav-link:focus > span:not([class]), .rui-yaybar-dark.rui-yaybar .nav .nav-link.focus > span:not([class]) {
    color: #c7d1db;
}

.rui-yaybar-dark.rui-yaybar .nav .nav-link:hover > .rui-icon, .rui-yaybar-dark.rui-yaybar .nav .nav-link.hover > .rui-icon, .rui-yaybar-dark.rui-yaybar .nav .nav-link:focus > .rui-icon, .rui-yaybar-dark.rui-yaybar .nav .nav-link.focus > .rui-icon {
    stroke: #c7d1db;
}

.rui-yaybar-dark.rui-yaybar .nav .nav-link.active {
    color: #c7d1db;
}

.rui-yaybar-dark.rui-yaybar .nav .nav-link.active > span:not([class]) {
    color: #725ec3;
}

.rui-yaybar-dark.rui-yaybar .nav .nav-link.active > .rui-icon {
    stroke: #725ec3;
}

.rui-yaybar-dark.rui-yaybar .nav .nav-link.disabled {
    color: #454c55;
}

.rui-yaybar-dark.rui-yaybar .nav .nav-link.disabled > span:not([class]) {
    color: #454c55;
}

.rui-yaybar-dark.rui-yaybar .nav .nav-link.disabled > .rui-icon {
    stroke: #454c55;
}

.rui-yaybar-dark.rui-yaybar .yaybar-wrap ul > li > a {
    color: #586069;
}

.rui-yaybar-dark.rui-yaybar .yaybar-wrap ul > li > a::before {
    background-color: #586069;
}

.rui-yaybar-dark.rui-yaybar .yaybar-wrap ul > li > a > span:not([class]) {
    color: #c7d1db;
}

.rui-yaybar-dark.rui-yaybar .yaybar-wrap ul > li > a > .rui-yaybar-circle {
    background-color: #323942;
}

.rui-yaybar-dark.rui-yaybar .yaybar-wrap ul > li > a .yay-icon {
    color: #626973;
}

.rui-yaybar-dark.rui-yaybar .yaybar-wrap ul > li > a .yay-icon-collapse {
    color: #586069;
}

.rui-yaybar-dark.rui-yaybar .yaybar-wrap ul > li:hover > a,
.rui-yaybar-dark.rui-yaybar .yaybar-wrap ul > li.hover > a {
    color: #c7d1db;
}

.rui-yaybar-dark.rui-yaybar .yaybar-wrap ul > li:hover > a::before,
.rui-yaybar-dark.rui-yaybar .yaybar-wrap ul > li.hover > a::before {
    background-color: #c7d1db;
}

.rui-yaybar-dark.rui-yaybar .yaybar-wrap ul > li:hover > a > span:not([class]),
.rui-yaybar-dark.rui-yaybar .yaybar-wrap ul > li.hover > a > span:not([class]) {
    color: #c7d1db;
}

.rui-yaybar-dark.rui-yaybar .yaybar-wrap ul > li:hover > a > .yay-icon,
.rui-yaybar-dark.rui-yaybar .yaybar-wrap ul > li.hover > a > .yay-icon {
    color: #c7d1db;
}

.rui-yaybar-dark.rui-yaybar .yaybar-wrap ul > li:hover > a > .yay-icon-collapse,
.rui-yaybar-dark.rui-yaybar .yaybar-wrap ul > li.hover > a > .yay-icon-collapse {
    color: #c7d1db;
}

.rui-yaybar-dark.rui-yaybar .yaybar-wrap ul > li.yay-label {
    color: #626973;
}

.rui-yaybar-dark.rui-yaybar .yaybar-wrap ul > li.yay-item-active > a {
    color: #725ec3 !important;
}

.rui-yaybar-dark.rui-yaybar .yaybar-wrap ul > li.yay-item-active > a::before {
    background-color: #725ec3;
}

.rui-yaybar-dark.rui-yaybar .yaybar-wrap ul > li.yay-item-active > a > span:not([class]) {
    color: #725ec3;
}

.rui-yaybar-dark.rui-yaybar .yaybar-wrap ul > li.yay-item-active > a > .yay-icon {
    color: #725ec3;
}

.rui-yaybar-dark.rui-yaybar .yaybar-wrap ul > li.yay-item-active > a > .yay-icon-collapse {
    color: #725ec3;
}

.rui-yaybar-dark.rui-yaybar .yaybar-wrap ul > li.yay-item-active.yay-submenu-open > a {
    color: #725ec3;
}

.rui-yaybar-dark.rui-yaybar .yaybar-wrap ul > li.yay-item-active.yay-submenu-open > a::before {
    background-color: #725ec3;
}

.rui-yaybar-dark.rui-yaybar .yaybar-wrap ul > li.yay-item-active.yay-submenu-open > a > span:not([class]) {
    color: #725ec3;
}

.rui-yaybar-dark.rui-yaybar .yaybar-wrap ul > li.yay-item-active.yay-submenu-open > a > .yay-icon {
    color: #725ec3;
}

.rui-yaybar-dark.rui-yaybar .yaybar-wrap ul > li.yay-item-active.yay-submenu-open > a > .yay-icon-collapse {
    color: #725ec3;
}

.rui-yaybar-dark.rui-yaybar .yaybar-wrap ul > li.yay-submenu-open > a {
    color: #c7d1db !important;
}

.rui-yaybar-dark.rui-yaybar .yaybar-wrap ul > li.yay-submenu-open > a::before {
    background-color: #c7d1db;
}

.rui-yaybar-dark.rui-yaybar .yaybar-wrap ul > li.yay-submenu-open > a > span:not([class]) {
    color: #c7d1db;
}

.rui-yaybar-dark.rui-yaybar .yaybar-wrap ul > li.yay-submenu-open > a > .yay-icon {
    color: #c7d1db;
}

.rui-yaybar-dark.rui-yaybar .yaybar-wrap ul > li.yay-submenu-open > a > .yay-icon-collapse {
    color: #c7d1db;
}

.rui-yaybar-dark.rui-yaybar .yaybar-wrap ul .yay-submenu > li > a {
    color: #626973;
}

.rui-yaybar-dark.rui-yaybar .yaybar-wrap ul .yay-submenu > li:hover > a,
.rui-yaybar-dark.rui-yaybar .yaybar-wrap ul .yay-submenu > li.hover > a {
    color: #c7d1db;
}

.rui-yaybar-dark.rui-yaybar .yaybar-wrap ul .yay-submenu > li:hover > a > span:not([class]),
.rui-yaybar-dark.rui-yaybar .yaybar-wrap ul .yay-submenu > li.hover > a > span:not([class]) {
    color: #c7d1db;
}

.rui-yaybar-dark.rui-yaybar .yaybar-wrap ul.yay-submenu-open > a {
    color: #c7d1db;
}

.rui-yaybar-dark.rui-yaybar .dropdown .dropdown-item {
    color: #626973;
}

.rui-yaybar-dark.rui-yaybar .dropdown .dropdown-item > span:not([class]) {
    color: #c7d1db;
}

.rui-yaybar-dark.rui-yaybar .dropdown .dropdown-item > .rui-icon {
    stroke: #586069;
}

.rui-yaybar-dark.rui-yaybar .dropdown .dropdown-item:hover, .rui-yaybar-dark.rui-yaybar .dropdown .dropdown-item.hover {
    color: #c7d1db;
}

.rui-yaybar-dark.rui-yaybar .dropdown .dropdown-item:hover > span:not([class]), .rui-yaybar-dark.rui-yaybar .dropdown .dropdown-item.hover > span:not([class]) {
    color: #c7d1db;
}

.rui-yaybar-dark.rui-yaybar .dropdown .dropdown-item:hover > .rui-icon, .rui-yaybar-dark.rui-yaybar .dropdown .dropdown-item.hover > .rui-icon {
    stroke: #c7d1db;
}

.rui-yaybar-dark.rui-yaybar .dropdown:not(.dropdown-hover):focus, .rui-yaybar-dark.rui-yaybar .dropdown:not(.dropdown-hover).focus {
    color: #c7d1db;
}

.rui-yaybar-dark.rui-yaybar .dropdown:not(.dropdown-hover):focus > span:not([class]), .rui-yaybar-dark.rui-yaybar .dropdown:not(.dropdown-hover).focus > span:not([class]) {
    color: #c7d1db;
}

.rui-yaybar-dark.rui-yaybar .dropdown:not(.dropdown-hover):focus > .rui-icon, .rui-yaybar-dark.rui-yaybar .dropdown:not(.dropdown-hover).focus > .rui-icon {
    stroke: #c7d1db;
}

.rui-yaybar-dark.rui-yaybar .dropdown:not(.dropdown-hover):active {
    color: #725ec3;
}

.rui-yaybar-dark.rui-yaybar .dropdown:not(.dropdown-hover):active > span:not([class]) {
    color: #725ec3;
}

.rui-yaybar-dark.rui-yaybar .dropdown:not(.dropdown-hover):active > .rui-icon {
    stroke: #725ec3;
}

.rui-yaybar-dark.rui-yaybar .dropdown:not(.dropdown-hover).show > .dropdown-item {
    color: #c7d1db;
    background-color: transparent;
}

.rui-yaybar-dark.rui-yaybar .dropdown:not(.dropdown-hover).show > .dropdown-item > span:not([class]) {
    color: #c7d1db;
}

.rui-yaybar-dark.rui-yaybar .dropdown:not(.dropdown-hover).show > .dropdown-item > .rui-icon {
    stroke: #c7d1db;
}

.rui-yaybar-dark.rui-yaybar .dropdown-hover.hover > .dropdown-item {
    color: #c7d1db;
}

.rui-yaybar-dark.rui-yaybar .dropdown-hover.hover > .dropdown-item > span:not([class]) {
    color: #c7d1db;
}

.rui-yaybar-dark.rui-yaybar .dropdown-hover.hover > .dropdown-item > .rui-icon {
    stroke: #c7d1db;
}

.rui-yaybar-dark.rui-yaybar .dropdown-hover.hover > .dropdown-item.btn-custom-round,
.rui-yaybar-dark.rui-yaybar .dropdown-hover.hover > .dropdown-item > .btn-custom-round {
    color: #6c757d;
}

.rui-yaybar-dark.rui-yaybar .dropdown-hover.hover > .dropdown-item.btn-custom-round::after,
.rui-yaybar-dark.rui-yaybar .dropdown-hover.hover > .dropdown-item > .btn-custom-round::after {
    background-color: #323942;
}

.rui-yaybar-dark.rui-yaybar .dropdown-menu {
    background-color: #2b323b;
    border-color: #252c36;
    -webkit-box-shadow: 0 3px 10px rgba(36, 43, 53, 0.9);
            box-shadow: 0 3px 10px rgba(36, 43, 53, 0.9);
}

.rui-yaybar-dark.rui-yaybar .dropdown-menu .rui-nav-circle,
.rui-yaybar-dark.rui-yaybar .dropdown-menu .rui-dropdown-circle {
    background-color: #323942;
}

.rui-yaybar-dark.rui-yaybar .dropdown-triangle .dropdown-menu[x-placement="bottom-start"] > .dropdown-menu-triangle,
.rui-yaybar-dark.rui-yaybar .dropdown-triangle .dropdown-menu[x-placement="bottom-end"] > .dropdown-menu-triangle,
.rui-yaybar-dark.rui-yaybar .dropdown-triangle .dropdown-menu > .dropdown-menu-triangle {
    border-color: transparent;
    border-bottom-color: #2b323b;
}

.rui-yaybar-dark.rui-yaybar .dropdown-triangle .dropdown-menu[x-placement="bottom-start"] > .dropdown-menu-triangle::before,
.rui-yaybar-dark.rui-yaybar .dropdown-triangle .dropdown-menu[x-placement="bottom-end"] > .dropdown-menu-triangle::before,
.rui-yaybar-dark.rui-yaybar .dropdown-triangle .dropdown-menu > .dropdown-menu-triangle::before {
    border-color: transparent;
    border-bottom-color: #252c36;
}

.rui-yaybar-dark.rui-yaybar .dropdown-triangle .dropdown-menu[x-placement="top-start"] > .dropdown-menu-triangle,
.rui-yaybar-dark.rui-yaybar .dropdown-triangle .dropdown-menu[x-placement="top-end"] > .dropdown-menu-triangle {
    border-color: transparent;
    border-top-color: #2b323b;
}

.rui-yaybar-dark.rui-yaybar .dropdown-triangle .dropdown-menu[x-placement="top-start"] > .dropdown-menu-triangle::before,
.rui-yaybar-dark.rui-yaybar .dropdown-triangle .dropdown-menu[x-placement="top-end"] > .dropdown-menu-triangle::before {
    border-color: transparent;
    border-top-color: #252c36;
}

.rui-yaybar-dark.rui-yaybar .dropdown-triangle .dropdown-menu[x-placement="right-start"] > .dropdown-menu-triangle {
    border-color: transparent;
    border-right-color: #2b323b;
}

.rui-yaybar-dark.rui-yaybar .dropdown-triangle .dropdown-menu[x-placement="right-start"] > .dropdown-menu-triangle::before {
    border-color: transparent;
    border-right-color: #252c36;
}

.rui-yaybar-dark.rui-yaybar .dropdown-triangle .dropdown-menu[x-placement="right-end"] > .dropdown-menu-triangle {
    border-color: transparent;
    border-right-color: #2b323b;
}

.rui-yaybar-dark.rui-yaybar .dropdown-triangle .dropdown-menu[x-placement="right-end"] > .dropdown-menu-triangle::before {
    border-color: transparent;
    border-right-color: #252c36;
}

.rui-yaybar-dark.rui-yaybar .dropdown-triangle .dropdown-menu[x-placement="left-start"] > .dropdown-menu-triangle {
    border-color: transparent;
    border-left-color: #2b323b;
}

.rui-yaybar-dark.rui-yaybar .dropdown-triangle .dropdown-menu[x-placement="left-start"] > .dropdown-menu-triangle::before {
    border-color: transparent;
    border-left-color: #252c36;
}

.rui-yaybar-dark.rui-yaybar .dropdown-triangle .dropdown-menu[x-placement="left-end"] > .dropdown-menu-triangle {
    border-color: transparent;
    border-left-color: #2b323b;
}

.rui-yaybar-dark.rui-yaybar .dropdown-triangle .dropdown-menu[x-placement="left-end"] > .dropdown-menu-triangle::before {
    border-color: transparent;
    border-left-color: #252c36;
}

/*---------------------------
    Plugin Overlay Scrollbars
 ----------------------------*/
.rui-scrollbar {
    overflow: hidden;
}

.rui-scrollbar.os-theme-dark .os-scrollbar > .os-scrollbar-track > .os-scrollbar-handle {
    background-color: #f3f4f7;
}

.rui-scrollbar.os-theme-dark .os-scrollbar:hover > .os-scrollbar-track > .os-scrollbar-handle,
.rui-scrollbar.os-theme-dark .os-scrollbar.active > .os-scrollbar-track > .os-scrollbar-handle {
    background-color: #eaecf0;
}

.rui-scrollbar.os-theme-dark .os-scrollbar.os-scrollbar-vertical {
    width: 8px;
}

.rui-scrollbar.os-theme-dark .os-scrollbar.os-scrollbar-horizontal {
    height: 8px;
}

.rui-scrollbar.os-host-scrolling .os-scrollbar {
    visibility: visible;
    opacity: 1;
}

.rui-scrollbar.os-host-scrolling .os-scrollbar > .os-scrollbar-track > .os-scrollbar-handle {
    background-color: #eaecf0;
}

/*---------------------------
    Plugin Emojione Area
 ----------------------------*/
.emojionearea.rui-messenger-textarea {
    display: -webkit-box;
    display: -webkit-flex;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-align: center;
    -webkit-align-items: center;
        -ms-flex-align: center;
            align-items: center;
    width: auto;
    font-family: "Open Sans", sans-serif;
    border: 0;
    -webkit-box-shadow: none;
            box-shadow: none;
}

.emojionearea.rui-messenger-textarea .emojionearea-editor {
    top: auto;
    right: 0;
    left: 4px;
    margin-right: 0;
    color: #393f49;
}

.emojionearea.rui-messenger-textarea .emojionearea-editor::before {
    margin-left: 10px;
    color: #bcbec0;
}

.emojionearea.rui-messenger-textarea .emojionearea-button > div,
.emojionearea.rui-messenger-textarea .emojionearea-picker .emojionearea-wrapper::after {
    text-align: center;
    background-image: none !important;
}

.emojionearea.rui-messenger-textarea > .emojionearea-button,
.emojionearea.rui-messenger-textarea.emojionearea-inline > .emojionearea-button {
    top: 7px;
    right: 10px;
}

.emojionearea.rui-messenger-textarea > .emojionearea-button {
    color: #bcbec0;
    opacity: 1;
}

.emojionearea.rui-messenger-textarea > .emojionearea-button:hover {
    color: inherit;
}

.emojionearea.rui-messenger-textarea > .emojionearea-button > div {
    -webkit-transition: all .15s ease-in-out;
    -o-transition: all .15s ease-in-out;
    transition: all .15s ease-in-out;
}

.emojionearea.rui-messenger-textarea .emojionearea-button.active + .emojionearea-picker-position-top {
    margin-top: -280px;
}

.emojionearea.rui-messenger-textarea .emojionearea-picker {
    width: 314px;
    height: auto;
    max-height: 276px;
    margin: 0;
    border: 1px solid #f3f4f7;
    border-radius: 4px;
    -webkit-box-shadow: 0 3px 10px rgba(0, 0, 0, 0.03);
            box-shadow: 0 3px 10px rgba(0, 0, 0, 0.03);
    -webkit-transition: visibility .15s ease-in-out, opacity .15s ease-in-out;
    -o-transition: visibility .15s ease-in-out, opacity .15s ease-in-out;
    transition: visibility .15s ease-in-out, opacity .15s ease-in-out;
}

.emojionearea.rui-messenger-textarea .emojionearea-picker.emojionearea-picker-position-top {
    margin-top: -280px;
}

.emojionearea.rui-messenger-textarea .emojionearea-picker.emojionearea-picker-position-top .emojionearea-scroll-area {
    top: 55px;
    bottom: 1px;
    padding-bottom: 10px;
}

.emojionearea.rui-messenger-textarea .emojionearea-picker.emojionearea-filters-position-bottom .emojionearea-scroll-area {
    top: 1px;
    bottom: 55px;
    padding-top: 10px;
}

.emojionearea.rui-messenger-textarea .emojionearea-picker.emojionearea-filters-position-bottom .emojionearea-filters {
    bottom: 2px;
}

.emojionearea.rui-messenger-textarea .emojionearea-picker.rui-messenger-picker-complete .rui-messenger-picker-triangle {
    position: absolute;
    bottom: -6px;
    margin-left: 10px;
    border: 10px solid transparent;
    border-top: 9px solid #fff;
}

.emojionearea.rui-messenger-textarea .emojionearea-picker.rui-messenger-picker-complete .rui-messenger-picker-triangle::before {
    content: "";
    position: absolute;
    right: 0;
    bottom: 7px;
    left: -11px;
    border: 11px solid transparent;
    border-top: 10px solid #f3f4f7;
    z-index: -1;
}

.emojionearea.rui-messenger-textarea .emojionearea-picker .emojionearea-scroll-area {
    right: 1px;
    width: auto;
    height: 218px;
    padding-left: 12px;
}

.emojionearea.rui-messenger-textarea .emojionearea-picker .emojionearea-scroll-area::-webkit-scrollbar-track {
    background-color: #fff;
    border-radius: 4px;
}

.emojionearea.rui-messenger-textarea .emojionearea-picker .emojionearea-scroll-area::-webkit-scrollbar {
    width: 4px;
    background-color: #fff;
}

.emojionearea.rui-messenger-textarea .emojionearea-picker .emojionearea-scroll-area::-webkit-scrollbar-thumb {
    background-color: #eaecf0;
    border-radius: 4px;
}

.emojionearea.rui-messenger-textarea .emojionearea-picker .emojionearea-wrapper {
    width: auto;
}

.emojionearea.rui-messenger-textarea .emojionearea-picker .emojionearea-wrapper::after {
    display: none;
}

.emojionearea.rui-messenger-textarea .emojionearea-picker .emojibtn {
    display: -webkit-inline-box;
    display: -webkit-inline-flex;
    display: -ms-inline-flexbox;
    display: inline-flex;
    -webkit-box-align: center;
    -webkit-align-items: center;
        -ms-flex-align: center;
            align-items: center;
    -webkit-box-pack: center;
    -webkit-justify-content: center;
        -ms-flex-pack: center;
            justify-content: center;
    width: 32px;
    height: 32px;
    padding: 0;
    margin: 0;
    background-color: #fff;
    border-radius: 100%;
    -webkit-transition: background-color .1s ease-in-out;
    -o-transition: background-color .1s ease-in-out;
    transition: background-color .1s ease-in-out;
}

.emojionearea.rui-messenger-textarea .emojionearea-picker .emojibtn:hover {
    background-color: #f3f4f7;
    border-radius: 100%;
}

.emojionearea.rui-messenger-textarea .emojionearea-picker .emojionearea-filter {
    display: -webkit-inline-box;
    display: -webkit-inline-flex;
    display: -ms-inline-flexbox;
    display: inline-flex;
    -webkit-box-align: center;
    -webkit-align-items: center;
        -ms-flex-align: center;
            align-items: center;
    -webkit-box-pack: center;
    -webkit-justify-content: center;
        -ms-flex-pack: center;
            justify-content: center;
    width: 32px;
    height: 32px;
    padding: 0;
}

.emojionearea.rui-messenger-textarea .emojionearea-picker .emojionearea-filter > img {
    margin: 0;
}

.emojionearea.rui-messenger-textarea .emojionearea-picker .emojionearea-filters {
    height: auto;
    padding: 12px;
    background-color: #fff;
}

.emojionearea.rui-messenger-textarea .emojionearea-picker .emojionearea-category:first-of-type {
    margin-top: -10px;
}

.emojionearea.rui-messenger-textarea .emojionearea-picker .emojionearea-category-title {
    padding: 15px 8px 7px;
    font-size: 80%;
    font-weight: 400;
    color: #eaecf0;
}

.emojionearea.rui-messenger-textarea .emojionearea-picker .emojionearea-category-block {
    padding: 0;
}

.emojionearea.rui-messenger-textarea .emojione,
.emojionearea.rui-messenger-textarea .emojioneemoji {
    width: 18px;
    min-width: auto;
    max-width: 18px;
    height: 18px;
    min-height: auto;
    max-height: 18px;
}

.emojione,
.emojioneemoji {
    max-width: 16px;
    max-height: 16px;
    margin-top: -2px;
}

.textcomplete-dropdown {
    display: -webkit-box;
    display: -webkit-flex;
    display: -ms-flexbox;
    display: flex;
    visibility: visible;
    opacity: 1;
    z-index: 99993 !important;
}

.textcomplete-dropdown.dropdown-menu[data-strategy="emojionearea"] {
    min-width: 10px;
    padding: 5px;
    border: 1px solid #f3f4f7;
    border-radius: .25rem;
    -webkit-box-shadow: 0 3px 10px rgba(0, 0, 0, 0.03);
            box-shadow: 0 3px 10px rgba(0, 0, 0, 0.03);
}

.textcomplete-dropdown.dropdown-menu[data-strategy="emojionearea"] li.textcomplete-item {
    padding: 7px;
    font-size: 0;
    border-radius: 3px;
}

.textcomplete-dropdown.dropdown-menu[data-strategy="emojionearea"] li.textcomplete-item img.emojioneemoji {
    margin-right: 0;
}

.textcomplete-dropdown.dropdown-menu[data-strategy="emojionearea"] li.textcomplete-item.active, .textcomplete-dropdown.dropdown-menu[data-strategy="emojionearea"] li.textcomplete-item:hover {
    background-color: #f3f4f7;
}

/*---------------------------
    Plugin Sortablejs
 ----------------------------*/
.rui-sortable .sortable-chosen {
    background-color: #f8f9fa;
}

/*---------------------------
    Plugin Swiper
 ----------------------------*/
.rui-swiper {
    position: relative;
}

.rui-swiper .swiper-container {
    border-radius: .25rem;
}

.rui-swiper .swiper-container::before, .rui-swiper .swiper-container::after {
    content: "";
    position: absolute;
    display: block;
    top: 0;
    bottom: 0;
    width: 20px;
    z-index: 2;
}

.rui-swiper .swiper-container::before {
    left: 0;
    background-image: -webkit-gradient(linear, left top, right top, from(#f3f4f7), to(rgba(255, 255, 255, 0)));
    background-image: -o-linear-gradient(left, #f3f4f7, rgba(255, 255, 255, 0));
    background-image: linear-gradient(90deg, #f3f4f7, rgba(255, 255, 255, 0));
}

.rui-swiper .swiper-container::after {
    right: 0;
    background-image: -webkit-gradient(linear, right top, left top, from(#f3f4f7), to(rgba(255, 255, 255, 0)));
    background-image: -o-linear-gradient(right, #f3f4f7, rgba(255, 255, 255, 0));
    background-image: linear-gradient(270deg, #f3f4f7, rgba(255, 255, 255, 0));
}

.rui-swiper .swiper-button-prev,
.rui-swiper .swiper-button-next {
    display: -webkit-inline-box;
    display: -webkit-inline-flex;
    display: -ms-inline-flexbox;
    display: inline-flex;
    -webkit-box-align: center;
    -webkit-align-items: center;
        -ms-flex-align: center;
            align-items: center;
    -webkit-box-pack: center;
    -webkit-justify-content: center;
        -ms-flex-pack: center;
            justify-content: center;
    width: 38px;
    height: 38px;
    margin-top: -19px;
    color: #6c757d;
    background-color: #fff;
    background-image: none;
    border: 1px solid #e6ecf0;
    border-radius: 100%;
    -webkit-transition: background-color .15s ease-in-out, color .15s ease-in-out;
    -o-transition: background-color .15s ease-in-out, color .15s ease-in-out;
    transition: background-color .15s ease-in-out, color .15s ease-in-out;
}

.rui-swiper .swiper-button-prev::after,
.rui-swiper .swiper-button-next::after {
    content: none;
}

.rui-swiper .swiper-button-prev:focus, .rui-swiper .swiper-button-prev.hover,
.rui-swiper .swiper-button-next:focus,
.rui-swiper .swiper-button-next.hover {
    outline: none;
}

.rui-swiper .swiper-button-prev:hover, .rui-swiper .swiper-button-prev.hover,
.rui-swiper .swiper-button-next:hover,
.rui-swiper .swiper-button-next.hover {
    color: #393f49;
    background-color: #f8f9fa;
}

.rui-swiper .swiper-button-prev {
    left: -19px;
}

.rui-swiper .swiper-button-next {
    right: -19px;
}

.rui-swiper .swiper-slide {
    width: calc(17% - 30px);
    height: auto;
}

@media screen and (max-width: 1600px) and (min-width: 1200px) {
    .rui-swiper .swiper-slide {
        width: 20%;
    }
}

@media (min-width: 992px) and (max-width: 1199.98px) {
    .rui-swiper .swiper-slide {
        width: 30%;
    }
}

@media (min-width: 768px) and (max-width: 991.98px) {
    .rui-swiper .swiper-slide {
        width: 40%;
    }
}

@media (min-width: 576px) and (max-width: 767.98px) {
    .rui-swiper .swiper-slide {
        width: 60%;
    }
}

@media (max-width: 575.98px) {
    .rui-swiper .swiper-slide {
        width: 100%;
    }
}

.rui-swiper .rui-swiper-slide-total {
    width: calc(34% - 36px);
}

.rui-swiper .rui-swiper-slide-total > .rui-widget {
    height: 100%;
}

@media (min-width: 992px) and (max-width: 1199.98px) {
    .rui-swiper .rui-swiper-slide-total {
        width: 40%;
    }
}

@media (min-width: 768px) and (max-width: 991.98px) {
    .rui-swiper .rui-swiper-slide-total {
        width: 50%;
    }
}

@media (min-width: 576px) and (max-width: 767.98px) {
    .rui-swiper .rui-swiper-slide-total {
        width: 70%;
    }
}

@media (max-width: 575.98px) {
    .rui-swiper .rui-swiper-slide-total {
        width: 100%;
    }
}

/*---------------------------
    Plugin Fullcalendar
 ----------------------------*/
.rui-calendar .fc-event,
.fc-event-list .fc-event {
    padding: 4px 10px 6px;
    margin: 5px 5px 0;
    color: #6c757d;
    background-color: #fff;
    border: 1px solid #d7d9e0;
    border-left-width: 2px;
    border-radius: .2rem;
    -webkit-transition: border-color .1s ease-in-out;
    -o-transition: border-color .1s ease-in-out;
    transition: border-color .1s ease-in-out;
}

.rui-calendar .fc-event .fc-daygrid-event-dot,
.fc-event-list .fc-event .fc-daygrid-event-dot {
    display: none;
}

.rui-calendar .fc-event .fc-content .fc-title,
.fc-event-list .fc-event .fc-content .fc-title {
    font-size: 12px;
    color: #6c757d;
    -webkit-transition: color .1s ease-in-out;
    -o-transition: color .1s ease-in-out;
    transition: color .1s ease-in-out;
}

.rui-calendar .fc-event .fc-content .fc-time,
.fc-event-list .fc-event .fc-content .fc-time {
    display: inline-block;
    min-width: 20px;
    margin-right: 5px;
    font-size: 12px;
    font-weight: 400;
    color: #393f49;
}

.rui-calendar .fc-event:hover,
.fc-event-list .fc-event:hover {
    border-color: #d7d9e0;
}

.rui-calendar .fc-event:hover .fc-content .fc-title,
.fc-event-list .fc-event:hover .fc-content .fc-title {
    color: #393f49;
}

.rui-calendar .fc-event.fc-event-brand,
.fc-event-list .fc-event.fc-event-brand {
    border-left-color: #725ec3;
}

.rui-calendar .fc-event.fc-event-primary,
.fc-event-list .fc-event.fc-event-primary {
    border-left-color: #007bff;
}

.rui-calendar .fc-event.fc-event-secondary,
.fc-event-list .fc-event.fc-event-secondary {
    border-left-color: #6c757d;
}

.rui-calendar .fc-event.fc-event-success,
.fc-event-list .fc-event.fc-event-success {
    border-left-color: #2fc787;
}

.rui-calendar .fc-event.fc-event-danger,
.fc-event-list .fc-event.fc-event-danger {
    border-left-color: #ef5164;
}

.rui-calendar .fc-event.fc-event-warning,
.fc-event-list .fc-event.fc-event-warning {
    border-left-color: #fdbf21;
}

.rui-calendar .fc-event.fc-event-info,
.fc-event-list .fc-event.fc-event-info {
    border-left-color: #32bfc8;
}

.rui-calendar .fc-event.fc-event-light,
.fc-event-list .fc-event.fc-event-light {
    border-left-color: #f8f9fa;
}

.rui-calendar .fc-event.fc-event-dark,
.fc-event-list .fc-event.fc-event-dark {
    border-left-color: #393f49;
}

.fc-event-list .fc-event {
    margin-right: 0;
    margin-left: 0;
}

.rui-calendar {
    --fc-today-bg-color: #f8f9fa;
    --fc-border-color: #e6ecf0;
}

.rui-calendar tr:first-child > td > .fc-day-grid-event {
    margin-top: 13px;
}

.rui-calendar .fc-scrollgrid {
    border-radius: .25rem;
}

.rui-calendar .fc-toolbar h2 {
    margin-top: 10px;
    font-size: 1.29rem;
}

.rui-calendar .fc-toolbar.fc-header-toolbar {
    margin-bottom: 20px;
}

.rui-calendar .fc-toolbar .fc-button {
    display: -webkit-inline-box;
    display: -webkit-inline-flex;
    display: -ms-inline-flexbox;
    display: inline-flex;
    height: auto;
    padding: 10px 15px;
    font-size: 11px;
    font-weight: 700;
    color: #6c757d;
    text-transform: uppercase;
    background: none;
    background-color: #f8f9fa;
    border: 0;
    -webkit-box-shadow: none;
            box-shadow: none;
    -webkit-transition: background-color .15s ease-in-out, color .15s ease-in-out;
    -o-transition: background-color .15s ease-in-out, color .15s ease-in-out;
    transition: background-color .15s ease-in-out, color .15s ease-in-out;
}

.rui-calendar .fc-toolbar .fc-button > .rui-icon {
    margin: 0 -5px;
}

.rui-calendar .fc-toolbar .fc-button:hover, .rui-calendar .fc-toolbar .fc-button.fc-button-active {
    color: #393f49;
    background-color: #eaecf0;
}

@media (max-width: 575.98px) {
    .rui-calendar .fc-toolbar {
        display: -webkit-box;
        display: -webkit-flex;
        display: -ms-flexbox;
        display: flex;
        -webkit-box-orient: vertical;
        -webkit-box-direction: normal;
        -webkit-flex-direction: column;
            -ms-flex-direction: column;
                flex-direction: column;
    }
    .rui-calendar .fc-toolbar .fc-center {
        -webkit-box-ordinal-group: 1;
        -webkit-order: 0;
            -ms-flex-order: 0;
                order: 0;
        margin-bottom: 10px;
    }
    .rui-calendar .fc-toolbar .fc-left {
        -webkit-box-ordinal-group: 2;
        -webkit-order: 1;
            -ms-flex-order: 1;
                order: 1;
        margin-bottom: 10px;
    }
    .rui-calendar .fc-toolbar .fc-right {
        -webkit-box-ordinal-group: 3;
        -webkit-order: 2;
            -ms-flex-order: 2;
                order: 2;
    }
}

.rui-calendar a.fc-more,
.rui-calendar a.fc-more:not([href]):not([tabindex]) {
    display: block;
    margin-top: 13px;
    font-size: 12px;
    color: #bcbec0;
    text-align: center;
}

.rui-calendar a.fc-more:hover,
.rui-calendar a.fc-more:not([href]):not([tabindex]):hover {
    color: #6c757d;
}

.rui-calendar .fc-popover {
    border-radius: .25rem;
    -webkit-box-shadow: 0 3px 10px rgba(0, 0, 0, 0.03);
            box-shadow: 0 3px 10px rgba(0, 0, 0, 0.03);
}

.rui-calendar .fc-popover .fc-header {
    padding: 15px 20px;
    background: none;
}

.rui-calendar .fc-popover .fc-header + .fc-body {
    margin-top: -5px;
}

.rui-calendar .fc-popover .fc-header + .fc-body .fc-event-container {
    padding: 0 15px 20px;
}

.rui-calendar .fc-popover .fc-header .fc-title {
    margin: 0;
    color: #393f49;
}

.rui-calendar .fc-popover .fc-header .fc-close {
    display: -webkit-inline-box;
    display: -webkit-inline-flex;
    display: -ms-inline-flexbox;
    display: inline-flex;
    -webkit-box-align: center;
    -webkit-align-items: center;
        -ms-flex-align: center;
            align-items: center;
    -webkit-box-pack: center;
    -webkit-justify-content: center;
        -ms-flex-pack: center;
            justify-content: center;
    width: 16px;
    height: 16px;
    margin-top: 1px;
    margin-right: -5px;
    font-size: 9px;
    color: #bcbec0;
    -webkit-transition: color .15s ease-in-out;
    -o-transition: color .15s ease-in-out;
    transition: color .15s ease-in-out;
}

.rui-calendar .fc-popover .fc-header .fc-close:hover {
    color: #393f49;
}

.rui-calendar .fc-month-view .fc-content-skeleton,
.rui-calendar .fc-dayGridMonth-view .fc-content-skeleton {
    pointer-events: none;
}

.rui-calendar .fc-month-view .fc-content-skeleton .fc-day-grid-event,
.rui-calendar .fc-month-view .fc-content-skeleton .fc-more-cell > div > .fc-more,
.rui-calendar .fc-dayGridMonth-view .fc-content-skeleton .fc-day-grid-event,
.rui-calendar .fc-dayGridMonth-view .fc-content-skeleton .fc-more-cell > div > .fc-more {
    pointer-events: visible;
}

.rui-calendar .fc-month-view td,
.rui-calendar .fc-month-view th,
.rui-calendar .fc-dayGridMonth-view td,
.rui-calendar .fc-dayGridMonth-view th {
    -webkit-transition: background-color .1s ease-in-out;
    -o-transition: background-color .1s ease-in-out;
    transition: background-color .1s ease-in-out;
}

.rui-calendar .fc-month-view thead th,
.rui-calendar .fc-dayGridMonth-view thead th {
    padding: 15px;
    font-weight: 600;
}

@media (max-width: 575.98px) {
    .rui-calendar .fc-month-view thead th,
    .rui-calendar .fc-dayGridMonth-view thead th {
        padding: 10px 15px;
    }
}

.rui-calendar .fc-month-view .fc-scrollgrid-sync-table tbody td > span,
.rui-calendar .fc-month-view tbody td > span,
.rui-calendar .fc-dayGridMonth-view .fc-scrollgrid-sync-table tbody td > span,
.rui-calendar .fc-dayGridMonth-view tbody td > span {
    display: block;
    text-align: center;
}

.rui-calendar .fc-month-view .fc-daygrid-day-number,
.rui-calendar .fc-dayGridMonth-view .fc-daygrid-day-number {
    margin-left: auto;
    margin-right: auto;
    padding-top: 3px;
    color: #6c757d;
}

.rui-calendar .fc-agenda-view > table,
.rui-calendar .fc-timeGridDay-view > table {
    width: calc(100% + 1px);
}

.rui-calendar .fc-agenda-view thead th,
.rui-calendar .fc-agenda-view thead .fc-row,
.rui-calendar .fc-timeGridDay-view thead th,
.rui-calendar .fc-timeGridDay-view thead .fc-row {
    border-color: transparent;
}

.rui-calendar .fc-agenda-view thead .fc-day-header,
.rui-calendar .fc-timeGridDay-view thead .fc-day-header {
    padding: 15px 0;
    font-weight: 600;
}

@media (max-width: 575.98px) {
    .rui-calendar .fc-agenda-view thead .fc-day-header,
    .rui-calendar .fc-timeGridDay-view thead .fc-day-header {
        padding: 10px 0;
    }
}

.rui-calendar .fc-agenda-view tbody > tr > td,
.rui-calendar .fc-timeGridDay-view tbody > tr > td {
    border: 0;
}

.rui-calendar .fc-agenda-view tbody td,
.rui-calendar .fc-agenda-view tbody .fc-event,
.rui-calendar .fc-timeGridDay-view tbody td,
.rui-calendar .fc-timeGridDay-view tbody .fc-event {
    margin: 5px;
}

.rui-calendar .fc-agenda-view .fc-widget-content,
.rui-calendar .fc-timeGridDay-view .fc-widget-content {
    height: 18px;
}

.rui-calendar .fc-agenda-view .fc-day-grid .fc-row,
.rui-calendar .fc-timeGridDay-view .fc-day-grid .fc-row {
    min-height: 47px;
    border-right-color: transparent;
}

.rui-calendar .fc-agenda-view .fc-bg .fc-axis,
.rui-calendar .fc-timeGridDay-view .fc-bg .fc-axis {
    padding-top: 8px;
    vertical-align: top;
}

.rui-calendar .fc-agenda-view .fc-bg .fc-axis,
.rui-calendar .fc-agenda-view .fc-slats .fc-axis,
.rui-calendar .fc-timeGridDay-view .fc-bg .fc-axis,
.rui-calendar .fc-timeGridDay-view .fc-slats .fc-axis {
    font-size: 13px;
    color: #bcbec0;
}

.rui-calendar .fc-agenda-view .fc-event,
.rui-calendar .fc-timeGridDay-view .fc-event {
    padding: 7px 10px 8px;
}

.rui-calendar .fc-agenda-view .fc-event .fc-content .fc-time,
.rui-calendar .fc-timeGridDay-view .fc-event .fc-content .fc-time {
    margin-right: 0;
    margin-bottom: 5px;
}

.rui-calendar .fc-agenda-view .fc-divider,
.rui-calendar .fc-timeGridDay-view .fc-divider {
    display: none;
}

.rui-calendar .fc-agenda-view td.fc-today,
.rui-calendar .fc-timeGridDay-view td.fc-today {
    background-color: #fff;
}

.rui-calendar .fc-list {
    --fc-list-event-hover-bg-color: transparent;
    --fc-neutral-bg-color: transparent;
    padding: 0 0 0 25px;
}

.rui-calendar .fc-list .fc-scroller {
    padding: 0 30px 30px 0;
}

.rui-calendar .fc-list .fc-list-empty {
    background-color: #fff;
}

.rui-calendar .fc-list .fc-list-heading .fc-widget-header {
    padding: 45px 0 18px;
    background: none;
    border-color: transparent;
}

.rui-calendar .fc-list .fc-list-heading:first-child .fc-widget-header {
    padding: 24px 0 18px;
}

.rui-calendar .fc-list .fc-list-day-cushion {
    margin-top: 25px;
}

.rui-calendar .fc-list .fc-list-day > th,
.rui-calendar .fc-list .fc-list-event > td {
    --fc-border-color: transparent;
}

.rui-calendar .fc-list .fc-list-event + .fc-list-event > td {
    padding-top: 18px;
}

.rui-calendar .fc-list .fc-list-event.fc-event-brand .fc-list-event-dot {
    border-color: #725ec3;
}

.rui-calendar .fc-list .fc-list-event.fc-event-primary .fc-list-event-dot {
    border-color: #007bff;
}

.rui-calendar .fc-list .fc-list-event.fc-event-secondary .fc-list-event-dot {
    border-color: #6c757d;
}

.rui-calendar .fc-list .fc-list-event.fc-event-success .fc-list-event-dot {
    border-color: #2fc787;
}

.rui-calendar .fc-list .fc-list-event.fc-event-danger .fc-list-event-dot {
    border-color: #ef5164;
}

.rui-calendar .fc-list .fc-list-event.fc-event-warning .fc-list-event-dot {
    border-color: #fdbf21;
}

.rui-calendar .fc-list .fc-list-event.fc-event-info .fc-list-event-dot {
    border-color: #32bfc8;
}

.rui-calendar .fc-list .fc-list-event.fc-event-light .fc-list-event-dot {
    border-color: #f8f9fa;
}

.rui-calendar .fc-list .fc-list-event.fc-event-dark .fc-list-event-dot {
    border-color: #393f49;
}

.rui-calendar .fc-list .fc-list-event .fc-list-event-time {
    color: #bcbec0;
}

.rui-calendar .fc-list .fc-list-event .fc-list-event-title {
    color: #6c757d;
    -webkit-transition: color .1s ease-in-out;
    -o-transition: color .1s ease-in-out;
    transition: color .1s ease-in-out;
}

.rui-calendar .fc-list .fc-list-event .fc-list-event-marker {
    padding-right: 15px;
    padding-left: 15px;
}

.rui-calendar .fc-list .fc-list-event:hover .fc-list-event-title {
    color: #393f49;
}

/*---------------------------
    Plugin Fancybox
 ----------------------------*/
.fancybox-slide--html {
    display: -webkit-box;
    display: -webkit-flex;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-align: center;
    -webkit-align-items: center;
        -ms-flex-align: center;
            align-items: center;
    -webkit-box-pack: center;
    -webkit-justify-content: center;
        -ms-flex-pack: center;
            justify-content: center;
    padding: 0;
}

.fancybox-container .fancybox-bg {
    background-color: rgba(50, 57, 66, 0.9);
    opacity: 0;
    will-change: opacity;
    -webkit-transition: opacity .25s ease-in-out;
    -o-transition: opacity .25s ease-in-out;
    transition: opacity .25s ease-in-out;
}

.fancybox-is-open .fancybox-bg {
    opacity: 1;
}

.fancybox-toolbar {
    top: 10px;
    right: 10px;
}

.fancybox-toolbar > .fancybox-button {
    background-color: #fff;
    border-radius: 0;
}

.fancybox-toolbar > .fancybox-button:hover {
    background-color: #fff;
}

.fancybox-toolbar > .fancybox-button:first-of-type {
    border-radius: .25rem 0 0 .25rem;
}

.fancybox-toolbar > .fancybox-button:last-of-type {
    border-radius: 0 .25rem .25rem 0;
}

.fancybox-button {
    color: #6c757d;
    background: none;
    -webkit-transition: color .15s ease-in-out;
    -o-transition: color .15s ease-in-out;
    transition: color .15s ease-in-out;
}

.fancybox-button > div {
    background-color: #fff;
    border-radius: .25rem;
}

.fancybox-button:hover {
    color: #393f49;
}

.fancybox-button:hover > div {
    background-color: #fff;
}

/*---------------------------
    Plugin Jstree
 ----------------------------*/
.rui-jstree .jstree-icon:not(.jstree-ocl) {
    display: none;
}

.rui-jstree .jstree-ocl,
.rui-jstree .jstree-node {
    background: none;
}

.rui-jstree .jstree-ocl {
    position: relative;
    margin-right: -24px;
    cursor: pointer;
    z-index: 1;
}

.rui-jstree .jstree-ocl:hover + .jstree-anchor > .rui-icon {
    stroke: #393f49;
}

.rui-jstree .jstree-node {
    margin-left: 30px;
}

.rui-jstree .jstree-node.jstree-open > .jstree-anchor > .rui-jstree-icon-open {
    opacity: 0;
}

.rui-jstree .jstree-node.jstree-open > .jstree-anchor > .rui-jstree-icon-close {
    opacity: 1;
}

.rui-jstree .jstree-node.jstree-closed > .jstree-anchor > .rui-jstree-icon-open {
    opacity: 1;
}

.rui-jstree .jstree-node.jstree-closed > .jstree-anchor > .rui-jstree-icon-close {
    opacity: 0;
}

.rui-jstree .rui-jstree-icon {
    -webkit-transition: opacity .15s ease-in-out;
    -o-transition: opacity .15s ease-in-out;
    transition: opacity .15s ease-in-out;
}

.rui-jstree .rui-jstree-icon-open {
    opacity: 1;
}

.rui-jstree .rui-jstree-icon-close {
    position: absolute;
    opacity: 0;
}

.rui-jstree .jstree-anchor {
    display: -webkit-inline-box;
    display: -webkit-inline-flex;
    display: -ms-inline-flexbox;
    display: inline-flex;
    -webkit-box-align: center;
    -webkit-align-items: center;
        -ms-flex-align: center;
            align-items: center;
}

.rui-jstree .jstree-anchor + .jstree-children {
    padding-top: 5px;
}

.rui-jstree .jstree-anchor.jstree-clicked, .rui-jstree .jstree-anchor.jstree-hovered {
    background: none;
    -webkit-box-shadow: none;
            box-shadow: none;
}

.rui-jstree .jstree-anchor > .rui-icon {
    margin-right: 14px;
    stroke: #bcbec0;
    -webkit-transition: stroke .1s ease-in-out;
    -o-transition: stroke .1s ease-in-out;
    transition: stroke .1s ease-in-out;
}

.rui-jstree .jstree-anchor:hover {
    background: none;
    -webkit-box-shadow: none;
            box-shadow: none;
}

.rui-jstree .jstree-anchor:hover > .rui-icon {
    stroke: #393f49;
}

.rui-jstree .jstree-open > .jstree-ocl:hover + .jstree-anchor > .rui-icon {
    stroke: #725ec3;
}

.rui-jstree .jstree-open > .jstree-anchor {
    color: #725ec3;
}

.rui-jstree .jstree-open > .jstree-anchor > .rui-icon {
    stroke: #725ec3;
}

.rui-jstree .jstree-children .jstree-node + .jstree-node {
    padding-top: 5px;
}

.rui-jstree-lines .jstree-children .jstree-children .jstree-node {
    position: relative;
}

.rui-jstree-lines .jstree-children .jstree-children .jstree-node + .jstree-node::before {
    top: -12px;
    height: 29px;
}

.rui-jstree-lines .jstree-children .jstree-children .jstree-node::before {
    content: "";
    position: absolute;
    top: 3px;
    left: -21px;
    width: 10px;
    height: 10px;
    border-bottom: 1px solid #e6ecf0;
    border-left: 1px solid #e6ecf0;
}

/*---------------------------
    Plugin Sweetalert
 ----------------------------*/
.swal2-container.swal2-shown {
    background-color: rgba(50, 57, 66, 0.9);
}

.swal2-popup {
    padding: 30px;
    -webkit-box-shadow: 0 3px 10px rgba(0, 0, 0, 0.03);
            box-shadow: 0 3px 10px rgba(0, 0, 0, 0.03);
    z-index: 1;
}

.swal2-popup .swal2-icon {
    margin-top: 0;
}

.swal2-popup .swal2-title {
    margin-bottom: 1.4rem;
    font-size: 1.43rem;
    font-weight: 400;
    color: #393f49;
    text-transform: capitalize;
}

.swal2-popup .swal2-type {
    text-transform: capitalize;
}

.swal2-popup .swal2-content {
    font-size: 1rem;
    color: #4b515b;
}

.swal2-popup .swal2-styled {
    min-width: 100px;
    padding: 9.5px 15px;
    margin: 8px 0 0;
}

.swal2-popup .swal2-styled.swal2-confirm {
    font-size: 13px;
    background-color: #725ec3;
    border-radius: .25rem;
    -webkit-transition: background-color .1s ease-in-out, -webkit-box-shadow .1s ease-in-out;
    transition: background-color .1s ease-in-out, -webkit-box-shadow .1s ease-in-out;
    -o-transition: background-color .1s ease-in-out, box-shadow .1s ease-in-out;
    transition: background-color .1s ease-in-out, box-shadow .1s ease-in-out;
    transition: background-color .1s ease-in-out, box-shadow .1s ease-in-out, -webkit-box-shadow .1s ease-in-out;
}

.swal2-popup .swal2-styled.swal2-confirm:hover {
    background-color: #5641ad;
}

.swal2-popup .swal2-styled.swal2-confirm:focus {
    -webkit-box-shadow: 0 0 0 0.2rem rgba(114, 94, 195, 0.5);
            box-shadow: 0 0 0 0.2rem rgba(114, 94, 195, 0.5);
}

.swal2-popup .swal2-actions:not(.swal2-loading) .swal2-styled:hover {
    background-image: none;
}

.swal2-icon.swal2-brand {
    color: #725ec3;
    border-color: rgba(114, 94, 195, 0.3);
}

.swal2-icon.swal2-brand [class^=swal2-brand-line] {
    background-color: #725ec3;
}

.swal2-icon.swal2-brand .swal2-success-ring {
    border-color: rgba(114, 94, 195, 0.3);
}

.swal2-icon.swal2-error {
    border-color: rgba(239, 81, 100, 0.3);
}

.swal2-icon.swal2-error [class^="swal2-x-mark-line"] {
    background-color: #ef5164;
}

.swal2-icon.swal2-error .swal2-success-ring {
    border-color: rgba(239, 81, 100, 0.3);
}

.swal2-icon.swal2-primary {
    color: #007bff;
    border-color: rgba(0, 123, 255, 0.3);
}

.swal2-icon.swal2-primary [class^=swal2-primary-line] {
    background-color: #007bff;
}

.swal2-icon.swal2-primary .swal2-success-ring {
    border-color: rgba(0, 123, 255, 0.3);
}

.swal2-icon.swal2-error {
    border-color: rgba(239, 81, 100, 0.3);
}

.swal2-icon.swal2-error [class^="swal2-x-mark-line"] {
    background-color: #ef5164;
}

.swal2-icon.swal2-error .swal2-success-ring {
    border-color: rgba(239, 81, 100, 0.3);
}

.swal2-icon.swal2-secondary {
    color: #6c757d;
    border-color: rgba(108, 117, 125, 0.3);
}

.swal2-icon.swal2-secondary [class^=swal2-secondary-line] {
    background-color: #6c757d;
}

.swal2-icon.swal2-secondary .swal2-success-ring {
    border-color: rgba(108, 117, 125, 0.3);
}

.swal2-icon.swal2-error {
    border-color: rgba(239, 81, 100, 0.3);
}

.swal2-icon.swal2-error [class^="swal2-x-mark-line"] {
    background-color: #ef5164;
}

.swal2-icon.swal2-error .swal2-success-ring {
    border-color: rgba(239, 81, 100, 0.3);
}

.swal2-icon.swal2-success {
    color: #2fc787;
    border-color: rgba(47, 199, 135, 0.3);
}

.swal2-icon.swal2-success [class^=swal2-success-line] {
    background-color: #2fc787;
}

.swal2-icon.swal2-success .swal2-success-ring {
    border-color: rgba(47, 199, 135, 0.3);
}

.swal2-icon.swal2-error {
    border-color: rgba(239, 81, 100, 0.3);
}

.swal2-icon.swal2-error [class^="swal2-x-mark-line"] {
    background-color: #ef5164;
}

.swal2-icon.swal2-error .swal2-success-ring {
    border-color: rgba(239, 81, 100, 0.3);
}

.swal2-icon.swal2-danger {
    color: #ef5164;
    border-color: rgba(239, 81, 100, 0.3);
}

.swal2-icon.swal2-danger [class^=swal2-danger-line] {
    background-color: #ef5164;
}

.swal2-icon.swal2-danger .swal2-success-ring {
    border-color: rgba(239, 81, 100, 0.3);
}

.swal2-icon.swal2-error {
    border-color: rgba(239, 81, 100, 0.3);
}

.swal2-icon.swal2-error [class^="swal2-x-mark-line"] {
    background-color: #ef5164;
}

.swal2-icon.swal2-error .swal2-success-ring {
    border-color: rgba(239, 81, 100, 0.3);
}

.swal2-icon.swal2-warning {
    color: #fdbf21;
    border-color: rgba(253, 191, 33, 0.3);
}

.swal2-icon.swal2-warning [class^=swal2-warning-line] {
    background-color: #fdbf21;
}

.swal2-icon.swal2-warning .swal2-success-ring {
    border-color: rgba(253, 191, 33, 0.3);
}

.swal2-icon.swal2-error {
    border-color: rgba(239, 81, 100, 0.3);
}

.swal2-icon.swal2-error [class^="swal2-x-mark-line"] {
    background-color: #ef5164;
}

.swal2-icon.swal2-error .swal2-success-ring {
    border-color: rgba(239, 81, 100, 0.3);
}

.swal2-icon.swal2-info {
    color: #32bfc8;
    border-color: rgba(50, 191, 200, 0.3);
}

.swal2-icon.swal2-info [class^=swal2-info-line] {
    background-color: #32bfc8;
}

.swal2-icon.swal2-info .swal2-success-ring {
    border-color: rgba(50, 191, 200, 0.3);
}

.swal2-icon.swal2-error {
    border-color: rgba(239, 81, 100, 0.3);
}

.swal2-icon.swal2-error [class^="swal2-x-mark-line"] {
    background-color: #ef5164;
}

.swal2-icon.swal2-error .swal2-success-ring {
    border-color: rgba(239, 81, 100, 0.3);
}

.swal2-icon.swal2-light {
    color: #f8f9fa;
    border-color: rgba(248, 249, 250, 0.3);
}

.swal2-icon.swal2-light [class^=swal2-light-line] {
    background-color: #f8f9fa;
}

.swal2-icon.swal2-light .swal2-success-ring {
    border-color: rgba(248, 249, 250, 0.3);
}

.swal2-icon.swal2-error {
    border-color: rgba(239, 81, 100, 0.3);
}

.swal2-icon.swal2-error [class^="swal2-x-mark-line"] {
    background-color: #ef5164;
}

.swal2-icon.swal2-error .swal2-success-ring {
    border-color: rgba(239, 81, 100, 0.3);
}

.swal2-icon.swal2-dark {
    color: #393f49;
    border-color: rgba(57, 63, 73, 0.3);
}

.swal2-icon.swal2-dark [class^=swal2-dark-line] {
    background-color: #393f49;
}

.swal2-icon.swal2-dark .swal2-success-ring {
    border-color: rgba(57, 63, 73, 0.3);
}

.swal2-icon.swal2-error {
    border-color: rgba(239, 81, 100, 0.3);
}

.swal2-icon.swal2-error [class^="swal2-x-mark-line"] {
    background-color: #ef5164;
}

.swal2-icon.swal2-error .swal2-success-ring {
    border-color: rgba(239, 81, 100, 0.3);
}

/*---------------------------
    Plugin Jqvmap
 ----------------------------*/
.rui-jqvmap {
    width: 100%;
    min-height: 389px;
    border-radius: .25rem;
}

@media (max-width: 767.98px) {
    .rui-jqvmap {
        min-height: 300px;
    }
}

.rui-jqvmap .jqvmap-zoomin,
.rui-jqvmap .jqvmap-zoomout {
    top: 15px;
    left: 15px;
    width: 24px;
    height: 24px;
    line-height: 18px;
    color: #6c757d;
    background-color: #eaecf0;
    -webkit-transition: color .1s ease-in-out, background-color .1s ease-in-out;
    -o-transition: color .1s ease-in-out, background-color .1s ease-in-out;
    transition: color .1s ease-in-out, background-color .1s ease-in-out;
}

.rui-jqvmap .jqvmap-zoomin:hover,
.rui-jqvmap .jqvmap-zoomout:hover {
    color: #393f49;
    background-color: #d7d9e0;
}

.rui-jqvmap .jqvmap-zoomout {
    top: 45px;
}

.rui-jqvmap .jqvmap-region {
    -webkit-transition: fill .1s ease-in-out;
    -o-transition: fill .1s ease-in-out;
    transition: fill .1s ease-in-out;
}

.jqvmap-label {
    padding: 1px 5px;
    background-color: #393f49;
    border-radius: .2rem;
    z-index: 3;
}

/*---------------------------
    Plugin Datatable
 ----------------------------*/
.rui-datatable thead th {
    cursor: pointer;
    -webkit-transition: color .15s ease-in-out;
    -o-transition: color .15s ease-in-out;
    transition: color .15s ease-in-out;
}

.rui-datatable thead th.sorting_asc > .rui-icon,
.rui-datatable thead th.sorting_desc > .rui-icon {
    stroke: #6c757d;
}

.rui-datatable thead th.sorting_desc > .rui-icon {
    -webkit-transform: rotate(180deg);
        -ms-transform: rotate(180deg);
            transform: rotate(180deg);
}

.rui-datatable thead th > .rui-icon {
    stroke: #bcbec0;
    margin-top: -2px;
    margin-left: 7px;
    -webkit-transition: stroke .15s ease-in-out, -webkit-transform .15s ease-in-out;
    transition: stroke .15s ease-in-out, -webkit-transform .15s ease-in-out;
    -o-transition: stroke .15s ease-in-out, transform .15s ease-in-out;
    transition: stroke .15s ease-in-out, transform .15s ease-in-out;
    transition: stroke .15s ease-in-out, transform .15s ease-in-out, -webkit-transform .15s ease-in-out;
}

.rui-datatable thead th:focus {
    outline: none;
}

.rui-datatable thead .sorting,
.rui-datatable thead .sorting_desc {
    background-image: none !important;
}

.rui-datatable .rui-datatable-empty {
    visibility: hidden;
}

.dataTables_wrapper {
    position: relative;
    padding-bottom: 10px;
}

.dataTables_length {
    display: inline-block;
    margin-bottom: 25px;
}

.dataTables_length > label {
    margin-bottom: 0;
    font-size: 0;
}

.dataTables_length select {
    min-height: 36px;
    padding: 7px 15px;
    margin: 0;
    font-size: 1rem;
    color: #6c757d;
    background-color: #fbfcfc;
    border: 1px solid #e6ecf0;
    border-radius: .2rem;
    -webkit-transition: border-color .1s ease-in-out, -webkit-box-shadow .1s ease-in-out;
    transition: border-color .1s ease-in-out, -webkit-box-shadow .1s ease-in-out;
    -o-transition: box-shadow .1s ease-in-out, border-color .1s ease-in-out;
    transition: box-shadow .1s ease-in-out, border-color .1s ease-in-out;
    transition: box-shadow .1s ease-in-out, border-color .1s ease-in-out, -webkit-box-shadow .1s ease-in-out;
}

.dataTables_length select:focus {
    background-color: #fbfcfc;
    border-color: rgba(114, 94, 195, 0.6);
    outline: none;
    -webkit-box-shadow: 0 0 0 0.2rem rgba(114, 94, 195, 0.2);
            box-shadow: 0 0 0 0.2rem rgba(114, 94, 195, 0.2);
}

.dataTables_filter {
    position: absolute;
    display: inline-block;
    top: 0;
    right: 0;
}

.dataTables_filter > label {
    margin-bottom: 0;
    color: #6c757d;
}

.dataTables_filter input {
    min-height: 36px;
    padding: 7.5px 19px;
    margin-left: 19px;
    background-color: #fbfcfc;
    border: 1px solid #e6ecf0;
    border-radius: .2rem;
    -webkit-transition: border-color .1s ease-in-out, -webkit-box-shadow .1s ease-in-out;
    transition: border-color .1s ease-in-out, -webkit-box-shadow .1s ease-in-out;
    -o-transition: box-shadow .1s ease-in-out, border-color .1s ease-in-out;
    transition: box-shadow .1s ease-in-out, border-color .1s ease-in-out;
    transition: box-shadow .1s ease-in-out, border-color .1s ease-in-out, -webkit-box-shadow .1s ease-in-out;
}

.dataTables_filter input:focus {
    background-color: #fbfcfc;
    border-color: rgba(114, 94, 195, 0.6);
    outline: none;
    -webkit-box-shadow: 0 0 0 0.2rem rgba(114, 94, 195, 0.2);
            box-shadow: 0 0 0 0.2rem rgba(114, 94, 195, 0.2);
}

.dataTables_info {
    margin-top: 13px;
    color: #bcbec0;
}

.dataTables_paginate {
    position: absolute;
    display: inline-block;
    right: -5px;
    bottom: 0;
}

.dataTables_paginate .paginate_button {
    display: -webkit-inline-box;
    display: -webkit-inline-flex;
    display: -ms-inline-flexbox;
    display: inline-flex;
    -webkit-box-align: center;
    -webkit-align-items: center;
        -ms-flex-align: center;
            align-items: center;
    -webkit-box-pack: center;
    -webkit-justify-content: center;
        -ms-flex-pack: center;
            justify-content: center;
    min-width: 36px;
    padding: 10px;
    margin: 0 5px;
    font-size: 11px;
    font-weight: 700;
    color: #a4a6a8;
    text-transform: uppercase;
    cursor: pointer;
    background-color: #f8f9fa;
    border: 0;
    border-radius: .25rem;
    -webkit-transition: background-color .15s ease-in-out, color .15s ease-in-out;
    -o-transition: background-color .15s ease-in-out, color .15s ease-in-out;
    transition: background-color .15s ease-in-out, color .15s ease-in-out;
}

.dataTables_paginate .paginate_button.previous, .dataTables_paginate .paginate_button.next {
    padding-right: 15px;
    padding-left: 15px;
}

.dataTables_paginate .paginate_button:hover {
    color: #6c757d;
    text-decoration: none;
    background-color: #f3f4f7;
}

.dataTables_paginate .paginate_button:focus {
    color: #6c757d;
    background-color: #f3f4f7;
    outline: none;
    -webkit-box-shadow: none;
            box-shadow: none;
}

.dataTables_paginate .paginate_button:active {
    background-color: #eaecf0;
}

.dataTables_paginate .paginate_button.current {
    color: #fff;
    background-color: #725ec3;
}

.dataTables_paginate .paginate_button.disabled {
    color: #d7d9e0;
    pointer-events: none;
    cursor: auto;
    background-color: #f8f9fa;
}

/*---------------------------
    Plugin DateTime Picker
 ----------------------------*/
.xdsoft_datetimepicker {
    padding: 28px 20px 25px 21px;
    font-family: "Open Sans", sans-serif;
    background-color: #fff;
    border: 1px solid #e6ecf0;
    border-radius: .25rem;
    -webkit-box-shadow: none;
            box-shadow: none;
    -webkit-box-shadow: 0 3px 10px rgba(0, 0, 0, 0.03);
            box-shadow: 0 3px 10px rgba(0, 0, 0, 0.03);
    z-index: 10;
}

.xdsoft_datetimepicker .xdsoft_calendar {
    width: auto;
}

.xdsoft_datetimepicker .xdsoft_calendar thead th {
    padding-top: 11px;
    padding-bottom: 11px;
    font-weight: 600;
    color: #4b515b;
    background-color: transparent;
    border: 0;
}

.xdsoft_datetimepicker .xdsoft_datepicker {
    width: auto;
    margin-top: -10px;
    margin-bottom: -10px;
    margin-left: 0;
}

.xdsoft_datetimepicker .xdsoft_datepicker.active + .xdsoft_timepicker {
    margin: -10px 0 0 9px;
}

.xdsoft_datetimepicker .xdsoft_monthpicker {
    position: relative;
    display: -webkit-box;
    display: -webkit-flex;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-align: center;
    -webkit-align-items: center;
        -ms-flex-align: center;
            align-items: center;
}

.xdsoft_datetimepicker .xdsoft_monthpicker .xdsoft_prev,
.xdsoft_datetimepicker .xdsoft_monthpicker .xdsoft_next,
.xdsoft_datetimepicker .xdsoft_monthpicker .xdsoft_today_button,
.xdsoft_datetimepicker .xdsoft_monthpicker .xdsoft_label {
    float: none;
}

.xdsoft_datetimepicker .xdsoft_monthpicker .xdsoft_prev,
.xdsoft_datetimepicker .xdsoft_monthpicker .xdsoft_next,
.xdsoft_datetimepicker .xdsoft_monthpicker .xdsoft_today_button {
    display: -webkit-inline-box;
    display: -webkit-inline-flex;
    display: -ms-inline-flexbox;
    display: inline-flex;
    -webkit-box-align: center;
    -webkit-align-items: center;
        -ms-flex-align: center;
            align-items: center;
    -webkit-box-pack: center;
    -webkit-justify-content: center;
        -ms-flex-pack: center;
            justify-content: center;
    width: 34px;
    min-width: 34px;
    height: 34px;
    color: #bcbec0;
    text-indent: 0;
    background-image: none;
    border-radius: .2rem;
    opacity: 1;
    -webkit-transition: color .1s ease-in-out;
    -o-transition: color .1s ease-in-out;
    transition: color .1s ease-in-out;
}

.xdsoft_datetimepicker .xdsoft_monthpicker .xdsoft_prev:hover,
.xdsoft_datetimepicker .xdsoft_monthpicker .xdsoft_next:hover,
.xdsoft_datetimepicker .xdsoft_monthpicker .xdsoft_today_button:hover {
    color: #393f49;
}

.xdsoft_datetimepicker .xdsoft_monthpicker .xdsoft_next {
    margin-left: auto;
}

.xdsoft_datetimepicker .xdsoft_monthpicker .xdsoft_next > .rui-icon {
    -webkit-transform: rotate(180deg);
        -ms-transform: rotate(180deg);
            transform: rotate(180deg);
}

.xdsoft_datetimepicker .xdsoft_monthpicker .xdsoft_today_button {
    margin-right: auto;
    margin-left: 0;
}

.xdsoft_datetimepicker .xdsoft_monthpicker .xdsoft_label {
    padding: 0;
    font-weight: 400;
    color: #393f49;
}

.xdsoft_datetimepicker .xdsoft_monthpicker .xdsoft_label > i {
    display: none;
}

.xdsoft_datetimepicker .xdsoft_monthpicker .xdsoft_label:hover > span {
    text-decoration: none;
}

.xdsoft_datetimepicker .xdsoft_monthpicker .xdsoft_month {
    width: auto;
}

.xdsoft_datetimepicker .xdsoft_monthpicker .xdsoft_year {
    width: auto;
    margin-left: 8px;
}

.xdsoft_datetimepicker .xdsoft_label > .xdsoft_select {
    top: -5px;
    max-height: 257px;
    padding: 0 5px;
    border: 1px solid #e6ecf0;
    border-radius: .25rem;
    -webkit-box-shadow: 0 3px 10px rgba(0, 0, 0, 0.03);
            box-shadow: 0 3px 10px rgba(0, 0, 0, 0.03);
}

.xdsoft_datetimepicker .xdsoft_label > .xdsoft_select.xdsoft_yearselect {
    right: auto;
    left: 100%;
    margin-left: 10px;
}

.xdsoft_datetimepicker .xdsoft_label > .xdsoft_select.xdsoft_monthselect {
    right: 100%;
    margin-right: 10px;
}

.xdsoft_datetimepicker .xdsoft_label > .xdsoft_select > div {
    padding-top: 3px;
    padding-bottom: 3px;
}

.xdsoft_datetimepicker .xdsoft_label > .xdsoft_select > div > .xdsoft_option {
    position: relative;
    padding: 4px 8px;
    font-size: 12px;
    color: #6c757d;
    -webkit-transition: color .1s ease-in-out;
    -o-transition: color .1s ease-in-out;
    transition: color .1s ease-in-out;
}

.xdsoft_datetimepicker .xdsoft_label > .xdsoft_select > div > .xdsoft_option::before {
    content: "";
    position: absolute;
    top: 2px;
    right: 0;
    bottom: 2px;
    left: 0;
    border-radius: .2rem;
    -webkit-transition: background-color .1s ease-in-out;
    -o-transition: background-color .1s ease-in-out;
    transition: background-color .1s ease-in-out;
    z-index: -1;
}

.xdsoft_datetimepicker .xdsoft_label > .xdsoft_select > div > .xdsoft_option:hover {
    color: #393f49 !important;
    background-color: transparent !important;
}

.xdsoft_datetimepicker .xdsoft_label > .xdsoft_select > div > .xdsoft_option:hover::before {
    background-color: #f3f4f7;
}

.xdsoft_datetimepicker .xdsoft_label > .xdsoft_select > div > .xdsoft_option.xdsoft_current {
    font-weight: 400;
    color: #fff !important;
    background-color: transparent !important;
    -webkit-box-shadow: none;
            box-shadow: none;
}

.xdsoft_datetimepicker .xdsoft_label > .xdsoft_select > div > .xdsoft_option.xdsoft_current::before {
    background-color: rgba(114, 94, 195, 0.8);
}

.xdsoft_datetimepicker .xdsoft_label > .xdsoft_select .xdsoft_scrollbar {
    width: 4px;
    opacity: 0;
    -webkit-transition: opacity .1s ease-in-out;
    -o-transition: opacity .1s ease-in-out;
    transition: opacity .1s ease-in-out;
}

.xdsoft_datetimepicker .xdsoft_label > .xdsoft_select .xdsoft_scroller {
    padding: 0;
    background-color: #eaecf0 !important;
}

.xdsoft_datetimepicker .xdsoft_label > .xdsoft_select .xdsoft_scroller:hover {
    background-color: #eaecf0 !important;
}

.xdsoft_datetimepicker .xdsoft_label > .xdsoft_select:hover .xdsoft_scrollbar {
    opacity: 1;
}

.xdsoft_datetimepicker .xdsoft_timepicker {
    position: relative;
    width: auto;
    padding-top: 34px;
    margin-bottom: 5px;
    margin-left: 0;
}

.xdsoft_datetimepicker .xdsoft_timepicker.active {
    margin-top: -10px;
    margin-bottom: -10px;
}

.xdsoft_datetimepicker .xdsoft_timepicker .xdsoft_time_box {
    height: 228px;
    margin-top: 2px;
    border: 0;
}

.xdsoft_datetimepicker .xdsoft_timepicker .xdsoft_time_box:hover .xdsoft_scrollbar {
    opacity: 1;
}

.xdsoft_datetimepicker .xdsoft_timepicker .xdsoft_time_box .xdsoft_scrollbar {
    width: 4px;
    opacity: 0;
    -webkit-transition: opacity .1s ease-in-out;
    -o-transition: opacity .1s ease-in-out;
    transition: opacity .1s ease-in-out;
}

.xdsoft_datetimepicker .xdsoft_timepicker .xdsoft_time_box .xdsoft_scroller {
    padding: 0;
    background-color: #eaecf0 !important;
}

.xdsoft_datetimepicker .xdsoft_timepicker .xdsoft_time_box .xdsoft_scroller:hover {
    background-color: #eaecf0 !important;
}

.xdsoft_datetimepicker .xdsoft_timepicker .xdsoft_time_box > div > div {
    position: relative;
    display: -webkit-box;
    display: -webkit-flex;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-align: center;
    -webkit-align-items: center;
        -ms-flex-align: center;
            align-items: center;
    -webkit-box-pack: center;
    -webkit-justify-content: center;
        -ms-flex-pack: center;
            justify-content: center;
    height: 38px;
    padding: 0 10px;
    color: #6c757d;
    background-color: transparent;
    border: 0;
    border-radius: .2rem;
    -webkit-transition: background-color .1s ease-in-out, color .1s ease-in-out;
    -o-transition: background-color .1s ease-in-out, color .1s ease-in-out;
    transition: background-color .1s ease-in-out, color .1s ease-in-out;
}

.xdsoft_datetimepicker .xdsoft_timepicker .xdsoft_time_box > div > div::before {
    content: "";
    position: absolute;
    top: 4px;
    right: 0;
    bottom: 4px;
    left: 0;
    border-radius: .2rem;
    -webkit-transition: background-color .1s ease-in-out;
    -o-transition: background-color .1s ease-in-out;
    transition: background-color .1s ease-in-out;
    z-index: -1;
}

.xdsoft_datetimepicker .xdsoft_timepicker .xdsoft_time_box > div > div:hover {
    color: #393f49 !important;
    background-color: transparent !important;
}

.xdsoft_datetimepicker .xdsoft_timepicker .xdsoft_time_box > div > div:hover::before {
    background-color: #f3f4f7;
}

.xdsoft_datetimepicker .xdsoft_timepicker .xdsoft_time_box > div > div.xdsoft_current {
    font-weight: 400;
    color: #fff !important;
    background-color: transparent !important;
    -webkit-box-shadow: none;
            box-shadow: none;
}

.xdsoft_datetimepicker .xdsoft_timepicker .xdsoft_time_box > div > div.xdsoft_current::before {
    background-color: rgba(114, 94, 195, 0.8);
}

.xdsoft_datetimepicker .xdsoft_timepicker .xdsoft_prev,
.xdsoft_datetimepicker .xdsoft_timepicker .xdsoft_next {
    position: absolute;
    display: -webkit-inline-box;
    display: -webkit-inline-flex;
    display: -ms-inline-flexbox;
    display: inline-flex;
    -webkit-box-align: center;
    -webkit-align-items: center;
        -ms-flex-align: center;
            align-items: center;
    -webkit-box-pack: center;
    -webkit-justify-content: center;
        -ms-flex-pack: center;
            justify-content: center;
    top: 0;
    width: 25px;
    height: 34px;
    margin: 0;
    color: #bcbec0;
    text-indent: 0;
    background-image: none;
    opacity: 1;
    -webkit-transition: color .1s ease-in-out;
    -o-transition: color .1s ease-in-out;
    transition: color .1s ease-in-out;
}

.xdsoft_datetimepicker .xdsoft_timepicker .xdsoft_prev:hover,
.xdsoft_datetimepicker .xdsoft_timepicker .xdsoft_next:hover {
    color: #393f49;
}

.xdsoft_datetimepicker .xdsoft_timepicker .xdsoft_prev {
    left: 0;
}

.xdsoft_datetimepicker .xdsoft_timepicker .xdsoft_prev > .rui-icon {
    -webkit-transform: rotate(90deg);
        -ms-transform: rotate(90deg);
            transform: rotate(90deg);
}

.xdsoft_datetimepicker .xdsoft_timepicker .xdsoft_next {
    left: 25px;
}

.xdsoft_datetimepicker .xdsoft_timepicker .xdsoft_next > .rui-icon {
    -webkit-transform: rotate(-90deg);
        -ms-transform: rotate(-90deg);
            transform: rotate(-90deg);
}

.xdsoft_datetimepicker .xdsoft_calendar td {
    padding: 4px;
    text-align: center;
    background-color: transparent;
    border: 0;
}

.xdsoft_datetimepicker .xdsoft_calendar td.xdsoft_other_month {
    opacity: 1;
}

.xdsoft_datetimepicker .xdsoft_calendar td.xdsoft_other_month > div {
    color: #bcbec0;
}

.xdsoft_datetimepicker .xdsoft_calendar td.xdsoft_other_month > div:hover {
    color: #a4a6a8 !important;
}

.xdsoft_datetimepicker .xdsoft_calendar td > div {
    display: -webkit-inline-box;
    display: -webkit-inline-flex;
    display: -ms-inline-flexbox;
    display: inline-flex;
    -webkit-box-align: center;
    -webkit-align-items: center;
        -ms-flex-align: center;
            align-items: center;
    -webkit-box-pack: center;
    -webkit-justify-content: center;
        -ms-flex-pack: center;
            justify-content: center;
    width: 32px;
    height: 32px;
    padding-right: 0;
    color: #6c757d;
    border-radius: 100%;
    -webkit-transition: color .1s ease-in-out, background-color .1s ease-in-out;
    -o-transition: color .1s ease-in-out, background-color .1s ease-in-out;
    transition: color .1s ease-in-out, background-color .1s ease-in-out;
}

.xdsoft_datetimepicker .xdsoft_calendar td:hover {
    color: #393f49 !important;
    background-color: transparent !important;
}

.xdsoft_datetimepicker .xdsoft_calendar td:hover > div {
    color: #393f49 !important;
    background-color: #f3f4f7 !important;
}

.xdsoft_datetimepicker .xdsoft_calendar td.xdsoft_current {
    background-color: transparent !important;
    -webkit-box-shadow: none;
            box-shadow: none;
}

.xdsoft_datetimepicker .xdsoft_calendar td.xdsoft_current > div {
    font-weight: 400;
    color: #fff !important;
    background-color: rgba(114, 94, 195, 0.8) !important;
}

.xdsoft_datetimepicker .xdsoft_calendar td.xdsoft_current.xdsoft_today {
    background-color: transparent !important;
    -webkit-box-shadow: none;
            box-shadow: none;
}

.xdsoft_datetimepicker .xdsoft_calendar td.xdsoft_current.xdsoft_today > div {
    font-weight: 400;
    color: #fff !important;
    background-color: rgba(114, 94, 195, 0.8) !important;
}

.rui-datetimepicker-wrap {
    position: fixed;
    display: -webkit-box;
    display: -webkit-flex;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-align: center;
    -webkit-align-items: center;
        -ms-flex-align: center;
            align-items: center;
    -webkit-box-pack: center;
    -webkit-justify-content: center;
        -ms-flex-pack: center;
            justify-content: center;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    padding: 10px;
    visibility: hidden;
    opacity: 0;
    -webkit-transition: opacity .15s ease-in-out, visibility .15s ease-in-out;
    -o-transition: opacity .15s ease-in-out, visibility .15s ease-in-out;
    transition: opacity .15s ease-in-out, visibility .15s ease-in-out;
    z-index: 1010;
}

.rui-datetimepicker-wrap::after {
    content: "";
    position: absolute;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    background-color: rgba(50, 57, 66, 0.9);
}

.rui-datetimepicker-wrap.show {
    visibility: visible;
    opacity: 1;
}

.rui-datetimepicker-wrap > .xdsoft_datetimepicker {
    position: static !important;
    max-height: 100%;
    border-color: transparent;
}

/*---------------------------
    Plugin DateRange Picker
 ----------------------------*/
.rui-daterange {
    min-width: 200px;
}

.daterangepicker {
    width: auto;
    margin-top: 10px;
    font-family: "Open Sans", sans-serif;
    background-color: #fff;
    border: 1px solid #e6ecf0;
    border-radius: .25rem;
    -webkit-box-shadow: 0 3px 10px rgba(0, 0, 0, 0.03);
            box-shadow: 0 3px 10px rgba(0, 0, 0, 0.03);
    z-index: 1000;
}

.daterangepicker::before, .daterangepicker::after {
    display: none;
}

.daterangepicker .drp-calendar {
    max-width: none;
}

.daterangepicker .drp-calendar.left {
    padding: 15px 0 14px 16px;
}

.daterangepicker .drp-calendar.right {
    padding: 15px 15px 14px 26px;
}

.daterangepicker.show-calendar .drp-buttons {
    display: -webkit-box;
    display: -webkit-flex;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-align: center;
    -webkit-align-items: center;
        -ms-flex-align: center;
            align-items: center;
    -webkit-box-pack: justify;
    -webkit-justify-content: space-between;
        -ms-flex-pack: justify;
            justify-content: space-between;
    padding: 0 30px 30px;
    border: 0;
}

.daterangepicker.show-calendar .drp-buttons .drp-selected {
    display: block;
    padding: 0;
    margin-right: auto;
    color: #bcbec0;
    text-align: left;
}

.daterangepicker.show-calendar .drp-buttons .btn-sm {
    padding: 8.5px 14px;
    font-size: 9px;
}

.daterangepicker.show-calendar .drp-buttons .btn {
    margin-left: 11px;
    background-color: #725ec3;
    border-color: #725ec3;
}

.daterangepicker.show-calendar .drp-buttons .btn:hover {
    background-color: #5641ad;
    border-color: #5641ad;
}

.daterangepicker.show-calendar .drp-buttons .btn:focus {
    -webkit-box-shadow: 0 0 0 0.2rem rgba(114, 94, 195, 0.5);
            box-shadow: 0 0 0 0.2rem rgba(114, 94, 195, 0.5);
}

.daterangepicker.show-calendar .drp-buttons .btn.btn-default {
    color: #6c757d;
    background-color: transparent;
    border-color: transparent;
}

.daterangepicker.show-calendar .drp-buttons .btn.btn-default:hover {
    color: #393f49;
}

.daterangepicker.show-calendar .drp-buttons .btn.btn-default:focus {
    -webkit-box-shadow: none;
            box-shadow: none;
}

@media (max-width: 767.98px) {
    .daterangepicker {
        width: 307px;
    }
    .daterangepicker .drp-calendar.left,
    .daterangepicker .drp-calendar.right {
        padding: 15px;
    }
}

.daterangepicker .calendar-table {
    background-color: transparent;
}

.daterangepicker .calendar-table td {
    border: 0;
}

.daterangepicker .calendar-table thead th {
    height: 38px;
    font-size: 12px;
    font-weight: 600;
    color: #4b515b;
}

.daterangepicker .calendar-table tbody td {
    position: relative;
    width: 38px;
    min-width: 38px;
    height: 38px;
    color: #6c757d;
    background-color: transparent;
    -webkit-transition: color 1s ease-in-out;
    -o-transition: color 1s ease-in-out;
    transition: color 1s ease-in-out;
}

.daterangepicker .calendar-table tbody td::after {
    content: "";
    position: absolute;
    top: 4px;
    right: 4px;
    bottom: 4px;
    left: 4px;
    border-radius: 100%;
    -webkit-transition: background-color .1s ease-in-out;
    -o-transition: background-color .1s ease-in-out;
    transition: background-color .1s ease-in-out;
    z-index: -1;
}

.daterangepicker .calendar-table tbody td:hover {
    color: #393f49;
    background-color: transparent;
}

.daterangepicker .calendar-table tbody td:hover::after {
    background-color: #f8f9fa;
}

.daterangepicker .calendar-table tbody td.in-range::after {
    background-color: #f3f4f7;
}

.daterangepicker .calendar-table tbody td.off.start-date, .daterangepicker .calendar-table tbody td.off.end-date {
    background-color: transparent;
}

.daterangepicker .calendar-table tbody td.start-date, .daterangepicker .calendar-table tbody td.end-date {
    color: #fff;
}

.daterangepicker .calendar-table tbody td.start-date::after, .daterangepicker .calendar-table tbody td.end-date::after {
    background-color: rgba(114, 94, 195, 0.8);
}

.daterangepicker .calendar-table tbody td.off {
    color: #bcbec0;
}

.daterangepicker .calendar-table tbody td.off::after {
    background-color: #fff;
}

.daterangepicker .calendar-table tbody td.off:hover {
    color: #393f49;
}

.daterangepicker .calendar-table tbody td.off:hover::after {
    background-color: #f8f9fa;
}

.daterangepicker .calendar-table .prev,
.daterangepicker .calendar-table .next {
    width: 34px;
    min-width: 34px;
    height: 34px;
    color: #bcbec0;
    -webkit-transition: color .1s ease-in-out;
    -o-transition: color .1s ease-in-out;
    transition: color .1s ease-in-out;
}

.daterangepicker .calendar-table .prev > span,
.daterangepicker .calendar-table .next > span {
    display: none;
}

.daterangepicker .calendar-table .prev > .rui-icon,
.daterangepicker .calendar-table .next > .rui-icon {
    margin-top: -1px;
}

.daterangepicker .calendar-table .prev:hover,
.daterangepicker .calendar-table .next:hover {
    color: #393f49;
    background-color: transparent;
}

.daterangepicker .calendar-table .next > .rui-icon {
    -webkit-transform: rotate(180deg);
        -ms-transform: rotate(180deg);
            transform: rotate(180deg);
}

.daterangepicker .calendar-table .month {
    font-size: 1rem;
    font-weight: 400;
    color: #6c757d;
}

/*---------------------------
    Plugin Touchspin
 ----------------------------*/
.rui-touchspin .form-control {
    margin-right: 10px;
}

.rui-touchspin .input-group-prepend {
    margin-right: 10px;
}

.rui-touchspin .bootstrap-touchspin .form-control {
    border-radius: .25rem;
}

.rui-touchspin .bootstrap-touchspin .btn {
    -webkit-box-pack: center;
    -webkit-justify-content: center;
        -ms-flex-pack: center;
            justify-content: center;
    min-width: 38px;
    border-radius: .25rem;
}

.rui-touchspin .bootstrap-touchspin .btn-default {
    color: #6c757d;
    background-color: #f3f4f7;
}

.rui-touchspin .bootstrap-touchspin .btn-default:hover {
    background-color: #eaecf1;
}

.rui-touchspin .bootstrap-touchspin .btn-default:active {
    background-color: #e1e3eb;
}

.rui-touchspin-right .form-control {
    -webkit-box-ordinal-group: 1;
    -webkit-order: 0;
        -ms-flex-order: 0;
            order: 0;
    margin-right: 10px;
}

.rui-touchspin-right .input-group-prepend {
    -webkit-box-ordinal-group: 2;
    -webkit-order: 1;
        -ms-flex-order: 1;
            order: 1;
    margin-right: 10px;
}

.rui-touchspin-right .input-group-append {
    -webkit-box-ordinal-group: 3;
    -webkit-order: 2;
        -ms-flex-order: 2;
            order: 2;
}

.rui-touchspin-left .form-control {
    -webkit-box-ordinal-group: 3;
    -webkit-order: 2;
        -ms-flex-order: 2;
            order: 2;
    margin-right: 0;
}

.rui-touchspin-left .input-group-prepend {
    -webkit-box-ordinal-group: 1;
    -webkit-order: 0;
        -ms-flex-order: 0;
            order: 0;
    margin-right: 10px;
}

.rui-touchspin-left .input-group-append {
    -webkit-box-ordinal-group: 2;
    -webkit-order: 1;
        -ms-flex-order: 1;
            order: 1;
    margin-right: 10px;
}

.rui-touchspin-vertical .input-group-btn-vertical {
    display: -webkit-inline-box;
    display: -webkit-inline-flex;
    display: -ms-inline-flexbox;
    display: inline-flex;
    -webkit-box-orient: vertical;
    -webkit-box-direction: normal;
    -webkit-flex-direction: column;
        -ms-flex-direction: column;
            flex-direction: column;
    -webkit-box-pack: justify;
    -webkit-justify-content: space-between;
        -ms-flex-pack: justify;
            justify-content: space-between;
}

.rui-touchspin-vertical .input-group-btn-vertical .btn {
    min-width: 17px;
    height: 17px;
    padding: 0;
    line-height: 0;
    border-radius: .2rem;
}

.rui-touchspin-vertical.rui-touchspin-left .form-control {
    -webkit-box-ordinal-group: 2;
    -webkit-order: 1;
        -ms-flex-order: 1;
            order: 1;
    margin-right: 0;
}

.rui-touchspin-vertical.rui-touchspin-left .input-group-btn-vertical {
    -webkit-box-ordinal-group: 1;
    -webkit-order: 0;
        -ms-flex-order: 0;
            order: 0;
    margin-right: 10px;
}

.rui-touchspin-overlay .form-control {
    margin-right: 0;
}

.rui-touchspin-overlay .input-group-btn-vertical {
    display: -webkit-inline-box;
    display: -webkit-inline-flex;
    display: -ms-inline-flexbox;
    display: inline-flex;
    -webkit-box-orient: vertical;
    -webkit-box-direction: normal;
    -webkit-flex-direction: column;
        -ms-flex-direction: column;
            flex-direction: column;
    -webkit-box-pack: justify;
    -webkit-justify-content: space-between;
        -ms-flex-pack: justify;
            justify-content: space-between;
    padding-top: 1px;
    padding-bottom: 1px;
    margin-left: -18px;
    z-index: 4;
}

.rui-touchspin-overlay .input-group-btn-vertical .btn:first-of-type {
    border-radius: 0 .2rem 0 0;
}

.rui-touchspin-overlay .input-group-btn-vertical .btn:last-of-type {
    border-radius: 0 0 .2rem 0;
}

/*---------------------------
    Plugin Ion Range Slider
 ----------------------------*/
.rui-irs > input,
.rui-irs-double > input {
    display: none;
}

.rui-irs .rui-irs-value,
.rui-irs-double .rui-irs-value {
    display: -webkit-box;
    display: -webkit-flex;
    display: -ms-flexbox;
    display: flex;
    margin-top: 5px;
    color: #6c757d;
}

.rui-irs .irs,
.rui-irs-double .irs {
    font-family: "Open Sans", sans-serif;
}

.rui-irs .irs-line,
.rui-irs-double .irs-line {
    height: 6px;
    background-color: #eaecf0;
    border-radius: 3px;
}

.rui-irs .irs-bar,
.rui-irs-double .irs-bar {
    height: 6px;
    background-color: #d4cded;
}

.rui-irs .irs-min,
.rui-irs .irs-max,
.rui-irs-double .irs-min,
.rui-irs-double .irs-max {
    top: 4px;
    padding: 0;
    font-size: 11px;
    color: #bcbec0;
    background-color: transparent;
}

.rui-irs .irs-from,
.rui-irs .irs-to,
.rui-irs .irs-single,
.rui-irs-double .irs-from,
.rui-irs-double .irs-to,
.rui-irs-double .irs-single {
    padding: 1px 4px;
    font-size: 10px;
    visibility: hidden;
    background-color: #9383d1;
    border-radius: .2rem;
    opacity: 0;
    -webkit-transition: opacity .1s ease-in-out, visibility .1s ease-in-out;
    -o-transition: opacity .1s ease-in-out, visibility .1s ease-in-out;
    transition: opacity .1s ease-in-out, visibility .1s ease-in-out;
}

.rui-irs .irs-from::before,
.rui-irs .irs-to::before,
.rui-irs .irs-single::before,
.rui-irs-double .irs-from::before,
.rui-irs-double .irs-to::before,
.rui-irs-double .irs-single::before {
    display: none;
}

.rui-irs .irs-handle,
.rui-irs-double .irs-handle {
    top: 20px;
    height: 16px;
}

.rui-irs .irs-handle > i:first-child,
.rui-irs-double .irs-handle > i:first-child {
    left: 1px;
    width: 16px;
    height: 16px;
    background-color: #725ec3;
    border-radius: 100%;
    -webkit-transition: background-color .1s ease-in-out, -webkit-box-shadow .1s ease-in-out;
    transition: background-color .1s ease-in-out, -webkit-box-shadow .1s ease-in-out;
    -o-transition: background-color .1s ease-in-out, box-shadow .1s ease-in-out;
    transition: background-color .1s ease-in-out, box-shadow .1s ease-in-out;
    transition: background-color .1s ease-in-out, box-shadow .1s ease-in-out, -webkit-box-shadow .1s ease-in-out;
}

.rui-irs .irs-handle:hover > i:first-child,
.rui-irs-double .irs-handle:hover > i:first-child {
    background-color: #5641ad;
}

.rui-irs .irs-handle:active > i:first-child,
.rui-irs .irs-handle.state_hover > i:first-child,
.rui-irs-double .irs-handle:active > i:first-child,
.rui-irs-double .irs-handle.state_hover > i:first-child {
    background-color: #a396d8;
    -webkit-box-shadow: 0 0 0 1px #fff, 0 0 0 0.2rem rgba(114, 94, 195, 0.2);
            box-shadow: 0 0 0 1px #fff, 0 0 0 0.2rem rgba(114, 94, 195, 0.2);
}

.rui-irs.rui-irs-change .irs-from,
.rui-irs.rui-irs-change .irs-to,
.rui-irs.rui-irs-change .irs-single,
.rui-irs-double.rui-irs-change .irs-from,
.rui-irs-double.rui-irs-change .irs-to,
.rui-irs-double.rui-irs-change .irs-single {
    visibility: visible;
    opacity: 1;
}

/*---------------------------
    EasyMDE (Markdown)
 ----------------------------*/
.editor-toolbar {
    padding: 11px 10px;
    background-color: #f8f9fa;
    border-color: #e6ecf0;
}

.editor-toolbar::before, .editor-toolbar::after {
    display: none;
}

.editor-toolbar button {
    color: #6c757d;
    -webkit-transition: color .1s ease-in-out;
    -o-transition: color .1s ease-in-out;
    transition: color .1s ease-in-out;
}

.editor-toolbar button:hover {
    color: #393f49;
    background-color: transparent;
    border-color: transparent;
}

.editor-toolbar i.separator {
    border-right-color: transparent;
    border-left-color: #e6ecf0;
}

.CodeMirror {
    padding: 10px 15px;
    color: #4b515b;
    border-color: #e6ecf0;
}

.CodeMirror .CodeMirror-scroll {
    margin-right: -32px;
}

.editor-statusbar {
    padding: 0;
    margin-top: 11px;
    color: #bcbec0;
}

.rui-markdown-fullscreen .rui-navbar,
.rui-markdown-fullscreen .yaybar {
    z-index: 1;
}

/*---------------------------
    Colorpicker
 ----------------------------*/
.rui-colorpicker {
    position: relative;
}

.rui-colorpicker.rui-colorpicker-presence-addon .pickr {
    position: absolute;
    top: 0;
    bottom: 0;
    visibility: hidden;
    opacity: 0;
}

.rui-colorpicker.rui-colorpicker-presence-addon .pickr .pcr-button {
    height: 100%;
}

.rui-colorpicker .pickr {
    display: -webkit-inline-box;
    display: -webkit-inline-flex;
    display: -ms-inline-flexbox;
    display: inline-flex;
    -webkit-box-align: center;
    -webkit-align-items: center;
        -ms-flex-align: center;
            align-items: center;
}

.rui-colorpicker .pickr .pcr-button {
    width: 16px;
    height: 16px;
    border-radius: .2rem;
}

.rui-colorpicker .pickr .pcr-button::before {
    border-radius: .2rem;
    -webkit-box-shadow: 0 0 0 0 transparent;
            box-shadow: 0 0 0 0 transparent;
    opacity: .4;
    -webkit-transition: -webkit-box-shadow .15s ease-in-out;
    transition: -webkit-box-shadow .15s ease-in-out;
    -o-transition: box-shadow .15s ease-in-out;
    transition: box-shadow .15s ease-in-out;
    transition: box-shadow .15s ease-in-out, -webkit-box-shadow .15s ease-in-out;
}

.rui-colorpicker .pickr .pcr-button:focus {
    -webkit-box-shadow: none;
            box-shadow: none;
}

.rui-colorpicker .pickr .pcr-button:focus::before {
    -webkit-box-shadow: 0 0 0 .15rem currentColor;
            box-shadow: 0 0 0 .15rem currentColor;
}

.pcr-app[data-theme="classic"] {
    width: auto;
    min-width: 320px;
    height: auto;
    padding: 20px;
    border: 1px solid #e6ecf0;
    border-radius: .25rem;
    -webkit-box-shadow: 0 3px 10px rgba(0, 0, 0, 0.03);
            box-shadow: 0 3px 10px rgba(0, 0, 0, 0.03);
}

.pcr-app[data-theme="classic"] .pcr-selection .pcr-color-preview {
    width: 20px;
    min-width: 20px;
    margin-right: 10px;
}

.pcr-app[data-theme="classic"] .pcr-selection .pcr-color-preview::before {
    border-radius: .25rem;
    opacity: .3;
}

.pcr-app[data-theme="classic"] .pcr-selection .pcr-color-preview .pcr-last-color {
    position: relative;
}

.pcr-app[data-theme="classic"] .pcr-selection .pcr-color-preview .pcr-last-color::before {
    content: "";
    position: absolute;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    border-radius: .2rem;
    -webkit-box-shadow: 0 0 0 0 transparent;
            box-shadow: 0 0 0 0 transparent;
    opacity: .6;
    -webkit-transition: -webkit-box-shadow .15s ease-in-out;
    transition: -webkit-box-shadow .15s ease-in-out;
    -o-transition: box-shadow .15s ease-in-out;
    transition: box-shadow .15s ease-in-out;
    transition: box-shadow .15s ease-in-out, -webkit-box-shadow .15s ease-in-out;
}

.pcr-app[data-theme="classic"] .pcr-selection .pcr-color-preview .pcr-last-color:focus {
    -webkit-box-shadow: none;
            box-shadow: none;
}

.pcr-app[data-theme="classic"] .pcr-selection .pcr-color-preview .pcr-last-color:focus::before {
    -webkit-box-shadow: 0 0 0 0.15rem rgba(248, 249, 250, 0.5), 0 0 0 0.15rem currentColor;
            box-shadow: 0 0 0 0.15rem rgba(248, 249, 250, 0.5), 0 0 0 0.15rem currentColor;
}

.pcr-app[data-theme="classic"] .pcr-selection .pcr-color-preview .pcr-last-color,
.pcr-app[data-theme="classic"] .pcr-selection .pcr-color-preview .pcr-current-color {
    height: calc(50% - 5px);
    border-radius: .2rem;
}

.pcr-app[data-theme="classic"] .pcr-selection .pcr-color-preview .pcr-current-color {
    -webkit-box-shadow: 0 -12px 0 0 #fff;
            box-shadow: 0 -12px 0 0 #fff;
}

.pcr-app[data-theme="classic"] .pcr-selection .pcr-color-palette {
    min-height: 100px;
}

.pcr-app[data-theme="classic"] .pcr-selection .pcr-color-palette .pcr-palette {
    border-radius: .2rem;
}

.pcr-app[data-theme="classic"] .pcr-selection .pcr-color-palette .pcr-palette::before {
    border-radius: .25rem;
    opacity: .3;
}

.pcr-app[data-theme="classic"] .pcr-selection .pcr-color-chooser {
    margin-left: 10px;
}

.pcr-app[data-theme="classic"] .pcr-selection .pcr-color-opacity {
    margin-left: 10px;
}

.pcr-app[data-theme="classic"] .pcr-selection .pcr-picker {
    width: 14px;
    height: 14px;
    border: 1px solid #e6ecf0;
}

.pcr-app[data-theme="classic"] .pcr-interaction {
    display: -webkit-box;
    display: -webkit-flex;
    display: -ms-flexbox;
    display: flex;
    -webkit-flex-wrap: wrap;
        -ms-flex-wrap: wrap;
            flex-wrap: wrap;
    margin: 0 -5px 0;
}

.pcr-app[data-theme="classic"] .pcr-interaction > input {
    display: -webkit-inline-box;
    display: -webkit-inline-flex;
    display: -ms-inline-flexbox;
    display: inline-flex;
    -webkit-box-align: center;
    -webkit-align-items: center;
        -ms-flex-align: center;
            align-items: center;
    min-height: 36px;
    padding: 7.5px 15px;
    margin: 10px 5px 0;
    font-size: 1rem;
    letter-spacing: initial;
    border-radius: .25rem;
}

.pcr-app[data-theme="classic"] .pcr-interaction .pcr-save,
.pcr-app[data-theme="classic"] .pcr-interaction .pcr-type {
    padding: 9.5px 15px;
    font-size: 10px;
    font-weight: 700;
    text-transform: uppercase;
}

.pcr-app[data-theme="classic"] .pcr-interaction .pcr-result {
    padding: 7.5px 15px;
    color: #4b515b;
    background-color: #fbfcfc;
    border: 1px solid #e6ecf0;
    -webkit-transition: border-color .15s ease-in-out, -webkit-box-shadow .15s ease-in-out;
    transition: border-color .15s ease-in-out, -webkit-box-shadow .15s ease-in-out;
    -o-transition: border-color .15s ease-in-out, box-shadow .15s ease-in-out;
    transition: border-color .15s ease-in-out, box-shadow .15s ease-in-out;
    transition: border-color .15s ease-in-out, box-shadow .15s ease-in-out, -webkit-box-shadow .15s ease-in-out;
}

.pcr-app[data-theme="classic"] .pcr-interaction .pcr-result:hover {
    background-color: #fbfcfc;
}

.pcr-app[data-theme="classic"] .pcr-interaction .pcr-result:focus {
    background-color: #fbfcfc;
    border-color: rgba(114, 94, 195, 0.6);
    -webkit-box-shadow: 0 0 0 0.2rem rgba(114, 94, 195, 0.2);
            box-shadow: 0 0 0 0.2rem rgba(114, 94, 195, 0.2);
}

.pcr-app[data-theme="classic"] .pcr-interaction .pcr-type {
    line-height: 0;
    color: #6c757d;
    background-color: #f8f9fa;
    -webkit-transition: color .15s ease-in-out, background-color .15s ease-in-out, -webkit-box-shadow .15s ease-in-out;
    transition: color .15s ease-in-out, background-color .15s ease-in-out, -webkit-box-shadow .15s ease-in-out;
    -o-transition: color .15s ease-in-out, background-color .15s ease-in-out, box-shadow .15s ease-in-out;
    transition: color .15s ease-in-out, background-color .15s ease-in-out, box-shadow .15s ease-in-out;
    transition: color .15s ease-in-out, background-color .15s ease-in-out, box-shadow .15s ease-in-out, -webkit-box-shadow .15s ease-in-out;
}

.pcr-app[data-theme="classic"] .pcr-interaction .pcr-type:hover {
    background-color: #f3f4f7;
    -webkit-filter: none;
            filter: none;
}

.pcr-app[data-theme="classic"] .pcr-interaction .pcr-type:focus {
    color: #6c757d;
    background-color: #eaecf0;
    -webkit-box-shadow: none;
            box-shadow: none;
}

.pcr-app[data-theme="classic"] .pcr-interaction .pcr-type.active {
    color: #6c757d;
    background-color: #eaecf0;
}

.pcr-app[data-theme="classic"] .pcr-interaction .pcr-type:active {
    background-color: #d7d9e0;
}

.pcr-app[data-theme="classic"] .pcr-interaction .pcr-save {
    line-height: 0;
    color: #fff;
    background-color: #725ec3;
    -webkit-transition: color .15s ease-in-out, background-color .15s ease-in-out, -webkit-box-shadow .15s ease-in-out;
    transition: color .15s ease-in-out, background-color .15s ease-in-out, -webkit-box-shadow .15s ease-in-out;
    -o-transition: color .15s ease-in-out, background-color .15s ease-in-out, box-shadow .15s ease-in-out;
    transition: color .15s ease-in-out, background-color .15s ease-in-out, box-shadow .15s ease-in-out;
    transition: color .15s ease-in-out, background-color .15s ease-in-out, box-shadow .15s ease-in-out, -webkit-box-shadow .15s ease-in-out;
}

.pcr-app[data-theme="classic"] .pcr-interaction .pcr-save:hover {
    background-color: #624bbc;
    -webkit-filter: none;
            filter: none;
}

.pcr-app[data-theme="classic"] .pcr-interaction .pcr-save:focus {
    color: #fff;
    background-color: #624bbc;
    -webkit-box-shadow: 0 0 0 0.2rem rgba(114, 94, 195, 0.4);
            box-shadow: 0 0 0 0.2rem rgba(114, 94, 195, 0.4);
}

.pcr-app[data-theme="classic"] .pcr-interaction .pcr-save:active {
    background-color: #5641ad;
}

.rui-colorpicker-wrap {
    position: fixed;
    display: -webkit-box;
    display: -webkit-flex;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-align: center;
    -webkit-align-items: center;
        -ms-flex-align: center;
            align-items: center;
    -webkit-box-pack: center;
    -webkit-justify-content: center;
        -ms-flex-pack: center;
            justify-content: center;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    visibility: hidden;
    z-index: 1010;
}

.rui-colorpicker-wrap > .pcr-app[data-theme="classic"] {
    position: static;
}

/*---------------------------
    Plugin Chartist
 ----------------------------*/
.ct-series:hover > .ct-point {
    stroke-opacity: 1;
}

.ct-series-a .ct-line,
.ct-series-a .ct-point,
.ct-series-a .ct-slice-donut {
    stroke: #5e77ff;
}

.ct-series-a .ct-bar {
    stroke: rgba(94, 119, 255, 0.8);
    stroke-opacity: 1;
}

.ct-series-a .ct-area,
.ct-series-a .ct-slice-pie,
.ct-series-a .ct-slice-donut-solid {
    fill: rgba(94, 119, 255, 0.8);
    fill-opacity: 1;
}

.ct-series-b .ct-line,
.ct-series-b .ct-point,
.ct-series-b .ct-slice-donut {
    stroke: #abb8ff;
}

.ct-series-b .ct-bar {
    stroke: rgba(171, 184, 255, 0.8);
    stroke-opacity: 1;
}

.ct-series-b .ct-area,
.ct-series-b .ct-slice-pie,
.ct-series-b .ct-slice-donut-solid {
    fill: rgba(171, 184, 255, 0.8);
    fill-opacity: 1;
}

.ct-series-c .ct-line,
.ct-series-c .ct-point,
.ct-series-c .ct-slice-donut {
    stroke: #cad2ff;
}

.ct-series-c .ct-bar {
    stroke: rgba(202, 210, 255, 0.8);
    stroke-opacity: 1;
}

.ct-series-c .ct-area,
.ct-series-c .ct-slice-pie,
.ct-series-c .ct-slice-donut-solid {
    fill: rgba(202, 210, 255, 0.8);
    fill-opacity: 1;
}

.ct-series-d .ct-line,
.ct-series-d .ct-point,
.ct-series-d .ct-slice-donut {
    stroke: #d9deff;
}

.ct-series-d .ct-bar {
    stroke: rgba(217, 222, 255, 0.8);
    stroke-opacity: 1;
}

.ct-series-d .ct-area,
.ct-series-d .ct-slice-pie,
.ct-series-d .ct-slice-donut-solid {
    fill: rgba(217, 222, 255, 0.8);
    fill-opacity: 1;
}

.ct-series-e .ct-line,
.ct-series-e .ct-point,
.ct-series-e .ct-slice-donut {
    stroke: #e6e9ff;
}

.ct-series-e .ct-bar {
    stroke: rgba(230, 233, 255, 0.8);
    stroke-opacity: 1;
}

.ct-series-e .ct-area,
.ct-series-e .ct-slice-pie,
.ct-series-e .ct-slice-donut-solid {
    fill: rgba(230, 233, 255, 0.8);
    fill-opacity: 1;
}

.ct-line {
    stroke-width: 2px;
}

.ct-point {
    stroke-width: 6px;
    stroke-opacity: 0;
    -webkit-transition: stroke-opacity .15s ease-in-out;
    -o-transition: stroke-opacity .15s ease-in-out;
    transition: stroke-opacity .15s ease-in-out;
}

.ct-label {
    font-size: 12px;
    color: #bcbec0;
}

.ct-label.ct-horizontal.ct-end {
    -webkit-box-pack: center;
    -webkit-justify-content: center;
        -ms-flex-pack: center;
            justify-content: center;
    margin-top: 5px;
    margin-left: -50%;
}

.ct-bar {
    stroke-width: 43px;
}

.ct-chart-bar .ct-label.ct-horizontal.ct-end {
    margin-left: 0;
}

.ct-chart-pie .ct-series:hover ~ g:last-child > .ct-label {
    opacity: 1;
}

.ct-chart-pie .ct-label {
    fill: #fff;
    opacity: 0;
    will-change: opacity;
    -webkit-transition: opacity .1s ease-in-out;
    -o-transition: opacity .1s ease-in-out;
    transition: opacity .1s ease-in-out;
}

.ct-chart-pie .ct-label:hover {
    opacity: 1;
}

.rui-chartist {
    position: relative;
}

.rui-chartist-donut {
    margin: -5px;
}

.rui-chartist-donut::after {
    content: "";
    position: absolute;
    top: 10px;
    right: 10px;
    bottom: 10px;
    left: 10px;
    border: 3px solid #f3f4f7;
    border-radius: 100%;
}

.rui-chartist-donut .ct-series-a .ct-slice-donut {
    stroke: url(#gradient);
    stroke-linecap: round;
}

.rui-chartist-donut .ct-series-b .ct-slice-donut {
    stroke: transparent;
}

/*---------------------------
    Plugin Selectize
 ----------------------------*/
.rui-selectize .rui-selectize-element {
    display: -webkit-box;
    display: -webkit-flex;
    display: -ms-flexbox;
    display: flex;
    padding: 0;
    border: 0;
}

.rui-selectize .rui-selectize-element .selectize-input {
    min-height: 36px;
    padding: 7px 30px 7px 17px;
    background-color: #fbfcfc;
    border: 1px solid #e6ecf0;
    border-radius: .25rem;
    -webkit-box-shadow: 0 0 0 0 rgba(114, 94, 195, 0);
            box-shadow: 0 0 0 0 rgba(114, 94, 195, 0);
    -webkit-transition: border-color .15s ease-in-out, -webkit-box-shadow .15s ease-in-out;
    transition: border-color .15s ease-in-out, -webkit-box-shadow .15s ease-in-out;
    -o-transition: border-color .15s ease-in-out, box-shadow .15s ease-in-out;
    transition: border-color .15s ease-in-out, box-shadow .15s ease-in-out;
    transition: border-color .15s ease-in-out, box-shadow .15s ease-in-out, -webkit-box-shadow .15s ease-in-out;
}

.rui-selectize .rui-selectize-element .selectize-input:focus, .rui-selectize .rui-selectize-element .selectize-input.focus {
    background-color: #fbfcfc;
    border-color: rgba(114, 94, 195, 0.6);
    -webkit-box-shadow: 0 0 0 0.2rem rgba(114, 94, 195, 0.2);
            box-shadow: 0 0 0 0.2rem rgba(114, 94, 195, 0.2);
}

.rui-selectize .rui-selectize-element .selectize-dropdown {
    height: auto;
    padding: 9px 5px 10px;
    margin-top: 10px;
    border: 1px solid #e6ecf0;
    border-radius: .25rem;
    -webkit-box-shadow: 0 3px 10px rgba(0, 0, 0, 0.03);
            box-shadow: 0 3px 10px rgba(0, 0, 0, 0.03);
}

.rui-selectize .rui-selectize-element .selectize-dropdown .option {
    padding: 6px 14px;
    color: #6c757d;
    cursor: pointer;
    -webkit-transition: color .1s ease-in-out;
    -o-transition: color .1s ease-in-out;
    transition: color .1s ease-in-out;
}

.rui-selectize .rui-selectize-element .selectize-dropdown .option:hover {
    color: #393f49;
}

.rui-selectize .rui-selectize-element .selectize-dropdown .create {
    display: -webkit-box;
    display: -webkit-flex;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-align: center;
    -webkit-align-items: center;
        -ms-flex-align: center;
            align-items: center;
    padding: 6px 14px;
    color: #6c757d;
}

.rui-selectize .rui-selectize-element .selectize-dropdown .create strong {
    margin-left: 5px;
    font-weight: 400;
    color: #4b515b;
}

.rui-selectize .rui-selectize-element .selectize-dropdown .highlight {
    background-color: #f3f4f7;
}

.rui-selectize .rui-selectize-element .selectize-dropdown .active {
    color: #4b515b;
    background-color: transparent;
}

.rui-selectize .rui-selectize-select .selectize-input::after,
.rui-selectize .rui-selectize-select.single .selectize-input::after {
    border-color: #bcbec0 transparent transparent transparent;
    border-style: solid;
    border-width: 5px 5px 0 5px;
    -webkit-transition: -webkit-transform .15s ease-in-out;
    transition: -webkit-transform .15s ease-in-out;
    -o-transition: transform .15s ease-in-out;
    transition: transform .15s ease-in-out;
    transition: transform .15s ease-in-out, -webkit-transform .15s ease-in-out;
    -webkit-transform: rotate(0);
        -ms-transform: rotate(0);
            transform: rotate(0);
}

.rui-selectize .rui-selectize-select .selectize-input:focus::before, .rui-selectize .rui-selectize-select .selectize-input.focus::before,
.rui-selectize .rui-selectize-select.single .selectize-input:focus::before,
.rui-selectize .rui-selectize-select.single .selectize-input.focus::before {
    display: none;
}

.rui-selectize .rui-selectize-select .selectize-input:focus::after, .rui-selectize .rui-selectize-select .selectize-input.focus::after,
.rui-selectize .rui-selectize-select.single .selectize-input:focus::after,
.rui-selectize .rui-selectize-select.single .selectize-input.focus::after {
    margin-top: -3px;
    border-color: #4b515b transparent transparent transparent;
    border-style: solid;
    border-width: 5px 5px 0 5px;
    -webkit-transform: rotate(180deg);
        -ms-transform: rotate(180deg);
            transform: rotate(180deg);
}

.rui-selectize .rui-selectize-select .rui-selectize-select-icon.selectize-input .rui-selectize-select-icon {
    position: absolute;
    top: 50%;
    right: 11px;
    margin-top: -8px;
    stroke: #bcbec0;
    -webkit-transition: stroke .15s ease-in-out, -webkit-transform .15s ease-in-out;
    transition: stroke .15s ease-in-out, -webkit-transform .15s ease-in-out;
    -o-transition: stroke .15s ease-in-out, transform .15s ease-in-out;
    transition: stroke .15s ease-in-out, transform .15s ease-in-out;
    transition: stroke .15s ease-in-out, transform .15s ease-in-out, -webkit-transform .15s ease-in-out;
    -webkit-transform: rotate(0);
        -ms-transform: rotate(0);
            transform: rotate(0);
}

.rui-selectize .rui-selectize-select .rui-selectize-select-icon.selectize-input:focus .rui-selectize-select-icon,
.rui-selectize .rui-selectize-select .rui-selectize-select-icon.selectize-input.focus .rui-selectize-select-icon {
    stroke: #4b515b;
    -webkit-transform: rotate(180deg);
        -ms-transform: rotate(180deg);
            transform: rotate(180deg);
}

.rui-selectize .rui-selectize-select .rui-selectize-select-icon.selectize-input::after {
    display: none;
}

.rui-selectize .rui-selectize-input .selectize-input.has-items {
    padding: 5px 15px;
}

.rui-selectize .rui-selectize-input .selectize-input::after {
    display: none;
}

.rui-selectize .rui-selectize-input .selectize-input .item {
    padding: 1px 6px 3px;
    margin: 2px 5px 2px 0;
    color: #6c757d;
    background-color: #f3f4f7;
    border-radius: .2rem;
    -webkit-transition: background-color .1s ease-in-out, color .1s ease-in-out;
    -o-transition: background-color .1s ease-in-out, color .1s ease-in-out;
    transition: background-color .1s ease-in-out, color .1s ease-in-out;
}

.rui-selectize .rui-selectize-input .selectize-input .item:hover {
    background-color: #eaecf1;
}

.rui-selectize .rui-selectize-input .selectize-input .item.active {
    color: #4b515b;
    background-color: #eaecf1;
}

.rui-selectize .rui-selectize-input .selectize-dropdown .create strong {
    display: -webkit-inline-box;
    display: -webkit-inline-flex;
    display: -ms-inline-flexbox;
    display: inline-flex;
    padding: 1px 6px 3px;
    background-color: #f8f9fa;
    border-radius: .2rem;
}

.rui-selectize .rui-selectize-icon .selectize-input {
    display: -webkit-box;
    display: -webkit-flex;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-align: center;
    -webkit-align-items: center;
        -ms-flex-align: center;
            align-items: center;
}

.rui-selectize .rui-selectize-icon .selectize-input.input-active {
    display: -webkit-box;
    display: -webkit-flex;
    display: -ms-flexbox;
    display: flex;
}

.rui-selectize .rui-selectize-icon .selectize-input.dropdown-active > .option > .rui-icon {
    stroke: #4b515b;
}

.rui-selectize .rui-selectize-icon .selectize-input > .option {
    display: -webkit-inline-box;
    display: -webkit-inline-flex;
    display: -ms-inline-flexbox;
    display: inline-flex;
    -webkit-box-align: center;
    -webkit-align-items: center;
        -ms-flex-align: center;
            align-items: center;
}

.rui-selectize .rui-selectize-icon .selectize-input > .option > .rui-icon {
    margin-right: 10px;
    stroke: #bcbec0;
    -webkit-transition: stroke .15s ease-in-out;
    -o-transition: stroke .15s ease-in-out;
    transition: stroke .15s ease-in-out;
}

.rui-selectize .rui-selectize-icon .selectize-dropdown .option {
    display: -webkit-box;
    display: -webkit-flex;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-align: center;
    -webkit-align-items: center;
        -ms-flex-align: center;
            align-items: center;
    padding: 7px 14px;
}

.rui-selectize .rui-selectize-icon .selectize-dropdown .option.active > .rui-icon {
    stroke: #4b515b;
}

.rui-selectize .rui-selectize-icon .selectize-dropdown .option > .rui-icon {
    margin-right: 10px;
    stroke: #bcbec0;
    -webkit-transition: stroke .15s ease-in-out;
    -o-transition: stroke .15s ease-in-out;
    transition: stroke .15s ease-in-out;
}

.rui-selectize .rui-selectize-github .selectize-dropdown {
    padding: 0;
}

.rui-selectize .rui-selectize-github .selectize-dropdown > .selectize-dropdown-content {
    width: 100%;
}

.rui-selectize .rui-selectize-github .selectize-dropdown > .selectize-dropdown-content > div {
    width: 100%;
    padding: 15px 20px 13px;
    background-color: #fff;
    -webkit-transition: background-color .15s ease-in-out;
    -o-transition: background-color .15s ease-in-out;
    transition: background-color .15s ease-in-out;
}

.rui-selectize .rui-selectize-github .selectize-dropdown > .selectize-dropdown-content > div .title {
    display: -webkit-box;
    display: -webkit-flex;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-align: center;
    -webkit-align-items: center;
        -ms-flex-align: center;
            align-items: center;
}

.rui-selectize .rui-selectize-github .selectize-dropdown > .selectize-dropdown-content > div .title .by {
    display: inline-block;
    margin-left: 15px;
    font-size: 10px;
    color: #a4a6a8;
}

.rui-selectize .rui-selectize-github .selectize-dropdown > .selectize-dropdown-content > div .title .by::before {
    content: "by ";
}

.rui-selectize .rui-selectize-github .selectize-dropdown > .selectize-dropdown-content > div .description {
    display: block;
    margin-top: 12px;
    color: #a4a6a8;
}

.rui-selectize .rui-selectize-github .selectize-dropdown > .selectize-dropdown-content > div .meta {
    display: -webkit-box;
    display: -webkit-flex;
    display: -ms-flexbox;
    display: flex;
    padding: 0;
    margin: 9px 0 0;
    font-size: 10px;
    color: #6c757d;
}

.rui-selectize .rui-selectize-github .selectize-dropdown > .selectize-dropdown-content > div .meta > li {
    display: inline-block;
    padding: 1px 10px 1px 0;
    list-style: none;
}

.rui-selectize .rui-selectize-github .selectize-dropdown > .selectize-dropdown-content > div.selected, .rui-selectize .rui-selectize-github .selectize-dropdown > .selectize-dropdown-content > div.active {
    background-color: #f8f9fa;
}

/*---------------------------
    Plugin Peity (chart)
 ----------------------------*/
.peity {
    background-color: #f8f9fa;
    border-radius: .2rem;
    -webkit-transition: background-color .1s ease-in-out;
    -o-transition: background-color .1s ease-in-out;
    transition: background-color .1s ease-in-out;
}

.peity:hover, .peity.hover {
    background-color: #f3f4f7;
}

.peity-pie + .peity {
    background-color: transparent;
}

.peity-pie + .peity:hover, .peity-pie + .peity.hover {
    background-color: transparent;
}

.peity-light + .peity {
    background-color: transparent;
}

.peity-light + .peity:hover, .peity-light + .peity.hover {
    background-color: transparent;
}

.peity-line,
.peity-pie,
.peity-donut,
.peity-bar {
    display: none;
}

.peity-brand + .peity > polygon,
.peity-brand + .peity > rect {
    fill: rgba(114, 94, 195, 0.8);
    stroke: transparent;
    -webkit-transition: fill .1s ease-in-out;
    -o-transition: fill .1s ease-in-out;
    transition: fill .1s ease-in-out;
}

.peity-brand + .peity:hover > polygon,
.peity-brand + .peity:hover > rect, .peity-brand + .peity.hover > polygon,
.peity-brand + .peity.hover > rect {
    fill: #725ec3;
}

.peity-primary + .peity > polygon,
.peity-primary + .peity > rect {
    fill: rgba(0, 123, 255, 0.8);
    stroke: transparent;
    -webkit-transition: fill .1s ease-in-out;
    -o-transition: fill .1s ease-in-out;
    transition: fill .1s ease-in-out;
}

.peity-primary + .peity:hover > polygon,
.peity-primary + .peity:hover > rect, .peity-primary + .peity.hover > polygon,
.peity-primary + .peity.hover > rect {
    fill: #007bff;
}

.peity-secondary + .peity > polygon,
.peity-secondary + .peity > rect {
    fill: rgba(108, 117, 125, 0.8);
    stroke: transparent;
    -webkit-transition: fill .1s ease-in-out;
    -o-transition: fill .1s ease-in-out;
    transition: fill .1s ease-in-out;
}

.peity-secondary + .peity:hover > polygon,
.peity-secondary + .peity:hover > rect, .peity-secondary + .peity.hover > polygon,
.peity-secondary + .peity.hover > rect {
    fill: #6c757d;
}

.peity-success + .peity > polygon,
.peity-success + .peity > rect {
    fill: rgba(47, 199, 135, 0.8);
    stroke: transparent;
    -webkit-transition: fill .1s ease-in-out;
    -o-transition: fill .1s ease-in-out;
    transition: fill .1s ease-in-out;
}

.peity-success + .peity:hover > polygon,
.peity-success + .peity:hover > rect, .peity-success + .peity.hover > polygon,
.peity-success + .peity.hover > rect {
    fill: #2fc787;
}

.peity-danger + .peity > polygon,
.peity-danger + .peity > rect {
    fill: rgba(239, 81, 100, 0.8);
    stroke: transparent;
    -webkit-transition: fill .1s ease-in-out;
    -o-transition: fill .1s ease-in-out;
    transition: fill .1s ease-in-out;
}

.peity-danger + .peity:hover > polygon,
.peity-danger + .peity:hover > rect, .peity-danger + .peity.hover > polygon,
.peity-danger + .peity.hover > rect {
    fill: #ef5164;
}

.peity-warning + .peity > polygon,
.peity-warning + .peity > rect {
    fill: rgba(253, 191, 33, 0.8);
    stroke: transparent;
    -webkit-transition: fill .1s ease-in-out;
    -o-transition: fill .1s ease-in-out;
    transition: fill .1s ease-in-out;
}

.peity-warning + .peity:hover > polygon,
.peity-warning + .peity:hover > rect, .peity-warning + .peity.hover > polygon,
.peity-warning + .peity.hover > rect {
    fill: #fdbf21;
}

.peity-info + .peity > polygon,
.peity-info + .peity > rect {
    fill: rgba(50, 191, 200, 0.8);
    stroke: transparent;
    -webkit-transition: fill .1s ease-in-out;
    -o-transition: fill .1s ease-in-out;
    transition: fill .1s ease-in-out;
}

.peity-info + .peity:hover > polygon,
.peity-info + .peity:hover > rect, .peity-info + .peity.hover > polygon,
.peity-info + .peity.hover > rect {
    fill: #32bfc8;
}

.peity-light + .peity > polygon,
.peity-light + .peity > rect {
    fill: rgba(248, 249, 250, 0.8);
    stroke: transparent;
    -webkit-transition: fill .1s ease-in-out;
    -o-transition: fill .1s ease-in-out;
    transition: fill .1s ease-in-out;
}

.peity-light + .peity:hover > polygon,
.peity-light + .peity:hover > rect, .peity-light + .peity.hover > polygon,
.peity-light + .peity.hover > rect {
    fill: #f8f9fa;
}

.peity-dark + .peity > polygon,
.peity-dark + .peity > rect {
    fill: rgba(57, 63, 73, 0.8);
    stroke: transparent;
    -webkit-transition: fill .1s ease-in-out;
    -o-transition: fill .1s ease-in-out;
    transition: fill .1s ease-in-out;
}

.peity-dark + .peity:hover > polygon,
.peity-dark + .peity:hover > rect, .peity-dark + .peity.hover > polygon,
.peity-dark + .peity.hover > rect {
    fill: #393f49;
}

p > .peity {
    margin-top: -2px;
}

/*---------------------------
    Plugin eCharts
 ----------------------------*/
.echarts {
    width: calc(100% + 20px);
    min-height: 320px;
    margin-left: -10px;
}

/*---------------------------
    Plugin Flot (chart)
 ----------------------------*/
.flot-svg .flot-x-axis,
.flot-svg .flot-y-axis {
    font-family: "Open Sans", sans-serif;
    font-size: 12px;
    fill: #bcbec0;
}

.flot-line,
.flot-bar,
.flot-pie,
.flot-realtime {
    min-height: 320px;
}

.flot-pie .flot-overlay:hover ~ .pieLabel {
    opacity: 1;
}

.flot-pie .pieLabel {
    opacity: 0;
    -webkit-transition: opacity .1s ease-in-out;
    -o-transition: opacity .1s ease-in-out;
    transition: opacity .1s ease-in-out;
}

.flot-pie .pieLabel:hover {
    opacity: 1;
}

/*---------------------------
    Plugin Chartjs
 ----------------------------*/
.rui-chartjs-doughnut {
    height: 80px;
}

.rui-chartjs-container {
    position: relative;
    display: -webkit-box;
    display: -webkit-flex;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-align: center;
    -webkit-align-items: center;
        -ms-flex-align: center;
            align-items: center;
    -webkit-box-pack: center;
    -webkit-justify-content: center;
        -ms-flex-pack: center;
            justify-content: center;
}

.rui-chartjs-container > .rui-chartjs-doughnut:not(:first-of-type) {
    position: absolute;
}

/*---------------------------
    Plugin Highlight
 ----------------------------*/
.hljs {
    width: 100%;
    padding: 0;
    margin-top: -3px;
    margin-bottom: -3px;
    color: #4b515b;
    background: none;
}

.hljs-string {
    color: #ef5164;
}

.hljs-literal {
    color: #2fc787;
}

.hljs-number {
    color: #007bff;
}

/*---------------------------
    Plugin Quill
 ----------------------------*/
.rui-quill .rui-quill-toolbar {
    display: none;
    padding: 24px 21px 18px;
    font-family: "Nunito Sans", sans-serif;
    border-color: #e6ecf0;
    border-bottom: 0;
    border-radius: .25rem .25rem 0 0;
}

.rui-quill .rui-quill-toolbar.ql-toolbar {
    display: block;
}

.rui-quill .rui-quill-toolbar .ql-stroke {
    stroke: #bcbec0;
    -webkit-transition: stroke .15s ease-in-out;
    -o-transition: stroke .15s ease-in-out;
    transition: stroke .15s ease-in-out;
}

.rui-quill .rui-quill-toolbar .ql-fill {
    fill: #bcbec0;
    -webkit-transition: fill .15s ease-in-out;
    -o-transition: fill .15s ease-in-out;
    transition: fill .15s ease-in-out;
}

.rui-quill .rui-quill-toolbar button:hover .ql-stroke {
    stroke: #4b515b;
}

.rui-quill .rui-quill-toolbar button:hover .ql-fill {
    fill: #4b515b;
}

.rui-quill .rui-quill-toolbar button.ql-active .ql-stroke {
    stroke: #725ec3;
}

.rui-quill .rui-quill-toolbar button.ql-active .ql-fill {
    fill: #725ec3;
}

.rui-quill .rui-quill-toolbar .ql-picker {
    width: auto;
    padding-right: 25px;
}

.rui-quill .rui-quill-toolbar .ql-picker svg.rui-icon {
    width: 16px;
    margin-top: -8px;
    stroke: #bcbec0;
    -webkit-transition: stroke .15s ease-in-out;
    -o-transition: stroke .15s ease-in-out;
    transition: stroke .15s ease-in-out;
}

.rui-quill .rui-quill-toolbar .ql-picker .ql-picker-label {
    display: -webkit-inline-box;
    display: -webkit-inline-flex;
    display: -ms-inline-flexbox;
    display: inline-flex;
    -webkit-box-align: center;
    -webkit-align-items: center;
        -ms-flex-align: center;
            align-items: center;
    color: #bcbec0;
    border: 0;
    -webkit-transition: color .15s ease-in-out;
    -o-transition: color .15s ease-in-out;
    transition: color .15s ease-in-out;
}

.rui-quill .rui-quill-toolbar .ql-picker .ql-picker-label > svg {
    display: none;
}

.rui-quill .rui-quill-toolbar .ql-picker .ql-picker-label::after {
    content: "";
    position: absolute;
    display: block;
    top: 0;
    right: 0;
    bottom: 0;
    width: 30px;
    margin-right: -30px;
    z-index: 1;
}

.rui-quill .rui-quill-toolbar .ql-picker .ql-picker-label:focus {
    outline: none;
}

.rui-quill .rui-quill-toolbar .ql-picker .ql-picker-label:hover {
    color: #4b515b;
}

.rui-quill .rui-quill-toolbar .ql-picker .ql-picker-options {
    padding: 15px 19px;
    margin-top: 3px;
    margin-left: -13px;
    border: 1px solid #f3f4f7;
    border-radius: .25rem;
    -webkit-box-shadow: 0 3px 10px rgba(0, 0, 0, 0.03);
            box-shadow: 0 3px 10px rgba(0, 0, 0, 0.03);
}

.rui-quill .rui-quill-toolbar .ql-picker .ql-picker-options .ql-picker-item {
    padding: 2px 0;
    font-weight: 400;
    color: #bcbec0;
    -webkit-transition: color .15s ease-in-out;
    -o-transition: color .15s ease-in-out;
    transition: color .15s ease-in-out;
}

.rui-quill .rui-quill-toolbar .ql-picker .ql-picker-options .ql-picker-item[data-value="1"] {
    margin-top: -5px;
}

.rui-quill .rui-quill-toolbar .ql-picker .ql-picker-options .ql-picker-item[data-value="1"]::before {
    font-size: 1.715rem;
}

.rui-quill .rui-quill-toolbar .ql-picker .ql-picker-options .ql-picker-item[data-value="2"] {
    margin-top: -1px;
}

.rui-quill .rui-quill-toolbar .ql-picker .ql-picker-options .ql-picker-item[data-value="2"]::before {
    font-size: 1.43rem;
}

.rui-quill .rui-quill-toolbar .ql-picker .ql-picker-options .ql-picker-item[data-value="3"] {
    margin-top: 2px;
}

.rui-quill .rui-quill-toolbar .ql-picker .ql-picker-options .ql-picker-item[data-value="3"]::before {
    font-size: 1.29rem;
}

.rui-quill .rui-quill-toolbar .ql-picker .ql-picker-options .ql-picker-item:last-child {
    margin-top: 3px;
}

.rui-quill .rui-quill-toolbar .ql-picker .ql-picker-options .ql-picker-item:hover {
    color: #4b515b;
}

.rui-quill .rui-quill-toolbar .ql-picker .ql-picker-options .ql-picker-item:focus {
    outline: none;
}

.rui-quill .rui-quill-toolbar .ql-picker:focus {
    outline: none;
}

.rui-quill .rui-quill-toolbar .ql-picker.ql-expanded svg.rui-icon {
    stroke: #4b515b;
}

.rui-quill .rui-quill-toolbar .ql-picker.ql-expanded .ql-picker-label {
    color: #4b515b;
}

.rui-quill .rui-quill-toolbar .ql-picker.ql-expanded .ql-picker-options {
    border-color: #e6ecf0;
}

.rui-quill .rui-quill-editor {
    min-height: 200px;
    border-color: #e6ecf0;
    border-radius: 0 0 .25rem .25rem;
}

.rui-quill .rui-quill-editor .ql-editor {
    min-height: 200px;
    padding: 2px 29px 25px;
    font-size: 1rem;
}

.rui-quill .rui-quill-editor .ql-editor h1 {
    font-size: 1.846rem;
}

.rui-quill .rui-quill-editor .ql-editor h2 {
    font-size: 1.538rem;
}

.rui-quill .rui-quill-editor .ql-editor h3 {
    font-size: 1.385rem;
}

.rui-quill .rui-quill-editor .ql-editor h4 {
    font-size: 1.231rem;
}

.rui-quill .rui-quill-editor .ql-editor h5 {
    font-size: 1.077rem;
}

.rui-quill .rui-quill-editor .ql-editor h6 {
    font-size: .923rem;
}

/*---------------------------
    Plugin Dropzone
 ----------------------------*/
.rui-dropzone {
    position: relative;
    display: -webkit-box;
    display: -webkit-flex;
    display: -ms-flexbox;
    display: flex;
    -webkit-flex-wrap: wrap;
        -ms-flex-wrap: wrap;
            flex-wrap: wrap;
    padding: 30px;
    border: 1px solid #e6ecf0;
    border-radius: .25rem;
    -webkit-transition: border-color .2s ease-in-out;
    -o-transition: border-color .2s ease-in-out;
    transition: border-color .2s ease-in-out;
}

.rui-dropzone .rui-dropzone-images {
    display: none;
}

.rui-dropzone.dz-started {
    padding: 15px;
}

.rui-dropzone:hover {
    border-color: #dce5ea;
}

.rui-dropzone.dz-drag-hover {
    border-color: #9383d1;
}

.rui-dropzone.dz-drag-hover .dz-message {
    opacity: 1;
}

.rui-dropzone > .rui-dropzone-remove-icon {
    display: none;
}

.rui-dropzone .rui-dropzone-input {
    display: none;
}

.rui-dropzone .dz-message {
    display: -webkit-box;
    display: -webkit-flex;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-orient: vertical;
    -webkit-box-direction: normal;
    -webkit-flex-direction: column;
        -ms-flex-direction: column;
            flex-direction: column;
    -webkit-box-align: center;
    -webkit-align-items: center;
        -ms-flex-align: center;
            align-items: center;
    -webkit-box-pack: center;
    -webkit-justify-content: center;
        -ms-flex-pack: center;
            justify-content: center;
    width: 100%;
    min-height: 250px;
    margin: -5px 0;
}

.rui-dropzone .rui-dropzone-icon {
    margin-bottom: 20px;
    color: #d7d9e0;
}

.rui-dropzone .rui-dropzone-icon > .rui-icon {
    width: 50px;
    height: 50px;
}

.rui-dropzone .rui-dropzone-text {
    font-size: 1.29rem;
    color: #6c757d;
}

@media (max-width: 575.98px) {
    .rui-dropzone {
        min-height: 100px;
    }
    .rui-dropzone .rui-dropzone-icon > .rui-icon {
        width: 40px;
        height: 40px;
    }
    .rui-dropzone .rui-dropzone-text {
        font-size: 1rem;
    }
}

.rui-dropzone .dz-preview,
.rui-dropzone .dz-preview.dz-file-preview {
    -webkit-box-flex: 0;
    -webkit-flex: 0 0 16.6%;
        -ms-flex: 0 0 16.6%;
            flex: 0 0 16.6%;
    max-width: 16.6%;
    padding: 15px;
    margin: 0;
}

@media (max-width: 1199.98px) {
    .rui-dropzone .dz-preview,
    .rui-dropzone .dz-preview.dz-file-preview {
        -webkit-box-flex: 0;
        -webkit-flex: 0 0 20%;
            -ms-flex: 0 0 20%;
                flex: 0 0 20%;
        max-width: 20%;
    }
}

@media (max-width: 767.98px) {
    .rui-dropzone .dz-preview,
    .rui-dropzone .dz-preview.dz-file-preview {
        -webkit-box-flex: 0;
        -webkit-flex: 0 0 33.3%;
            -ms-flex: 0 0 33.3%;
                flex: 0 0 33.3%;
        max-width: 33.3%;
    }
}

@media (max-width: 575.98px) {
    .rui-dropzone .dz-preview,
    .rui-dropzone .dz-preview.dz-file-preview {
        -webkit-box-flex: 0;
        -webkit-flex: 0 0 50%;
            -ms-flex: 0 0 50%;
                flex: 0 0 50%;
        max-width: 50%;
    }
}

.rui-dropzone .dz-preview .dz-remove,
.rui-dropzone .dz-preview.dz-file-preview .dz-remove {
    position: absolute;
    display: -webkit-box;
    display: -webkit-flex;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-align: center;
    -webkit-align-items: center;
        -ms-flex-align: center;
            align-items: center;
    -webkit-box-pack: center;
    -webkit-justify-content: center;
        -ms-flex-pack: center;
            justify-content: center;
    top: 25px;
    right: 25px;
    width: 16px;
    height: 16px;
    color: #bcbec0;
    background-color: #fff;
    border-radius: .2rem;
    opacity: 0;
    -webkit-transition: opacity .2s ease-in-out, color .1s ease-in-out;
    -o-transition: opacity .2s ease-in-out, color .1s ease-in-out;
    transition: opacity .2s ease-in-out, color .1s ease-in-out;
    z-index: 100;
}

.rui-dropzone .dz-preview .dz-remove > .rui-icon,
.rui-dropzone .dz-preview.dz-file-preview .dz-remove > .rui-icon {
    width: 14px;
    height: 14px;
    cursor: pointer;
}

.rui-dropzone .dz-preview .dz-remove > .rui-icon > line,
.rui-dropzone .dz-preview.dz-file-preview .dz-remove > .rui-icon > line {
    cursor: pointer;
}

.rui-dropzone .dz-preview .dz-remove:hover,
.rui-dropzone .dz-preview.dz-file-preview .dz-remove:hover {
    color: #6c757d;
}

.rui-dropzone .dz-preview.dz-error .dz-image::before,
.rui-dropzone .dz-preview.dz-file-preview.dz-error .dz-image::before {
    content: "";
}

.rui-dropzone .dz-preview .dz-image:not(.rui-dropzone-image),
.rui-dropzone .dz-preview.dz-file-preview .dz-image:not(.rui-dropzone-image) {
    width: auto;
    height: auto;
    background: none;
    background-color: #f3f4f7;
    border-radius: .25rem;
}

.rui-dropzone .dz-preview .dz-image:not(.rui-dropzone-image)::before,
.rui-dropzone .dz-preview.dz-file-preview .dz-image:not(.rui-dropzone-image)::before {
    content: "";
    display: block;
    margin-top: 100%;
}

.rui-dropzone .dz-preview .dz-image:not(.rui-dropzone-image) > img,
.rui-dropzone .dz-preview.dz-file-preview .dz-image:not(.rui-dropzone-image) > img {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    font-family: "object-fit: cover";
    -o-object-fit: cover;
       object-fit: cover;
}

.rui-dropzone .dz-preview .rui-dropzone-image,
.rui-dropzone .dz-preview.dz-file-preview .rui-dropzone-image {
    display: -webkit-box;
    display: -webkit-flex;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-align: center;
    -webkit-align-items: center;
        -ms-flex-align: center;
            align-items: center;
    -webkit-box-pack: center;
    -webkit-justify-content: center;
        -ms-flex-pack: center;
            justify-content: center;
    width: auto;
    height: auto;
    background: none;
    background-color: #fff;
    border: 1px solid #e6ecf0;
    border-radius: .25rem;
    -webkit-transition: background-color .15s ease-in-out, border-color .15s ease-in-out;
    -o-transition: background-color .15s ease-in-out, border-color .15s ease-in-out;
    transition: background-color .15s ease-in-out, border-color .15s ease-in-out;
}

.rui-dropzone .dz-preview .rui-dropzone-image::before,
.rui-dropzone .dz-preview.dz-file-preview .rui-dropzone-image::before {
    content: "";
    display: block;
    margin-top: 100%;
}

.rui-dropzone .dz-preview .rui-dropzone-image > img,
.rui-dropzone .dz-preview.dz-file-preview .rui-dropzone-image > img {
    position: absolute;
}

.rui-dropzone .dz-preview .dz-details,
.rui-dropzone .dz-preview.dz-file-preview .dz-details {
    position: relative;
    display: -webkit-box;
    display: -webkit-flex;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-orient: vertical;
    -webkit-box-direction: normal;
    -webkit-flex-direction: column;
        -ms-flex-direction: column;
            flex-direction: column;
    padding: 0;
    opacity: 1;
}

.rui-dropzone .dz-preview .dz-details .dz-size,
.rui-dropzone .dz-preview.dz-file-preview .dz-details .dz-size {
    display: -webkit-box;
    display: -webkit-flex;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-ordinal-group: 2;
    -webkit-order: 1;
        -ms-flex-order: 1;
            order: 1;
    margin: 4px 0 -5px;
    font-size: 11px;
    color: #bcbec0;
}

.rui-dropzone .dz-preview .dz-details .dz-size > span,
.rui-dropzone .dz-preview.dz-file-preview .dz-details .dz-size > span {
    padding: 0;
    background-color: transparent;
}

.rui-dropzone .dz-preview .dz-details .dz-size strong,
.rui-dropzone .dz-preview.dz-file-preview .dz-details .dz-size strong {
    font-weight: 400;
}

.rui-dropzone .dz-preview .dz-details .dz-filename,
.rui-dropzone .dz-preview.dz-file-preview .dz-details .dz-filename {
    display: -webkit-box;
    display: -webkit-flex;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-ordinal-group: 1;
    -webkit-order: 0;
        -ms-flex-order: 0;
            order: 0;
    margin-top: 10px;
    overflow: hidden;
    color: #6c757d;
}

.rui-dropzone .dz-preview .dz-details .dz-filename > span,
.rui-dropzone .dz-preview.dz-file-preview .dz-details .dz-filename > span {
    padding: 0;
}

.rui-dropzone .dz-preview .dz-details .dz-filename:not(:hover) span,
.rui-dropzone .dz-preview.dz-file-preview .dz-details .dz-filename:not(:hover) span {
    border: 0;
}

.rui-dropzone .dz-preview .dz-details .dz-filename:hover span,
.rui-dropzone .dz-preview.dz-file-preview .dz-details .dz-filename:hover span {
    border: 0;
}

.rui-dropzone .dz-preview .dz-progress,
.rui-dropzone .dz-preview.dz-file-preview .dz-progress {
    position: absolute;
    top: 15px;
    right: 15px;
    left: 15px;
    width: auto;
    height: auto;
    margin: 0;
    background-color: rgba(255, 255, 255, 0.95);
    border-radius: .25rem;
}

.rui-dropzone .dz-preview .dz-progress::before,
.rui-dropzone .dz-preview.dz-file-preview .dz-progress::before {
    content: "";
    display: block;
    margin-top: 100%;
}

.rui-dropzone .dz-preview .dz-progress .dz-upload,
.rui-dropzone .dz-preview.dz-file-preview .dz-progress .dz-upload {
    top: 50%;
    right: 10px;
    bottom: auto;
    left: 10px;
    max-width: calc(100% - 20px);
    height: 6px;
    margin-top: -3px;
    background: none;
    background-color: #393f49;
    border-radius: 3px;
}

.rui-dropzone .dz-preview .dz-error-message,
.rui-dropzone .dz-preview.dz-file-preview .dz-error-message {
    display: -webkit-box;
    display: -webkit-flex;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-align: start;
    -webkit-align-items: flex-start;
        -ms-flex-align: start;
            align-items: flex-start;
    top: auto;
    right: 0;
    bottom: 0;
    left: 0;
    width: auto;
    padding: 0 0 0 15px;
    overflow-x: hidden;
    font-size: 12px;
    background: #ef5164;
    border-radius: .2rem;
}

.rui-dropzone .dz-preview .dz-error-message > span,
.rui-dropzone .dz-preview.dz-file-preview .dz-error-message > span {
    display: block;
    max-height: 57px;
    padding: 11px 32px 10px 0;
    margin-right: -17px;
    overflow-y: scroll;
}

.rui-dropzone .dz-preview .dz-error-message::after,
.rui-dropzone .dz-preview.dz-file-preview .dz-error-message::after {
    display: none;
}

@-webkit-keyframes emergence {
    0% {
        opacity: 1;
    }
    50% {
        opacity: 1;
    }
    100% {
        opacity: 0;
    }
}

@keyframes emergence {
    0% {
        opacity: 1;
    }
    50% {
        opacity: 1;
    }
    100% {
        opacity: 0;
    }
}

.rui-dropzone .dz-preview .dz-error-mark,
.rui-dropzone .dz-preview .dz-success-mark,
.rui-dropzone .dz-preview.dz-error .dz-error-mark,
.rui-dropzone .dz-preview.dz-success .dz-success-mark,
.rui-dropzone .dz-preview.dz-file-preview .dz-error-mark,
.rui-dropzone .dz-preview.dz-file-preview .dz-success-mark,
.rui-dropzone .dz-preview.dz-file-preview.dz-error .dz-error-mark,
.rui-dropzone .dz-preview.dz-file-preview.dz-success .dz-success-mark {
    top: 15px;
    right: 15px;
    left: 15px;
    margin: 0;
    border-radius: .25rem;
    opacity: 0;
    -webkit-animation: none;
            animation: none;
    z-index: 99;
}

.rui-dropzone .dz-preview .dz-error-mark::before,
.rui-dropzone .dz-preview .dz-success-mark::before,
.rui-dropzone .dz-preview.dz-error .dz-error-mark::before,
.rui-dropzone .dz-preview.dz-success .dz-success-mark::before,
.rui-dropzone .dz-preview.dz-file-preview .dz-error-mark::before,
.rui-dropzone .dz-preview.dz-file-preview .dz-success-mark::before,
.rui-dropzone .dz-preview.dz-file-preview.dz-error .dz-error-mark::before,
.rui-dropzone .dz-preview.dz-file-preview.dz-success .dz-success-mark::before {
    content: "";
    display: block;
    margin-top: 100%;
}

.rui-dropzone .dz-preview .dz-error-mark > svg,
.rui-dropzone .dz-preview .dz-success-mark > svg,
.rui-dropzone .dz-preview.dz-error .dz-error-mark > svg,
.rui-dropzone .dz-preview.dz-success .dz-success-mark > svg,
.rui-dropzone .dz-preview.dz-file-preview .dz-error-mark > svg,
.rui-dropzone .dz-preview.dz-file-preview .dz-success-mark > svg,
.rui-dropzone .dz-preview.dz-file-preview.dz-error .dz-error-mark > svg,
.rui-dropzone .dz-preview.dz-file-preview.dz-success .dz-success-mark > svg {
    display: none;
}

.rui-dropzone .dz-preview .dz-error-mark,
.rui-dropzone .dz-preview.dz-error .dz-error-mark,
.rui-dropzone .dz-preview.dz-file-preview .dz-error-mark,
.rui-dropzone .dz-preview.dz-file-preview.dz-error .dz-error-mark {
    background-color: rgba(239, 81, 100, 0.2);
}

.rui-dropzone .dz-preview.dz-error .dz-error-mark,
.rui-dropzone .dz-preview.dz-file-preview.dz-error .dz-error-mark {
    opacity: 1;
}

.rui-dropzone .dz-preview .dz-success-mark,
.rui-dropzone .dz-preview.dz-file-preview .dz-success-mark {
    background-color: rgba(47, 199, 135, 0.2);
}

.rui-dropzone .dz-preview.dz-success .dz-success-mark,
.rui-dropzone .dz-preview.dz-file-preview.dz-success .dz-success-mark {
    -webkit-animation: emergence 1s ease-in-out;
            animation: emergence 1s ease-in-out;
}

.rui-dropzone .dz-preview:hover .dz-remove,
.rui-dropzone .dz-preview.dz-file-preview:hover .dz-remove {
    opacity: 1;
}

.rui-dropzone .dz-preview:hover .dz-image img,
.rui-dropzone .dz-preview.dz-file-preview:hover .dz-image img {
    -webkit-filter: none;
            filter: none;
    -webkit-transform: none;
        -ms-transform: none;
            transform: none;
}

.rui-dropzone .dz-preview:hover .rui-dropzone-image,
.rui-dropzone .dz-preview.dz-file-preview:hover .rui-dropzone-image {
    border-color: #e6ecf0;
}

