/* PLACE YOUR CUSTOM STYLES IN THIS FILE */
:root {
    --success-bg-color: #2fc787 !important;
    --success-bg-color__focus: #259e6b !important;
    --success-border-color: #25a573 !important;
}

.container {
    max-width: 1280px;
}

.list-group-item-action.active {
    background-color: var(--success-bg-color);
    border-color: var(--success-border-color);
}

.show__desktop {
    display: block;
}

.show__mobile {
    display: none;
}

.dashboard-nav__wrapper {
    align-items: center;
    justify-content: space-between;
    padding: 10px 0;
    display: flex;
    position: relative;
}

.mobile-dropdown__menu {
    position: absolute;
    width: 100%;
    left: 0;
    top: 69px;
    border-radius: 0;
    box-shadow: 0 12px 11px 0 rgb(228 230 229);
}

.mobile-dropdown__menu .list-group .list-group-item {
    border-radius: 0;
}

.dashboard-main-logo {
    margin-right: 40px;
    margin-left: 15px;
}

.dashboard-nav__left {
    justify-content: flex-start;
    align-items: center;
}

.dashboard-nav__right li:last-of-type {
    display: none;
}

.burger-menu__wrapper {
    background: #eef1f2;
    padding: 5px 5px 7px 5px;
    border-radius: 3px;
    border: 1px solid #d7e1e8;
}

.burger-menu__wrapper:hover, .burger-menu__wrapper:focus {
    background: #d7e1e8;
    outline: none;
}

.main-navbar {
    border-bottom: 1px solid #e6ecf0;
}

.preloader {
    display: flex;
    align-items: center;
    justify-content: center;
    margin: 20px 0;
}

.btn-group .btn:focus {
    outline: none !important;
    box-shadow: none;
}

.group-wrapper {
    min-height: 50px;
}

.links-list__wrapper {
    width: 100%;
    overflow: scroll;
}

.Toastify__toast--success {
    background: var(--success-bg-color) !important;
}
.Toastify__progress-bar {
    background: var(--success-border-color);
}
.popup-content {
    margin: auto;
    background: none;
    border: none;
    width: 40%;
    padding: 0;
}
.popup-overlay  {
    z-index: 1002 !important;
}
.CalendarDay__selected_span {
    background: var(--success-bg-color);
    border: 1px double var(--success-border-color);
    color: #fff;
}

.CalendarDay__selected_span:hover {
    background: var(--success-border-color);
    border: 1px double var(--success-border-color);
    color: #fff;
}

.CalendarDay__selected, .CalendarDay__selected:active, .CalendarDay__selected:hover {
    background: var(--success-bg-color__focus);
    border: 1px double var(--success-border-color);
    color: #fff;
}

.CalendarDay__hovered_span, .CalendarDay__hovered_span:hover {
    background: rgba(47, 199, 135, 0.51);
    border: 1px double var(--success-border-color);
}
.DateRangePickerInput__withBorder {
    height: 50px;
}

.invalid_input {
    background-color: #fef9fa;
    border-color: #ef5164;
    padding-right: calc(1.5em + .75rem);
    background-repeat: no-repeat;
    background-position: right calc(.375em + .1875rem) center;
    background-size: calc(.75em + .375rem) calc(.75em + .375rem);
}
.invalid-feedback {
    display: block;
}

.progress-meta {
    display: flex;
    justify-content: space-between;
    align-items: center;
}

.toast-component__wrapper {
    margin-top: 50px;
    min-width: 250px;
}

.auth-form__wrapper {
    display: flex;
    justify-content: center;
    align-items: center;
    height: 100vh;
    max-width: 600px;
}

.preloader__wrapper {
    position: absolute;
    right: 35px;
}

.input-preloader__wrapper {
    position: absolute;
    top: 20px;
    right: 30px;
}

@media screen and (max-width: 767px) {
    .dashboard-nav__right li {
        display: none;
    }
}

@media screen and (max-width: 540px) {
    .chart-tabs__wrapper {
        margin-top: 20px;
        margin-right: 31px;
    }
    .card-body {
        padding: 15px 10px;
    }
    .sm-mt__10 {
        margin-top: 10px;
    }
    .sm-mt__20 {
        margin-top: 20px;
    }
    .show__desktop {
        display: none;
    }

    .show__mobile {
        display: block;
    }

    .show__mobile .card-body {
        padding: 15px 10px;
    }

    .show__mobile .link-tools__wrapper {
        background: #e6ecef;
        border-top-right-radius: 0.25rem;
        border-top-left-radius: 0.25rem;
        padding: 10px 15px 10px 15px;
        margin: 15px -10px -15px -10px
    }
    .show__mobile .link-tools {
        display: flex;
        justify-content: space-between;
        align-items: center;
    }
    .show__mobile .creation-date {
        display: flex;
        justify-content: space-between;
        align-items: center;
    }

    .initial_url--short {
        max-height: 25px;
        overflow: hidden;
        display: block;
    }
    .dashboard-nav__right li {
        display: none;
    }

    .dashboard-nav__right li:last-of-type {
        display: block;
    }

    .dashboard-nav__left li {
        display: none;
    }

    .dashboard-nav__left li:first-of-type {
        display: block;
    }

}


.ais-Hits-list {
    list-style: none;
    padding: 0;
    margin-bottom: 0;
}

.search-results__main{
    position: absolute;
    width: calc(100% - 60px);
    z-index: 100;
    padding: 0;
    background: white;
    border-radius: 5px;
    margin-top: 0;
    border: 1px solid #e6ecef;
}
.search-results__wrapper {
    border-top: none !important;
    border-left: none !important;
    border-right: none !important;
    border-bottom: none !important;
    border-radius: 0;
}
.search-results__header {
    display: flex;
    justify-content: space-between;
    align-items: center;
    background-color: #2ec787;
    color: #1e2126;
}

.ais-SearchBox-form {
    display: flex;
    justify-content: space-between;
    align-items: stretch;
}
.ais-SearchBox-submit, .ais-SearchBox-reset {
    position: relative;
    display: -webkit-inline-flex;
    display: -ms-inline-flexbox;
    display: inline-flex;
    -webkit-box-align: stretch;
    -webkit-align-items: stretch;
    -ms-flex-align: stretch;
    align-items: stretch;
    padding: 9px 15px;
    font-size: 11px;
    font-weight: 700;
    text-transform: uppercase;
    border-radius: 2px;
    color: #fff !important;
    background-color: #725ec3;
    border-color: #725ec3;
    border: none !important;
    margin-right: 5px;
    margin-left: 5px;
    flex: 1;
}
.ais-SearchBox-input {
    margin-right: 10px;
    display: block;
    width: 100%;
    height: calc(1.5em + 0.75rem + 2px);
    padding: 0.375rem 0.75rem;
    font-size: 1rem;
    font-weight: 400;
    line-height: 1.5;
    color: #495057;
    background-color: #fff;
    background-clip: padding-box;
    border: 1px solid #ced4da;
    border-radius: 0.25rem;
    transition: border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;
}

.ais-SearchBox-input:focus {
    outline: none !important;
    background-color: #fbfcfc;
    border-color: rgba(114,94,195,.6);
    -webkit-box-shadow: 0 0 0 0.2rem rgb(114 94 195 / 20%);
    box-shadow: 0 0 0 0.2rem rgb(114 94 195 / 20%);
}
.search-form__wrapper {
    display: flex;
    justify-content: space-between;
    align-items: stretch;
}
.search-form__input {
    margin-right: 20px;
}
.search-form__btn {
    display: flex;
    justify-content: center;
    align-items: center;
}
.exit__link:hover{
    cursor: pointer;
}
.previous_wrapper {
    display: none;
}
.page-link:hover {
    cursor: pointer;
}
