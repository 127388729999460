.form-control {
  padding: 20px 15px !important;
}
.create-link-form {
  .custom-select {
    min-height: 42px;
  }
  .btn {
    padding: 10px 15px 7px 15px;
  }
  .btn-round {
    padding: 10px;
    margin-right: 20px;
  }
  .row {
    margin-top: 20px;
    &:first-of-type {
      margin-top: 0;
    }
  }
  .card-body {

  }
  .form-group-wrapper {
    background: rgba(250, 249, 255, 0.2);
    border: 1px solid #faf9ff;
    padding: 20px 20px 30px 20px;
  }
}
